import React from 'react'
import { FaqEquipmentSec } from './faqEquipmentSec'

export const FaqEquipment = () => {
  return (
    <>
    <FaqEquipmentSec />
    </>
  )
}
