import React from 'react'
import { NotfoundSec } from './notfoundSec'

export const Notfound = () => {
  return (
    <>
    <NotfoundSec />
    </>
  )
}
