import React from 'react'
import { Banner } from './banner'
import { WikiPortalSec } from './wikiPortalSec'

export const WikiPortal = () => {
  return (
    <>
    <Banner />
    <WikiPortalSec />
    </>
  )
}
