import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const AlertSection = () => {
  return (
    <>
    <section className='alertbx'>
        <Container>
            <Row>
                <Col xs lg="12" className='text-left'>
                <p>As part of your Mastercard program, you receive the benefit of text alerts and notifications to protect yourself and your company from fraud. The Alerts and Notifications (A&N) program was designed with the business traveler in mind. Staying aware of fraudulent activity and having the means to combat it helps travelers stay safe and secure while on the road or in the air.</p>
                <h4>How does it work?</h4>
                <p className='fw-normal'>Nowadays, people no longer travel without a mobile device or a credit card. Unfortunately, one or the other may be compromised. Whether through a data breach, system/network attack, or card skimming, credit cards have a high chance of being hit with identity theft. The good news is that Comdata’s A&N program allows cardholders to continue using their card, even if it has been compromised, with help from their mobile device. </p>
                <p className='fw-normal'>If our risk system identifies a suspicious transaction, the cardholder will receive an alert via text message. Your cardholder can reply confirming whether or not the transaction was fraud. If they confirm fraud, the card will go into a locked-down state and each additional transaction will be declined. However, the cardholder will receive a text message with each declined transaction allowing them to override the declined authorization. If overridden, they can swipe their card again and continue the transaction as normal. If the cardholder receives a declined alert they don’t recognize, there’s no need to respond as the transaction was not authorized. </p>
                <p className='fw-normal'>In addition, cardholders can also receive a text message if their card is declined for non-fraudulent reasons, such as a PIN/ID number being entered incorrectly. In these cases, the cardholder can correct the error and rerun the transaction.</p>
                </Col>
            </Row>
        </Container>
    </section>

    {/* <section className='alertsec'>
      <Container>
        <Row>
          <div className='col-md-6 position-relative'>
            <div className='alrt-lftbx'>
                <p>To take advantage of the TCB Pay Alerts and Notifications security feature simply take the following action:</p>
                <h5>Text the word IN to 57911 using the mobile phone associated with your corporate card.</h5>
                <p>You will receive a return text message confirming your enrollment.</p>
            </div>
          </div> 
          <div className='col-md-6 position-relative'>
            <div className='alrt-rgtbx'>
                <img src="assets/imgs/alertpic.png" alt="" />
            </div>
          </div>
        </Row>
      </Container>
    </section>  */}

    {/* <section className="alertnotify gradient-background8">
      
        <div className="wave16 d-none d-lg-block">
            <img src="assets/imgs/wave-16.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className='col-md-6'>
                    <div className='alrt-lstbx'>
                        <div className='alrt-lstbx-inner'>
                            <h6>Who would use our Alerts and Notifications?</h6>
                            <p>Anyone that travels for business with our Mastercard, such as sales people, marketing associates, and over-the-road truck drivers.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-1'>&nbsp;</div>
                <div className='col-md-5'>
                    <div className='alrt-lstbx'>
                        <div className='alrt-lstbx-inner'>
                            <h6>Why should I use Alerts and Notifications?</h6>
                              <div className="listpp2">
                                <ul>
                                <li>Ability to confirm, deny, or override transactions right from your mobile device. </li>
                                <li>Availability of Alerts and Notifications in the US and Canada.</li>
                                <li>Convenience of continuing to use your card even if it has been compromised.</li>
                                <li>No additional charge other than standard data and text rates from your mobile phone provider.</li>
                                </ul>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="wave17 d-none d-lg-block">
        <img src="assets/imgs/wave-17.png" alt="" />
        </div>
        
    </section> */}



    <section className="alert-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 alert-text text-left">
                    <div data-aos="fade-up" data-aos-duration={"2000"} className="blue_box aos-init aos-animate">
                        <p>To take advantage of the TCB Pay Alerts and Notifications security feature simply take the following action:
                        </p>

                        <h3>Text the word <span>IN</span> to <span>57911</span> using the mobile phone associated with your corporate card.</h3>

                        <p>You will receive a return text message confirming your enrollment.</p>

                    </div>
                </div>
                <div className="col-lg-6 mobile-sec d-lg-flex align-items-lg-center justify-content-lg-center">
                    <div data-aos="fade-up" data-aos-duration={"3000"} className="image_wrapper aos-init aos-animate">
                    <img src="assets/imgs/alertpic.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container gradient-banner-inr">
            <div className="row">
                <div className='col-md-6'>
                  <div className='alrt-lstbx'>
                        <div className='alrt-lstbx-inner'>
                            <h6>Who would use our Alerts and Notifications?</h6>
                            <p>Anyone that travels for business with our Mastercard, such as sales people, marketing associates, and over-the-road truck drivers.</p>
                        </div>
                  </div>
                </div>
                <div className='col-md-1'>&nbsp;</div>
                <div className='col-md-5'>
                <div className='alrt-lstbx'>
                <div className='alrt-lstbx-inner'>
                <h6>Why should I use Alerts and Notifications?</h6>
                <div className="listpp2">
                  <ul>
                  <li>Ability to confirm, deny, or override transactions right from your mobile device. </li>
                  <li>Availability of Alerts and Notifications in the US and Canada.</li>
                  <li>Convenience of continuing to use your card even if it has been compromised.</li>
                  <li>No additional charge other than standard data and text rates from your mobile phone provider.</li>
                  </ul>
                </div>
                </div>
                </div>
                </div>
            </div>
        </div>

        <div className="gradient_area">
        </div>
        
</section> 
<div className="wave17 d-none d-lg-block">
        <img src="assets/imgs/wave-17.png" alt="" />
        </div>
    </>
  )
}
