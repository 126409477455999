import React from 'react'
import { Whitel403Sec } from './whitel403Sec'

export const Whitel403 = () => {
  return (
    <>
    <Whitel403Sec />
    </>
  )
}
