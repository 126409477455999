import React from 'react'
import { Banner } from './banner'
import { RetailSec } from './retailSec'

export const Retail = () => {
  return (
    <>
    <Banner />
    <RetailSec />
    </>
  )
}
