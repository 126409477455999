import React from 'react'
import { FaqSec } from './faqSec'

export const FaqLnk = () => {
  return (
    <>
    <FaqSec />
    </>
  )
}
