import React from 'react'
import { IntegrationCreditAuthorizeSec } from './integrationCreditAuthorizeSec'

export const IntegrationCreditAuthorize = () => {
  return (
    <>
    <IntegrationCreditAuthorizeSec />
    </>
  )
}
