import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const AchSale = () => {
const exampleCodeText_4 = `<form name="" id="formID" action="https://gateway2-stg.tcbpay.com/apis/ach_sale.php" method="post">
<input type="text" name="username" value="tcbpayusername"/>
<input type="text" name="api_key" value="tcbpayapikey"/>
<input type="text" name="account_number" value="0000000016"/>
<input type="text" name="routing_number" value="122105278"/>
<input type="text" name="account_type" value="CHECKING"/>
<input type="text" name="amount" value="14.5"/>
<input type="text" name="currency" value="USD"/>
<input type="text" name="company_name" value="TCB Pay"/>
<input type="text" name="first_name" value="Roberto"/>
<input type="text" name="last_name" value="Diaz"/>
<input type="text" name="address" value="4446 Green Avenue, Suite 56"/>
<input type="text" name="city" value="Los Angeles"/>
<input type="text" name="state" value="CA"/>
<input type="text" name="country_code" value="US"/>
<input type="text" name="zip_code" value="11111"/>
<input type="text" name="phone_number" value="323-323-3223"/>
<input type="text" name="client_ip" value="127.0.0.1"/>
<input type="text" name="email" value="abcdef@mail.com"/>
<input type="checkbox" name="email_receipt" value="Y"/>
<br/>
<input type="submit" value="submit"/>
</form>`;
    const exampleCodeText_5 = `{"response":{"code":"00","description":"Transaction Entry Success","transaction_id":8405,"total_amount":9.99,"convenience_fee":0,"reference_id":""}}`;

    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);

  return (
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                        <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      {/* <h2>ACH API</h2>
                      <ul className="card-url">
                          <li>
                              <div className="card-url-list">
                                   <p>URL To Call: </p>
                                   <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_sale.php</Link></span>
                              </div>
                            
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Example:</p>
                                  <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Production endpoint URL:</p>
                                  <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                              </div>
                          </li>
                      </ul> */}
                  </div>

                  <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title">
                          <h2>ACH</h2>
                      </div>
                      <div className="api-menu-item-wrapper">
                          <h3>Sale API</h3>
                          <hr />
                          <p>This API is responsible for creating new payment transactions, and this API is compatible with both encrypted and plain credit card methods of transactions.</p>
                         
                          <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox endpoint URL:</p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_sale.php </Link></span>
                                  </div>
                                
                              </li>

                              <li>
                                  <div className="card-url-list">
                                      <p>Production endpoint URL</p>
                                      <span><Link to=''>{config.gatewayApisUrlProd}/apis/ach_sale.php</Link></span>
                                 </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method of form submission</p>
                                      <span><Link to=''>POST</Link></span>
                                 </div>
                              </li>
                              <br/>
                          </ul>
                      </div>
                  </div>

                  <div className="api-table">
                      <h5>This table describes the request parameters for Sale API.</h5>

                      <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                <th style={{ width: '100px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>username</td>
                                <td>TCB Pay Gateway Username</td>
                                <td>Y</td>
                                <td>TCB Pay Gateway Username</td>
                                </tr>
                                <tr>
                                <td>api_key</td>
                                <td>TCB Pay Gateway API Key</td>
                                <td>Y</td>
                                <td>TCB Pay Gateway API Key</td>
                                </tr>
                                <tr>
                                    <td>account_number </td>
                                    <td>Bank account number<br />
                                    (Set the secret key in the account setting to encrypt account_number. Make sure to encrypt account_number with AES 128-bit ECB cipher code.)</td>
                                    <td>Y</td>
                                    <td>0000000016</td>
                                </tr>
                                <tr>
                                    <td>routing_number </td>
                                    <td>Bank routing number<br />
                                    (Set the secret key in the account setting to encrypt routing_number. Make sure to encrypt routing_number with AES 128-bit ECB cipher code.)</td>
                                    <td>Y</td>
                                    <td>122105278</td>
                                </tr>
                                <tr>
                                    <td>account_type </td>
                                    <td>Bank account type</td>
                                    <td>Y</td>
                                    <td>SAVINGS, CHECKING, OTHER</td>
                                </tr>
                                <tr>
                                <td>amount</td>
                                <td>Amount to be charged</td>
                                <td>Y</td>
                                <td>133.24</td>
                                </tr>
                                <tr>
                                <td>currency</td>
                                <td>Currency of Transaction (allowed currencies will be determined during the time of merchant boarding).</td>
                                <td>Y</td>
                                <td>USD,EUR,GBP,CAD,AUD,NZD,AED,<br />DKK,MXN,SEK,CHF</td>
                                </tr>
                                <tr>
                                <td>company_name</td>
                                <td>Company Name of the cardholder</td>
                                <td>N</td>
                                <td>-</td>
                                </tr>
                                <tr>
                                <td>first_name</td>
                                <td>First name of the cardholder</td>
                                <td>Y</td>
                                <td>Roberto</td>
                                </tr>
                                <tr>
                                <td>last_name</td>
                                <td>Last name of the cardholder</td>
                                <td>Y</td>
                                <td>Diaz</td>
                                </tr>
                                <tr>
                                <td>address</td>
                                <td>Address of the cardholder</td>
                                <td>N</td>
                                <td>4446 Green Avenue, Suite 56</td>
                                </tr>
                                <tr>
                                <td>city</td>
                                <td>City of the cardholder</td>
                                <td>N</td>
                                <td>Los Angeles</td>
                                </tr>
                                <tr>
                                <td>state</td>
                                <td>State of the cardholder</td>
                                <td>N</td>
                                <td>CA</td>
                                </tr>
                                <tr>
                                <td>country_code</td>
                                <td>Country of the cardholder(ISO alpha-2)</td>
                                <td>Y</td>
                                <td>US, CN</td>
                                </tr>
                                <tr>
                                <td>zip_code</td>
                                <td>Zip code of the cardholder</td>
                                <td>Y</td>
                                <td>94085</td>
                                </tr>
                                <tr>
                                <td>phone_number</td>
                                <td>Phone Number of the cardholder</td>
                                <td>N</td>
                                <td>408-345-2323</td>
                                </tr>
                                

                                <tr>
                                <td>ship_address_as_bill_address</td>
                                <td>Billing address copy Shipping address</td>
                                <td>Y</td>
                                <td>Y or N (default value N) Y- Billing address copy to Shipping address N- Not copy</td>
                                </tr>
                                <tr>
                                <td>ship_company_name</td>
                                <td>Shipping Company Name </td>
                                <td>N</td>
                                <td>-</td>
                                </tr>
                                <tr>
                                <td>ship_first_name</td>
                                <td>Shipping First name </td>
                                <td>Y</td>
                                <td>Roberto</td>
                                </tr>
                                <tr>
                                <td>ship_last_name</td>
                                <td>Shipping Last name </td>
                                <td>Y</td>
                                <td>Diaz</td>
                                </tr>
                                <tr>
                                <td>ship_address</td>
                                <td>Shipping Address </td>
                                <td>N</td>
                                <td>4446 Green Avenue, Suite 56</td>
                                </tr>
                                <tr>
                                <td>ship_city</td>
                                <td>Shipping City </td>
                                <td>N</td>
                                <td>Los Angeles</td>
                                </tr>
                                <tr>
                                <td>ship_state</td>
                                <td>Shipping State </td>
                                <td>N</td>
                                <td>CA</td>
                                </tr>
                                <tr>
                                <td>ship_country_code</td>
                                <td>Shipping Country (ISO alpha-2)</td>
                                <td>Y</td>
                                <td>US, CN</td>
                                </tr>
                                <tr>
                                <td>ship_zip_code</td>
                                <td>Shipping Zip code </td>
                                <td>Y</td>
                                <td>94085</td>
                                </tr>
                                <tr>
                                <td>ship_phone_number</td>
                                <td>Shipping Phone Number </td>
                                <td>N</td>
                                <td>408-345-2323</td>
                                </tr>

                                
                                <tr>
                                <td>email</td>
                                <td>Email ID of the cardholder</td>
                                <td>Y</td>
                                <td>test@test.com</td>
                                </tr>
                                <tr>
                                <td>email_receipt</td>
                                <td>Sends transaction details to specified cardholder email </td>
                                <td>N</td>
                                <td>Y or N (default value N) 
                                    Y- Send email 
                                    N- Not send
                                </td>
                                </tr>
                                <tr>
                                <td>emailReceiptDBAEmail</td>
                                <td>Email Receipt to Merchant DBA Contact Email  </td>
                                <td>N</td>
                                <td>Y or N (default value N) 
                                    Y- Send email 
                                    N- Not send
                                </td>
                                </tr>
                                <tr>
                                <td>memo</td>
                                <td>Memo of the transaction </td>
                                <td>N</td>
                                <td>
                                </td>
                                </tr>
                                <tr>
                                <td>client_ip</td>
                                <td>Client IP address</td>
                                <td>N</td>
                                <td>127.0.0.1</td>
                                </tr>
                                <tr>
                                <td>custom_field_1</td>
                                <td></td>
                                <td>N</td>
                                <td>additional parameter 1</td>
                                </tr>
                                <tr>
                                <td>custom_field_2</td>
                                <td></td>
                                <td>N</td>
                                <td>additional parameter 2</td>
                                </tr>
                                <tr>
                                <td>source</td>
                                <td></td>
                                <td>C</td>
                                <td>source=SHOPIFY if using Shopify otherwise it is not required</td>
                                </tr>
                                
                                <tr>
                                <td>reference_id</td>
                                <td>Transaction reference id</td>
                                <td>N</td>
                                <td>Reference ID should be unique</td>
                                </tr>
                            </tbody>
                          </table>
                      </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip4">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_4">
                                <pre>{exampleCodeText_4}</pre>
                            </div>
    
                            </div>
                      </div>
                  </div>

                  
                      <div className="api-menu-item-wrapper mt-60">
                          <h3>Response:</h3>
                          <hr />
                          <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, transaction_id, and reference_id. The response code for a successful API call is always 00.</p>
                          <div className="example-box-wrapper mb-20">
                              <div className="d-flex align-items-start">
                                  <b></b>
                                  <span>Sample Response :</span>
                              </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip5">
                                <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                
                                </div>

                                <div className="ex-text-box" id="ExampleCodeText_5">
                                    <pre>{exampleCodeText_5}</pre>
                                </div>
    
                            </div>
                          </div>
                      </div>
               
                   


              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
    </section>
  )
}
