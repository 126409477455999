import React, { useEffect, forwardRef, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../Config';

export const Calender = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {

  const targetDivRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.type = 'text/javascript';
    script.async = true;
    
    document.body.appendChild(script);

    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  return (
    <section ref={ref} className="transaction-area gradient-background" id="show-calendly" >
        <div className="wave2 d-lg-block">
            <img src={`${config.siteBaseUrl}/assets/imgs/wave-2.png`} alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-lg-12 m-auto">
                    <div className='calendly-inline-widget' data-url='https://calendly.com/chris-tcb/30min?primary_color=397ab7'></div>
                </div>   
            </div>
        </div>

        <div className="wave d-lg-block">
        <img src={`${config.siteBaseUrl}/assets/imgs/wave-1.png`} alt="" />
        </div>
    </section>
  );
});