import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<form action="https://portal.tcbpay.com/apis/bank_verification.php" method="post" >
    <input type="text" name="username" value="tcbpayusername"/>
    <input type="text" name="api_key" value="tcbpayapikey"/>
    <input type="text" name="use_secret_key" value="N"/>
    <input type="text" name="account_number" value="0000000016"/>
    <input type="text" name="routing_number" value="122105278"/>
    <input type="text" name="account_type" value="CHECKING"/>
    <input type="text" name="business_name" value=""/>
    <input type="text" name="first_name" value="Jane"/>
    <input type="text" name="middle_name" value=""/>
    <input type="text" name="last_name" value="Doe"/>
  
    <input type="submit" value="submit" />
</form>   `;
  const exampleCodeText_2 = `{"response":{"code":"00","description":"Account verified","routing_number":"122105278","account_number":"********0016","business_name":"","item_reference_id":"10125186087","bank_name":"WELLS FARGO BANK NA (ARIZONA)","account_response_code":"1111: Account Verified - The account was found to be an open and valid account","customer_response_code":"CA11: Customer authentication passed verification","verification_response":"Pass","timestamp":"2024-08-30 6:10:05 AM","account_added_date":"2023-06-23","account_last_updated_date":"2024-06-23"}} 
  `;
  

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h2>Bank Verification API</h2>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/bank_verification.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Bank Verification</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Bank Verification API</h3>
                            <hr />
                            <p>This API is responsible for bank verification.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/bank_verification.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                                <br />
                            </ul>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>The table describes the request parameters for Bank Verification API.</h5>

                        <div className="table-responsive">
                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Variable Name</th>
                        <th>Description</th>
                        <th>Required</th>
                        <th>Expected Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>username</td>
                        <td>TCB Pay Gateway Username</td>
                        <td>Y</td>
                        <td>TCB Pay Gateway Username</td>
                      </tr>
                      <tr>
                        <td>api_key</td>
                        <td>TCB Pay Gateway API Key</td>
                        <td>Y</td>
                        <td>TCB Pay Gateway API Key</td>
                      </tr>
                      <tr>
                        <td>use_secret_key</td>
                        <td>Set the secret key in the account setting to encrypt account_number and routing_number. Make sure to encrypt account_number and routing_number with AES 128-bit ECB cipher code.</td>
                        <td>N</td>
                        <td>Y or N (default value N) <br/>
                            Y- encrypt account_number and routing_number using AES 128 bit ECB cipher mode encryption

                        </td>
                      </tr>
                      <tr>
                        <td>account_number </td>
                        <td>Bank account number<br/>
                        (5 to 17 digits account number)</td>
                        <td>Y</td>
                        <td>0000000016</td>
                      </tr>
                      <tr>
                        <td>routing_number </td>
                        <td>Bank routing number<br/>
                        (9 digits routing number)</td>
                        <td>Y</td>
                        <td>122105278</td>
                      </tr>
                      <tr>
                        <td>account_type </td>
                        <td>Bank account type</td>
                        <td>Y</td>
                        <td>SAVINGS, CHECKING, OTHER</td>
                      </tr>
                      <tr>
                        <td>business_name </td>
                        <td>Business name of account holder<br/>
                        (Provide Business name or First name, Last name if account holder's name needs to be matched)
                        </td>
                        <td>C</td>
                        <td>Smith and Associates Consulting</td>
                      </tr>
                      <tr>
                        <td>first_name </td>
                        <td>First name of account holder <br/>
                        (Provide Business name or First name, Last name if account holder's name needs to be matched)
                        </td>
                        <td>C</td>
                        <td>Jane</td>
                      </tr>
                      <tr>
                        <td>middle_name </td>
                        <td>Middle name of account holder</td>
                        <td>N</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>last_name  </td>
                        <td>Last name of account holder<br/>
                        (Provide Business name or First name, Last name if account holder's name needs to be matched)</td>
                        <td>C</td>
                        <td>Doe</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip1">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box bighgt">
                                    <pre>{exampleCodeText_1}</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-0">
                            
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip2">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_2}</pre>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                 
                     


                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



