import React from 'react'
import { Banner } from './banner'
import { SecuritySec } from './securitySec'

export const Security = () => {
  return (
    <>
    <Banner />
    <SecuritySec />
    </>
  )
}
