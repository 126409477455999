import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const Faq = () => {
  return (
    <>
    <section className='faqbx'>
       <img src="assets/imgs/orng-bg.png" alt=""  className='orng-dots'/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='faq-lft'>
                    <h6 className="seamless-heading mb-30">FAQ</h6>
                        <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is the TCB Pay Portal?</Accordion.Header>
                            <Accordion.Body>
                            More than a payment gateway, the TCB Pay Portal is your one-stop shop for all your payment needs.<br/><br/>
                            With the Portal, you can easily process card and ACH payments, send payment links via QR code, create invoices, set up recurring payments, manage your customer database, track chargebacks, and more.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What is a payment gateway?</Accordion.Header>
                            <Accordion.Body>
                            A payment gateway is a virtual terminal that is connected to your merchant account and allows you to accept credit card payments, both in person and online.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How do I know my transactions are secure?</Accordion.Header>
                            <Accordion.Body>
                            At TCB Pay, our security solutions include encryption and tokenization to protect card data. Encryption protects the card data as it travels across various systems and networks while Tokenization replaces cardholder data with a unique token value that is substituted for the payment data in the processing environment. In the unlikely event a breach occurs, the sensitive cardholder information is virtually inaccessible.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How do you maintain security?</Accordion.Header>
                            <Accordion.Body>
                            As data breaches increasingly impact the processing industry the need for sophisticated technologies to identify and remedy gaps and weaknesses is for everyone’s benefit. TCB Pay can help protect your business from security vulnerabilities tied to payment card data and maintain a Payment Card Industry Data Security Standard (PCI DSS) compliant status – ensuring core security requirements and PCI best practices are in place. 
                            <br /><br /> TCB recognizes that maintaining PCI DSS compliance can take a significant amount of your time and technical prowess to address. TCB Pay’s PCI Team offers a hands-on concierge service to our customers; not only protecting their business(es), but also their cardholders.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How do you protect me from fraud?</Accordion.Header>
                            <Accordion.Body>
                            TCB Pay offers various features to reduce fraud and provide peace of mind. These features include Address Verification Service (AVS) to check if the billing address matches what the issuing bank has on file, Card Verification Value (CVV) check that acts as an added security measure, 3DSecure (3DS) for increased chargeback protection, Lockdown Controls to restrict transactions, and exclusive Risk-Scoring along with continuous monitoring to recognize fraudulent transactions. 
                            <br /><br /> For more information, please visit <Link to="/security">tcbpay.com/security</Link>.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>What is AVS (Address Verification Service)?</Accordion.Header>
                            <Accordion.Body>
                            When customers purchase items, they will provide their billing address and ZIP code at the time of purchase. The AVS feature will check if this address matches with what the issuing bank has on file. The returning response helps you determine if the transaction is having a full or partial AVS match or if a deeper investigation should be carried out before fulfilling the order.
                            <br /><br />  For more information, please visit <Link to="/security">tcbpay.com/security.</Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>What is CVV Check?</Accordion.Header>
                            <Accordion.Body>
                            This 3 or 4-digit code (varies based on card type) is available on every payment card and acts as an added security measure, allowing only the cardholder to use the card. When placing orders on your website and it is found the CVV does not match, the transaction will not be approved until the transaction and cardholder can be verified; further helping combat fraud and reduce chargebacks. 
                            <br /><br /> For more information, please visit <Link to="/security">tcbpay.com/security.</Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>What is 3DS?</Accordion.Header>
                            <Accordion.Body>
                            TCB Pay is only a handful of processors with the ability to implement 3DS and offer increased chargeback protection; one of the most robust fraud prevention tools you can utilize that also looks after your business interests.
                            <br /><br /> For more information, please visit <Link to="/security">tcbpay.com/security.</Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </div>
                    
                </div>
                <div className='col-md-6'>
                    <div className='faq-rgt'>
                      <img src="assets/imgs/faq-pic1.webp" alt="" />
                      <div className='innerbx'>
                        <h6>User guide</h6>
                      <p>Explore the <b>TCB Pay Portal Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform.</p>
                      <Link className='wiki-btn' to="/wiki-portal">Go to TCB Pay Portal Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                      </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
