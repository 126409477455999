import React from 'react'
import { Notfound403Sec } from './notfound403Sec'

export const Notfound403 = () => {
  return (
    <>
    <Notfound403Sec />
    </>
  )
}
