  
  const domainName:string = window.location.hostname;
  let siteBaseUrl;
  let siteBaseUrlWithoutwww;

//======================= FOR LOCAL ============================================
  if(domainName==='localhost'){
    siteBaseUrl = 'http://localhost:3000';
  } else {
    siteBaseUrl = 'https://dev.tcbpay.com';
  }

  const config = {
    internalApiUrl: 'https://dev.tcbpay.com/internal_apis',
    pluginModuleUrl: 'https://dev.tcbpay.com',
    gatewayApisUrlSand: 'https://gateway2-stg.tcbpay.com',
    gatewayApisUrlProd: 'https://portal.tcbpay.com',
    portalUrl: 'https://portal.tcbpay.com',
    issuingUrl: 'https://issuing.tcbpay.com',
    siteBaseUrl: siteBaseUrl,
    siteBaseUrlWithoutwww: siteBaseUrlWithoutwww,
    blogUrl: 'https://www.tcbpay.com/blog',
    gCaptchaSiteKey: '6LcekHAqAAAAAPqhuZVPfqFzT-IwVDp8iUPLZ8k8',
    gCaptchaSecretKey: '6LcekHAqAAAAAMxkXUTvtuj8h_JoEBkk84kv-c5a'
  };



//======================= FOR UAT ============================================
  // if(domainName==='localhost'){
  //   siteBaseUrl = 'http://localhost:3000';
  // } else {
  //   siteBaseUrl = 'https://stg.tcbpay.com';
  // }

  // const config = {
  //   internalApiUrl: 'https://stg.tcbpay.com/internal_apis',
  //   pluginModuleUrl: 'https://stg.tcbpay.com',
  //   gatewayApisUrlSand: 'https://gateway2-stg.tcbpay.com',
  //   gatewayApisUrlProd: 'https://portal.tcbpay.com',
  //   portalUrl: 'https://portal.tcbpay.com',
  //   issuingUrl: 'https://issuing.tcbpay.com',
  //   siteBaseUrl: siteBaseUrl,
  //   blogUrl: 'https://www.tcbpay.com/blog',
  //   gCaptchaSiteKey: '6Lecw7AqAAAAAJT9NOaCTkPV_35HMV72qSdVETA5',
  //   gCaptchaSecretKey: '6Lecw7AqAAAAAP1XeWrR11Stb4vf31C8THS3qjDi'
  // };




//======================= FOR PROD ============================================
  // if(domainName==='localhost'){
  //   siteBaseUrl = 'http://localhost:3000';
  //   siteBaseUrlWithoutwww = 'http://localhost:3000';
  // } else {
  //   siteBaseUrl = 'https://www.tcbpay.com';
  //   siteBaseUrlWithoutwww = 'https://tcbpay.com';
  // }

  // const config = {
  //   internalApiUrl: 'https://www.tcbpay.com/internal_apis',
  //   pluginModuleUrl: 'https://www.tcbpay.com',
  //   gatewayApisUrlSand: 'https://gateway2-stg.tcbpay.com',
  //   gatewayApisUrlProd: 'https://portal.tcbpay.com',
  //   portalUrl: 'https://portal.tcbpay.com',
  //   issuingUrl: 'https://issuing.tcbpay.com',
  //   siteBaseUrl: siteBaseUrl,
  //   siteBaseUrlWithoutwww: siteBaseUrlWithoutwww,
  //   blogUrl: 'https://www.tcbpay.com/blog/',
  //   gCaptchaSiteKey: '6Lecw7AqAAAAAJT9NOaCTkPV_35HMV72qSdVETA5',
  //   gCaptchaSecretKey: '6Lecw7AqAAAAAP1XeWrR11Stb4vf31C8THS3qjDi'
  // };

  export default config;