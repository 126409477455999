import React from 'react'
import { LegalCookiesPolicySec } from './legalCookiesPolicySec'

export const LegalCookiesPolicy = () => {
  return (
   <>
   <LegalCookiesPolicySec />
   </>
  )
}
