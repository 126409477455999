import React,{useState,useEffect,useRef} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../Config';

import { ContactUsService } from '../services/ContactUsService';

type FormData = {
  topic_type:string,
  company_name:string,
  full_name:string,
  email:string,
  phone_number:string,
  business_address_1:string,
  business_address_2:string,
  city:string,
  province:string,
  zipcode:string,
  representative:string,
  uploaded_file: File | null,
  message:string
};

type FormErrors = {
  topic_type?:string,
  company_name?:string,
  full_name?:string,
  email?:string,
  phone_number?:string,
  business_address_1?:string,
  business_address_2?:string,
  city?:string,
  province?:string,
  zipcode?:string,
  representative?:string,
  uploaded_file?:string,
  message?:string,
  captcha?:string,
  common_err?:string,
  success?:string
};

type FileItem = {
  id: string;
  file: File;
};

export const LegalCodeConductFormSec = () => {

    const {seo_url} = useParams<any>();

    const [formData, setFormData] = useState<FormData>({
      topic_type:'Transparency and Disclosure',
        company_name:'',
        full_name:'',
        email:'',
        phone_number:'',
        business_address_1:'',
        business_address_2:'',
        city:'',
        province:'',
        zipcode:'',
        representative:'',
        uploaded_file: null,
        message:''
      });
    
      const [formErrors, setFormErrors] = useState<FormErrors>({});
      const [uploaded_file, setUploaded_file] = useState<FileItem[]>([]);
      const [captchaValue, setCaptchaValue] = useState<string | null>(null);
      const captchaRef = useRef<ReCAPTCHA | null>(null);


      // Handle removing a file from the list
      const removeFile = (fileId: string) => {
        setUploaded_file((prevFiles) => prevFiles.filter((fileItem) => fileItem.id !== fileId));
      };
        
      // Create a ref for the file input
      const fileInputRef = useRef<HTMLInputElement | null>(null);
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    
      const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) : void => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setFormErrors({
          uploaded_file: '',
        });

        const selectedFiles = event.target.files;
        if (selectedFiles) {

          // Maximum allowed file size (in bytes, 5MB = 5 * 1024 * 1024)
          const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
          // Allowed file types (MIME types)
          const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

          const selectedFileArray = Array.from(selectedFiles);
          const validFiles: File[] = [];
          let errorMessage = '';
    
          // Validate files
          for (let i = 0; i < selectedFileArray.length; i++) {
            const file = selectedFileArray[i];
    
            // Validate file type
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
              errorMessage = `Invalid file type: ${file.name}. Only JPEG, PNG, and PDF are allowed.`;
            }
    
            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
              errorMessage = `File size exceeds the limit of 5MB: ${file.name}.`;
            }
    
            validFiles.push(file);
          }

          // If any errors, show the error message
          if (errorMessage) {
            setFormErrors({
              uploaded_file: errorMessage,
            });
            if (fileInputRef.current) { fileInputRef.current.value = '';  }
          } else {

            const newFiles = Array.from(selectedFiles).map((file) => ({
              id: URL.createObjectURL(file), // Unique id based on file URL
              file,
            }));

            setUploaded_file((prevFiles) => [...prevFiles, ...newFiles]);
          }
        }
      };  

      const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
        setFormErrors({captcha:''});
      };
    
      const validate = () => {
        const errors: FormErrors = {};
        
    
        if (!formData.company_name) {
          errors.company_name = 'Business name is required';
        } else if (!/^[0-9a-zA-Z\s]+$/.test(formData.company_name)) {
          errors.company_name = 'Business name is invalid';
        }
    
        if (!formData.full_name) {
          errors.full_name = 'Full name is required';
        } else if (!/^[a-zA-Z\s]+$/.test(formData.full_name)) {
          errors.full_name = 'Full name is invalid';
        }
    
        if (!formData.email) {
          errors.email = 'Email address is required';
        } else if (!/^[a-zA-Z][_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,10})$/.test(formData.email)) {
          errors.email = 'Email address is invalid';
        }

        if (!formData.phone_number) {
          errors.phone_number = 'Phone number is required';
        } else if (!/^[0-9+]+$/.test(formData.phone_number)) {
          errors.phone_number = 'Phone number is invalid';
        } else if (formData.phone_number.length<10) {
          errors.phone_number = 'Phone number should be minimum 10 digits';
        } else if (formData.phone_number.length>13) {
          errors.phone_number = 'Phone number should be maximum 12 digits';
        }

        if ((formData.business_address_1) && !/^[0-9a-zA-Z.,\s]+$/.test(formData.business_address_1)) {
          errors.business_address_1 = 'Business Address - Line 1 is invalid';
        }
        if ((formData.business_address_2) && !/^[0-9a-zA-Z.,\s]+$/.test(formData.business_address_2)) {
          errors.business_address_2 = 'Business Address - Line 2 is invalid';
        }
        if ((formData.city) && !/^[0-9a-zA-Z\s]+$/.test(formData.city)) {
          errors.city = 'City is invalid';
        }
        if ((formData.province) && !/^[0-9a-zA-Z.,\s]+$/.test(formData.province)) {
          errors.province = 'Province is invalid';
        }
        if ((formData.zipcode) && (!/^[0-9\s]+$/.test(formData.zipcode) || formData.zipcode.length<5)) {
          errors.zipcode = 'ZIP Code is invalid';
        }
        if ((formData.representative) && !/^[0-9a-zA-Z.,\s]+$/.test(formData.representative)) {
          errors.representative = 'Representative you spoke with is invalid';
        }

        if (!formData.topic_type) {
          errors.topic_type = 'Complaint\'s Topic is required';
        }
    
        if (!formData.message) {
          errors.message = 'Message is required';
        } else if (!/^[0-9a-zA-Z.,'":_-\s]+$/.test(formData.message)) {
          errors.message = 'Message is invalid';
        }
    
        if (!captchaValue) {
          errors.captcha = 'Captcha is required';
        } else {
          ContactUsService.googleCaptchaVerify(captchaValue)
            .then((res) =>{
              console.log(res);
              // const capApiResponse:any = res.success;
              // if(capApiResponse){
    
              // }
            }
          )
          .catch((err) =>{
            errors.captcha = err;
            }
          );
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0; // Returns true if no errors
      };
    
      const handleSubmit =  (event:React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
    
        const submitBtnElement = document.getElementById("submitBtn");
        if (submitBtnElement) {
          submitBtnElement.classList.add("disabled");
        }

        if (captchaRef.current) {
          captchaRef.current.reset();
          setCaptchaValue(null);
        }

        if(validate()) {
    
          ContactUsService.sendCodeOfConductFormData(formData,uploaded_file)
          .then((res) =>{
                const apiResponse:any = res.data;
                if(apiResponse){
                  const response:any = apiResponse.response;
                  const apiCode:any = response.code;
                  const apiDescription:any = response.description;
    
                  if(response.code==='00'){
                    setFormData({
                      topic_type:'Transparency and Disclosure',
                      company_name:'',
                      full_name:'',
                      email:'',
                      phone_number:'',
                      business_address_1:'',
                      business_address_2:'',
                      city:'',
                      province:'',
                      zipcode:'',
                      representative:'',
                      uploaded_file: null,
                      message:''
                    });
                    if (fileInputRef.current) { fileInputRef.current.value = '';  }
                    setFormErrors({
                      success: response.description,
                    });
                    const intervalId = setInterval(() => {
                      setFormErrors({
                        success: '',
                      });
                      clearInterval(intervalId);
                    }, 10000);
                    setUploaded_file([]);
                  } else {
                    setFormErrors({
                      ...formErrors,
                      common_err: response.description,
                    });
                  }
                }

                if (submitBtnElement) {
                  submitBtnElement.classList.remove("disabled");
                }
              }
          )
          .catch((err) =>{
            console.log('err'); 
            if (submitBtnElement) {
              submitBtnElement.classList.remove("disabled");
            } 
              }
          );
    
          console.log('Form data submitted:', formData);
        } else {
          console.log('Form contains errors');
          if (submitBtnElement) {
            submitBtnElement.classList.remove("disabled");
          }
        }
    
    
      };

    
    return (
        <>
        
            <form id="contactForm1" name="contactForm" encType='multipart/form-data' className="contactForm text-left" onSubmit={handleSubmit}>
                <div className="row">

                <div className="col-md-6">
                    <Form.Group className="mb-3">
                    
                    <Form.Control type="text" name='company_name' maxLength={50} value={formData.company_name} onChange={handleChange} placeholder='Business Name' required />
                          {formErrors.company_name && <span style={{ color: 'red' }}>{formErrors.company_name}</span>}
                          <Form.Label><span>Business Name</span> </Form.Label>
                    </Form.Group>
                    
                </div>
                <div className="col-md-6"> 
                    <Form.Group className="mb-3">
                    
                    <Form.Control type="text" name='full_name' maxLength={50} value={formData.full_name} onChange={handleChange} placeholder='Your Full Name' required />
                          {formErrors.full_name && <span style={{ color: 'red' }}>{formErrors.full_name}</span>}
                          <Form.Label><span>Your Full Name</span> </Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='email' maxLength={100} value={formData.email} onChange={handleChange} placeholder='Email Address' required />
                          {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                          <Form.Label><span>Email Address</span> </Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6"> 
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='phone_number' maxLength={12} value={formData.phone_number} onChange={handleChange} placeholder='Phone Number' required />
                          {formErrors.phone_number && <span style={{ color: 'red' }}>{formErrors.phone_number}</span>}
                          <Form.Label><span>Phone Number</span> </Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='business_address_1' value={formData.business_address_1} onChange={handleChange} placeholder='Business Address - Line 1' />
                          {formErrors.business_address_1 && <span style={{ color: 'red' }}>{formErrors.business_address_1}</span>}
                          {/* <Form.Label>Business Address - Line 1</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-6"> 
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='business_address_2' value={formData.business_address_2} onChange={handleChange} placeholder='Business Address - Line 2' />
                          {formErrors.business_address_2 && <span style={{ color: 'red' }}>{formErrors.business_address_2}</span>}
                          {/* <Form.Label>Business Address - Line 2</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='city' maxLength={50} value={formData.city} onChange={handleChange} placeholder='City' />
                          {formErrors.city && <span style={{ color: 'red' }}>{formErrors.city}</span>}
                          {/* <Form.Label>City</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-3"> 
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='province' maxLength={50} value={formData.province} onChange={handleChange} placeholder='Province' />
                          {formErrors.province && <span style={{ color: 'red' }}>{formErrors.province}</span>}
                          {/* <Form.Label>Province</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-3"> 
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='zipcode' maxLength={6} value={formData.zipcode} onChange={handleChange} placeholder='ZIP Code' />
                          {formErrors.zipcode && <span style={{ color: 'red' }}>{formErrors.zipcode}</span>}
                          {/* <Form.Label>ZIP Code</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3">
                    <Form.Control type="text" name='representative' maxLength={50} value={formData.representative} onChange={handleChange} placeholder='Interlocutor' />
                          {formErrors.representative && <span style={{ color: 'red' }}>{formErrors.representative}</span>}
                          {/* <Form.Label>Representative you spoke with</Form.Label> */}
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3">
                        <Form.Select aria-label="Default select example" onChange={handleSelectChange} name='topic_type' required>
                        <option value="Transparency and Disclosure">Transparency and Disclosure</option>
                        <option value="Notice of fee increase or new fee">Notice of fee increase or new fee</option>
                        <option value="Contract Cancellation">Contract Cancellation </option>
                        <option value="No Obligation Acceptance">No Obligation Acceptance </option>
                        <option value="Payment Method Discount">Payment Method Discount </option>
                        <option value="Negative Option Acceptance">Negative Option Acceptance</option>
                        <option value="Contactless Payments">Contactless Payments </option>
                        <option value="Renewal and Cancellation Disclosure">Renewal and Cancellation Disclosure</option>
                        <option value="Complaint Handling Process"> Complaint Handling Process</option>
                        </Form.Select>
                        <Form.Label>Topic </Form.Label>
                        {formErrors.topic_type && <span style={{ color: 'red' }}>{formErrors.topic_type}</span>}
                    </Form.Group>
                </div>

                <div className="col-md-12">
                    <Form.Group className="mb-3">
                    
                    <Form.Control as="textarea" name='message' value={formData.message} rows={8} onChange={handleChange} required />
                          {formErrors.message && <span style={{ color: 'red' }}>{formErrors.message}</span>}
                          <Form.Label>Your Message </Form.Label>
                    </Form.Group>
                </div>
                
                <div className='col-md-12'>
                <Form.Group controlId="formFile" className="mb-3">
                
                {uploaded_file.length > 0 &&
                  <>
                    <Form.Group controlId="formFile" className="mb-3">
                      <ul className='legalcnd'>
                        {uploaded_file.map((fileItem) => (
                          <li key={fileItem.id}>
                            <span><img src="../assets/imgs/cd-img.svg" alt='' /> {fileItem.file.name}</span>
                            <button onClick={() => removeFile(fileItem.id)}><img src="../assets/imgs/cd-close.svg" alt='' /></button>
                          </li>
                        ))}
                      </ul>
                    </Form.Group>
                  </>
                }
                <Form.Label className='clkuld'>Drop files here or click to upload</Form.Label>
                <Form.Control type="file" ref={fileInputRef} name="uploaded_file" multiple onChange={handleFileChange} accept="image/jpeg, image/png, application/pdf" />
                {formErrors.uploaded_file && <span style={{ color: 'red' }}>{formErrors.uploaded_file}</span>}
                </Form.Group>
                </div>
                <div className="col-md-6 mb-4 mt-3"> 
                  <ReCAPTCHA 
                    sitekey={config.gCaptchaSiteKey}
                    onChange={handleCaptchaChange}
                    ref={captchaRef}
                  />
                  {formErrors.captcha && <span style={{ color: 'red' }}>{formErrors.captcha}</span>}
                </div>
                <div className="col-md-12">
                    <Form.Group>
                    <Button type="submit" id="submitBtn" className="btn btn-warning w-100">Submit</Button>
                    </Form.Group>
                </div>
                <div className="col-md-12 mt-3">
                    {formErrors.common_err && <span style={{ color: 'red' }}>{formErrors.common_err}</span>}
                    {formErrors.success && <span className='frmcd'>{formErrors.success}</span>}
                </div>
                </div>
            </form>
            {/* <span className='frmcd'>Thank you, your submission has been received successfully. Our team will get back to you shortly.</span> */}

        </>
    )
}
