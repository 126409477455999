import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqIntegrationsSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                      <h2 className='faq-rgtsectitle'>Integrations</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What integrations do you offer?</div>
                        <div className='qusans'>
                            <p>We offer a multitude of integrations. Please visit our Developers page to learn more about what integrations we offer. If what you're looking for is not there, email us! We are more than happy to work with you on finding the best solution. </p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Are you able to integrate our current CRM?</div>
                        <div className='qusans'>
                            <p>If you are currently on a specific CRM and do not want to part ways with it, we are more than happy to work with you on finding a solution to keeping your CRM while you begin to process with us.</p>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
