import React from 'react'
import { IntegrationCreditReportSec } from './integrationCreditReportSec'

export const IntegrationCreditReport = () => {
  return (
    <>
    <IntegrationCreditReportSec />
    </>
  )
}
