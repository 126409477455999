import React, {useRef} from 'react'
import { Banner } from './banner'
import { ItCounter } from './itCounter'
import { ItSolutionSec } from './itSolutionSec'

export const ItSolutions = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
    <Banner scrollRef={scrollRef}/>
    <ItCounter />
    <ItSolutionSec ref={scrollRef}/>
    </>
  )
}
