import React from 'react'
import { Banner } from './banner'
import { IntregrationList } from './intregrationList'

export const Integration = () => {
  return (
    <>
    <Banner />
    <IntregrationList />
    </>
  )
}
