import React from 'react'
import { IntegrationCreditSandboxSec } from './integrationCreditSandboxSec'

export const IntegrationCreditSandbox = () => {
  return (
    <>
    <IntegrationCreditSandboxSec />
    </>
  )
}
