import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Animations from '../animations/animation1';
import RunningRectangle from '../animations/animation1';
import OurIntegrations from '../animations/animation1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

// const actions = [
//     { name: "button 1", type: "b1" },
//     { name: "button 2", type: "b2" },
//     { name: "button 3", type: "b3" },
//     { name: "button 4", type: "b4" },
//   ];

export const Highrisk = () => {
    // const [contentType, setContentType] = React.useState("b1");
    // const Content1 = () => <div> <img src={`${config.siteBaseUrl}/assets/imgs/itpic.png`} alt=''/> </div>;
    // const Content2 = () => <div> <img src={`${config.siteBaseUrl}/assets/imgs/itpic1.png`} alt=''/> </div>;
    // const Content3 = () => <div> <img src={`${config.siteBaseUrl}/assets/imgs/itpic2.png`} alt=''/> </div>;
    // const Content4 = () => <div> <img src={`${config.siteBaseUrl}/assets/imgs/itpic3.png`} alt=''/> </div>;
    return (
        <>
        <section className='highrisk-specialistsbx'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='highrisk-inner'>
                            <h6><span>High-risk</span> specialists</h6>
                            <p>Open doors to new opportunities for your high-risk business with TCB Pay’s approved services. Our expert solutions are designed to address the specific challenges of high-risk industries, offering dependable payment processing and effective risk management.</p>
                            <Link to="/high-risk" className="highrisk-inner-btn mbhib"><img src={`${config.siteBaseUrl}/assets/imgs/arw-agn.svg`} alt=''/> Read more about our high-risk expertise </Link>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'>
                        <img src={`${config.siteBaseUrl}/assets/imgs/highrisk-hm.svg`} alt='' />
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='col-md-12'>
                        <div className='highrisk-inner-pic order-none'>
                            <ul>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img1.svg`} alt="" /><span className='t'>CBD/Hemp/Vape</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img2.svg`} alt="" /><span className='t'>Credit Repair</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img3.svg`} alt="" /><span className='t'>Cryptocurrency</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img4.svg`} alt="" /><span className='t'>Digital Goods</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow even'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img5.svg`} alt="" /><span className='t'>Electronics</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img6.svg`} alt="" /><span className='t'>Gaming</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img7.svg`} alt="" /><span className='t'>Pharma/Nutra</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img8.svg`} alt="" /><span className='t'>Tech Support</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img9.svg`} alt="" /><span className='t'>Telecommunication</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img10.svg`} alt="" /><span className='t'>Telemedicine</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img11.svg`} alt="" /><span className='t'>Travel</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img12.svg`} alt="" /><span className='t'>Warranties</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='highrisk-inner-pic order-block'>
                            <ul>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img1.svg`} alt="" /><span className='t'>CBD/Hemp/Vape</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img2.svg`} alt="" /><span className='t'>Credit Repair</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img3.svg`} alt="" /><span className='t'>Cryptocurrency</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img4.svg`} alt="" /><span className='t'>Digital Goods</span>
                                    </div>
                                </li>

                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img6.svg`} alt="" /><span className='t'>Gaming</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow even'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img5.svg`} alt="" /><span className='t'>Electronics</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img8.svg`} alt="" /><span className='t'>Tech Support</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img7.svg`} alt="" /><span className='t'>Pharma/Nutra</span>
                                    </div>
                                </li>
                                

                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img9.svg`} alt="" /><span className='t'>Telecommunication</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img10.svg`} alt="" /><span className='t'>Telemedicine</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img11.svg`} alt="" /><span className='t'>Travel</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='yellow'>
                                        <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img12.svg`} alt="" /><span className='t'>Warranties</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>

        <section className='our-integrationsbx bg-none pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8 m-auto'>
                        <div className='our-integration-inner text-center'>
                            <h6 className='text-center'>Our <span>integrations</span></h6>
                            <p className='text-center'>Experience effortless integration with TCB Pay's comprehensive suite of payment solutions.</p>
                            <p className='text-center'> Our platform offers seamless integration capabilities, allowing you to connect with various third-party systems and applications to enhance your business operations. TCB Pay's integrations provide the flexibility and scalability you need to thrive in today's digital economy.</p>
                            <Link to="/integrations" className="integration-btn text-center">Discover our integrations <FontAwesomeIcon icon={faArrowRight} /> </Link>
                        </div>    
                    </div>
                    <div className='col-md-12'>
                    <div className='our-integration-pic'>
                            <div className="marquee marquee--8 mb-180">
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo1.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo2.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo3.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo4.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo5.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo6.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo7.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo8.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo9.svg`} alt="" />
                            <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo10.svg`} alt="" />
                        </div>
                    </div>
                    </div>
                    {/* <div className='col-md-12'>
                        <div className='our-integration-pic'>
                            <RunningRectangle/>
                        </div>    
                    </div>  */}
                        </div>
                    </div>
        </section>

        <section className='our-integrationsbx'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='highrisk-inner'>
                            <h6>Tailored <span>IT Solutions</span> </h6>
                            <p>Discover how our tailored IT solutions can transform your business. From custom software development and expert IT consulting to comprehensive cybersecurity measures and reliable support services, we make technology work for you.</p>
                            <Link to={`${config.siteBaseUrl}/it-solutions`} className="integration-btn4 mt-30">Discover our IT Solutions <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    {/*<div className='col-md-5'>
                         <div className='itsolution-rgtbx'>
                            <div className='itsolution-btn'>
                                {actions.map((action) => (
                                <button
                                    key={action.type}
                                    style={{
                                    backgroundColor:
                                        action.type === contentType ? "#397AB7" : "#C8DCEF",
                                    }}
                                    onClick={() => setContentType(action.type)}
                                >
                                    {action.name}
                                </button>
                                ))}
                            </div>
                            <div>
                                {contentType === "b1" && <Content1 />}
                                {contentType === "b2" && <Content2 />}
                                {contentType === "b3" && <Content3 />}
                                {contentType === "b4" && <Content4 />}
                            </div>
                        </div> 
                    </div>*/}
                    <div className='col-md-5'>
                        <div className="d-flex itsolutionh">
                            <div className="nav nav-pills order-sm-2 order-lg-1" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</button>
                                <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                                <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</button>
                                <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button>
                            </div>
                            <div className="tab-content order-sm-1 order-lg-2" id="v-pills-tabContent">
                                <div className="tab-pane show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"><img src={`${config.siteBaseUrl}/assets/imgs/itpic.png`} alt=''/></div>
                                <div className="tab-pane" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><img src={`${config.siteBaseUrl}/assets/imgs/itpic1.png`} alt=''/></div>
                                <div className="tab-pane" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><img src={`${config.siteBaseUrl}/assets/imgs/itpic2.png`} alt=''/></div>
                                <div className="tab-pane" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab"><img src={`${config.siteBaseUrl}/assets/imgs/itpic3.png`} alt=''/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            {/* <section style={{padding:'200px 0'}}>

                <RunningRectangle />

            </section> */}
        </>
    )
}
