import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';

export const IntegrationsStickySec = () => {

    return (
        <>
            <section className="credit-api-main">
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <IntrigrationLeftSec />
                        </div>
                        <div className="col-lg-9">
                            <div className="creadit-api-upper-wrapper">
                                <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                                <h2>Sticky</h2>
                                <p className='mt-30'>You can easily connect your TCB Pay account to your Sticky CRM account. For Sticky integrations, please <Link to={`${config.siteBaseUrl}/contact`}>contact us</Link> directly, as the integration process is handled internally to ensure a seamless and accurate setup.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />

                <ScrollToTop smooth />
            </section>
        </>
    )
}
