import React from 'react'
import { LegalACHTermsAndConditionsSec } from './legalACHTermsAndConditionsSec'

export const LegalACHTermsAndConditions = () => {
  return (
   <>
   <LegalACHTermsAndConditionsSec />
   </>
  )
}
