import React from 'react'
import { Banner } from './banner'
import { AchProcessSec } from './achProcessSec'

export const AchProcessing = () => {
  return (
    <>
    <Banner />
    <AchProcessSec />
    </>
  )
}
