import React, { useRef } from 'react'
import { Banner } from './banner'
import { Whitelabel_product } from './whitelabel_product'

export const WhiteLabel = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
    <Banner scrollRef={scrollRef}/>
    <Whitelabel_product ref={scrollRef} />

    </>
  )
}
