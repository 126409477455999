import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingSpendingProfile = () => {
  const exampleCodeText_4 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
	"sp_name": "profile name"
"cycle_type": "M",
"cycle_reset_day": "23",
	"cycle_spending_limit": 10000,
	"daily_spending_limit": 2000,
	"max_amt_per_tran": 1000
}`;

const exampleCodeText_5 = `{
	"message": "Spending profile successfully created.",
	"code": 00,
	"data": {
    		"spending_profile_uid": "{unique id of spending profile}",
    	"sp_name": "spending profile name"
"cycle_type": "M",
"cycle_reset_day": "23",
	"cycle_spending_limit": 10000,
		"daily_spending_limit": 2000,
		"max_amt_per_tran": 1000,
		"created_date": 0000-00-00 00:00:00
        }
}`;
        
  const onCopy = React.useCallback((id:number) => {
      const element = document.getElementById("myTooltip"+id);
      if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
          element.classList.remove("show");
          clearInterval(intervalId);
      }, 2000);
      }
  }, []);
                
  return (
  <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                      <div className="creadit-api-upper-wrapper">
                      <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      </div>
              
                      <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
            <div className="api-menu-item-wrapper mb-40">
                <p>For every API request, the following headers and parameters are required:
                </p>
                <ul>
                    <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                    <li style={{listStyleType:'disc'}}>Parameters:
                        <ul>
                            <li>- GET Requests: Include these parameters in the parameters area</li>
                            <li>- POST Requests: Include these parameters in the request JSON body.</li>
                        </ul>
                    </li>
                </ul>
                
            <div className="api-table">

            <div className="table-responsive mb-20">
                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th style={{ width: '100px' }}>Variable Name</th>
                        <th style={{ width: '420px' }}>Description</th>
                        <th style={{ width: '100px' }}>Required</th>
                        <th style={{ width: '130px' }}>Expected Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>api_username</td>
                        <td>Unique API username associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    <tr>
                        <td>api_key</td>
                        <td>Unique API key associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    
                    
                    </tbody>
                </table>
            </div>
            <ul>
                <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                <li>- All dates and times are in GMT.</li>
            </ul>

            </div>
            </div>
                      <div className="api-menu-item-title"><h2>Spending Profiles APIs</h2></div>
  
                      <div className="api-menu-item-wrapper">
                          <h3>Spending Profile Add</h3>
                          <hr/>
                          <ul className="card-url">
                          <li>
                                  <div className="card-url-list">
                                      <p>Name of API:</p>
                                      <span>Spending Profile Add</span>
                                  </div>
                                  
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Description:</p>
                                      <span>Adds a spending profile that can be used for credit card limits
                                      </span>
                                  </div>
                                  
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>URL:</p>
                                      <span>https://issuing.tcbpay.com/apis/spendingprofile/add</span>
                                  </div>
                                  
                              </li>
                              
                              <li>
                                  <div className="card-url-list">
                                      <p>Sandbox endpoint URL:</p>
                                      <span>https://issuing-stg.tcbpay.com/apis/spendingprofile/add</span>
                                  </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method:</p>
                                      <span>POST</span>
                                  </div>
                              </li>
                          </ul>
                      </div>
                      <div className="api-table">
                      <h5>JSON Body Parameters:</h5>
        
                      <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>sp_name</td>
                                <td>Name of spending profile</td>
                                <td>Yes</td>
                                <td>String</td>
                              </tr>

                              <tr>
                                <td>cycle_type</td>
                                <td>When card will reset (weekly/monthly)</td>
                                <td>Yes</td>
                                <td>String(
                                “M” - month, 
                                “W” - week
                                )
                                </td>
                              </tr>
                              <tr>
                                <td>cycle_reset_day</td>
                                <td>Day it will reset</td>
                                <td>Yes</td>
                                <td>Integer(
                                  cycle_type = M e.g., 1-30
                                  cycle_type = W e.g., 1-07
                                  )
                                </td>
                              </tr>

                              <tr>
                                <td>cycle_spending_limit</td>
                                <td>Cycle spending limit (applicable for credit card)</td>
                                <td>Yes</td>
                                <td>Integer (e.g., 100, 1000, 2000, 10000, etc.)</td>
                              </tr>
                              <tr>
                                <td>daily_spending_limit</td>
                                <td>Daily spending limit (applicable for credit card)</td>
                                <td>Yes</td>
                                <td>Integer (e.g., 100, 1000, 2000, 10000, etc.)</td>
                              </tr>
                              <tr>
                                <td>max_amt_per_tran</td>
                                <td>Maximum amount allowed per transaction (applicable for credit card)</td>
                                <td>Yes</td>
                                <td>Integer (e.g., 100, 1000, 2000, 10000, etc.)</td>
                              </tr>

                              
                            </tbody>
                          </table>
                      </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                              <p><span>Example Request Payload Body(JSON)
                              </span></p>
                          </div>
                          <div className="example-box">
                              <div className="example-box-top">
                              <div className="tooltip1" id="myTooltip4">
                              <span className="tooltiptext">Copied</span>
                              </div>
                              <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                              <FontAwesomeIcon icon={faCopy} />
                              </CopyToClipboard>
                              
                              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                  
                              </div>
  
                              <div className="ex-text-box" id="ExampleCodeText_4">
                                  <pre>{exampleCodeText_4}</pre>
                              </div>
      
                              </div>
                      </div>
                    
                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                              <p><span>Example Response Payload(JSON)
                              </span></p>
                          </div>
                          <div className="example-box">
                              <div className="example-box-top">
                              <div className="tooltip1" id="myTooltip">
                              <span className="tooltiptext">Copied</span>
                              </div>
                              <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                              <FontAwesomeIcon icon={faCopy} />
                              </CopyToClipboard>
                              
                              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                  
                              </div>
  
                              <div className="ex-text-box" id="ExampleCodeText_">
                                  <pre>{exampleCodeText_5}</pre>
                              </div>
      
                              </div>
                      </div>




  
                      </div>
  
  
                  
                  </div>
  
  
              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
  </section>
  )
  }
