import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {
  const scrollToMiddle = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
   <>
   <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-5'>
            <span><img src='assets/imgs/tcbappicon.svg' alt='' />TCB PAY APP</span>
            <h1>Run your business from your phone or tablet</h1>
            <p>As an extension of the TCB Pay Portal, the TCB Pay App ensures your business always stays in your hands, wherever you are, whatever you do.</p>
            {/* <Link to={`${config.siteBaseUrl}#show-calendly`} className="get-btn1">Get Started</Link> */}
            <Link to="https://portal.tcbpay.com/app/signup" target="_blank" className="get-btn1">Get Started</Link>
            <a onClick={scrollToMiddle} className="contact-btn" style={{cursor:'pointer'}}>Get App</a>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-6'>
            <img src='assets/imgs/tcbpayapp.svg' alt='' className='banner2'/>
            </div>
          </Row>    
        </Container>

    </section>
   </>
  )
}
