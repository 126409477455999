import React from 'react'
import { Banner } from './banner'
import { Seamless } from './seamless'
import { Faq } from './faq'

export const TcbpayPortal = () => {
  return (
    <>
    <Banner />
    <Seamless />
    <Faq />
    </>
  )
}
