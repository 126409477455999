import React, { useEffect, forwardRef } from 'react';
import { useId } from 'react';
import config from '../Config';

export const PartnerSection = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {
    const ex3 = useId();
    useEffect(() => {    

        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.type = 'text/javascript';
        script.async = true;
        
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (
    <>

    <section className='marquee-scroll streamlined-bx'>
        <article className="container">
             <h6 className="seamless-heading mb-50">What we offer our partners</h6>
            <div className="marquee1">
            <div className="marquee__group">
              <div className='marquee-inner'><img src="assets/imgs/partner-pic6.svg" alt="" /> Ability to board on multiple platforms</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic7.svg" alt="" /> Automated Boarding</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic8.svg" alt="" /> Lifetime Residuals</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic9.svg" alt="" /> 70% Residual Split</div>   
            </div>

            <div aria-hidden="true" className="marquee__group">
            <div className='marquee-inner'><img src="assets/imgs/partner-pic6.svg" alt="" /> Ability to board on multiple platforms</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic7.svg" alt="" /> Automated Boarding</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic8.svg" alt="" /> Lifetime Residuals</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic9.svg" alt="" /> 70% Residual Split</div>     
            </div>
            </div>
            <div className="marquee1 marquee--reverse">
            <div className="marquee__group">
              <div className='marquee-inner'><img src="assets/imgs/partner-pic10.svg" alt="" /> Custom Plans Available (minimums)</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic11.svg" alt="" /> No Exclusivity</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic12.svg" alt="" /> Cash Discount, Flat Rate, 3 Tier, 2 Tier and IC+ Pricing available</div>     
            </div>

            <div aria-hidden="true" className="marquee__group">
              <div className='marquee-inner'><img src="assets/imgs/partner-pic10.svg" alt="" /> Custom Plans Available (minimums)</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic11.svg" alt="" /> No Exclusivity</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic12.svg" alt="" /> Cash Discount, Flat Rate, 3 Tier, 2 Tier and IC+ Pricing available</div>   
            </div>
            </div>
            <div className="marquee1">
            <div className="marquee__group">
              <div className='marquee-inner'><img src="assets/imgs/partner-pic13.svg" alt="" /> Next Day Funding</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic14.svg" alt="" /> Analytics and Reporting</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic15.svg" alt="" /> Pass Through Schedule A</div>   
              <div className='marquee-inner'><img src="assets/imgs/partner-pic16.svg" alt="" /> Low / Med / High Risk BINs available</div> 
                
            </div>

            <div aria-hidden="true" className="marquee__group">
              <div className='marquee-inner'><img src="assets/imgs/partner-pic13.svg" alt="" /> Next Day Funding</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic14.svg" alt="" /> Analytics and Reporting</div>
              <div className='marquee-inner'><img src="assets/imgs/partner-pic15.svg" alt="" /> Pass Through Schedule A</div>   
              <div className='marquee-inner'><img src="assets/imgs/partner-pic16.svg" alt="" /> Low / Med / High Risk BINs available</div>
            </div>
            </div>

        </article>
    </section>

    <section className='streamlined-bx'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-7 d-flex align-items-center'>
                    <div className='issuing-inbx'>
                        <h6 className="seamless-heading"><span>Simple</span> deal submission </h6>
                        <p className='partnerpg'>We are here to assist you every step of the way! Your dedicated Account Manager will assist you in completing and submitting merchant applications and, for your first few contracts, we will walk you through every step of the process.</p>
                        <div className="tracking-facility text-left">
                            <div className="tracking-inr-box p-0 pe-4">
                                <img src="assets/imgs/issuing-icon13.svg" alt="" className='hgcustm' />
                                <h6>One-on-One training call</h6>
                                <div className='listing1 hg-auto'>
                                    <ul>
                                        <li>How to complete contracts correctly </li>
                                        <li>Selecting which processor fits your needs </li>
                                        <li>Collecting required documents, submitting the deal, and keying it in the system</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tracking-inr-box p-0 ps-4 pe-4">
                                <img src="assets/imgs/partner-icn5.svg" alt="" />
                                <h6>Our system will notify you when</h6>
                                <div className='listing1'>
                                    <ul>
                                        <li>An application is submitted </li>
                                        <li>More information is required</li>
                                        <li>Account is approved or declined</li>
                                        <li>A MID is generated and the account is onboarded.</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-md-5 d-flex align-items-center justify-content-end'><img src="assets/imgs/p-pic.webp" alt="" className='partner-ppic' /></div>
            </div>
        </div>
    </section>
    
    <section className='transaction-area gradient-background7'>
        <div className="wave9 d-lg-block">
            <img src="assets/imgs/p-bg.png" alt="" />
        </div>
        <div className='container text-left'>
            <div className='row'>
                <div className='col-md-12 custm-transactionbx'>
                    <h6 className="seamless-heading">Agents resell our products and services</h6>
                    <div className="row">
                        <div className='col-md-3'>
                            <div className='partner-swiperbx'>
                                <img src='assets/imgs/partner-pic1.webp' alt='' />
                                <div className='title'>TCB Pay Portal</div>
                                <p>Discover our all-in-one payment platform to boost your business growth and increase revenue.</p>
                                <div className='listing'>
                                    <ul>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Simplified payment processing</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Grow merchant reach</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Integrate advanced solutions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-swiperbx'>
                                <img src='assets/imgs/partner-pic2.webp' alt='' />
                                <div className='title'>TCB Pay Issuing</div>
                                <p>Empower your team with customizable corporate cards. All in one issuing platform.</p>
                                <div className='listing'>
                                    <ul>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Enhance business spending</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Streamline company expenses</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Enable secure transactions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-swiperbx'>
                                <img src='assets/imgs/partner-pic3.webp' alt='' />
                                <div className='title'>Integrations</div>
                                <p>Seamlessly connect with our versatile payment integrations. From shopping carts to APIs.</p>
                                <div className='listing'>
                                    <ul>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Effortless payment system</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Compatibility with platforms</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Unified payment experience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='partner-swiperbx'>
                                <img src='assets/imgs/partner-pic4.webp' alt='' />
                                <div className='title'>White-Label</div>
                                <p>Customize our products and services to match your own brand with your logo and colors.</p>
                                <div className='listing'>
                                    <ul>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Flexibility in branding</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Faster time-to-market</li>
                                        <li><img src='assets/imgs/p-arrow.svg' alt='' />Cost-effective scalability</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div className="wave10 d-lg-block">
            <img src="assets/imgs/p-bg1.png" alt="" />
        </div>
    </section>

    <section className="featuresbx partner-spin">
        <div className="container">
            <div className='row'>
                <div className='col-md-12'>
                    <h6 className='seamless-heading'><span>TCB Pay</span> is responsible for</h6>
                </div>
            </div>
                
            <div className="circle-bx">
                <div className='row'>
                    <div className='col-md-12'>
                    {/* <div className="circle1">
                        <ul>
                            <li><span className="codehim"><img src='assets/imgs/logos.webp' /></span></li>
                            <li>Merchant account onboarding</li>
                            <li>Account statements and invoicing</li>
                            <li>24/7 customer support</li>
                            <li>Submission process</li>
                            <li>Underwriting, risk monitoring & compliance</li>
                            <li>Training and relationship management</li>
                            <li>Chargeback and disputes</li>
                            <li>Lifetime residuals with 0 minimum</li>
                            <li>Installation</li>
                        </ul>
                    </div> */}
                    <div className='p-dots'><img src='assets/imgs/p-dot.svg' alt='' /></div>
                        <div className="circle">
                            
                            <ul id="ex3">
                                <span className="codehim"><img src='assets/imgs/logos.webp' /></span>
                                <li><img src='assets/imgs/pat-icon9.svg' alt='' />Merchant account onboarding</li>
                                <li><img src='assets/imgs/pat-icon8.svg' alt='' />Account statements and invoicing</li>
                                <li><img src='assets/imgs/pat-icon7.svg' alt='' />24/7 customer support</li>
                                <li><img src='assets/imgs/pat-icon6.svg' alt='' />Submission process</li>
                                <li><img src='assets/imgs/pat-icon5.svg' alt='' />Underwriting, risk monitoring <br /> & compliance</li>
                                <li><img src='assets/imgs/pat-icon4.svg' alt='' />Training and relationship management</li>
                                <li><img src='assets/imgs/pat-icon3.svg' alt='' />Chargeback and disputes</li>
                                <li><img src='assets/imgs/pat-icon2.svg' alt='' />Lifetime residuals with 0 minimum</li>
                                <li><img src='assets/imgs/pat-icon1.svg' alt='' />Installation</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>   
        </div>
    </section>

    <section className="featuresbx partner-benefits">
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
        <div className="container">
            <div className='row'>
                <div className='col-md-12'>
                    <h6 className='seamless-heading'>We make the process <span>easy</span></h6>
                </div>
            </div>
                
            <div className="featuresbx-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/partner-icn1.svg" alt="" /></div>
                        <h4>Continuous Support</h4>
                        <p>We are relationship-oriented and offer a reliable presence before, during, and after the sale.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/partner-icn2.svg" alt="" /></div>
                            <h4>Agent Care</h4>
                            <p>We provide dedicated Relationship Managers for all Agents and 24/7 dedicated full-service customer support.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/partner-icn3.svg" alt="" /></div>
                            <h4>Solid Infrastructure</h4>
                            <p>We are a global team of industry experts. Get prompt residual payments every 30 days.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/partner-icn4.svg" alt="" /></div>
                            <h4>Effortless Administration</h4>
                            <p>Easy, minimal program administration – we handle just about everything for you.</p>
                        </div> 
                    </div>
                </div>
            </div>   
        </div>
    </section>

    <section ref={ref} className="effort-area-bottom" >
            <div className="container">
                <div className="col-lg-12 m-auto">
                    <div className='calendly-inline-widget' data-url='https://calendly.com/chris-tcb/partners?hide_gdpr_banner=1&primary_color=397ab7'></div>
                </div>     
            </div>
    </section>
    </>
  );
});
