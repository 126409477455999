import React, { useEffect, forwardRef } from 'react';
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper as SwiperType } from 'swiper';
import './styleTest.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

import SwiperCore from 'swiper';
import { Mousewheel,Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

//SwiperCore.use([Pagination]);

export const Seamless = () => {

    const swiperRef = useRef<SwiperType | null>(null);
    const progressBarRef = useRef<HTMLDivElement | null>(null); // Define the progressBarRef
    const [progress, setProgress] = useState(0.5); // Set initial progress to 50%
    const totalSlides = 8; // Total number of slides
    const slidesPerView = 4; // Number of slides visible at once

    useEffect(() => {
        const handleProgressUpdate = () => {

            if (swiperRef.current) {
                const currentIndex = swiperRef.current.activeIndex;

                // Calculate progress based on total slides and slides per view
                const calculatedProgress = (currentIndex / (totalSlides - slidesPerView));
                setProgress(Math.min(0.5 + calculatedProgress * 0.5, 1)); // Start at 50%, fill remaining 50% as you scroll
            }
        };

        if (swiperRef.current) {
            swiperRef.current.on('slideChange', handleProgressUpdate);
            handleProgressUpdate();
        }

        return () => {
            if (swiperRef.current) {
                swiperRef.current.off('slideChange', handleProgressUpdate);
            }
        };
    }, []);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const onMouseMove = (event: MouseEvent) => {
        
            const rect = progressBarRef.current!.getBoundingClientRect();
            const x = event.clientX - rect.left; // Get mouse position relative to the progress bar
            const percentage = Math.max(0, Math.min(1, x / rect.width)); // Clamp between 0 and 1

            // Update the swiper to the corresponding slide
            if (swiperRef.current) {
                swiperRef.current.slideTo(Math.floor(percentage * (totalSlides - slidesPerView)));
            }
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };


    return (
        <>
            <section className="featuresbx">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <h6 className='seamless-heading'>Seamless <span>payment solutions</span></h6>
                        </div>
                    </div>

                    <div className="featuresbx-inr">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className="inr-box innerblue">
                                    <div className='imginerdiv'><img src="assets/imgs/seamless_icon.svg" alt="" /></div>
                                    <h4>Easy Integration</h4>
                                    <p>Easily connect your ecommerce website to our platform with just a few clicks. Start in seconds with our robust APIs.</p>
                                </div>
                                <Link to="/integrations" className="highrisk-inner-btn m-visible"><img src='assets/imgs/arw-agn.svg' /> Discover our integrations</Link>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box innerblue">
                                    <div className='imginerdiv'><img src="assets/imgs/feature-icon1.svg" alt="" /></div>
                                    <h4>24/7 Dedicated Support</h4>
                                    <p>Our support team is available 24/7 to assist our merchants with unparalleled customer service.</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box innerblue">
                                    <div className='imginerdiv'><img src="assets/imgs/seamless_icon1.svg" alt="" /></div>
                                    <h4>White-Label</h4>
                                    <p>Tailor your payment solutions by customizing our Portal and other TCB Pay products to reflect your branding.</p>
                                </div>
                                <Link to="/white-label" className="highrisk-inner-btn m-visible"><img src='assets/imgs/arw-agn.svg' /> View our white-label offerings</Link>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box innerblue">
                                    <div className='imginerdiv'><img src="assets/imgs/feature-icon2.svg" alt="" /></div>
                                    <h4>100% Transparency</h4>
                                    <p>No commitment, no month-to-month agreements, and no setup or hidden fees. No surprises, just honest business.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-20'>
                        <div className='col-md-6'><Link to="/integrations" className="highrisk-inner-btn d-none"><img src='assets/imgs/arw-agn.svg' /> Discover our integrations</Link></div>
                        <div className='col-md-6'><Link to="/white-label" className="highrisk-inner-btn d-none"><img src='assets/imgs/arw-agn.svg' /> View our white-label offerings</Link></div>
                    </div>


                </div>
            </section>

            <section className='allin-onepayment '>
                <div className='container'>
                    <div className='row justify-content-md-center'>
                        <div className='col-md-8'>
                            <h6 className='seamless-heading'><span>All-in-one</span> payment portal</h6>
                            <p>Centralize your payment processes with tools that ensure efficiency, security, and ease of use. This suite of features is designed to handle transactions smoothly, from routing to final processing.</p>
                            <Link to="https://calendly.com/chris-tcb/portal " className="book-btn" target='blank'>Book a free demo <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </div>
                </div>
            </section>


            <section className="slider-swiper progressbar">
                <div className='container d-block d-sm-none'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/artboard6.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/artboard7.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/artboard.svg" alt="" className='smimg' />
                                <div className='title'>ACH Processing</div>
                                <p>ACH transfers can now be processed in real-time, making it the perfect option for those who cannot accept card payments.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/creditprocessing1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/creditprocessing2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/creditprocessing.svg" alt="" className='smimg' />
                                <div className='title'>Credit Card Processing</div>
                                <p>Accept all major card types, from Visa, MasterCard, Discover, American Express, and Apple Pay.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/batchprocessing1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/batchprocessing2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/batchprocessing.svg" alt="" className='smimg' />
                                <div className='title'>Batch Processing</div>
                                <p>Save time and process large volumes of transactions all at once by uploading CSV files directly to the platform.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/qrcode1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/qrcode2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/qrcode.svg" alt="" className='smimg' />
                                <div className='title'>QR Codes</div>
                                <p>Scan-to-pay with QR codes for a fast, contactless, and secure checkout experience.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/invoicing1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/invoicing2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/invoicing.svg" alt="" className='smimg' />
                                <div className='title'>Invoicing</div>
                                <p>Customize and send invoices electronically, and allow your customers to pay with a card or ACH via a secured payment link.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/subscriptions1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/subscriptions2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/subscriptions.svg" alt="" className='smimg' />
                                <div className='title'>Subscriptions</div>
                                <p>Automate your billing cycles with our reliable recurring payment system for subscription-based services.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/powerfulreporting1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/powerfulreporting2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/powerfulreporting.svg" alt="" className='smimg' />
                                <div className='title'>Powerful Reporting</div>
                                <p>Access detailed reports about your transactions to get valuable insights about your business.</p>
                            </div>
                            <div className='slider-swiperbx'>
                                <img src="assets/imgs/paymentterminals1.svg" alt="" className='sliderv' />
                                <img src="assets/imgs/paymentterminals2.svg" alt="" className='sliderh' />
                                <img src="assets/imgs/paymentterminals.svg" alt="" className='smimg1' />
                                <div className='title'>Payment Terminals</div>
                                <p>Pair our reliable payment terminals with our mobile app in just a few simple steps for added convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fuild d-none d-lg-block'>
                    <div className='row'>
                        <div className='col-md-11 m-auto'>
                            <Swiper 
                                
                                modules={[Navigation]}
                                slidesPerView={4}
                                spaceBetween={20}
                                pagination={{ clickable: true }}
                                mousewheel={true}
                                direction={'horizontal'}
                                navigation={true}
                                onSwiper={(swiper: SwiperType) => { // Type swiper here
                                    swiperRef.current = swiper;
                                }}
                                breakpoints={{
                                    320: {
                                        width: 320,
                                        slidesPerView: 1,
                                      },
                                    576: {
                                      width: 576,
                                      slidesPerView: 2,
                                    },
                                    768: {
                                      width: 768,
                                      slidesPerView: 2,
                                    },
                                    978: {
                                        width: 978,
                                        slidesPerView: 4,
                                      },
                                      1300: {
                                        width: 1300,
                                        slidesPerView: 4,
                                      },
                                }}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/artboard6.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/artboard7.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/artboard.svg" alt="" className='smimg' />
                                        <div className='title'>ACH Processing</div>
                                        <p>ACH transfers can now be processed in real-time, making it the perfect option for those who cannot accept card payments.</p>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/creditprocessing1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/creditprocessing2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/creditprocessing.svg" alt="" className='smimg' />
                                        <div className='title'>Credit Card Processing</div>
                                        <p>Accept all major card types, from Visa, MasterCard, Discover, American Express, and Apple Pay.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/batchprocessing1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/batchprocessing2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/batchprocessing.svg" alt="" className='smimg' />
                                        <div className='title'>Batch Processing</div>
                                        <p>Save time and process large volumes of transactions all at once by uploading CSV files directly to the platform.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/qrcode1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/qrcode2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/qrcode.svg" alt="" className='smimg' />
                                        <div className='title'>QR Codes</div>
                                        <p>Scan-to-pay with QR codes for a fast, contactless, and secure checkout experience.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/invoicing1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/invoicing2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/invoicing.svg" alt="" className='smimg' />
                                        <div className='title'>Invoicing</div>
                                        <p>Customize and send invoices electronically, and allow your customers to pay with a card or ACH via a secured payment link.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/subscriptions1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/subscriptions2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/subscriptions.svg" alt="" className='smimg' />
                                        <div className='title'>Subscriptions</div>
                                        <p>Automate your billing cycles with our reliable recurring payment system for subscription-based services.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/powerfulreporting1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/powerfulreporting2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/powerfulreporting.svg" alt="" className='smimg' />
                                        <div className='title'>Powerful Reporting</div>
                                        <p>Access detailed reports about your transactions to get valuable insights about your business.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='slider-swiperbx'>
                                        <img src="assets/imgs/paymentterminals1.svg" alt="" className='sliderv' />
                                        <img src="assets/imgs/paymentterminals2.svg" alt="" className='sliderh' />
                                        <img src="assets/imgs/paymentterminals.svg" alt="" className='smimg1' />
                                        <div className='title'>Payment Terminals</div>
                                        <p>Pair our reliable payment terminals with our mobile app in just a few simple steps for added convenience.</p>
                                    </div>
                                </SwiperSlide>


                            </Swiper>

                            <div
                            ref={progressBarRef}
                            className="progress-bar-container"
                            style={{
                                width: '100%',
                                height: '10px',
                                backgroundColor: '#e0e0e0',
                                position: 'relative',
                                marginTop: '20px',
                                cursor: 'pointer',
                            }}
                            onMouseDown={handleMouseDown} 
                        >
                            <div
                                className="progress-bar"
                                style={{
                                    width: `${progress * 100}%`,
                                    height: '100%',
                                    background: `linear-gradient(to right, yellow 50%, blue)`, // Half yellow, half blue
                                    transition: 'width 0.3s',
                                }}
                            />
                            </div>
                        </div>
                    </div>
                </div>

            </section >

            <section className='enhanced'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <h6 className="seamless-heading"><span>Enhanced</span> payment options</h6>
                            <div className="tracking-facility">
                                <div className="tracking-inr-box order-sm-1 order-lg-1">
                                    <img src="assets/imgs/enhanced-icon1.svg" alt="" />
                                    <h6>Transaction Routing</h6>
                                    <p>Distribute transactions across multiple payment processors to help reduce high-volume fees from one single account.
                                    </p>
                                </div>
                                <div className="tracking-inr-box order-sm-2 order-lg-2">
                                    <img src="assets/imgs/enhanced-icon2.png" alt="" />
                                    <h6>TCB Pay App</h6>
                                    <p>Run your business from your phone while on-the-go, ensuring that business never stops.</p>
                                </div>
                                <div className="tracking-inr-box order-sm-4 order-lg-3">
                                    <img src="assets/imgs/enhanced-icon3.svg" alt="" />
                                    <h6>Multiple Currencies</h6>
                                    <p>Conduct transactions in more than 100+ currencies for global business reach.</p>
                                </div>
                                <div className="tracking-inr-box order-sm-3 order-lg-4">
                                    <img src="assets/imgs/enhanced-icon4.svg" alt="" />
                                    <h6>Next Day Funding</h6>
                                    <p>Accelerate your cash flow with next day funding availability for your business.</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-5 position-relative'>
                            <img src="assets/imgs/enhanced.svg" alt="" className='enhanced-pic' />
                            {/* <img src='assets/imgs/yellowdots.svg' alt='' className='enhanced-yellowdots' /> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="featuresbx pb-180">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <h6 className='seamless-heading'>Unwavering <span>security</span> and compliance</h6>
                        </div>
                    </div>

                    <div className="featuresbx-inr">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className="inr-box unwave-yellow">
                                    <div className='imginerdiv'><img src="assets/imgs/unwave-icon1.svg" alt="" /></div>
                                    <h4>Risk Rules</h4>
                                    <p>We created a set of 120 customizable risk rules to ensure your transactions flow securely and at the right pace.</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box unwave-yellow">
                                    <div className='imginerdiv'><img src="assets/imgs/unwave-icon4.svg" alt="" /></div>
                                    <h4>Change Logs</h4>
                                    <p>Keep track of all the changes made to your account, when it happened, and who made the change.</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box unwave-yellow">
                                    <div className='imginerdiv'><img src="assets/imgs/unwave-icon3.svg" alt="" /></div>
                                    <h4>PCI DSS Level 1</h4>
                                    <p>We adhere to the strict technical and security compliance programs established by world-class industry experts to prevent fraud.</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="inr-box unwave-yellow">
                                    <div className='imginerdiv'><img src="assets/imgs/unwave-icon2.svg" alt="" /></div>
                                    <h4>IP Monitoring</h4>
                                    <p>Detect network traffic, identify threats, and maintain system integrity by monitoring devices connecting your account.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-40'>
                        <div className='col-md-6 d-flex justify-content-center m-auto'><Link to="/security" className="unwave-btn11">Read more about our security protocols <FontAwesomeIcon icon={faArrowRight} /></Link></div>
                    </div>


                </div>
            </section>

            <section className='streamlined-bx'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-5 order-sm-3 order-lg-1'><img src="assets/imgs/streamline.svg" alt="" className="streamlined-picr" /></div>
                        <div className='col-md-1 order-sm-2 order-lg-2'>&nbsp; </div>
                        <div className='col-md-6 order-sm-1 order-lg-3'>
                            <h6 className="seamless-heading"><span>Streamlined </span> operations</h6>
                            <p>Efficiently manage your payment ecosystem with tools designed for optimal customer and transaction oversight.</p>
                            <div className="streamlined-bxinner">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="streamlined-inr-box">
                                            <img src="assets/imgs/streamline-icon1.svg" alt="" />
                                            <h6>Multiple MIDs</h6>
                                            <p>Do not switch logins to handle your various businesses. Manage them all in one single account.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="streamlined-inr-box">
                                            <img src="assets/imgs/streamline-icon2.svg" alt="" />
                                            <h6>Manage Customer Database</h6>
                                            <p>Organize and access customer data with ease, ensuring a personalized and secure service.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="streamlined-inr-box">
                                            <img src="assets/imgs/streamline-icon3.svg" alt="" />
                                            <h6>Chargebacks</h6>
                                            <p>Handle chargebacks effectively, with tools to dispute and resolve them promptly.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="streamlined-inr-box">
                                            <img src="assets/imgs/streamline-icon4.svg" alt="" />
                                            <h6>Multi-Users</h6>
                                            <p>Enable team collaboration with multi-user access and customize permissions for various users.</p>
                                        </div>
                                    </div>
                                </div>
                                <Link to={`${config.siteBaseUrl}/assets/pdf/Portal-Sell-Sheet.pdf`} target='blank' className="integration-btn4 mt-30">Download Product Sell Sheet<FontAwesomeIcon icon={faArrowRight} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='honest'>
        <div className='container'>
            <div className='row'></div>
        </div>
    </section> */}

            <section className="honest-area gradient-background1">
                <div className="wave3 d-none d-lg-block">
                    <img src="assets/imgs/wave-3.png" alt="" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-40 text-left">
                            <h6 className="seamless-heading">Honest pricing for seamless payments</h6>
                        </div>
                        <div className='col-md-8'>
                            <div className='honest-lftbx'>
                                <div className='honest-lftbx-inner'>
                                    <h6>Card-Present</h6>
                                    <p>For business that is made in-person</p>
                                    <div className='pricetxt'>2.75% + $0.20</div>
                                    <span>Per transaction</span>
                                </div>
                                <div className='honest-lftbx-inner border-0'>
                                    <h6>Card-Not-Present</h6>
                                    <p>For business that is made online</p>
                                    <div className='pricetxt'>3.5% + $0.20</div>
                                    <span>Per transaction</span>
                                </div>
                            </div>
                            <Link to="/pricing" className='pricingdetail-btn'>View pricing details <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                        <div className='col-md-4 position-relative'>
                            <div className='honest-rgtbx'>
                                <h4>High-Risk & Custom Pricing</h4>
                                <p>If you have a unique business model, reach out to us and we will find the best solutions for your business.</p>
                                <Link to={`${config.siteBaseUrl}/contact`} className='letstalk'>Let’s talk</Link>
                            </div>
                            <img src='assets/imgs/bluedots1.svg' alt='' className='blue-dots1' />
                        </div>
                    </div>
                </div>

                <div className="wave4 d-none d-lg-block">
                    <img src="assets/imgs/wave-4.png" alt="" />
                </div>

            </section>

            <section className="nutshell">
                {/* <div className="bluedotsbg d-none d-lg-block">
           <img src='assets/imgs/bluedots.png' alt='' /> 
        </div> */}

                <div className="container">
                    <div className='nutshell-inner'>
                        <div className="row">
                            <div className="col-lg-12 mb-40">
                                <h6 className="seamless-heading">TCB Pay Portal in a <span>nutshell</span></h6>
                            </div>
                        </div>
                        <div className="row dksmls">
                            <div className='col-md-3'>
                                <div className='nutshell-list'>
                                    <div className='deepblue'>
                                        <div className='heading'>Payments</div>
                                        <div className='listtxt'>
                                            <h6>Payment Options</h6>
                                            <ul>
                                                <li>Credit Card Processing</li>
                                                <li><b>ACH Processing</b></li>
                                                <li>QR Codes</li>
                                                <li>Payment links</li>
                                                <li><b>Batch processing</b></li>
                                                <li>Payment terminals</li>
                                                <li><b>Split payments</b></li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Other Payment Options</h6>
                                            <ul>
                                                <li>Capture</li>
                                                <li>Authorize</li>
                                                <li>Refund</li>
                                                <li>Void</li>
                                                <li>Credit</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Billing</h6>
                                            <ul>
                                                <li><b>Invoicing</b></li>
                                                <li><b>Recurring billing</b></li>
                                                <li>Plans</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Accepted Payment Methods</h6>
                                            <ul>
                                                <li>Cards</li>
                                                <li>Bank transfers</li>
                                                <li>Digital wallets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='nutshell-list'>
                                    <div className='deepyellow'>
                                        <div className='heading'>Fraud & Security</div>
                                        <div className='listtxt'>
                                            <h6>Fraud Prevention</h6>
                                            <ul>
                                                <li>Vault</li>
                                                <li>Card data theft protection</li>
                                                <li>PCI DSS level 1 compliance</li>
                                                <li>Encrypted transactions</li>
                                                <li><b>Tokenization</b></li>
                                                <li>AVS (Address Verification System)</li>
                                                <li>3D-Secure</li>
                                                <li>Card ID verification</li>
                                                <li>KYC (Know Your Customer)</li>
                                                <li>AML (Anti-Money Laundering)</li>
                                                <li><b>Risk rules automation</b></li>
                                                <li><b>ACH Verify</b></li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Account Security</h6>
                                            <ul>
                                                <li><b>2-factor authentication</b></li>
                                                <li><b>IP monitoring</b></li>
                                                <li>Notifications & alerts</li>
                                                <li>Change logs</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='nutshell-list'>
                                    <div className='skyblue'>
                                        <div className='heading'>Convenience</div>
                                        <div className='listtxt'>
                                            <h6>Transaction Management</h6>
                                            <ul>
                                                <li><b>Multiple MIDs capability</b></li>
                                                <li><b>Transaction Routing</b></li>
                                                <li>100+ currencies</li>
                                                <li>Next-day funding availability</li>
                                                <li>Chargeback management</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Mobile</h6>
                                            <ul>
                                                <li>Mobile-friendly view</li>
                                                <li>IOS and Android apps linked to the account</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Integrations</h6>
                                            <ul>
                                                <li><b>Easy API integrations</b></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='nutshell-list'>
                                    <div className='orange'>
                                        <div className='heading'>Operations</div>
                                        <div className='listtxt'>
                                            <h6>User Access</h6>
                                            <ul>
                                                <li>Multi-user capabilities </li>
                                                <li>Permission management</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Transparency</h6>
                                            <ul>
                                                <li><b>White-label offerings</b></li>
                                                <li>Customized dashboard</li>
                                                <li>100% transparency in pricing</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Management Tools</h6>
                                            <ul>
                                                <li>Customer database management</li>
                                                <li>Automatic card updater</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Support</h6>
                                            <ul>
                                                <li><b>24/7 customer service</b></li>
                                                <li>Email, text and phone support</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Reporting</h6>
                                            <ul>
                                                <li>Real-time transactions</li>
                                                <li>Chargebacks</li>
                                                <li>Settlements</li>
                                                <li>Cross-processors reports</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row d-block d-sm-none'>

                            <div className="accordiontcbpotal" id="accordionExample">
                                <div className="accordion-item nutshell-list" style={{border: '1px solid #CCDAE6'}}>
                                    <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{background:'#397AB7'}}>
                                        <div className='pricing-column'>
                                        <div className='pricing-row-title'>Payments</div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div className='deepblue' style={{border:'0'}}>
                                        <div className='listtxt'>
                                            <h6>Payment Options</h6>
                                            <ul>
                                                <li>Credit Card Processing</li>
                                                <li><b>ACH Processing</b></li>
                                                <li>QR Codes</li>
                                                <li>Payment links</li>
                                                <li><b>Batch processing</b></li>
                                                <li>Payment terminals</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Other Payment Options</h6>
                                            <ul>
                                                <li>Capture</li>
                                                <li>Authorize</li>
                                                <li>Refund</li>
                                                <li>Void</li>
                                                <li>Credit</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Billing</h6>
                                            <ul>
                                                <li><b>Invoicing</b></li>
                                                <li><b>Recurring billing</b></li>
                                                <li>Plans</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Accepted Payment Methods</h6>
                                            <ul>
                                                <li>Cards</li>
                                                <li>Bank transfers</li>
                                                <li>Digital wallets</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item nutshell-list" style={{border: '1px solid #f0a611'}}>
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button deepyellow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{background:'#F0A611'}}>
                                        <div className='pricing-column'>
                                        <div className='pricing-row-title'>Fraud & Security</div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div className='deepyellow' style={{border:'0'}}>
                                        <div className='listtxt'>
                                            <h6>Fraud Prevention</h6>
                                            <ul>
                                                <li>Vault</li>
                                                <li>Card data theft protection</li>
                                                <li>PCI Compliance</li>
                                                <li>Encrypted transactions</li>
                                                <li><b>Tokenization</b></li>
                                                <li>AVS (Address Verification System)</li>
                                                <li>3D-Secure</li>
                                                <li>Card ID verification</li>
                                                <li>KYC (Know Your Customer)</li>
                                                <li>AML (Anti-Money Laundering)</li>
                                                <li><b>Risk rules automation</b></li>
                                                <li><b>ACH Verify</b></li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Account Security</h6>
                                            <ul>
                                                <li><b>2-factor authentication</b></li>
                                                <li><b>IP monitoring</b></li>
                                                <li>Notifications & alerts</li>
                                                <li>Change logs</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item nutshell-list" style={{border: '1px solid #6da2d3'}}>
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button skyblue collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{background:'#6DA2D3'}}>
                                        <div className='pricing-column'>
                                        <div className='pricing-row-title'>Convenience</div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div className='skyblue' style={{border:'0'}}>
                                        <div className='listtxt'>
                                            <h6>Payment Options</h6>
                                            <ul>
                                                <li>Credit Card Processing</li>
                                                <li><b>ACH Processing</b></li>
                                                <li>QR Codes</li>
                                                <li>Payment links</li>
                                                <li><b>Batch processing</b></li>
                                                <li>Payment terminals</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Other Payment Options</h6>
                                            <ul>
                                                <li>Capture</li>
                                                <li>Authorize</li>
                                                <li>Refund</li>
                                                <li>Void</li>
                                                <li>Credit</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Billing</h6>
                                            <ul>
                                                <li><b>Invoicing</b></li>
                                                <li><b>Recurring billing</b></li>
                                                <li>Plans</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Accepted Payment Methods</h6>
                                            <ul>
                                                <li>Cards</li>
                                                <li>Bank transfers</li>
                                                <li>Digital wallets</li>
                                            </ul>
                                        </div>
                                    </div>    
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item nutshell-list" style={{border: '1px solid #feca61'}}>
                                    <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button orange heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{background:'#FECA61'}}>
                                        <div className='pricing-column'>
                                        <div className='pricing-row-title'>Operations</div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <div className='orange' style={{border:'0'}}>
                                        <div className='listtxt'>
                                            <h6>User Access</h6>
                                            <ul>
                                                <li>Multi-user capabilities </li>
                                                <li>Permission management</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Transparency</h6>
                                            <ul>
                                                <li><b>White-label offerings</b></li>
                                                <li>Customized dashboard</li>
                                                <li>100% transparency in pricing</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Management Tools</h6>
                                            <ul>
                                                <li>Customer database management</li>
                                                <li>Automatic card updater</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt'>
                                            <h6>Support</h6>
                                            <ul>
                                                <li><b>24/7 customer service</b></li>
                                                <li>Email, text and phone support</li>
                                            </ul>
                                        </div>
                                        <div className='listtxt border-0'>
                                            <h6>Reporting</h6>
                                            <ul>
                                                <li>Real-time transactions</li>
                                                <li>Chargebacks</li>
                                                <li>Settlements</li>
                                                <li>Cross-processors reports</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        
                    </div>
                </div>



            </section>

            <section className="nutshell gradient-background2">
                <div className="wave5 d-none d-lg-block">
                    <img src="assets/imgs/wave-5a.png" alt="" />
                </div>


                <div className="wave6 d-none d-lg-block">
                    <img src="assets/imgs/wave-6.png" alt="" />
                </div>

            </section>
        </>
    )
}
