import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<form name="" action="${config.gatewayApisUrlProd}/apis/update_plan.php" method="post" >
  <input type="text" name="username" value="tcbpayusername"/>
  <input type="text" name="api_key" value="tcbpayapikey"/>
  <input type="text" name="plan_id" value="existingplanid"/>
  <input type="text" name="plan_name" value="plan name"/>
  <input type="text" name="amount" value="115.45"/>
  <input type="text" name="currency" value="USD"/>
  <input type="text" name="no_of_charge" value="2"/>

  <input type="submit" value="submit" />
</form>   `;
  const exampleCodeText_2 = `{"response":{ "description": "Plan is successfully Updated", "code": "00" }`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h2>Subscriptions API</h2>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/update_plan.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Update Plan</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Update Plan API</h3>
                            <hr />
                            <p>This API is responsible to update existing subscription plan.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/update_plan.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>The table describes the request parameters for Update Plan API.</h5>

                        <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Variable Name</th>
                                <th>Description</th>
                                <th>Required</th>
                                <th>Expected Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>username</td>
                                <td>TCB Pay Gateway Username</td>
                                <td>Y</td>
                                <td>TCB Pay Gateway Username</td>
                              </tr>
                              <tr>
                                <td>api_key</td>
                                <td>TCB Pay Gateway API Key</td>
                                <td>Y</td>
                                <td>TCB Pay Gateway API Key</td>
                              </tr>
                              <tr>
                                <td>plan_id</td>
                                <td>Plan ID</td>
                                <td>Y</td>
                                <td>Existing Plan ID</td>
                              </tr>
                              <tr>
                                <td>plan_name</td>
                                <td>Plan Name</td>
                                <td>Y</td>
                                <td>Plan Name</td>
                              </tr>
                              
                              <tr>
                                <td>amount</td>
                                <td>Amount to be charged</td>
                                <td>Y</td>
                                <td>115.45</td>
                              </tr>
                              <tr>
                                <td>currency</td>
                                <td>Currency of Transaction (allowed currencies will be determined during the time of merchant onboarding).</td>
                                <td>Y</td>
                                <td>USD, EUR, GBP, CAD, AUD, NZD, AED, DKK, MXN, SEK, CHF</td>
                              </tr>
                              <tr>
                                <td>no_of_charge</td>
                                <td>No of times to charge </td>
                                <td>Y</td>
                                <td>No of charge should be greater than 0</td>
                              </tr>
                              
                              
                              
                            </tbody>
                          </table>
                </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip1">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box">
                                    <pre>{exampleCodeText_1}</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-60">
                            <h3>Response</h3>
                            <hr />
                            <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, and transaction_id. The response code for a successful API call is always 00.</p>
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip2">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_2}</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                     


                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



