import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';

export const LegalPayfacTermsAndConditionsSec = () => {

    const {seo_url} = useParams<any>();
        
    return (
        <>
        <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-start">











<div className="accordion mblegal-mnu" id="faqaccordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <NavLink to='' className="sidebar-linkmb" >
            <span className="align-middle">Payfac Terms and Conditions</span>
        </NavLink>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
        <ul className="sidebar-nav groupaside">
            <li className="sidebar-item">
                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
            </li>
        </ul>
      
      </div>
    </div>
  </div>

</div>















                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                            </div>
                            <div className="tab-content">

                                <div className="tab-pane fade show active">
                                    <div className="payfac-terms-and-conditions">
                                        <div className="container">
                                            <div className="title-sec">
                                                <h2><span>Payment Facilitator Terms &amp; conditions</span></h2>
                                            </div>
                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="text2">
                                                        <p>
                                                            TCB Pay LTD ("Payment Facilitator" or "Agent of the Payee") and its Sponsor Bank(s) ("Bank")
                                                            collectively "we", us" or "our" will provide
                                                            _____________________________________, with offices located at
                                                            ___________________________________________ ______________________ ("Sub-Merchant", "you" or
                                                            "your")
                                                            with certain Card processing services. Capitalized terms used in this Agreement are referred to
                                                            in Exhibit 1. By entering into this Sub-Merchant Processing Agreement (the
                                                            "Agreement"), you agree to comply with the (1) Card Association Rules as they pertain to Card
                                                            information you receive through the Payment Facilitator service and (2) Operating
                                                            Procedures.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Acceptance of Cards</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            You agree to comply with the Operating Procedures and all Card Association Rules, as such may be
                                                            changed from time to time. You understand that we may be required to modify the Operating
                                                            Procedures and the Agreement from time to time to comply with requirements imposed by the Card
                                                            Associations.
                                                        </p>

                                                        <p>In offering payment options to your customers, you may select any one of the following options:
                                                        </p>

                                                        <ul>
                                                            <li>(i) Accept all types of Visa and MasterCard cards, including
                                                                consumer credit and debit/check cards, and commercial credit and debit/check cards;</li>

                                                            <li>
                                                                (ii) Accept only Visa and MasterCard credit cards and commercial cards (If you select this
                                                                option, you must accept all consumer credit cards (but not consumer debit/check cards) and
                                                                all commercial card products, including business debit/check cards); or
                                                            </li>

                                                            <li>
                                                                (iii) Accept only Visa and MasterCard consumer debit/check cards (If you select this option,
                                                                you must accept all consumer debit/check card products (but not business debit/check cards)
                                                                and refuse to accept any kind of credit cards).
                                                            </li>
                                                        </ul>

                                                        <p>The acceptance options above apply only to domestic transactions.</p>

                                                        <p>
                                                            If you choose to limit the types of Visa and MasterCard cards you accept, you must display
                                                            appropriate signage to indicate acceptance of the limited acceptance category you have selected
                                                            (that is, accept only debit/check card products or only credit and commercial products).
                                                        </p>

                                                        <p>
                                                            For recurring transactions, you must obtain a written request or similar authentication from
                                                            your customer for the goods and/ or services to be charged to the customer's account,
                                                            specifying the frequency of the recurring charge and the duration of time during which such
                                                            charges may be made.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Settlement</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Upon our receipt of the Transaction Data for Card transactions, we will process the Transaction
                                                            Data to facilitate the funds transfer from the various Card Associations or the Card sales.
                                                            After we receive credit for such Transaction Data, subject to our other rights under this
                                                            Agreement, we will provide provisional credit to your Settlement Account as an Agent of the
                                                            Payee.
                                                            You must not submit transactions for payment until the goods are delivered or shipped, or the
                                                            services are performed. If the Cardholder disputes being charged for merchandise or services
                                                            before receiving them, a Chargeback may result.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Chargebacks</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Chargebacks can be received for a few reasons. The following are some of the most common reasons
                                                            for Chargebacks:
                                                        </p>

                                                        <ul>
                                                            <li>(1) a refund is not issued to a customer upon the return or
                                                                non-delivery of goods or services</li>

                                                            <li>(2) an authorization/approval code was required and not obtained
                                                            </li>

                                                            <li>(3) the transaction was allegedly fraudulent</li>

                                                            <li>
                                                                (4) the customer disputes the Card sale or the signature on the sale documentation, or
                                                                claims that the sale is subject to a set-off, defense or counterclaim; or
                                                            </li>

                                                            <li>
                                                                (5) The customer refuses to make payment for a Card sale because, in the customer's good
                                                                faith opinion, a claim or complaint has not been resolved, or has been resolved but in an
                                                                unsatisfactory manner.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Fees</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            Pursuant to the Merchant Application, you agree to pay the Payment Facilitator the fees
                                                            associated with the provision of the services.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Reserve</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            You acknowledge that in addition to any other rights afforded us hereunder, we may establish a
                                                            reserve account to satisfy your obligations or potential obligations under this Agreement
                                                            (the "Reserve Account"), which may be funded by:
                                                        </p>

                                                        <ul>
                                                            <li>(i) demand and receive immediate payment for such amounts</li>

                                                            <li>(ii) debit the account identified in the Settlement Account</li>

                                                            <li>(iii) withhold your settlement payments until all amounts are paid
                                                            </li>

                                                            <li>(iv) delay presentation of your refunds until you make a payment
                                                                to us of a sufficient amount to cover the Reserve Account</li>

                                                            <li>(v) pursue any remedies we may have at law or in equity.</li>
                                                        </ul>

                                                        <p>
                                                            The Reserve Account will contain sufficient funds to cover any unbilled processing costs plus
                                                            our estimated exposure based on reasonable criteria for Chargebacks, fines, returns and
                                                            unshipped merchandise and/or unfulfilled services. We may (but are not required to) apply funds
                                                            in the Reserve Account toward and may set off any funds that would otherwise be payable to
                                                            you against, the satisfaction of any amounts which are or become due from you pursuant to this
                                                            Agreement. The Reserve Account will not bear interest, and you will have no right or interest
                                                            in the funds in the Reserve Account; provided that upon satisfaction of all your obligations
                                                            under this Agreement, we will pay to you any funds then remaining in the Reserve Account. Any
                                                            funds in the Reserve Account may be commingled with other funds and need not be maintained in a
                                                            separate account. Effective upon our establishment of a Reserve Account, you irrevocably
                                                            grant to us a security interest in all funds, together with the proceeds thereof, that may at
                                                            any time be in our possession and would otherwise be payable to you pursuant to the terms of
                                                            this Agreement. You agree to execute and deliver to us such instruments and documents that we
                                                            may reasonably request to perfect and confirm the security interest and right of setoff set
                                                            forth in this Agreement. The parties rights and obligations under this Section shall survive the
                                                            termination of this Agreement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Financial Information</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            Upon request, you will provide us with financial statements. Such financial statements shall be
                                                            prepared in accordance with generally accepted accounting principles. You will also provide
                                                            other information concerning your business and your compliance with the terms and provisions of
                                                            this Agreement as we may reasonably request.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Data Security and Privacy</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            You represent to us that you do not have access to Card information (such as the cardholder's
                                                            account number, expiration date, and CVV2) and you will not request access to such Card
                                                            information from us. If you receive such Card information in connection with the processing
                                                            services provided under this Agreement, you agree that you will not use it for any fraudulent
                                                            purpose or in violation of any Card Association Rules, including but not limited to Payment Card
                                                            Industry Data Security Standards ("PCI DSS") or applicable law. If at any time you
                                                            believe that Card information has been compromised, you must notify us promptly and assist in
                                                            providing notification to the proper parties. You must ensure your compliance and that of any
                                                            third-party service provider utilized by you, with all security standards and guidelines that
                                                            are applicable to you and published from time to time by Visa, MasterCard or any other Card
                                                            Association, including, without limitation, the Visa U.S.A. Cardholder Information Security
                                                            Program ("CISP"), the MasterCard Site Data Protection ("SDP"), and (where
                                                            applicable), the PCI Security Standards Council, Visa, and MasterCard PA-DSS ("Payment
                                                            Application Data Security Standards") (collectively, the "Security Guidelines"). If
                                                            any Card Association requires an audit of you due to a data security compromise event or
                                                            suspected event, you agree to cooperate with such an audit. You may not use any Card information
                                                            other than for the sole purpose of completing the transaction authorized by the customer for
                                                            which the information was provided to you, or as specifically allowed by Card Rules,
                                                            Association Operating Procedures or required by law.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Term; Events of Default</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            The initial term of this Agreement shall commence upon the date of execution hereof and shall
                                                            thereafter automatically continue until terminated by either party upon the provision of
                                                            thirty (30) days' notice to the other party.
                                                        </p>

                                                        <p>If any of the following events shall occur (each an "Event of Default"):</p>

                                                        <ul>
                                                            <li>i. a material adverse change in your business, financial
                                                                condition, business procedures, prospects, products, or services; or</li>

                                                            <li>ii. any assignment or transfer of voting control of you or your
                                                                parent; or</li>

                                                            <li>iii. a sale of all or a substantial portion of your assets; or
                                                            </li>

                                                            <li>
                                                                iv. irregular Card sales by you, excessive Chargebacks, noncompliance with any applicable
                                                                data security standards, as determined by us, of any Card Association, or any other entity,
                                                                or
                                                                an actual or suspected data security breach, or
                                                            </li>

                                                            <li>
                                                                v. any other circumstances which, in our sole discretion, may increase our exposure for your
                                                                Chargebacks or otherwise present a financial or security risk to us; or any of your
                                                                conditions, covenants, obligations or representations or warranties in this Agreement are
                                                                breached in any material respect or are incorrect in any material respect when made or
                                                                deemed
                                                                to be made; or
                                                            </li>

                                                            <li>
                                                                vi. you shall file a petition or have a petition filed by another party under the Bankruptcy
                                                                Code or any other laws relating to bankruptcy, insolvency or similar arrangement for
                                                                adjustment of debts; consent to or fail to contest in a timely and appropriate manner any
                                                                petition filed against you in an involuntary case under such laws; apply for or consent to,
                                                                or
                                                                fail to contest in a timely and appropriate manner, the appointment of, or the taking of
                                                                possession by, a receiver, custodian, trustee or liquidator of itself or of a substantial
                                                                part
                                                                of its property; or make a general assignment for the benefit of creditors; or take any
                                                                corporate action for the purpose of authorizing any of the foregoing; or
                                                            </li>

                                                            <li>
                                                                vii. a violation by you of any applicable law or Card Association rule or our reasonable
                                                                belief that termination of this Agreement or suspension of services is necessary to comply
                                                                with
                                                                any law including without limitation the rules and regulations promulgated by the Office of
                                                                Foreign Assets Control of the US Department of the Treasury or your breach, as determined by
                                                                Servicers, of your requirement to comply with all applicable laws.
                                                            </li>
                                                        </ul>

                                                        <p>
                                                            Upon the occurrence of (1) an Event of Default specified in subsections (iv), (vi), or (vii), we
                                                            may consider this Agreement to be terminated immediately, without notice, and all amounts
                                                            payable hereunder shall be immediately due and payable in full without demand or other notice of
                                                            any kind, all of which are expressly waived by you, and (2) any other Event of Default,
                                                            this Agreement may be terminated by us giving not less than 10 days notice to you, and upon such
                                                            notice, all amounts payable hereunder shall be due and payable on demand.
                                                        </p>

                                                        <p>
                                                            Neither the expiration nor termination of this Agreement shall terminate the obligations and
                                                            rights of the parties pursuant to provisions of this Agreement which by their terms are
                                                            intended to survive or be perpetual or irrevocable. Such provisions shall survive the expiration
                                                            or termination of this Agreement. All obligations by you to pay or reimburse us for any
                                                            obligations associated with transactions you have submitted to us are intended to survive the
                                                            termination of this Agreement.
                                                        </p>

                                                        <p>
                                                            If any Event of Default shall have occurred and regardless of whether such Event of Default has
                                                            been cured, we may, in our sole discretion, exercise all our rights and remedies under
                                                            applicable law, and this Agreement.
                                                        </p>

                                                        <p>
                                                            The Card Associations often maintain lists of merchants who have had their merchant agreements
                                                            or Card acceptance rights terminated for cause. If this Agreement is terminated for cause,
                                                            you acknowledge that we may be required to report your business name and the names and other
                                                            information regarding its principals to the Card Associations for inclusion on such list(s).
                                                            You expressly agree and consent to such reporting if you are terminated because of the
                                                            occurrence of an Event of Default or for any reason specified as cause by Visa, MasterCard or
                                                            Discover Network. Furthermore, you agree to waive and hold us harmless from and against all
                                                            claims that you may have as a result of such reporting.
                                                        </p>

                                                        <p>
                                                            In the event we provide notice to you of an increase in the fees for Services, you may terminate
                                                            this Agreement without further cause or penalty by providing us 30 days advance written
                                                            notice of termination. You must terminate within 30 days after we provide notice of the fee
                                                            increase. However, maintaining your account with us or your continued use of our Services after
                                                            the effective date of any increase shall be deemed acceptance of the increased fees for
                                                            Services, throughout the term of this Agreement.
                                                        </p>

                                                        <p>
                                                            We may terminate this Agreement at any time and for any or no reason upon the provision of
                                                            thirty (30) days prior notice. We may also temporarily suspend or delay payments to you during
                                                            our investigation of any issue and/or designate an amount of funds that we must maintain to
                                                            protect us against the risk of, among other things, existing, potential, or anticipated
                                                            Chargebacks arising under this Agreement or other amounts that may be owing to us under this
                                                            Agreement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Warranties; Exclusion of Consequential Damages; Limitation on Liability</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            This Agreement and any addenda is an agreement for services and except as expressly provided in
                                                            this Agreement, and any addenda, we and our affiliates disclaim all representations or
                                                            warranties, express or implied, made to the Sub-Merchant or any other person, including without
                                                            limitation, any warranties regarding the quality, suitability, merchantability, fitness for
                                                            a particular purpose or otherwise (regardless of any course of dealing, custom or usage of
                                                            trade) of any services or any goods provided incidental to the Services provided under this
                                                            Agreement. Notwithstanding anything in this Agreement and any addenda to the contrary,
                                                            in no event shall we or our affiliates be liable under any theory of tort, contract, strict
                                                            liability or other legal theory for lost profits, lost revenues, lost business opportunities,
                                                            exemplary, punitive, special, incidental, indirect or consequential damages, each of which is
                                                            hereby excluded by agreement of the parties, regardless of whether such damages were foreseeable
                                                            or whether any party or any entity has been advised of the possibility of such damages.
                                                        </p>

                                                        <p>
                                                            Notwithstanding anything in this Agreement and any addenda to the contrary, we and our
                                                            affiliates' cumulative liability, in the aggregate (inclusive of any and all claims made by
                                                            Sub-Merchant against us and/or our affiliates, whether related or unrelated) for all losses,
                                                            claims, suits, controversies, breaches, or damages for any cause whatsoever (including, but not
                                                            limited to, those arising out of or related to this Agreement any addenda) and regardless of the
                                                            form of action or legal theory shall not exceed the lesser of (i) $100,000; or (ii) the
                                                            amount of fees received by us pursuant to any addenda attached and incorporated herein for
                                                            services performed in the immediately preceding 12 months.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Confidentiality</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            Each party acknowledges and agrees that any information the other may be providing to it and
                                                            that it may become aware of the confidential and proprietary information of the other party,
                                                            including but not limited to, the terms of this Agreement, financial information and other
                                                            information related to each party's business operations. Each party agrees that it will
                                                            maintain the confidentiality of such information and neither party shall disclose any such
                                                            information to any other person or entity (other than to those of its employees, agents,
                                                            contractors, representatives, and Affiliates to whom disclosure is reasonably necessary for the
                                                            furtherance of the performance of this Agreement). Notwithstanding the foregoing, the
                                                            requirements of non-disclosure shall not apply to any information which: (a) at the time of
                                                            disclosure is already in the possession of the receiving party; (b) is independently developed
                                                            by the receiving party without reliance on the disclosed confidential or proprietary
                                                            information; (c) is or becomes publicly available through no wrongdoing of the receiving party
                                                            or (d)
                                                            becomes available to receiving party on a non-confidential basis from a person, other than the
                                                            disclosing party, who is not bound by a confidentiality obligation or otherwise restricted
                                                            from transmitting the information to the receiving party. Furthermore, this section shall not
                                                            prohibit the receiving party from making legally required disclosures pursuant to subpoena,
                                                            court order or the order of any other authority having jurisdiction; provided that receiving
                                                            party shall provide the disclosing party with prompt notice, unless prohibited by law or court
                                                            order, thereof so that disclosing party may seek an appropriate protective order or other
                                                            remedies. If in the absence of a protective order or other remedy or waiver of the terms of this
                                                            section, if receiving party determines in its sole discretion that it is required by law,
                                                            regulation, legal process, or regulatory authority to disclose any such confidential or
                                                            proprietary information, receiving party may disclose such information upon written notice to
                                                            disclosing party unless prohibited by law or court order.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Visa and MasterCard Disclosure</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            Upon request, you will provide us with financial statements. Such financial statements shall be
                                                            prepared in accordance with generally accepted accounting principles. You will also provide
                                                            other information concerning your business and your compliance with the terms and provisions of
                                                            this Agreement as we may reasonably request.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Important Member Bank Responsibilities</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <ul>
                                                            <li>The Bank is the only entity approved to extend acceptance of Card Association products
                                                                directly to a Merchant.</li>

                                                            <li>The Bank must be a principal (signer) to the Merchant Agreement.</li>

                                                            <li>The Bank is responsible for educating Merchants on pertinent Visa and MasterCard Rules with
                                                                which Merchants must comply, but this information may be provided to you by Processor.</li>

                                                            <li>The Bank is responsible for and must provide settlement funds to the Merchant.</li>

                                                            <li>The Bank is responsible for all funds held in reserve.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Important Merchant Responsibilities</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <ul>
                                                            <li style={{ listStyle: 'disc' }}>Ensure compliance with cardholder data security and storage requirements.</li>

                                                            <li style={{ listStyle: 'disc' }}>Maintain fraud and chargebacks below Card Association thresholds.</li>

                                                            <li style={{ listStyle: 'disc' }}>Review and understand the terms of the Merchant Agreement.</li>

                                                            <li style={{ listStyle: 'disc' }}>Comply with Card Association rules.</li>

                                                            <li style={{ listStyle: 'disc' }}>Retain a signed copy of this Disclosure Page.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Merchant Resources</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>The Associations make excerpts of their Operating Regulations available online, including via:
                                                        </p>

                                                        <ul>
                                                            <li style={{ listStyle: 'disc' }}><Link target="_blank"
                                                                to="https://www.mastercard.us/en-us/business/overview/support/rules.html">
                                                                https://www.mastercard.us/en-us/business/overview/support/rules.html; </Link></li>

                                                            <li style={{ listStyle: 'disc' }}><Link target="_blank" to="https://usa.visa.com/support/consumer/visa-rules.html">
                                                                https://usa.visa.com/support/consumer/visa-rules.html </Link> ;</li>

                                                            <li style={{ listStyle: 'disc' }}><Link target="_blank" to="https://www.americanexpress.com/merchantopguide">
                                                                https://www.americanexpress.com/merchantopguide </Link> ;</li>

                                                            <li style={{ listStyle: 'disc' }}>
                                                                <Link target="_blank"
                                                                    to="https://www.discoverglobalnetwork.com/content/dam/discover/en_us/dgn/pdfs/">
                                                                    https://www.discoverglobalnetwork.com/content/dam/discover/en_us/dgn/pdfs/ </Link>
                                                            </li>
                                                        </ul>

                                                        <p>
                                                            MITImplementation-Guide.pdf. Each applicable Association's complete Operating Regulations are
                                                            incorporated by reference into this Agreement and will control with respect to any
                                                            conflict in terms between this Agreement and such Operating Regulation. You will not
                                                            discriminate against Cards or Issuers (e.g., limited acceptance options) except in full
                                                            compliance with
                                                            the Operating Regulations, and will comply with all Operating Regulations, applicable laws, and
                                                            regulations related to its business operations, PCI-DSS obligations, the use of an
                                                            Association's marks, and each transaction acquired hereunder. You expressly agree to accept
                                                            Cards and protect, utilize, or restrict transaction data, including the magnetic stripe and
                                                            CVV2, in accordance with the terms of this Agreement, applicable law or regulation, and the
                                                            Operating Regulations, and will cooperate with any audit requested by an Association until such
                                                            an audit is completed.
                                                        </p>

                                                        <p>
                                                            In addition to complying with each Association's obligations or prohibitions related to the
                                                            acceptance, disbursement, or resubmission of a transaction, you may not submit any illegal,
                                                            fraudulent, or unauthorized transaction and shall only submit transactions for the sale of its
                                                            own goods or services, and not any other person or company, and may not receive payment on
                                                            behalf of or, unless authorized by law, redirect payments to any other party. You covenant that
                                                            you are not a third-party beneficiary under any agreement with an Association, however, an
                                                            Association may be a third-party beneficiary of this Agreement and shall have the rights, but
                                                            not any obligation, necessary to fully enforce the terms of this Agreement against you.
                                                        </p>

                                                        <p>
                                                            You authorize the Payment Facilitator to conduct checks of your background, credit, or banking
                                                            information, as necessary and agree that all information obtained under this Agreement may be
                                                            shared with an Association. You acknowledge and agree that you are responsible for your
                                                            employees' actions, you will notify the Payment Facilitator of any 3rd party that will have
                                                            access to cardholder data, and you will immediately report all instances of a data breach to the
                                                            Payment Facilitator immediately after it reasonably identifies an incident.
                                                        </p>

                                                        <p>
                                                            You acknowledge and agree that you may be converted from the OptBlue Program to a direct Card
                                                            acceptance relationship with American Express if and when it becomes a High CV Merchant in
                                                            accordance with the Operating Regulations. You further agree that, upon conversion, i. You will
                                                            be bound by American Express' then-current Card Acceptance Agreement; and ii. American
                                                            Express will set pricing and other fees payable by you for Card acceptance.
                                                        </p>

                                                        <p>
                                                            The responsibilities above do not replace the terms of the Merchant Agreement and are provided
                                                            to ensure the Merchant understands some important obligations of each party and that the Bank
                                                            is the ultimate authority should the Merchant experience any problems.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Compliance with Laws</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>Each party agrees to comply with all laws, regulations, and Card Association Rules applicable to
                                                            it and each is responsible for their own costs and compliance of such.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Force Majeure</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            No party shall be liable for any default or delay in the performance of its obligations under
                                                            this Agreement if and to the extent such default or delay is caused directly or indirectly by
                                                            a force majeure event. In any such event, the nonperforming party shall be excused from any
                                                            further performance and observance of the obligations so affected only for as long as such
                                                            circumstances prevail, and such party continues to use commercially reasonable efforts to
                                                            recommence performance or observance as soon as practicable.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Notices</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            All notices and other communications required or permitted hereunder (other than those involving
                                                            normal operational matters relating to the Services) shall be in writing; all
                                                            communications when hand-delivered or sent by mail, courier, email,
                                                        </p>

                                                        <p>or facsimile shall be deemed delivered immediately when the sender obtains a confirmation receipt
                                                            for mail, courier, email, or facsimile, addressed as follows:</p>

                                                        <ul>
                                                            <li>
                                                                i. if to the Sub-Merchant: at the address, email address or facsimile number as provided in
                                                                the Merchant Application and/or updated during the Merchant Application account setup and
                                                                management updates
                                                            </li>

                                                            <li>
                                                                ii. if to the Payment Facilitator: at 433 Plaza Real Suite 275, Boca Raton, FL 33432 with an
                                                                email address of <Link to="mailto:support@tcbpay.com"> support@tcbpay.com </Link>
                                                            </li>

                                                            <li>iii. if to the Bank: at the address provided above</li>

                                                            <p>
                                                                Notice given in any other manner shall be effective when delivered by electronic means
                                                                (i.e., any platform offered by the Payment Facilitator and used by the Sub-Merchant to
                                                                access
                                                                their account), including but not limited to email.
                                                            </p>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Indemnification</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            The parties agree to indemnify each other from and against any losses, actions, causes of
                                                            action, claims, demands, costs, liabilities, expenses, damages, sanctions, fines, legal fees or
                                                            penalties arising from (i) a party's misrepresentation or breach of warranty, covenant, or any
                                                            provision under this Agreement; or (ii) a party's employees'/agents' fraud,
                                                            gross negligence, willful misconduct, or failure to comply with this Agreement and the Card
                                                            Association Rules.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Publicity</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            No party shall make any press release or other public disclosure concerning the terms and
                                                            conditions of this Agreement without the prior written consent of the other party (such consent
                                                            not to be unreasonably withheld).
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Applicable Law</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            This Agreement will be governed by and interpreted in accordance with the laws of the State of
                                                            Nevada, without regard to its conflict-of-law provisions. Sub-Merchant consents and submits
                                                            to the jurisdiction and venue of any state or federal court sitting in the State of Nevada with
                                                            respect to any claim arising out of or relating to this Agreement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Entire Agreement; Waiver.</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            This Agreement constitutes the entire agreement between the parties with respect to the subject
                                                            matter hereof and supersedes any previous agreements and understandings. Except as provided
                                                            in this Agreement, this Agreement can be changed only by a written agreement signed by all
                                                            parties. A party's waiver of a breach of any term or condition of this Agreement shall not be
                                                            deemed a waiver of any subsequent breach of the same or another term or condition.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Severability</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            The parties intend every provision of this Agreement and any addenda to be severable. If any
                                                            part of this Agreement and any addenda are not enforceable, the remaining provisions shall
                                                            remain valid and enforceable. In such case, the parties will in good faith modify or substitute
                                                            a provision consistent with their original intent. If any remedy fails of its essential
                                                            purpose, then all other provisions, including the limitations on liability and exclusion of
                                                            damages, will remain fully effective.
                                                        </p>

                                                        <p>The parties hereto have caused this Agreement to be executed by their duly authorized officers.
                                                        </p>

                                                        <p>THIS AGREEMENT IS NOT BINDING UPON THE PARTIES UNTIL FULLY EXECUTED BY ALL PARTIES.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="text2">
                                                        <ul className="signagree">
                                                            <li>
                                                                Sub-Merchant
                                                                <span></span>
                                                            </li>

                                                            <li>
                                                                Payment Facilitator
                                                                <span>TCB Pay LTD</span>
                                                            </li>
                                                            <li>
                                                                By
                                                                <span></span>
                                                            </li>
                                                            <li>
                                                                By
                                                                <span></span>
                                                            </li>

                                                            <li>
                                                                Name
                                                                <span></span>
                                                            </li>
                                                            <li>
                                                                Name
                                                                <span></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="text2" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Exhibit 1 - Definitions</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>
                                                            <strong>"Agent of the Payee"</strong> is the intermediary that facilitates the receipt and
                                                            settlement of payments to merchants, sub-merchants, or other payees, rather than
                                                            facilitating the transaction of funds on behalf of a sender.
                                                        </p>

                                                        <p>
                                                            <strong>"Affiliate"</strong> is an entity that, directly or indirectly, (i) owns or controls a
                                                            party to this agreement or (ii) is under common ownership or control with a party to
                                                            this agreement.
                                                        </p>

                                                        <p>
                                                            <strong> "Card"</strong> is a valid credit or debit card in the form issued under license from
                                                            Visa U.S.A., Inc. Visa International, Inc., Mastercard International Incorporated,
                                                            American Express, Discover Network or any other valid credit or debit card accepted by agreement
                                                            with the Payment Facilitator.
                                                        </p>

                                                        <p>
                                                            <strong>"Card Association"</strong> is any entity formed to administer card schemes, including
                                                            but not limited to Visa U.S.A., Inc. Visa International, Inc., Mastercard
                                                            International Incorporated, American Express, Discover Network and any other similar
                                                            organizations or associations, and any successor organizations or associations to any of the
                                                            foregoing.
                                                        </p>

                                                        <p>
                                                            <strong>"Card Association Rules"</strong> are the rules, regulations, releases, interpretations,
                                                            and other requirements (whether contractual or otherwise) imposed or adopted by any
                                                            Card Association.
                                                        </p>

                                                        <p>
                                                            <strong>"Chargeback"</strong> is a Card transaction dispute (or disputed portion) that is
                                                            returned to us by the Issuer. Sub-Merchant is responsible for payment to the Payment
                                                            Facilitator for all Chargebacks.
                                                        </p>

                                                        <p>
                                                            <strong>"Operating Procedures"</strong> mean our operating rules and regulations, provided
                                                            separately, and incorporated herein by reference, as may be changed by us from time to
                                                            time.
                                                        </p>

                                                        <p><strong>"Reserve Account" </strong> means an account established and funded at our request or on
                                                            your behalf, pursuant to the Reserve section of the Agreement.</p>

                                                        <p><strong>"Retrieval Request"</strong> is a request for information by a customer or Card
                                                            Association relating to a claim or complaint concerning a Transaction.</p>

                                                        <p>
                                                            <strong>"Settlement Account" </strong> is an account or account(s) at a financial institution
                                                            designated by Sub-Merchant as the account to be debited and credited by Payment
                                                            Facilitator or Bank* for Card transactions, fees, Chargebacks, and other amounts due under the
                                                            Agreement or in connection with the Agreement.
                                                        </p>

                                                        <p>
                                                            <strong>"Transaction"</strong> is a transaction conducted between a customer and Sub-Merchant
                                                            utilizing a Card in which consideration is exchanged between the customer and
                                                            Sub-Merchant.
                                                        </p>

                                                        <p><strong>"Transaction Data"</strong> is the written or electronic record of a Transaction.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
        </section>
        </>
    )
}
