import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import StickyBox from "react-sticky-box";

import { FaqService } from '../services/FaqService';
import classNames from 'classnames';

interface IState {
    loading: boolean;
    faqCategory: any;
    errorMsg: string;
  }

export const FaqLeftSec = () => {

  const {seo_url} = useParams<any>();

    const elementBtn = document.getElementById("menuBtnResp");
    if (elementBtn) {
      elementBtn.classList.add("collapsed");
    }
    const element = document.getElementById("collapseOne");
    if (element) {
      element.classList.remove("show");
    }

  const [state, setState] = useState<IState>({
    loading: false,
    faqCategory: [] as any,
    errorMsg: "",
});

//network request
useEffect(() => {
    setState({ ...state, loading: true });
    FaqService.getFaqCategory()
    .then((res) =>
    setState({
        ...state,
        loading: false,
        faqCategory: res.data as any,
    })
    )
    .catch((err) =>
    setState({
        ...state,
        loading: false,
        errorMsg: err.message,
    })
    );
//eslint-disable-next-line
}, []);
const { loading, faqCategory, errorMsg } = state;
const topicsCat = faqCategory.response?.topics;
const productsCat = faqCategory.response?.products;

  return (
    <>
    {topicsCat?.length > 0 &&
      <>
        <div className='faq-leftsectitle mb-faq'>Topics</div>
        <ul className="sidebar-nav groupaside mb-faq">
          {topicsCat?.map((topics: any) => (
            topics.id!=4 &&
            <>
              <li className="sidebar-item">
                <NavLink to={`/faq/${topics.seo_url}`} className="sidebar-link" >
                  <div dangerouslySetInnerHTML={{__html: topics.icon}}></div>
                  <span className="align-middle">{topics.short_name}</span>
                </NavLink>
              </li>
            </>
          ))}
        </ul>
      </>
    }

    {productsCat?.length > 0 &&
      <>
        <div className='faq-leftsectitle mb-faq'>Products</div>
        <ul className="sidebar-nav groupaside mb-faq">
          {productsCat?.map((products: any) => (
            products.id!=9 &&
            <>
              <li className="sidebar-item">
                <NavLink to={`/faq/${products.seo_url}`} className="sidebar-link" >
                  <div dangerouslySetInnerHTML={{__html: products.icon}}></div>
                  <span className="align-middle">{products.short_name}</span>
                </NavLink>
              </li>
            </>
          ))}
        </ul>
      </>
    }

<div className="accordion" id="faqaccordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <NavLink to='' className="sidebar-linkmb" >
          {topicsCat?.map((topics: any) => (
            topics.seo_url===seo_url &&
            <>
              <span dangerouslySetInnerHTML={{__html: topics.icon}}></span>
              <span className="align-middle">{topics.short_name}</span>
            </>
          ))}

          {productsCat?.map((products: any) => (
            products.seo_url===seo_url &&
            <>
              <span dangerouslySetInnerHTML={{__html: products.icon}}></span>
              <span className="align-middle">{products.short_name}</span>
            </>
          ))}

        </NavLink>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <div className='faq-leftsectitle'>Topics</div>
        <ul className="sidebar-nav groupaside">
          {topicsCat?.map((topics: any) => (
            topics.id!=4 &&
            <>
              <li className="sidebar-item">
                <NavLink to={`/faq/${topics.seo_url}`} className="sidebar-link" >
                  <div dangerouslySetInnerHTML={{__html: topics.icon}}></div>
                  <span className="align-middle">{topics.short_name}</span>
                </NavLink>
              </li>
            </>
          ))}
        </ul>
      <div className='faq-leftsectitle'>Products</div>
        <ul className="sidebar-nav groupaside">
          {productsCat?.map((products: any) => (
            products.id!=9 &&
            <>
              <li className="sidebar-item">
                <NavLink to={`/faq/${products.seo_url}`} className="sidebar-link" >
                  <div dangerouslySetInnerHTML={{__html: products.icon}}></div>
                  <span className="align-middle">{products.short_name}</span>
                </NavLink>
              </li>
            </>
          ))}
        </ul>
      </div>
    </div>
  </div>

</div>

    </>
  )
}
