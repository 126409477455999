import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { BsCircleFill } from 'react-icons/bs';

export const IssuingSpendingProfileDlt = () => {
const exampleCodeText_4 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
"spending_profile_uid": "{Unique id of spending profile}"
}`;
const exampleCodeText_5 = `{
"message": "Successfully deleted spending profile.",
"code": 00,
	"spending_profile_uid": "{Unique id of spending profile}"
	"deleted_date": "2024-01-01 23:59:59"
}`;
    
                const onCopy = React.useCallback((id:number) => {
                    const element = document.getElementById("myTooltip"+id);
                    if (element) {
                    element.classList.add("show");
                    const intervalId = setInterval(() => {
                        element.classList.remove("show");
                        clearInterval(intervalId);
                    }, 2000);
                    }
                }, []);
            
            return (
            <section className="credit-api-main">
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <IntrigrationLeftSec />
                        </div>
                        <div className="col-lg-9">
                                <div className="creadit-api-upper-wrapper">
                                <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                                </div>
                        
                                <div className="creadit-api-lower-wrapper">
                                
                                <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
                                <div className="api-menu-item-wrapper mb-40">
                                    <p>For every API request, the following headers and parameters are required:
                                    </p>
                                    <ul>
                                        <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                                        <li style={{listStyleType:'disc'}}>Parameters:
                                            <ul>
                                                <li>- GET Requests: Include these parameters in the parameters area</li>
                                                <li>- POST Requests: Include these parameters in the request JSON body.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    
                                <div className="api-table">
            
                                <div className="table-responsive mb-20">
                                    <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '100px' }}>Variable Name</th>
                                            <th style={{ width: '420px' }}>Description</th>
                                            <th style={{ width: '100px' }}>Required</th>
                                            <th style={{ width: '130px' }}>Expected Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>api_username</td>
                                            <td>Unique API username associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        <tr>
                                            <td>api_key</td>
                                            <td>Unique API key associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                                <ul>
                                    <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                                    <li>- All dates and times are in GMT.</li>
                                </ul>
            
                                </div>
                                </div>




                                <div className="api-menu-item-title"><h2>Spending Profiles APIs</h2></div>
            
                                <div className="api-menu-item-wrapper">
                                    <h3>Spending profile delete</h3>
                                    <hr/>
                                    <ul className="card-url">
                                    <li>
                                            <div className="card-url-list">
                                                <p>Name of API:</p>
                                                <span> Spending profile delete
                                                </span>
                                            </div>
                                            
                                        </li>
                                        <li>
                                            <div className="card-url-list">
                                                <p>Description:</p>
                                                <span>Remove a user from the system.
                                                </span>
                                            </div>
                                            
                                        </li>
                                        <li>
                                            <div className="card-url-list">
                                                <p>URL:</p>
                                                <span>https://issuing.tcbpay.com/apis/spendingprofile/delete</span>
                                            </div>
                                            
                                        </li>
                                        
                                        <li>
                                            <div className="card-url-list">
                                                <p>Sandbox endpoint URL:</p>
                                                <span>https://issuing-stg.tcbpay.com/apis/spendingprofile/delete</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="card-url-list">
                                                <p>Method:</p>
                                                <span>POST</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="api-table">
                                <h5>JSON Body Parameters:</h5>
            
                                <div className="table-responsive">
                                    <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '100px' }}>Variable Name</th>
                                            <th style={{ width: '420px' }}>Description</th>
                                            <th style={{ width: '100px' }}>Required</th>
                                            <th style={{ width: '130px' }}>Expected Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>spending_profile_uid</td>
                                            <td>Unique ID received upon creation of spending profile you are deleting</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
            
                                <div className="example-box-wrapper">
                                    <div className="d-flex align-items-start">
                                        <b></b>
                                        <p><span>Example Request Payload Body(JSON)
                                        </span></p>
                                    </div>
                                    <div className="example-box">
                                        <div className="example-box-top">
                                        <div className="tooltip1" id="myTooltip4">
                                        <span className="tooltiptext">Copied</span>
                                        </div>
                                        <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                        <FontAwesomeIcon icon={faCopy} />
                                        </CopyToClipboard>
                                        
                                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                            
                                        </div>
            
                                        <div className="ex-text-box" id="ExampleCodeText_4">
                                            <pre>{exampleCodeText_4}</pre>
                                        </div>
                
                                        </div>
                                </div>

                                <div className="example-box-wrapper">
                                    <div className="d-flex align-items-start">
                                        <b></b>
                                        <p><span>Example Response Payload(JSON)
                                        </span></p>
                                    </div>
                                    <div className="example-box">
                                        <div className="example-box-top">
                                        <div className="tooltip1" id="myTooltip5">
                                        <span className="tooltiptext">Copied</span>
                                        </div>
                                        <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                        <FontAwesomeIcon icon={faCopy} />
                                        </CopyToClipboard>
                                        
                                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                            
                                        </div>
            
                                        <div className="ex-text-box" id="ExampleCodeText_5">
                                            <pre>{exampleCodeText_5}</pre>
                                        </div>
                
                                        </div>
                                </div>
    
                                </div>

                                <div className="api-menu-item-wrapper mt-60">
                                    <h3>Response Codes</h3>
                                    <hr />
                                    <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, two elements are returned, code, description token. The response code is always 00 for a successful API call.</p>
                                    <div className="table-responsive  mb-20">
                                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{width: '100px'}}>Code</th>
                                                    <th style={{width: '420px'}}>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>00</td>
                                                    <td>Success</td>
                                                </tr>
                                                <tr>
                                                    <td>01</td>
                                                    <td>API Key is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>02</td>
                                                    <td>Username is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>03</td>
                                                    <td>Spending profile uid is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>04</td>
                                                    <td>Spending profile name is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>05</td>
                                                    <td>Cycle type is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>06</td>
                                                    <td>Cycle reset day is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>07</td>
                                                    <td>Cycle spending limit is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>08</td>
                                                    <td>Daily spending limit is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>09</td>
                                                    <td>Maximum amount per transaction is missing or invalid.</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Cycle spending limit should be equal to or greater than daily spending limit.</td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>Maximum amount per transaction should be equal to or less than cycle spending limit and daily spending limit.</td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>Cannot create more spending profiles. 50 spending profiles have already been created.</td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td>Unable to create spending profile.</td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>Unable to delete spending profile.</td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td>Unable to modify spending profile.</td>
                                                </tr>
                                                <tr>
                                                    <td>16</td>
                                                    <td>Unable to modify spending profile that has cards attached to it.</td>
                                                </tr>
                                                <tr>
                                                    <td>17</td>
                                                    <td>Unable to delete spending profile that has cards attached to it. </td>
                                                </tr>
                                                <tr>
                                                    <td>18</td>
                                                    <td>Unable to fetch spending profile list.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                </div>
                                </div>
            

            
                            
                            </div>
            
            
                        </div>
                    </div>
                </div>
                <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
                <ScrollToTop smooth />
            </section>
            )
            }
