import React from 'react'
import CountUp from 'react-countup';

export const ItCounter = () => {
  return (
    <>
    <section className='counter-bx'>
        <div className='container'>
            <div className='row'>
                
                <div className='col-md-3'>
                    <div className='counter-txt'>
                    {/* <CountUp start={0} end={1000} decimal="." duration={2.75} delay={0} />+ */}
                    <CountUp start={0} end={1000} separator="" duration={2.75} delay={0} />+
                    <div className='countertxtin'>Successful projects</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    <CountUp start={0} end={250} duration={2.75} delay={0} />+
                    <div className='countertxtin'>Happy clients</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    <CountUp start={0} end={50} duration={2.75} delay={0} />+
                    <div className='countertxtin'>Expert developers</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    {/* <CountUp start={0} end={50} duration={2.75} delay={0}/> */}
                    <img src='assets/imgs/it-iconinfinity.svg' alt='' style={{ height: '38px' }}/> 
                    <div className='countertxtin'>Satisfaction</div>
                    </div>
                </div>
               
            </div>
        </div>

    </section> 
    </>
  )
}
