import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqEquipmentSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                      <h2 className='faq-rgtsectitle'>Equipment</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What payment terminal can I use with TCB Pay?</div>
                        <div className='qusans'>
                            <p>We are integrated with the Ingenico Lane 3000. You may use it with the TCB Pay Portal and the TCB Pay App. 
                            </p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Can I accept contactless payments like Apple Pay and Google Pay with the Ingenico Lane 3000?</div>
                        <div className='qusans'>
                            <p>Yes, the Ingenico Lane 3000 can accept contactless payments.</p>
                        </div>
                    </div>
                

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
