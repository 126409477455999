import React from 'react'
import { Banner } from './banner'
import { Contactdetail } from './contactdetail'

export const ContactUs = () => {
  return (
    <>
    <Banner />
    {/* <Contactdetail /> */}
    </>
  )
}
