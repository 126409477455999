import React from 'react'
import { Banner } from './banner'
import { Counter } from './counter'
import { Aboutcontent } from './aboutcontent'

export const AboutUs = () => {
  return (
    <>
    <Banner />
    <Counter />
    <Aboutcontent />
    </>
  )
}
