import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const FaqTcbIssuingSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                  <h2 className='faq-rgtsectitle'>TCB Pay Issuing Wiki</h2>
                  <div className='faq-rgt mt-30 mb-40'>
                    <div className='row'>
                       <div className='col-md-7'>
                        <div className='innerbx pb-30'>
                        <h6>User guide</h6>
                        <p className="mt-20 mb-20">Explore the <b>TCB Pay Issuing Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform. </p>
                        <Link className='wiki-btn mb-0' target='_blank' to="/wiki-issuing">Go to TCB Pay Issuing Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                      </div>
                      <div className='col-md-5'><img src="assets/imgs/faq-pic.webp" alt="" className='faqissuingpic' /></div> 
                    </div>
                      
                      
                    </div>
                      <h2 className='faq-rgtsectitle'>TCB Pay Issuing</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I get support for TCB Pay Issuing-related issues?</div>
                        <div className='qusans'>
                            <p>Please contact our support team at <Link to="mailto:issuing@tcbpay.com">issuing@tcbpay.com</Link> and our team will assist you.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is a corporate card?</div>
                        <div className='qusans'>
                            <p>A corporate card is a specialized credit card issued to employees for charging approved business-related expenses. It's linked to the company's account, facilitating the management of corporate expenditures and simplifying the purchasing and accounting processes within the organization.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is TCB issuing, and how does it relate to your new corporate card program?</div>
                        <div className='qusans'>
                            <p>TCB Pay Issuing is a platform that provides corporate card programs to companies, allowing the issuing of Mastercard corporate cards with flexible terms, including both pre-paid and post-paid card programs. These cards can be used wherever Mastercard is accepted, and companies can benefit from the instant issuance of digital cards or physical cards with speedy delivery.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Why switch to a corporate card?</div>
                        <div className='qusans'>
                            <p>Opting for a corporate card streamlines expense management, offering a clearer track of spending, reducing the necessity for cash advances, and enhancing adherence to company spending policies. </p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I use my card?</div>
                        <div className='qusans'>
                            <p>You may swipe, insert, or tap the card anywhere Mastercard© is accepted. You may also add the card to your mobile wallet and pay with your phone.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Where can I use my card?</div>
                        <div className='qusans'>
                            <p>Your TCB Pay card is accepted at a multitude of Mastercard locations worldwide. It includes customizable controls at the card level, allowing your company to specify limits on where, when, and how the card is used. For detailed guidelines on your card's usage for business expenses, please consult with your TCB Pay program administrator.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How can I get a corporate card?</div>
                        <div className='qusans'>
                            <p>To obtain a corporate card, get in touch with our Sales team which will submit a TCB Pay Issuing application on your behalf. This process typically requires submitting your business's financial information, including credit history, financial statements, and tax identification.</p>
                            <p>Once approved, your company is set up with an account, and you can start distributing cards to designated employees.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is the advantage of choosing TCB Pay Issuing?</div>
                        <div className='qusans'>
                            <p>TCB Pay's corporate card offers benefits such as efficient expense management, instant reporting, and potential savings. Their platform is tailored to help businesses control their expenditures, automate expense reporting, and provide valuable financial insights.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How much does the TCB Pay corporate card cost?</div>
                        <div className='qusans'>
                            <p>The good news is TCB Pay Issuing won't cost you anything! The TCB Pay corporate card is offered without any monthly or annual fees, making it a cost-effective solution for business expense management.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Will applying impact my personal credit score?</div>
                        <div className='qusans'>
                            <p>Generally, a corporate card application involves a credit inquiry on the business rather than the individual, so it shouldn't affect your personal credit score. However, it's wise to confirm with TCB Pay to understand their specific credit assessment process.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I order cards?</div>
                        <div className='qusans'>
                            <p>You may order either a physical card, which is shipped by mail within 7-10 business days, or a digital card, which is available instantly. The platform provides management tools for tracking transactions, managing cards and users, and setting spending profiles.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What types of transactions can be secured using TCB Pay Issuing in the corporate card program?</div>
                        <div className='qusans'>
                            <p>TCB Pay Issuing secures all transactions made with corporate cards, including those flagged as suspicious or declined for non-fraudulent reasons, ensuring a comprehensive security measure for all corporate card activities.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Is TCB issuance compatible with various payment methods and networks?</div>
                        <div className='qusans'>
                            <p>Yes, TCB Pay Issuing is compatible with the Mastercard network, ensuring widespread acceptance and usability across various payment methods and networks where Mastercard is accepted.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What measures are in place to address any potential issues or errors related to TCB Pay Issuing during transactions?</div>
                        <div className='qusans'>
                            <p>Users can dispute transactions through the platform by contacting the support team, indicating a process for addressing issues or errors related to transactions.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What documentation or reporting is available to track and manage TCB Pay Issuing and transactions?</div>
                        <div className='qusans'>
                            <p>The platform provides comprehensive reports on spending, including overviews by category, user, and refunds, accessible by users, admins, and analysts, with customizable access and restrictions.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How to change my name on a TCB Pay card?</div>
                        <div className='qusans'>
                            <p>You may contact your account administrator so they can issue you a new card with your new name on it.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How does TCB Pay Issuing enhance the security of our corporate card transactions?</div>
                        <div className='qusans'>
                            <p>TCB Pay Issuing enhances security through the Alerts and Notifications system provided by ComData, their card issuer partner. This system sends text messages for transactions flagged as suspicious and for non-fraudulent declines (e.g., incorrect PIN), allowing for immediate authorization or denial of these transactions.</p>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
