import React from 'react'
import { FaqTcbPortalSec } from './faqTcbPortalSec'

export const FaqTcbPortal = () => {
  return (
    <>
    <FaqTcbPortalSec />
    </>
  )
}
