import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const RetailSec = () => {
  return (
    <>
    <section className="ecommerce-bx gradient-background9">
        <div className="wave19 d-lg-block">
            <img src="assets/imgs/wave-19.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h6 className="seamless-heading abut-txt text-left">We make the process easy</h6>
                </div>
                
                    <div className="tracking-facility text-left">
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/r-icon1.svg" alt="" />
                            <h6>Easy Onboarding Process</h6>
                            <p>Our sales team will assist you throughout the entire process, from submitting the application to setting up your account for a seamless start.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/r-icon2.svg" alt="" />
                            <h6>24/7 Customer Service</h6>
                            <p>Our support team is available 24/7 to assist our merchants with unparalleled customer service.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/r-icon3.svg" alt="" />
                            <h6>Get Paid Easily</h6>
                            <p>Accept major cards like Visa, MasterCard, Discover, and American Express, as well as PIN-debit, ACH, and QR code payments.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/r-icon4.svg" alt="" />
                            <h6>High Security</h6>
                            <p>Rest easy with industry-leading security tools and services to help safeguard your business against fraud.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div className="wave4 d-lg-block">
        <img src="assets/imgs/wave-20.png" alt="" />
        </div>
        
    </section>

    <section className="gallery">
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center'>
            <div className="left">
              <div className="desktopContent">
              <div className="desktopContentSection1">
                  <div className='tcbpayinfobx-inner'>
                    <h4>Explore our comprehensive <span>payment partner</span></h4>
                    <p>The TCB Pay Portal is simple, efficient, and accessible from any device. It allows you to accept payments in multiple currencies and consolidate multiple MIDs into one account.</p>
                    <Link to="/portal" className="integration-btn1">Discover the Portal <FontAwesomeIcon icon={faArrowRight} /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="right1">
                <div className='desktopPhoto1'><img src='assets/imgs/r-pic1.svg' alt=''/></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className='tcbpayapp pt-50'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 order-sm-2 order-lg-1'>
                    <img src='assets/imgs/r-pic.svg' alt="" className='retail-pic' />
                </div>
                <div className='col-md-6 d-flex align-items-center order-sm-1 order-lg-2'>
                    <div className="our-integration-inner p-80"><h6>TCB Pay <span>App</span></h6>
                    <p>Access TCB Pay’s comprehensive suite of services seamlessly through our mobile app, extending the functionality of the TCB Pay Portal to your fingertips. With the TCB Pay App, managing your business transactions becomes effortless, allowing you to streamline operations wherever you go.</p>
                    <Link to='/app' className="integration-btn mt-30">Discover the TCB Pay App <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='retail-bg'></section>

    <section className="gallery pb-248 m-top200">
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center'>
            <div className="left">
              <div className="desktopContent">
              <div className="desktopContentSection1">
                  <div className='tcbpayinfobx-inner'>
                    <h4>Discover our <span>White-Label</span> offerings </h4>
                    <p>Our White-Label service offers customizable products and services, allowing you to create a seamless customer experience with your branding. It’s cost-effective, saving you money on development, infrastructure, and staff, as we handle these aspects.</p> 
                    <p>Our customer support team ensures a swift setup process, simplifying it for busy users. The solution is scalable, accommodating your business growth without technical limitations.</p>
                    <Link to="/white-label" className="integration-btn1">Discover White-Label <FontAwesomeIcon icon={faArrowRight} /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="right1">
                <div className='desktopPhoto1'><img src='assets/imgs/e-pic2.svg' alt=''/></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
