import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingTransactionsApi = () => {
    const exampleCodeText_4 = `{
	"message": "Transactions successfully received.",
	"data": {
	"current_page": 1,
	"total_pages": 1
    	"transactions": [
        	{
            	"transaction_details": {
                		"transaction_id": "{uid of transaction}",
                		"card_uid": "{uid of card performing transaction}",
				"card_last_four": "1234"
                		"amount": "500.00",
                		"status": "Approved",
                    		"merchant": "TCB PAY",
               		 	"approval_code": "011111"
               		 	"transaction_time_gmt": "2024-01-10 23:59:59",
                		"authorization_time_gmt": "2024-01-10 23:59:59",
                		"posted_time_gmt": "2024-01-10 23:59:59",
                		"void_time_gmt": null
            		}
        	}
    	]
	}
}`;


  const onCopy = React.useCallback((id:number) => {
      const element = document.getElementById("myTooltip"+id);
      if (element) {
        element.classList.add("show");
        const intervalId = setInterval(() => {
          element.classList.remove("show");
          clearInterval(intervalId);
        }, 2000);
      }
    }, []);

return (
  <section className="credit-api-main">
  <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
    <div className="container">
        <div className="row">
            <div className="col-lg-3">
                <IntrigrationLeftSec />
            </div>
            <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                      <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
                                <div className="api-menu-item-wrapper mb-40">
                                    <p>For every API request, the following headers and parameters are required:
                                    </p>
                                    <ul>
                                        <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                                        <li style={{listStyleType:'disc'}}>Parameters:
                                            <ul>
                                                <li>- GET Requests: Include these parameters in the parameters area</li>
                                                <li>- POST Requests: Include these parameters in the request JSON body.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    
                                <div className="api-table">
            
                                <div className="table-responsive mb-20">
                                    <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '100px' }}>Variable Name</th>
                                            <th style={{ width: '420px' }}>Description</th>
                                            <th style={{ width: '100px' }}>Required</th>
                                            <th style={{ width: '130px' }}>Expected Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>api_username</td>
                                            <td>Unique API username associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        <tr>
                                            <td>api_key</td>
                                            <td>Unique API key associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                                <ul>
                                    <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                                    <li>- All dates and times are in GMT.</li>
                                </ul>
            
                                </div>
                                </div>

                                <div className='api-menu-item-title'>
                                  <h2>Transactions API</h2>
                                </div>
                      
                      <ul className="card-url">
                      <li>
                              <div className="card-url-list">
                                  <p>Name of API:</p>
                                  <span>Last 30 Days Transactions</span>
                              </div>
                              
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Description:</p>
                                  <span>Retrieves transaction details for the last 30 days.</span>
                              </div>
                              
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>URL:</p>
                                  <span>https://issuing.tcbpay.com/apis/transactions/report</span>
                              </div>
                              
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Sandbox endpoint URL:</p>
                                  <span>https://issuing-stg.tcbpay.com/apis/transactions/report</span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Method:</p>
                                  <span>GET</span>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div className="api-table">
                  <h5>Parameters:</h5>
                    <div className="table-responsive">
                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: '100px' }}>Variable Name</th>
                              <th style={{ width: '420px' }}>Description</th>
                              <th style={{ width: '100px' }}>Required</th>
                              <th style={{ width: '130px' }}>Expected Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>page_no</td>
                              <td>Specified Page number - 100 records per page</td>
                              <td>Yes</td>
                              <td>INT</td>
                            </tr>
                            <tr>
                              <td>transaction_type</td>
                              <td>Specified transaction type</td>
                              <td>No</td>
                              <td>Comma separated String(“Approved”, “Declined”, “Refund”, “Pending”, “Reversal”)</td>
                            </tr>
                            
                            <tr>
                              <td>start_date</td>
                              <td>Start date based on the created date of a user</td>
                              <td>No</td>
                              <td>Date</td>
                            </tr>
                            <tr>
                              <td>end_date</td>
                              <td>End date based on the created date of a user</td>
                              <td>No</td>
                              <td>Date</td>
                            </tr>

                            
                        
                          </tbody>
                        </table>
                    </div>
                    <div className="example-box-wrapper">
                        <div className="d-flex align-items-start">
                            <b></b>
                           <p><span>Example Response Payload (JSON):</span></p>
                        </div>
                        <div className="example-box">
                          <div className="example-box-top">
                          <div className="tooltip1" id="myTooltip4">
                            <span className="tooltiptext">Copied</span>
                          </div>
                          <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                            <FontAwesomeIcon icon={faCopy} />
                          </CopyToClipboard>
                          
                          <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                             
                          </div>

                          <div className="ex-text-box" id="ExampleCodeText_4">
                              <pre>{exampleCodeText_4}</pre>
                          </div>
  
                          </div>
                    </div>

                  </div>

                    <div className="api-menu-item-wrapper mt-60">
                        <h3>Response Codes</h3>
                        <hr />
                        <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, two elements are returned, code, description token. The response code is always 00 for a successful API call.</p>
                        <div className="table-responsive  mb-20">
                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: '100px' }}>Code</th>
                              <th style={{ width: '420px' }}>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>00</td>
                              <td>Success</td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>API Key is missing or invalid.</td>
                            </tr> 
                            <tr>
                              <td>02</td>
                              <td>Username is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>03</td>
                              <td>Start date is invalid date format.</td>
                            </tr> 
                            <tr>
                              <td>04</td>
                              <td>End date is invalid date format.</td>
                            </tr> 
                            <tr>
                              <td>05</td>
                              <td>Transaction type is missing or invalid.</td>
                            </tr> 
                            <tr>
                              <td>06</td>
                              <td>Page number is missing or invalid.</td>
                            </tr> 
                            <tr>
                              <td>07</td>
                              <td>No transactions on record.</td>
                            </tr>                                                 
                          </tbody>
                        </table>
                        </div>
                    </div>
             
                 


            </div>
        </div>
    </div>
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
    <ScrollToTop smooth />
  </section>
)
}
