import React,{useState,useRef} from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../Config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { ContactUsService } from '../services/ContactUsService';

type FormData = {
  contact_type:string,
  company_name:string,
  full_name:string,
  email:string,
  phone_number:string,
  message:string
};

type FormErrors = {
  contact_type?:string,
  company_name?:string,
  full_name?:string,
  email?:string,
  phone_number?:string,
  message?:string,
  captcha?:string,
  common_err?:string,
  success?:string
};


export const Banner = () => {

  const [formData, setFormData] = useState<FormData>({
    contact_type:'General Inquiry',
    company_name:'',
    full_name:'',
    email:'',
    phone_number:'',
    message:''
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange2 = (e: React.ChangeEvent<HTMLSelectElement>) : void => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
    setFormErrors({captcha:''});
  };


  const validate = () => {
    const errors: FormErrors = {};

    if (!formData.contact_type) {
      errors.contact_type = 'Contact type is required';
    }

    if (!formData.company_name) {
      errors.company_name = 'Company name is required';
    } else if (!/^[0-9a-zA-Z\s]+$/.test(formData.company_name)) {
      errors.company_name = 'Company name is invalid';
    }

    if (!formData.full_name) {
      errors.full_name = 'Full name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.full_name)) {
      errors.full_name = 'Full name is invalid';
    }

    if (!formData.email) {
      errors.email = 'Email address is required';
    } else if (!/^[a-zA-Z][_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,10})$/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formData.phone_number) {
      errors.phone_number = 'Phone number is required';
    } else if (!/^[0-9+]+$/.test(formData.phone_number)) {
      errors.phone_number = 'Phone number is invalid';
    } else if (formData.phone_number.length<10) {
      errors.phone_number = 'Phone number should be minimum 10 digits';
    } else if (formData.phone_number.length>13) {
      errors.phone_number = 'Phone number should be maximum 12 digits';
    }
    
    if (!formData.message) {
      errors.message = 'Message is required';
    } else if (!/^[0-9a-zA-Z.,'":_-\s]+$/.test(formData.message)) {
      errors.message = 'Message is invalid';
    }

    if (!captchaValue) {
      errors.captcha = 'Captcha is required';
    } else {
      ContactUsService.googleCaptchaVerify(captchaValue)
        .then((res) =>{
          console.log(res);
          // const capApiResponse:any = res.success;
          // if(capApiResponse){

          // }
        }
      )
      .catch((err) =>{
        errors.captcha = err;
        }
      );
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleSubmit =  (event:React.FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();
    const submitBtnElement = document.getElementById("submitBtn");
    if (submitBtnElement) {
      submitBtnElement.classList.add("disabled");
    }

    if (captchaRef.current) {
      captchaRef.current.reset();
      setCaptchaValue(null);
    }
    if(validate()) {

      ContactUsService.sendContactFormData(formData)
      .then((res) =>{
            const apiResponse:any = res.data;
            if(apiResponse){
              const response:any = apiResponse.response;
              const apiCode:any = response.code;
              const apiDescription:any = response.description;

              if(response.code==='00'){
                setFormData({
                  contact_type:'General Inquiry',
                  company_name:'',
                  full_name:'',
                  email:'',
                  phone_number:'',
                  message:''
                });
                setFormErrors({
                  success: response.description,
                });
                const intervalId = setInterval(() => {
                  setFormErrors({
                    success: '',
                  });
                  clearInterval(intervalId);
                }, 10000);
              } else {
                setFormErrors({
                  ...formErrors,
                  common_err: response.description,
                });
              }
            }

            if (submitBtnElement) {
              submitBtnElement.classList.remove("disabled");
            }
          }
      )
      .catch((err) =>{
          console.log('err');
          if (submitBtnElement) {
            submitBtnElement.classList.remove("disabled");
          }
          }
      );

      console.log('Form data submitted:', formData);
    } else {
      console.log('Form contains errors');
      if (submitBtnElement) {
        submitBtnElement.classList.remove("disabled");
      }
    }
    

  };



  return (
    <>
    <section className='contact-bg'>
      
      <section className='contactus-bx gradient-backgroundfull'>
        <img src={`${config.siteBaseUrl}/assets/imgs/contct-dots.svg`} alt='' className='cdots' />  
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className="cdots1" />
          <Container className='mvcontainer'>
            <Row>
              <Col xs lg="6">
              <div className='contact-bx'>
                <div className='contact-txt'>
                  <img src={`${config.siteBaseUrl}/assets/imgs/contact-icn1.svg`} alt='' /> Let’s talk
                  </div>
                  <div className='contact-txt1'>Our team will be happy to answer your questions. Fill out the form below and we will be in touch as soon as possible.</div>
                  <form id="contactForm" name="contactForm" className="contactForm" onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-md-12">
                          <Form.Group className="mb-3">
                            <Form.Select aria-label="Default select example" onChange={handleChange2} name='contact_type'>
                              <option value="General Inquiry">General Inquiry</option>
                              <option value="Billing">Billing</option>
                              <option value="Bug Reporting">Bug Reporting</option>
                              <option value="Compliance">Compliance</option>
                              <option value="Hardware">Hardware</option>
                              <option value="Integrations">Integrations</option>
                              <option value="Issuing">Issuing</option>
                              <option value="IT Solutions">IT Solutions</option>
                              <option value="Job">Job</option>
                              <option value="Portal">Portal</option>
                              <option value="Statements">Statements</option>
                              <option value="Support">Support</option>
                            </Form.Select>
                            {formErrors.contact_type && <span style={{ color: 'red' }}>{formErrors.contact_type}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='company_name' maxLength={50} value={formData.company_name} onChange={handleChange} placeholder="Company Name" />
                          {formErrors.company_name && <span style={{ color: 'red' }}>{formErrors.company_name}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='full_name' maxLength={50} value={formData.full_name} onChange={handleChange} placeholder="Full Name" />
                          {formErrors.full_name && <span style={{ color: 'red' }}>{formErrors.full_name}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='email' maxLength={100} value={formData.email} onChange={handleChange} placeholder="Email Address" />
                          {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-6"> 
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='phone_number' maxLength={13} value={formData.phone_number} onChange={handleChange} placeholder="Phone Number" />
                          {formErrors.phone_number && <span style={{ color: 'red' }}>{formErrors.phone_number}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3">
                          <Form.Control as="textarea" name='message' value={formData.message} rows={6} onChange={handleChange} placeholder="Your Message" />
                          {formErrors.message && <span style={{ color: 'red' }}>{formErrors.message}</span>}
                          </Form.Group>
                          
                        </div>
                        <div className="col-md-6 mb-3"> 
                        <ReCAPTCHA 
                          sitekey={config.gCaptchaSiteKey}
                          onChange={handleCaptchaChange}
                          ref={captchaRef}
                        />
                        {formErrors.captcha && <span style={{ color: 'red' }}>{formErrors.captcha}</span>}
                        </div>
                        
                        <div className="col-md-12">
                          <Form.Group>
                            <Button type="submit" id="submitBtn" className="btn btn-warning w-100">Send Message</Button>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 mt-3"> 
                        {formErrors.common_err && <span style={{ color: 'red' }}>{formErrors.common_err}</span>}
                        {formErrors.success && <span className='frmcd'>{formErrors.success}</span>}
                        </div>
                      </div>
                  </form>
              
                </div>
              </Col>
              <Col xs lg="6">
                <div className='contact-rgtbx'>
                <img src={`${config.siteBaseUrl}/assets/imgs/contact-icn2.svg`} alt='' /> 
                <h4>Interested in opening a TCB Pay account?</h4>
                <p>See our general <Link to="/faq">Frequently Asked Questions</Link> and/or contact our Sales team using the contact form.</p>    
                </div>
                <div className='contact-rgtbx'>
                <img src={`${config.siteBaseUrl}/assets/imgs/contact-icn3.svg`} alt='' /> 
                <h4>Schedule a free consultation</h4>
                <p>Schedule a free consult with one of our experts to get a custom assessment of your business needs. We will answer any questions you may have. 
                  <br /><br /><Link to={`${config.siteBaseUrl}#show-calendly`}>Schedule Now</Link></p>    
                </div>
                <div className='contact-rgtbx1'>
                <img src={`${config.siteBaseUrl}/assets/imgs/contact-icn4.svg`} alt='' /> 
                <h4>Need assistance?</h4>
                <p>If you already have a TCB Pay account, you may visit our <Link to="/wiki-portal">Portal</Link> and <Link to="/wiki-issuing">Issuing</Link> Wikis, fill out the form, email our customer care team at <Link to="mailto:support@tcbpay.com">support@tcbpay.com</Link>, or call/text us at <Link to="tel:+1 (866) 444-8585">+1 (866) 444-8585</Link>.</p>    
                </div>
              </Col>
            </Row>    
          </Container>
      

      <section className='pp-contentbx '>
              <div className='container'>
                  <div className='row d-flex justify-content-between'>
                      <div className='col-md-12'>
                          <div className='contact-title mb-40'> Our locations</div>
                      </div>
                    
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic1.png`} alt="" />
                              <div className='bluebxpic'></div>
                          </div>
                          <div className="effect-text">
                          <div className='text-eftnew'>
                          <p>Headquarters</p>
                          <h6>Boca Raton, FL</h6>
                          <span>433 Plaza Real<br/> Suite 275 <br/> Boca Raton, FL 33432</span>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic2.png`} alt="" />
                              <div className='yelwbxpic'></div>
                          </div>
                          <div className="effect-text yelw">
                            <div className='text-eftnew'>
                              <p>Satellite Office</p>
                              <h6>Toronto</h6>
                              <span>10 Four Season Place<br/> Suite 1000<br/> Toronto, ON M9B 6H7, Canada</span>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic3.png`} alt="" />
                              <div className='bluebxpic'></div> 
                          </div>
                          <div className="effect-text">
                            <div className='text-eftnew'>
                              <p>Satellite Office</p>
                              <h6>Henderson, NV</h6>
                              <span>871 Coronado Center Dr<br/> Suite 200<br/> Henderson, NV 89052</span> 
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic4.png`} alt="" />
                              <div className='yelwbxpic'></div>
                          </div>
                          <div className="effect-text yelw">
                            <div className='text-eftnew'>
                              <p>Satellite Office</p>
                              <h6>New York City</h6>
                              <span>225 W 34th St<br/> Floor 9 -57<br/> New York, NY, 1012</span>
                            </div>    
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic5.png`} alt="" />
                              <div className='bluebxpic'></div>
                          </div>
                          <div className="effect-text">
                            <div className='text-eftnew'>
                              <p>Satellite Office</p>
                              <h6>Mexico City</h6>
                              <span>Av. Presidente Masaryk 111, Piso 1<br/> 
                              Col. Polanco V Seccion, Del. Miguel<br/> 
                              Hidalgo, Ciudad de Mexico, C.P. 11560</span>
                            </div>    
                          </div>
                      </div>
                  </div>
                  <div className="column">
                      <div className="effect-1">
                          <div className="effect-img">
                              <img src={`${config.siteBaseUrl}/assets/imgs/contact-pic6.png`} alt="" /> 
                              <div className='yelwbxpic'></div>
                          </div>
                          <div className="effect-text yelw">
                            <div className='text-eftnew'>
                              <p>Satellite Office</p>
                              <h6>Malta</h6>
                              <span>152/No.9, Naxxar Road,<br/> San Gwann<br/> SGN9030</span>
                            </div>   
                          </div>
                      </div>
                  </div>

                      
                  </div> 
              </div>
      </section>
      <section className='pp-contentbx pb-180'>
          <div className='container'>
              <div className='row d-flex justify-content-between'>
                  <div className='col-md-12'>
                      <div className='contact-title'> Follow us on social media</div>
                  </div>
                  <div className='col-md-3'>
                      <div className='contact-infobx'>
                          <div className='contact-infoicon'>
                          <img src={`${config.siteBaseUrl}/assets/imgs/contact-icon1.svg`} alt="" />
                          </div>
                          <p>@TCBPay</p>
                          <Link to="https://www.instagram.com/tcbpay/?igshid=ky6kgcgfmlh0" target='_blank' className='contact-followbtn'>Follow us now</Link>
                      </div>
                  </div>
                  <div className='col-md-3'>
                      <div className='contact-infobx'>
                          <div className='contact-infoicon'>
                          <img src={`${config.siteBaseUrl}/assets/imgs/contact-icon2.svg`} alt="" />
                          </div>
                          <p>TCB Pay Business Solutions</p>
                          <Link to="https://www.linkedin.com/company/tcbpay/" target='_blank' className='contact-followbtn'>Follow us now</Link>
                      </div>
                  </div>
                  <div className='col-md-3'>
                      <div className='contact-infobx'>
                          <div className='contact-infoicon'>
                          <img src={`${config.siteBaseUrl}/assets/imgs/contact-icon3.svg`} alt="" />
                          </div>
                          <p>@TCBPay</p>
                          <Link to="https://www.youtube.com/@TCBPay?app=desktop" target='_blank' className='contact-followbtn'>Follow us now</Link>
                      </div>
                  </div>


                  
              </div> 
          </div>
      </section>

      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      </section>
      
    </section>
    </>
  )
}
