import React, { useRef } from 'react'
import { Banner } from './banner'
import { Faq } from './faq'
import { CompactRobust } from './compactRobust'
import { TcbpayappTab } from './tcbpayappTab'
import { Payportal } from './payportal'

export const Tcbpayapp = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
    <Banner scrollRef={scrollRef}/>
    <CompactRobust />
    <TcbpayappTab />
    <Payportal ref={scrollRef}/>
    {/* <Faq /> */}
    </>
  )
}
