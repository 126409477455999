import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingUserStatus = () => {
    const exampleCodeText_3 = `<input type="button" value="submit"  onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />`;
    const exampleCodeText_4 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
	"first_name": "firstname",
	"last_name": "lastname",
	"email": "example@example.com",
"phone_number": "4071234567",
	"employee_number": "999999999",
	"shipping_address1": "123 example street", 
	"shipping_address2": "apt 123", 
	"shipping_city": "example",
	"shipping_state": "TX",
	"shipping_zip": "12345",
"billing_address1": "123 example street", 
	"billing_address2": "apt 123", 
	"billing_city": "example",
	"billing_state": "TX",
	"billing_zip": "12345",
}`;
    const exampleCodeText_5 = `{
	"message": "User successfully created.",
    "code": 00,
	"data": { 
		"user_uid": "{Unique ID of user}",
		"activation_code": "{Unique activation code needed to activate user}",
"first_name": "firstname", 
"last_name": "lastname", 
"email": "example@example.com", 
"phone_number": "4071234567", 
"employee_number": "999999999", 
"shipping_address1": "123 example street", 
"shipping_address2": "apt 123", 
"shipping_city": "example", 
"shipping_state": "TX", 
"shipping_zip": "12345", 
"billing_address1": "123 example street", 
"billing_address2": "apt 123", 
"billing_city": "example", 
"billing_state": "TX", 
"billing_zip": "12345" 
"created_date": "2024-12-03 14:25:00", 
}
}`;

const exampleCodeText_6 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
	"user_uid": "{Unique id of user to be modified}"
	"phone_number": "4071234567"
	"billing_address_type": "N",
	"billing_address1": "123 example street", 
	"billing_address2": "apt 123", 
	"billing_city": "example",
	"billing_state": "TX",
	"billing_zip": "12345",
}`;
    const exampleCodeText_7 = `{
	"message": "User successfully modified.",
	"code": 00,
	"data": { 
"user_uid": "{Unique id of user}", 
"first_name": "firstname", 
"last_name": "lastname", 
"user_type": "user",
"email": "example@example.com", 
"phone_number": "4071234567", 
"employee_number": "999999999", 
"shipping_address1": "123 example street", 
"shipping_address2": "apt 123", 
"shipping_city": "example", 
"shipping_state": "TX", 
"shipping_zip": "12345", 
"billing_address_type": "N",
	"billing_address1": "123 example street", 
	"billing_address2": "apt 123", 
	"billing_city": "example",
	"billing_state": "TX",
	"billing_zip": "12345",
	"modified_date": "2024-01-01 23:59:59"
}
}`;

const exampleCodeText_8 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
"status":"DELETE",
"user_uid": "{Unique id of user}"
}`;
const exampleCodeText_9 = `{
"message": "User status successfully changed.",
"code": 00,
	"user_uid": "{Unique id of user}"
"status":"DELETED",
	"deleted_date": "2024-01-01 23:59:59",.
}`;

const exampleCodeText_10 = `{
	"message": "Users successfully received.",
	"code": 00,
	"current_page": 1,
	"total_pages": 1,
	"total_record_count": 1,
	"data": {
       	"users": [
        {
       "user_details": { 
		"user_uid": "{Unique id of user}",
"first_name": "firstname", 
"last_name": "lastname", 
"email": "example@example.com", 
"phone_number": "4071234567", 
"user_type": "USER",
"employee_number": "999999999", 
"shipping_address1": "123 example street", 
"shipping_address2": "apt 123", 
"shipping_city": "example", 
"shipping_state": "TX", 
"shipping_zip": "12345", 
"billing_address1": "123 example street", 
"billing_address2": "apt 123", 
"billing_city": "example", 
"billing_state": "TX", 
"billing_zip": "12345" 
"status": "active",
"created_date": "2024-01-01 23:59:59",
"modified_date": "2024-01-01 23:59:59",
"deleted_date": "2024-01-01 23:59:59",
"is_deleted": "1"
}
}
      	]
	}
}`;
const exampleCodeText_11 = `{
  "api_username": "{Unique api username}",
  "api_key": "{Unique api_key}",
 "user_access_uid": "{unique id of user}"
  "card_uid": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "user_uid": [
	"{unique id of user}",
	"{unique id of user}"
  ],
  "operation_type": "Add"
}`;

const exampleCodeText_12 = `{
  "message": "Access list successfully modified",
  "code": 00,
  "cards": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "users": [
	"{unique id of user}",
	"{unique id of user}"
  ]
}`;

const exampleCodeText_13 = `{ "message": "Access list successfully recieved",
  "code": 00,
  "cards": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "users": [
	"{unique id of user}",
	"{unique id of user}"
  ]
}`;

    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);

  return (
  <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                    <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                    </div>
              
                    <div className="creadit-api-lower-wrapper">
                    <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
            <div className="api-menu-item-wrapper mb-40">
                <p>For every API request, the following headers and parameters are required:
                </p>
                <ul>
                    <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                    <li style={{listStyleType:'disc'}}>Parameters:
                        <ul>
                            <li>- GET Requests: Include these parameters in the parameters area</li>
                            <li>- POST Requests: Include these parameters in the request JSON body.</li>
                        </ul>
                    </li>
                </ul>
                
            <div className="api-table">

            <div className="table-responsive mb-20">
                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th style={{ width: '100px' }}>Variable Name</th>
                        <th style={{ width: '420px' }}>Description</th>
                        <th style={{ width: '100px' }}>Required</th>
                        <th style={{ width: '130px' }}>Expected Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>api_username</td>
                        <td>Unique API username associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    <tr>
                        <td>api_key</td>
                        <td>Unique API key associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    
                    
                    </tbody>
                </table>
            </div>
            <ul>
                <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                <li>- All dates and times are in GMT.</li>
            </ul>

            </div>
            </div>
                    <div className="api-menu-item-title"><h2>Users APIs</h2></div>

                    <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-wrapper">
                          <h3>Modify User Status</h3>
                          <hr />
                          
                          <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>Name of API:</p>
                                       <span>Modify User Status</span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Description:</p>
                                      <span>Remove a user from the system.</span>
                                 </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>URL:</p>
                                      <span>https://issuing.tcbpay.com/apis/users/modify-status</span>
                                 </div>
                              </li>
                              
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox endpoint URL:</p>
                                       <span>https://issuing-stg.tcbpay.com/apis/users/modify-status</span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method</p>
                                      <span>POST</span>
                                 </div>
                              </li>
                              
                          </ul>
                      </div>
                    </div>
                    <div className="api-table">
                      <h5>JSON Body Parameters:</h5>

                      <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>user_uid</td>
                                <td>Unique ID received upon creation of user you are deleting</td>
                                <td>Yes</td>
                                <td>String</td>
                              </tr>
                              <tr>
                                <td>status</td>
                                <td>How you are changing the user’s status<br/>
                                (IMPORTANT NOTE: <br/>
                                - Can’t delete users that have any locked or unlocked cards<br/>
                                - Deactivating a user will cancel all cards related to the user)
                                </td>
                                <td>Conditional</td>
                                <td>String(
                                DELETE,
                                ACTIVATE,
                                DEACTIVATE
                                )</td>
                              </tr>
                              
                              <tr>
                                <td>activation_code</td>
                                <td>Unique activation code received upon creation of user</td>
                                <td>Conditional (If status = ACTIVATE)</td>
                                <td>Varchar</td>
                              </tr>
                              <tr>
                                <td>password</td>
                                <td>Unique password for user</td>
                                <td>Conditional (If status = ACTIVATE)</td>
                                <td>Varchar encrypted with AES 128-bit ECB using secret key.<br/>
                                Must be:<br/>
                                10 or more characters<br/>
                                One lowercase character<br/>
                                One uppercase character<br/>
                                One number<br/>
                                One symbol
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                      </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example Request Payload Body(JSON)</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip8">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(8)} text={exampleCodeText_8}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_8">
                                <pre>{exampleCodeText_8}</pre>
                            </div>
    
                            </div>
                      </div>
                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example Response Payload(JSON)</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip9">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(9)} text={exampleCodeText_9}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_9">
                                <pre>{exampleCodeText_9}</pre>
                            </div>
    
                            </div>
                      </div>
                    </div>





               
                </div>


              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
  </section>
  )
}
