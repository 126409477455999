import React from 'react'
import { CreditANI } from './creditANI'

export const IntegrationCreditANI = () => {
  return (
    <>
    <CreditANI />
    </>
  )
}
