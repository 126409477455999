import React from 'react'
import { IntegrationUnreferencedSec } from './integrationUnreferencedSec'

export const IntegrationUnreferenced = () => {
  return (
    <>
    <IntegrationUnreferencedSec />
    </>
  )
}
