import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import CountUp from 'react-countup';


export const Counter = () => {
  return (
    <>

    <section className='counter-bx'>
        <div className='container'>
            <div className='row'>
                
                <div className='col-md-3'>
                    <div className='counter-txt'>
                    <CountUp start={0} end={1.5} prefix="$" decimal="." decimals={2} duration={1.75} delay={0} />B
                    <div className='countertxtin'>Annual volume</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    <CountUp start={0} end={100} duration={2.75} delay={0} />+
                    <div className='countertxtin'>Currencies supported</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    <CountUp start={0} end={96.99} decimal="." decimals={2} duration={2.75} delay={0} />%
                    <div className='countertxtin'>Approval rate</div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='counter-txt'>
                    <CountUp start={0} end={20} duration={2.75} delay={0}/>+
                    <div className='countertxtin'>Years of experience</div>
                    </div>
                </div>
               
            </div>
        </div>

    </section> 

    </>
  )
}
