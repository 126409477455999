import axios from 'axios'
import config from '../Config';
export class ContactUsService{
    private static URL:string = config.internalApiUrl;

    public static sendContactFormData(data:any=[]){
        const postData = {
            contact_type: data.contact_type,
            company_name: data.company_name,
            full_name: data.full_name,
            email: data.email,
            phone_number: data.phone_number,
            message: data.message
          };
        let UserURL:string = `${this.URL}/website_contact_mail.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        })
    }

    public static sendCodeOfConductFormData(data:any=[],uploaded_file:any=[]){
        const postData = {
            contact_type: data.topic_type,
            company_name: data.company_name,
            full_name: data.full_name,
            email: data.email,
            phone_number: data.phone_number,
            business_address_1: data.business_address_1,
            business_address_2: data.business_address_2,
            city: data.city,
            province: data.province,
            zipcode: data.zipcode,
            representative: data.representative,
            topic_type: data.topic_type,
            uploaded_file: uploaded_file,
            message: data.message
          };
        let UserURL:string = `${this.URL}/website_code_conduct_mail.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        })
    }

    public static googleCaptchaVerify(captchaVal:string){

      const postData = {
        secret: config.gCaptchaSecretKey,
        response: captchaVal,
      };
      let UserURL:string = `https://www.google.com/recaptcha/api/siteverify` 
      return axios.post(UserURL,postData,{
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
      })
    }
}