import axios from 'axios'
import config from '../Config';
export class SystemStatusDetailsService{
    private static URL:string = config.internalApiUrl;

    public static getAllSystemStatusDetails(startDate:any=''){
        const postData = {
            start: startDate
          };
        let UserURL:string = `${this.URL}/system_status_history.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        })
    }
}