import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const AchResponse = () => {
    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);

  return (
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                        <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      {/* <h2>ACH API</h2>
                      <ul className="card-url">
                          <li>
                              <div className="card-url-list">
                                   <p>URL To Call: </p>
                                   <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_sale.php</Link></span>
                              </div>
                            
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Example:</p>
                                  <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Production endpoint URL:</p>
                                  <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                              </div>
                          </li>
                      </ul> */}
                  </div>

                  <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title">
                          <h2>ACH</h2>
                      </div>
                      <div className="api-menu-item-wrapper">
                          <h3>Response Codes</h3>
                          <hr />
                      </div>
                  </div>

                  <div className="api-table">
                      <div className="table-responsive">   
                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Code</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>01</td>
                                <td>Merchant Information is Missing</td>
                            </tr>
                            <tr>
                                <td>02</td>
                                <td>Transaction Currency is missing</td>
                            </tr>
                            <tr>
                                <td>03</td>
                                <td>Account Number is missing or Invalid</td>
                            </tr>
                            <tr>
                                <td>04</td>
                                <td>Routing Number is missing or invalid</td>
                            </tr>
                            <tr>
                                <td>05</td>
                                <td>Check Number is missing or invalid</td>
                            </tr>
                            <tr>
                                <td>06</td>
                                <td>Invalid Amount</td>
                            </tr>
                            <tr>
                                <td>07</td>
                                <td>Account Type is Missing</td>
                            </tr>
                            <tr>
                                <td>08</td>
                                <td>Proper Zip code is missing</td>
                            </tr>
                            <tr>
                                <td>09</td>
                                <td>Proper Customer Email Id is missing</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Invalid Processor</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Requested Currency is not allowed for this merchant</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Invalid Merchant Information</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Invalid Country Code</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>Sale Volume exceeded for this Merchant</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>Monthly Volume exceeded for this Merchant</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Transaction limit  exceeded for this Merchant</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>IP-wise transaction limit  exceeded for this Merchant</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>Bank Name is missing</td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>Transaction Start Date is missing or invalid</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>Transaction End Date is missing or invalid</td>
                            </tr>
                            <tr>
                                <td>P1</td>
                                <td>Invalid page number</td>
                            </tr>
                            <tr>
                                <td>A1</td>
                                <td>Transaction entry failed</td>
                            </tr>
                            
                            </tbody>
                        </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
    </section>
  )
}    
