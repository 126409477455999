import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {
    const scrollToMiddle = () => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      };
  return (
    <>
    <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      

            <div className='text-left'>
            <section className="payportal-area padding-custmprc">
                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-40 text-right">
                        <h1 className="seamless-heading text-white fnt-800 pb-0"><img src='assets/imgs/price-icon.svg' alt='' className='pp-img icnpp'/> Pricing & Fees</h1>
                        </div>
                        <div className="col-lg-4 mb-40"></div>
                        <div className='col-md-8'>
                            <div className='honest-lftbx'>
                                <div className='honest-lftbx-inner'>
                                    <h6>Card-Present</h6>
                                    <p>For business that is made in-person</p>
                                    <div className='pricetxt'>2.75% + $0.20</div>
                                    <span>Per transaction</span>
                                </div>
                                <div className='honest-lftbx-inner border-0'>
                                    <h6>Card-Not-Present</h6>
                                    <p>For business that is made online</p>
                                    <div className='pricetxt'>3.5% + $0.20</div>
                                    <span>Per transaction</span>
                                </div>
                            </div>
                            <button onClick={scrollToMiddle} className='pricingdetail-btn'>View pricing details <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                        <div className='col-md-4 position-relative'>
                            <div className='honest-rgtbx'>
                                <h4>High-Risk & Custom Pricing</h4>
                                <p>If you have a unique business model, reach out to us and we will find the best solutions for your business.</p>
                                <Link to={`${config.siteBaseUrl}/contact`} className='letstalk'>Let’s talk</Link>
                            </div>
                            <img src='assets/imgs/bluedots1.svg' alt='' className='blue-dots2' />
                        </div>  
                    </div>
                </div>

                
            </section>

            </div>


    </section>
    </>
  )
}
