import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm career-bg'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='careerbg' />
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span><img src='assets/imgs/c-icon.svg' alt='' />CAREERS</span>
            <h1>Let’s change the payment industry together!</h1>
            </Col>
            <Col xs lg="7" className='text-left mb-180'>
            <p>For the latest updates on our job offers, please refer to our LinkedIn page. If there are currently no open positions or a position that matches what you are looking for, feel free to contact us or send us your resume.</p>
            <Link className="get-btn2 mb-80" to="https://www.linkedin.com/company/tcbpay/" target='blank' style={{width:'221px'}}>Follow us on LinkedIn</Link>
            </Col>
          </Row>  
        </Container>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dotscareer" />
    </section>
    </>
  )
}
