import React, { useRef } from 'react'
import { Footer } from '../components/Footer'
import { SectionAnimation } from './sectionAnimation'
import { Banner } from './banner'
import { Featuresbx } from './featuresbx'
import { Calender } from './calender'
import { Highrisk } from './highrisk'
import { Tcbpayinfobx } from './tcbpayinfobx'


export const Home = () => {

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <>
    <Banner scrollRef={scrollRef}/>
    {/* <SectionAnimation /> */}
    <Tcbpayinfobx />
    <Featuresbx />
    <Calender ref={scrollRef} />
    <Highrisk />
    </>
  )
}
