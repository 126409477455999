import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';

export const LegalPrivacyPolicySec = () => {

    const {seo_url} = useParams<any>();
        
    return (
        <>
        <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-start">

                            <div className="accordion mblegal-mnu" id="faqaccordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <NavLink to='' className="sidebar-linkmb" >
                                        <span className="align-middle">Privacy Policy</span>
                                    </NavLink>
                                </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                
                                    <ul className="sidebar-nav groupaside">
                                        <li className="sidebar-item">
                                            <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                        </li>
                                        <li className="sidebar-item">
                                            <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                                        </li>
                                    </ul>
                                
                                </div>
                                </div>
                            </div>

                            </div>
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                            </div>
                            <div className="tab-content">

                                <div className="tab-pane fade show active">
                                    <div className="privacy_policy-sec">
                                        <div className="container">
                                            <div className="title-sec">
                                                <h2><span> Privacy Policy </span></h2>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="text2">
                                                        <p>
                                                            TCB Pay is committed to maintaining the accuracy, confidentiality, and security of your personally identifiable information ("Personal Information"). As part of this commitment,
                                                            the following Ten Privacy Principles govern our actions as they relate to the collection, use and disclosure of Personal Information. The principles have been built upon the values
                                                            set by the <i>Model Code for the Protection of Personal Information and Personal Information Protection and Electronic Documents Act.</i>
                                                        </p>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <ul>
                                                            <li>Accountability</li>
                                                            <li>Identifying Purposes</li>
                                                            <li>Consent</li>
                                                            <li>Limiting Collection</li>
                                                            <li>Limiting Use, Disclosure and Retention</li>
                                                            <li>Accuracy</li>
                                                            <li>Safeguarding Personal Information</li>
                                                            <li>Openness</li>
                                                            <li>Customer Access</li>
                                                            <li>Handling Complaints and Suggestions</li>
                                                        </ul>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Accountability</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            TCB Pay is responsible for maintaining and protecting the Personal Information under its control. TCB Pay has designated an individual or individuals who is/are accountable for
                                                            compliance with the Ten Privacy Principles.
                                                        </p>
                                                    </div>

                                                    <div className="pp-sub-head">
                                                        <h5>Identifying Purposes</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            The purposes for which Personal Information is collected will be identified before or at the time the information is collected.
                                                        </p>
                                                    </div>

                                                    <div className="pp-sub-head">
                                                        <h5>Consent</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Limiting Collection</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            The Personal Information collected will be limited to those details necessary for the purposes identified by TCB Pay.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Limiting Use, Disclosure and Retention</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Personal Information may only be used or disclosed for the purpose for which it was collected unless you have otherwise consented, or when it is required or permitted by law.
                                                            Personal information will only be retained for the period of time required to fulfill the purpose for which it was collected or as required by law.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Accuracy</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Personal Information will be maintained in as accurate, complete and up-to-date form as is necessary to fulfill the purposes for which it is to be used.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Safeguarding Personal Information</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Openness</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            TCB Pay will make information available to our customers concerning the policies and practices that apply to the management of their Personal Information.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Customer Access</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Upon request, a customer shall be informed of the existence, use and disclosure of their Personal Information, and shall be given access to it. Customers may verify the accuracy
                                                            and completeness of their Personal Information, and may request that it be amended, if appropriate.
                                                        </p>
                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Handling Customer Complaints and Suggestions</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Customers may direct any questions or inquiries with respect to the privacy principles outlined above or about our practices by contacting:
                                                        </p>
                                                        <p>
                                                            <strong>Privacy Officer</strong> <br />
                                                            433 Plaza Real Suite 275<br />
                                                            Boca Raton, FL 33432 <br />
                                                            <Link to="tel:(702) 984-8228"> (702) 984-8228 </Link> <br />
                                                            <Link to="mailto:compliance@tcbpay.com"> compliance@tcbpay.com </Link>
                                                        </p>
                                                        <p>
                                                            Before TCB Pay is able to provide you with any information or correct any inaccuracies, however, we may ask you to verify your identity and to provide other details to help us
                                                            respond to your request. We will endeavour to respond within an appropriate timeframe.
                                                        </p>
                                                        <p>
                                                            If you have questions or concerns regarding our organization's privacy policy and practices, you should first contact TCB Pay directly.
                                                        </p>
                                                        <p>
                                                            <strong> What information is collected? </strong> <br />
                                                            At TCB Pay, we collect, use and disclose Personal Information to provide you with the services you have requested and to offer you additional products and services we believe you
                                                            might be interested always your choice.
                                                        </p>
                                                        <p>
                                                            The types of Personal Information we may ask for depends on the nature of the services we provide you and typically includes your name, address, telephone number and email
                                                            address. When you visit the TCB Pay websites, Personal Information is not collected unless you choose to provide it voluntarily. You are welcome to browse the websites at any time
                                                            anonymously and privately without revealing any personal information about yourself.
                                                        </p>
                                                        <p>
                                                            For purposes of maintaining quality service, calls to any of our customer service lines may be recorded. A recorded message given prior to your call being answered will let you
                                                            know if your call may be the subject of our random call recording quality assurance program.
                                                        </p>

                                                        <div className="pp-sub-head">
                                                            <h5>Types of Information We Collect</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                <strong> Information </strong> <br />
                                                                Most of the Personal Information we collect comes to us directly from you, and only with your consent. With your consent, we may collect Personal Information from you in
                                                                person, over the telephone or by corresponding with you via mail, facsimile, the Internet, or from referrals by third parties such as your financial institution.
                                                            </p>
                                                            <p>
                                                                If we are unable to accommodate your request based on the information that has been provided to us, we may ask for additional details in order to identify other ways to be of
                                                                assistance. TCB Pay also maintains a file containing contact history that is used for customer inquiry purposes.
                                                            </p>
                                                            <p>
                                                                <strong> Anonymous/Non-Personal Information </strong> <br />
                                                                At TCB Pay, we also collect anonymous/non-personal information. Anonymous/non- personal information is information that we do not associate with or trace back to a specific
                                                                individual or business entity. For example, our web servers collect some anonymous/non-personal information automatically when you visit one of our websites. Gathered
                                                                electronically, this information may include the pages you visited, the type of web browser you are using, the level of encryption your browser supports and your Internet
                                                                Protocol address. The anonymous/non-personal information collected may be used for research and analytical purposes and occasionally for security investigations.
                                                            </p>
                                                            <p>
                                                                To help us better understand our markets, we may also gather information for analytical purposes by conducting customer from existing files.
                                                            </p>
                                                            <p>
                                                                <strong> Consent </strong> <br />
                                                                Your provision of Personal Information to us means that: (a) you agree and consent that we may collect, use and disclose your Personal Information in accordance with this
                                                                policy; and (b) you or your representatives are authorized to provide us with your Personal Information. If you do not agree with these terms, you and your representatives are
                                                                requested not to provide any Personal Information to TCB Pay. Certain services can only be offered if you provide Personal Information to TCB Pay. Consequently, if you and your
                                                                representatives choose not to provide us with any required Personal Information, we may not be able to offer you certain services.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>How Your Information is Used</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                <strong>Personal Information </strong> <br />
                                                                We use your Personal Information to communicate with you, effectively provide the services you have requested and safeguard your interests. We may also send you information
                                                                about other products and services in which we believe you may be interested. Additionally, if you are a TCB Pay merchant client, we will keep you informed about your merchant
                                                                account activities, send you important notices, and respond to any special needs or inquiries you may have.
                                                            </p>
                                                        </div>

                                                        <div className="pp-sub-head">
                                                            <h5>TCB Pay Merchants</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                In particular, if you are a TCB Pay merchant customer, our merchant agreement with you authorizes us to use and disclose the Personal Information that you provide to us to:
                                                            </p>

                                                            <div className="sub-list">
                                                                <ul>
                                                                    <li>respond to any application for our services;</li>
                                                                    <li>
                                                                        provide our services to you, including
                                                                        <ul className="s-list">
                                                                            <li>
                                                                                determining your financial situation by collecting credit and related financial information from our affiliates, strategic partners, credit agencies, other
                                                                                financial institutions and from references provided by you;
                                                                            </li>
                                                                            <li>
                                                                                facilitating the provision of our services by sharing your information with our third party service providers, credit and debit card issuers, credit and debit
                                                                                card associations, credit agencies and similar parties connected to credit or debit card services;
                                                                            </li>
                                                                            <li>
                                                                                reporting purposes under credit or debit card association rules or regulations and to credit and debit card issuers, financial institutions or other credit or
                                                                                debit card related entities;
                                                                            </li>
                                                                            <li>offering you related products and services that might benefit you; and</li>
                                                                            <li>in the course of an actual or potential sale, reorganization, consolidation, merger or amalgamation of our business.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p>
                                                                If you are an individual who is using our other services, we may use and disclose Personal Information to provide the services that have been requested, as well as in the
                                                                following ways:
                                                            </p>
                                                            <div className="sub-list">
                                                                <ul>
                                                                    <li>
                                                                        to facilitate the provision of our services by sharing the Personal Information with our third party service providers and similar parties connected to the services;
                                                                    </li>
                                                                    <li>to offer related products and services that might benefit you; and</li>
                                                                    <li>in the course of an actual or potential sale, reorganization, consolidation, merger or amalgamation of our business.</li>
                                                                </ul>
                                                            </div>

                                                            <p>
                                                                We only use Personal Information for the purposes that we have disclosed to you. If for any reason your information is required to fulfill a different purpose, we will obtain
                                                                your consent before we proceed.
                                                            </p>
                                                            <p>
                                                                <strong> Anonymous/Non-Personal Information</strong><br />
                                                                The anonymous/non-personal information gathered from the TCB Pay websites is used primarily for technical, research and analytical purposes like evaluating the various sections
                                                                of the websites. This information does not include any personal information and it is only retained until its intended purpose is fulfilled. It is our goal to continuously
                                                                improve our service offerings to you. Therefore, we use anonymous/non-personal information collected from surveys, from public archives and by extracting information from
                                                                existing files to better understand our customers' interests, priorities, and satisfaction levels.<br />

                                                            </p>
                                                            <p><strong> When Information May be Disclosed to Outside Parties </strong> <br />
                                                                We are obliged to keep your Personal Information confidential except when authorized by you, when required by law and when permitted by law.
                                                            </p>
                                                            <p>
                                                                At TCB Pay, we sometimes contract outside organizations to perform specialized services such as terminal delivery and repair, and shipping and fulfillment services. Our trusted
                                                                service suppliers may at times be responsible for processing and handling some of the information we receive from you.
                                                            </p>
                                                            <p>
                                                                When we contract our suppliers to provide specialized services, they are given only the information necessary to perform those services. Additionally, they are prohibited from
                                                                storing, analyzing or using that information for purposes other than to carry out the service they have been contracted to provide. Furthermore, as part of our agreements, our
                                                                suppliers and their employees and representatives are required to protect your information in a manner that is consistent we have established.
                                                            </p>
                                                            <p>
                                                                <strong>When Required by Law</strong><br />
                                                                The type of information we are legally required to disclose may relate to criminal investigations or government tax reporting requirements. In some instances such as a legal
                                                                proceeding or court order, we may also be required to disclose certain information to authorities. Only the information specifically requested is disclosed and we take
                                                                precautions to satisfy ourselves that the authorities that are making the request have legitimate grounds to do so.
                                                            </p>

                                                            <strong>When Permitted by Law</strong>
                                                            <p>  There are some situations where we are legally permitted to disclose personal information such as employing reasonable and legal methods to enforce our rights under our
                                                                agreements, activities.
                                                            </p>
                                                        </div>

                                                        <div className="pp-sub-head">
                                                            <h5>With Whom We May Share Your Information</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                <b>Our Employees and Contractors</b><br />
                                                                In the course of daily operations, access to private, sensitive and confidential information is restricted to authorized employees who have a legitimate business purpose and
                                                                reason for accessing it. For example, when you call us, our designated employees will access your information to verify who you are and to assist you in fulfilling your
                                                                requests.
                                                            </p>
                                                            <p>
                                                                As a condition of their employment and their independent contractor agreement respectively, all employees and independent contractors of TCB Pay are informed about the
                                                                importance of privacy and are required to abide by the privacy standards we have established. They are also required to work within the principles of ethical behaviour as set
                                                                out in the relevant Code of Conduct and must follow all applicable laws and regulations.
                                                            </p>
                                                            <p>
                                                                Unauthorized access to and/or disclosure of customer information by an employee of TCB Pay is strictly prohibited. All employees and independent contractors are expected to
                                                                maintain the confidentiality of customer information at all times and failing to do so will result in appropriate disciplinary measures, which may include dismissal.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>How We Safeguard Your Information</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                We use state-of-the-art technologies and maintain current security standards to ensure that your Personal Information is protected against unauthorized access, disclosure,
                                                                inappropriate alteration or misuse. All safety and security measures are also appropriate to the sensitivity level of your information.
                                                            </p>
                                                            <p>
                                                                <strong>Customer Files</strong><br />
                                                                Electronic customer files are kept in a highly secured environment with restricted access. Paper-based files are stored in locked filing cabinets. Access is also restricted.
                                                            </p>
                                                            <p>
                                                                <strong>Online Security</strong><br />
                                                                We manage our server environment appropriately and our firewall infrastructure is strictly adhered to. Our security practices are reviewed on a regular basis and we routinely
                                                                employ current technologies to ensure that the confidentiality and privacy of your information is not compromised.
                                                            </p>
                                                            <p>
                                                                The TCB Pay websites use Secure Socket Layer (SSL) and 128 bit encryption technologies to enhance security when you visit the secured areas of these sites. SSL is the industry
                                                                standard tool for protecting and maintaining the security of message transmissions over the Internet. When you access your accounts or send information from secured sites,
                                                                encryption will scramble your data into an unreadable format to inhibit unauthorized access by others.
                                                            </p>
                                                            <p>
                                                                To safeguard against unauthorized access to your accounts, you may be required to sign-on using a user id and a password to certain secured areas of the TCB Pay websites. Both
                                                                user id and password are encrypted when sent over the Internet. If you are unable to provide the correct password, you will not be able to access these sections.
                                                            </p>
                                                            <p>
                                                                When you call our customer service centre you will be required to verify your identity by providing some personally identifying information.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>How You Can Protect Your Information</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                We do our utmost to protect and safeguard your Personal Information. We believe there are measures you should take as well. The following is a list of things you can do to
                                                                protect yourself against fraud and uninvited intrusion.
                                                            </p>
                                                            <p>
                                                                <strong>Passwords</strong><br />
                                                                Passwords are necessary to identify you and authenticate your permission to access your online accounts. The passwords you use are encrypted. Encryption is presently the most
                                                                effective way to achieve data security. Just as it is important for us to employ strict procedures to safeguard your information, you also should take precautions in handling
                                                                your passwords. When selecting a password, we suggest that you use a combination of letters and numbers and do not use words that can be easily associated with you such as the
                                                                name of a family member, a pet or the street on which you live. We also suggest that you change your password regularly.
                                                            </p>
                                                            <p>
                                                                <strong>Personal Information</strong><br />
                                                                You should not share personal information with others unless you clearly understand the purpose of their request and you know who you are dealing with.
                                                            </p>
                                                            <p>
                                                                <strong>Your Online Security</strong><br />
                                                                To make sure your connection to the protected areas of the TCB Pay websites is secure, look for either a 'closed lock' or an 'unbroken key' icon located at the bottom right
                                                                hand side of your browser's task bar. You may also check the Address bar to determine if SSL (Secure Socket Layer) is active by looking at the beginning of the address. If it
                                                                starts with https rather than the standard http, then SSL is operating.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Accessing and Amending Your Information</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                At TCB Pay, decisions are made based on the information we have. Therefore, it is important that your Personal Information is accurate and complete. As a customer, you have the
                                                                right to access, verify and amend the information held in your personal and financial files.
                                                            </p>
                                                            <p>
                                                                <strong>Accessing Your Information</strong><br />
                                                                For privacy-related matters, you may access and verify any of your information by calling our customer contact centre at (866) 444-8585.
                                                            </p>
                                                            <p>
                                                                <strong>Amending Your Information</strong><br />
                                                                To help us keep your Personal Information up-to-date, we encourage you to amend inaccuracies and make corrections as often as necessary. Despite our efforts, errors sometimes
                                                                do occur. Should you identify any incorrect or out-of-date information in your file(s), we will make the proper changes. Where appropriate, we will communicate these changes to
                                                                other parties who may have unintentionally received incorrect information from us.
                                                            </p>
                                                            <p>
                                                                <strong>To Make a Change</strong><br />
                                                                To make a change to the personal contact information contained in your file, you may do one of the following:
                                                            </p>
                                                            <ul className="list-al">
                                                                <li>
                                                                    Call the relevant Customer Contact Centre number (866) 444-8585 for TCB Pay merchant related matters, where one of our operators will be available to receive your request for a
                                                                    change in your personal contact information;
                                                                </li>
                                                                <li>Send a facsimile to us (866) 444-8585 to request a change in your personal contact information.</li>
                                                            </ul>
                                                            <p>In each case, you will be required to verify your identity by providing some personally identifying information.</p>
                                                            <p>
                                                                <strong>What is a Cookie?</strong><br />
                                                                A cookie is a small computer file or piece of information that may be stored in your computer's hard drive when you visit our websites. On the TCB Pay websites, cookies are
                                                                used to increase robustness and to provide more efficient navigation. Cookies cannot view or retrieve data from other cookies, nor can they capture files and data stored on
                                                                your computer. Cookies may be used by the TCB Pay websites to improve functionality and in some cases, to provide visitors with a customized online experience. Although cookies
                                                                have the capability of accomplishing a variety of undertakings, TCB Pay only uses cookies when they provide an obvious benefit to you. If you would like to browse the TCB Pay
                                                                websites, you may do so without accepting cookies. However, you should understand that if you choose not to accept cookies, some areas of the TCB Pay websites may not function
                                                                properly or optimally. Cookies are widely used and most web browsers are configured initially to accept cookies automatically. If you prefer not to accept cookies, you may
                                                                adjust your browser settings to alert you when a cookie is about to be sent, or you may configure your browser to refuse cookies automatically. If you would like to learn more
                                                                about how to set your cookie options, please refer to your browser's documentation or online help for instructions.
                                                            </p>
                                                            <strong>Questions, Concerns and Complaints </strong>
                                                            <p>
                                                                If you have a question about the privacy policies stated on this site, please call us at <Link to="tel:1-866-444-8585">1-866-444-8585</Link> or
                                                                <Link to="tel:1-702-718-7171">1-702-718-7171</Link>
                                                            </p>
                                                            <p>
                                                                If you have a concern or complaint about privacy, confidentiality or the personal information handling practices of TCB Pay, our employees or service suppliers, please contact:
                                                            </p>
                                                            <p>
                                                                <strong> Privacy Officer </strong><br />
                                                                433 Plaza Real Suite 275<br />
                                                                Boca Raton, FL 33432 <br />
                                                                <Link to="tel:(702) 984-8228">(702) 984-8228 </Link> <br />
                                                                <Link to="mailto:compliance@tcbpay.com">compliance@tcbpay.com</Link>
                                                            </p>
                                                            <p>
                                                                Before TCB Pay is able to provide you with any information or correct any inaccuracies, however, we may ask you to verify your identity and to provide other details to help us
                                                                to respond to your request. We will endeavour to respond within an appropriate timeframe. If you have questions or concerns regarding our organization's privacy policy and
                                                                practices, you should first contact TCB Pay directly.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Updating this Privacy Policy</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                Any changes to our privacy policy and information handling practices will be acknowledged in this policy in a timely manner. We may add, modify or remove portions of this
                                                                policy when we feel it is appropriate to do so. You may determine when this policy was last updated by referring to the modification date found at the bottom of this privacy
                                                                policy.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Websites Governed by this Privacy Policy</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                The websites that are governed by the provisions and practices stated in this privacy policy are:<br />
                                                                <Link to="https://www.tcbpay.com"> https://www.tcbpay.com </Link>
                                                            </p>

                                                            <strong className="pt-3">Other Websites</strong>
                                                            <p>
                                                                The foregoing websites may contain links to other third party sites that are not governed by this privacy policy. Although we endeavor to only link to sites with high privacy
                                                                standards, our privacy policy will no longer apply once you leave these websites. Additionally, we are not responsible for the privacy practices employed by other third party
                                                                websites. Therefore, we suggest that you examine the privacy statements of those sites to learn how your information may be collected, used, shared and disclosed.
                                                            </p>
                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Mobile App</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                TCB Pay Used permissions that are associated with accessing the approximate location of the mobile device. This permission allows the Service to enable Bluetooth. Our mobile app used POS device like BBPOS device. This permission is necessary to find <Link to="https://bbpos.com/chipper-bt" target="_blank">BBPOS</Link> payment devices.
                                                            </p>
                                                            <p>Permission like BLUETOOTH, BLUETOOTH_CONNECT, BLUETOOTH_SCAN, and BLUETOOTH_ADMIN&nbsp;are required to find a BBPOS device and connect to that particular&nbsp;device.
                                                            </p>
                                                            <p>Permissions like&nbsp;READ_EXTERNAL_STORAGE&nbsp;and&nbsp;WRITE_EXTERNAL_STORAGE are used to share files and upload documents. Like user can share their QRCode on another app&nbsp;as an image file.
                                                            </p>
                                                            <p>Permission like&nbsp;ACCESS_NETWORK_STATE and&nbsp;INTERNET are required to check network is enable&nbsp;or not. And also sync server as well&nbsp;as website.</p>
                                                            <p>The user does not have any option to register but the user does have the option to add more sub-users. Users have the option to delete those sub-account accounts.</p>
                                                            <p>We are not storing and sharing any kind of user data in the app. The user cannot delete his account from the app but can delete it from our website.
                                                            </p>
                                                            <p>
                                                                It doesn't store location data when the app is closed or not in use  and in the background. It doesn't use location data for advertising and also our app does not contain any advertisements.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
        </section>
        </>
    )
}
