import React from 'react'
import { Whitel404Sec } from './whitel404Sec'

export const Whitel404 = () => {
  return (
    <>
    <Whitel404Sec />
    </>
  )
}
