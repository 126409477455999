import React from 'react'
import { Banner } from './banner'
import { HighriskSec } from './highriskSec'

export const Highrisk = () => {
  return (
    <>
    <Banner />
    <HighriskSec />
    </>
  )
}
