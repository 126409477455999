import React from 'react'
import { IntegrationCreditVoidSec } from './integrationCreditVoidSec'

export const IntegrationCreditVoid = () => {
  return (
   <>
   <IntegrationCreditVoidSec />
   </>
  )
}
