import React from 'react'
import { LegalPayfacTermsAndConditionsSec } from './legalPayfacTermsAndConditionsSec'

export const LegalPayfacTermsAndConditions = () => {
  return (
   <>
   <LegalPayfacTermsAndConditionsSec />
   </>
  )
}
