import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingListCardUser = () => {
    const exampleCodeText_4 = `{
  "message": "Card list successfully received.",
  "data": {
	"user_uid": "{Unique id of cardholder user}",
	"cards": [
  	{
    		"card_details": {
      			"card_number": "1234 1234 1234 1234", // (encrypted)
      			"card_exp": "00/00",             	// (encrypted)
      			"card_cvv": "111",               	// (encrypted)
      			"card_uid": "Unique id of card 1",
      			"card_type": "DIGITAL",
      			"profile_flag": true,
      			"spending_profile_uid": "Unique id of spending profile",
      			"sp_name": "Spending Profile Name 1",
      			"cycle_type": "M",
      			"cycle_reset_day": "23",
      			"cycle_spending_limit": 10000,
      			"daily_spending_limit": 2000,
      			"max_amt_per_tran": 1000,
      			"max_number_of_trans": 15,
      			"first_name": "FirstName1",
      			"last_name": "LastName1",
      			"address_type": "C",
      			"address1": "1234 Street Example 1",
      			"address2": "Apt 123",
      			"city": "Austin",
      			"state": "TX",
      			"zip": "12345",
      			"created_date": "0000-00-00 00:00:00",
		"modified_date": "0000-00-00 00:00:00",
"deleted_date": "0000-00-00 00:00:00"

    		}
  	}
	]
  }
}`;

            const onCopy = React.useCallback((id:number) => {
                const element = document.getElementById("myTooltip"+id);
                if (element) {
                  element.classList.add("show");
                  const intervalId = setInterval(() => {
                    element.classList.remove("show");
                    clearInterval(intervalId);
                  }, 2000);
                }
              }, []);
        
          return (
          <section className="credit-api-main">
            <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
              <div className="container">
                  <div className="row">
                      <div className="col-lg-3">
                          <IntrigrationLeftSec />
                      </div>
                      <div className="col-lg-9">
                            <div className="creadit-api-upper-wrapper">
                            <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                            </div>
                      
                            <div className="creadit-api-lower-wrapper">
                            <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
            <div className="api-menu-item-wrapper mb-40">
                <p>For every API request, the following headers and parameters are required:
                </p>
                <ul>
                    <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                    <li style={{listStyleType:'disc'}}>Parameters:
                        <ul>
                            <li>- GET Requests: Include these parameters in the parameters area</li>
                            <li>- POST Requests: Include these parameters in the request JSON body.</li>
                        </ul>
                    </li>
                </ul>
                
            <div className="api-table">

            <div className="table-responsive mb-20">
                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th style={{ width: '100px' }}>Variable Name</th>
                        <th style={{ width: '420px' }}>Description</th>
                        <th style={{ width: '100px' }}>Required</th>
                        <th style={{ width: '130px' }}>Expected Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>api_username</td>
                        <td>Unique API username associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    <tr>
                        <td>api_key</td>
                        <td>Unique API key associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    
                    
                    </tbody>
                </table>
            </div>
            <ul>
                <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                <li>- All dates and times are in GMT.</li>
            </ul>

            </div>
            </div>
                            <div className="api-menu-item-title"><h2>Card APIs</h2></div>
        
                            <div className="api-menu-item-wrapper">
                                <h3>List Cards by User</h3>
                                <hr/>
                                <ul className="card-url">
                                <li>
                                        <div className="card-url-list">
                                            <p>Name of API:</p>
                                            <span>List of Cards Against One User                                       </span>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <div className="card-url-list">
                                            <p>Description:</p>
                                            <span>Get a list of all cards associated with a specific user.
                                            </span>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <div className="card-url-list">
                                            <p>URL:</p>
                                            <span>https://issuing.tcbpay.com/apis/cards/list</span>
                                        </div>
                                        
                                    </li>
                                    
                                    <li>
                                        <div className="card-url-list">
                                            <p>Sandbox endpoint URL:</p>
                                            <span>https://issuing-stg.tcbpay.com/apis/cards/list</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="card-url-list">
                                            <p>Method:</p>
                                            <span>GET</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="api-table">
                              <h5>Parameters:</h5>
        
                              <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th style={{ width: '100px' }}>Variable Name</th>
                                        <th style={{ width: '420px' }}>Description</th>
                                        <th style={{ width: '100px' }}>Required</th>
                                        <th style={{ width: '130px' }}>Expected Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>user_uid</td>
                                        <td>Unique ID associated with user you want cards of </td>
                                        <td>Yes</td>
                                        <td>String </td>
                                      </tr>

                                      
                                    </tbody>
                                  </table>
                              </div>
        
                              <div className="example-box-wrapper">
                                  <div className="d-flex align-items-start">
                                      <b></b>
                                     <p><span>Example Response Payload(JSON)
                                     </span></p>
                                  </div>
                                  <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip4">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                       
                                    </div>
        
                                    <div className="ex-text-box" id="ExampleCodeText_4">
                                        <pre>{exampleCodeText_4}</pre>
                                    </div>
            
                                    </div>
                              </div>

                            </div>
        
                            <div className="api-menu-item-wrapper mt-60">
                        <h3>Response Codes</h3>
                        <hr />
                        <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, two elements are returned, code, description token. The response code is always 00 for a successful API call.</p>
                        <div className="table-responsive  mb-20">
                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: '100px' }}>Code</th>
                              <th style={{ width: '420px' }}>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>00</td>
                              <td>Success</td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>API Key is missing or invalid.</td>
                            </tr> 
                            <tr>
                              <td>02</td>
                              <td>Username is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>03</td>
                              <td>User uid is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>04</td>
                              <td>Card uid is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>05</td>
                              <td>Card type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>06</td>
                              <td>Profile is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>07</td>
                              <td>Spending profile uid is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>08</td>
                              <td>Spending limit is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>09</td>
                              <td>Shipping address type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>Billing address type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td>User has no shipping address on record.</td>
                            </tr>
                            <tr>
                              <td>12</td>
                              <td>User has no billing address on record.</td>
                            </tr>
                            <tr>
                              <td>13</td>
                              <td>User has no company address on record.</td>
                            </tr>
                            <tr>
                              <td>14</td>
                              <td>Billing address 1 is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>15</td>
                              <td>Billing address 2 is invalid.</td>
                            </tr>
                            <tr>
                              <td>16</td>
                              <td>Billing city is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>17</td>
                              <td>Billing state is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>18</td>
                              <td>Billing ZIP code is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>19</td>
                              <td>Card status is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>20</td>
                              <td>Amount to load is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>21</td>
                              <td>Unable to set card’s billing address.</td>
                            </tr>
                            <tr>
                              <td>22</td>
                              <td>Unable to add card.</td>
                            </tr>
                            <tr>
                              <td>23</td>
                              <td>Unable to modify card status.</td>
                            </tr> 
                            <tr>
                              <td>24</td>
                              <td>Failed to fetch card list for specified user.</td>
                            </tr>
                            <tr>
                              <td>25</td>
                              <td>Unable to update spending limit.</td>
                            </tr>
                            <tr>
                              <td>26</td>
                              <td>Card must be unlocked and profile “N”  in order to reload.</td>
                            </tr>
                            <tr>
                              <td>27</td>
                              <td>Failed to fetch updated card data.</td>
                            </tr>
                            <tr>
                              <td>28</td>
                              <td>Couldn't reload card due to insufficient balance.</td>
                            </tr>
                                                                                   
                          </tbody>
                        </table>
                    </div>
                    </div>
        
                       
                        </div>
        
        
                      </div>
                  </div>
              </div>
              <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
              <ScrollToTop smooth />
          </section>
          )
        }
