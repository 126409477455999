import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const Issuingcard = () => {
  return (
    <>
    <section className="featuresbx">
        <div className="container">
            <div className='row'>
                <div className='col-md-12'>
                    <h6 className='seamless-heading'>Get corporate credit cards for your company</h6>
                </div>
            </div>
                
            <div className="featuresbx-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/issuing-icon1.svg" alt="" /></div>
                        <h4>MasterCard© Network</h4>
                        <p>Use your card wherever Mastercard© is accepted and block specific vendor categories for your employees.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/issuing-icon2.svg" alt="" /></div>
                            <h4>Flexible Terms</h4>
                            <p>Get a pre-paid or post-paid card program with flexible limits.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/issuing-icon3.svg" alt="" /></div>
                            <h4>Instant Issuance</h4>
                            <p>Get virtual cards immediately or order physical cards with speedy delivery.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/issuing-icon4.svg" alt="" /></div>
                            <h4>Peace Of Mind</h4>
                            <p>Rely on TCB Pay’s experienced compliance team, regulatory expertise, and dedicated support team.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='transaction-area gradient-background4'>
        <div className="wave9 d-lg-block">
            <img src="assets/imgs/wave-10.png" alt="" />
        </div>
        <div className='container text-left'>
            <div className='row'>
            <div className='col-md-7 custm-transactionbx'>
                 <h6 className="seamless-heading">Easy transaction <br /> tracking</h6>
                 <div className="tracking-facility">
                        <div className="tracking-inr-box order-sm-1 order-lg-1">
                            <img src="assets/imgs/issuing-icon5.svg" alt="" />
                            <h6>User-Friendly Platform</h6>
                            <p>A simple and easy-to-use platform for all users with a separate admin panel for advanced settings and features.
                            </p>
                        </div>
                        <div className="tracking-inr-box order-sm-4 order-lg-2">
                            <img src="assets/imgs/issuing-icon6.svg" alt="" />
                            <h6>Transactions</h6>
                            <p>Enjoy a seamless and real-time transaction tracking experience with access to detailed transaction insights.</p>
                        </div>
                        <div className="tracking-inr-box order-sm-2 order-lg-3">
                            <img src="assets/imgs/issuing-icon7.svg" alt="" />
                            <h6>Wallet</h6>
                            <p>Your employees can easily track their expenses and view card limits with our intuitive platform.</p>
                        </div>
                        <div className="tracking-inr-box order-sm-3 order-lg-4">
                            <img src="assets/imgs/issuing-icon8.svg" alt="" />
                            <h6>Easy Disputes</h6>
                            <p>Enjoy peace of mind with direct access to a responsive support team to quickly resolve disputes.</p>
                        </div>
                 </div>

                </div>
                <div className='col-md-5 position-relative'>
                 <img src="assets/imgs/issuing-pic.webp" alt=""  className='transaction-pic' />
                </div>

            </div>
        </div>
        <div className="wave10 d-lg-block">
            <img src="assets/imgs/wave-11.png" alt="" />
        </div>
    </section>

    <section className='streamlined-bx'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 order-sm-2 order-lg-1'><img src="assets/imgs/issuing-pic1.svg" alt="" className='max-wd5'/></div>
                <div className='col-md-6 ps-4 order-sm-1 order-lg-2'>
                <h6 className="seamless-heading mb-40">Keep everything<br/> under <span>control</span></h6>
                 <p className='mb-0'>We offer robust tools for managing corporate card programs, enabling precise control over card issuance and usage. It’s a comprehensive hub for financial oversight designed to accommodate both individual card management and bulk operations with ease.</p>
                
                 <div className="tracking-facility text-left d-none d-sm-block">
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon9.svg" alt="" />
                            <h6>Admin Panel</h6>
                            <p>Manage your team’s access, create cards for your employees, and select admins and analysts to manage the account.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon10.svg" alt="" />
                            <h6>Bulk Users Upload</h6>
                            <p>For large companies, easily upload a batch of users from a CSV file to create multiple user accounts with just a few clicks.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon11.svg" alt="" />
                            <h6>Customizable Limits</h6>
                            <p>Create spending profiles with weekly and monthly cycles, limiting the amount and the number of transactions per cycle.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon12.svg" alt="" />
                            <h6>Full Control</h6>
                            <p>Track your employees’ transactions and card usage in real-time and lock any cards or users anytime.</p>
                        </div>
                        <Link to={`${config.siteBaseUrl}/assets/pdf/Issuing-Sell-Sheet.pdf`} target='blank' className="integration-btn3 mt-10 ">Download Product Sell Sheet <FontAwesomeIcon icon={faArrowRight} /></Link>
                 </div>
                 <div className="tracking-facility text-left d-block d-sm-none">
                 <Link to={`${config.siteBaseUrl}/assets/pdf/Issuing-Sell-Sheet.pdf`} target='blank' className="integration-btn3 mb-40">Download Product Sell Sheet <FontAwesomeIcon icon={faArrowRight} /></Link>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon9.svg" alt="" />
                            <h6>Admin Panel</h6>
                            <p>Manage your team’s access, create cards for your employees, and select admins and analysts to manage the account.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon11.svg" alt="" />
                            <h6>Customizable Limits</h6>
                            <p>Create spending profiles with weekly and monthly cycles, limiting the amount and the number of transactions per cycle.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon12.svg" alt="" />
                            <h6>Full Control</h6>
                            <p>Track your employees’ transactions and card usage in real-time and lock any cards or users anytime.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon10.svg" alt="" />
                            <h6>Bulk Users Upload</h6>
                            <p>For large companies, easily upload a batch of users from a CSV file to create multiple user accounts with just a few clicks.</p>
                        </div>
                 </div>
                 
                </div>
            </div>
        </div>
    </section>

    <section className='streamlined-bx'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 d-flex align-items-center'>
                <div className='issuing-inbx'>
                    <h6 className="seamless-heading">Track your <br/> <span>expenses</span></h6>
                    <div className="tracking-facility text-left">
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon13.svg" alt="" className='hgcustm' />
                            <h6>Detailed Reporting</h6>
                            <p>We provide detailed reporting features, including a daily account overview, specific data by categories, and per user/card.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon14.svg" alt="" />
                            <h6>Analyst Access</h6>
                            <p>The customizable Analyst user access only provides a reporting view of the account, without allowing card management.</p>
                        </div>

                    </div>
                </div>
                 
                 
                </div>
                <div className='col-md-6 ps-4'><img src="assets/imgs/issuing-pic2nw.svg" alt="" /></div>
            </div>
        </div>
    </section>

    <section className="effort-area">
        <div className="container">
            <div className="row">
                    <div className="col-lg-6 order-sm-2 order-lg-1">
                        <div className="left-side-img">
                        <img src="assets/imgs/yellowdots.svg" alt="" className="enhanced-yellowdots1" />
                            <img src="assets/imgs/TCBPay-Card-Pay.gif" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center text-left order-sm-1 order-lg-2">
                    <div className='issuing-inbx'>
                    <h6 className="seamless-heading"><span>Effortless</span> payments</h6>
                    <div className="tracking-facility text-left">
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon15.svg" alt="" />
                            <h6>Digital Wallet</h6>
                            <p className='mb-20'>Add the TCB Pay card to your mobile wallet for quick payments wherever you go.</p>
                            <p className='fnt-13'>Compatible with Apple Pay and Google Pay.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/issuing-icon16.svg" alt="" />
                            <h6>Contactless Payments</h6>
                            <p>Use contactless payments for a quicker checkout process.</p>
                        </div>

                    </div>
                </div>       
                </div>
            </div>    
        </div>
    </section>
    
    </>
  )
}
