import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';

export const IntegrationsKonnektiveCrmSec = () => {
    
    return (
        <>
            <section className="credit-api-main">
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <IntrigrationLeftSec />
                        </div>
                        <div className="col-lg-9">
                            <div className="creadit-api-upper-wrapper">
                                <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                                <h2>Konnektive CRM</h2>

                                <p className='mt-30'>You can easily connect your TCB Pay account to your Konnektive CRM account. However, to ensure this process is completed correctly, <b>Konnektive strongly recommends all clients to reach out to their assigned account manager, before activating and testing the gateway</b>.<br/><br/>
                                    
                                For detailed instructions and additional support, please refer to the dedicated <Link to="https://help.konnektive.com/konnektive-crm/gateway-setup/adding-a-gateway" target='_blank'>Konnektive help page</Link>.</p>
                            </div>

                            <div className="creadit-api-lower-wrapper">
                                <div className="api-menu-item-wrapper">
                                    <h3 style={{color:'#397AB7'}}>Add a Gateway Category from the Konnektive platform</h3>
                                    <hr />
                                    <p>To prevent cross-corping, categorize your gateways by the corp they were acquired by.</p>

                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>1 </b>
                                    <p>Navigate to <strong>Gateways → Gateway</strong> List and click the green <strong>+ Category</strong> button.</p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>2 </b>
                                    <p>In the pop-up box, enter the new category name and click <strong>Create Category</strong>.</p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>3 </b>
                                    <p>Once categories are created, use the <strong>Category</strong> dropdown at the top of the Gateway List to filter for a specific category.</p>
                                </div>
                            </div>

                            <div className="creadit-api-lower-wrapper mt-80">
                                <div className="api-menu-item-wrapper">
                                    <h3 style={{color:'#397AB7'}}>Create a New Gateway from the Konnektive platform</h3>
                                    <hr />
                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>1 </b>
                                    <p>Navigate to <strong>Gateways → Gateway</strong> List and click the smaller green <strong>+</strong> button.</p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>2 </b>
                                    <p>Enter Gateway Information in the General Details Section:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>Title:</strong> A name or identifier for the gateway.</li>
                                                <li><strong>Category:</strong> Select the appropriate category for the gateway.</li>
                                                <li><strong>Gateway:</strong> Choose TCB Pay as the gateway.</li>
                                            </ul>
                                        </div>
                                        Additional inputs may vary depending on the gateway. At least one field will relate to the credentials provided by your merchant provider. For specific gateway requirements, refer to the Gateways section in Konnektive.
                                    </p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>3 </b>
                                    <p>(Optional) Add Customer Service Information:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>Phone #:</strong> Customer service number for gateway-related inquiries.</li> 
                                                <li><strong>Chargeback #:</strong> Contact number for chargebacks.</li>
                                                <li><strong>Email:</strong> Customer service email address.</li> 
                                                <li><strong>URL:</strong> Support web page for the gateway.</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>4 </b>
                                    <p>(Recommended) Enter Limits for the Gateway:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>Monthly Cap:</strong> Total processing volume allowed per month.
                                                    <div className="listintri" style={{margin:'0 0 0'}}>
                                                        <ul>
                                                            <li><strong>Default:</strong> Gross billings only (ignores refunds).</li>
                                                            <li><strong>Optional:</strong> Include refunds for net billings (enable by checking Include Refunds).</li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li><strong>Discount Rate:</strong> Percentage of volume taken by the bank for every sale.</li>
                                                <li><strong>Reserve Rate:</strong> Percentage of billings kept by the bank in a rolling reserve.</li>
                                                <li><strong>Transaction Fee:</strong> Flat fee charged per transaction.</li>
                                                <li><strong>Chargeback Fee:</strong> Flat fee for each chargeback.</li>
                                                <li><strong>RDR Fee:</strong> Flat fee for RDR refunds (see RDR for details).</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>5 </b>
                                    <p>Select Additional Options:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>Enabled:</strong> Allow or block sales on this gateway.</li>
                                                <li><strong>Block Prepaid Cards:</strong> Prevent prepaid cards from processing (if checked).</li>
                                                <li><strong>Block Debit Cards:</strong> Prevent debit cards from processing (if checked).</li>
                                                <li><strong>Direct Cascade Profile:</strong> (Optional) Cascade profile to retry soft-declined transactions (see Cascade Profiles for more details).</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>6 </b>
                                    <p>Choose Accepted Payments:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>eCheck:</strong> Check if eChecks are supported.</li>
                                                <li><strong>Mastercard, Visa, AmEx, Discover:</strong> Check supported card types.</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>7 </b>
                                    <p>Enter Gateway Details:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li><strong>Descriptor:</strong> Provided by the merchant provider.</li>
                                                <li><strong>MID #:</strong> Merchant ID number.</li>
                                                <li><strong>Processor:</strong> Payment processor.</li>
                                                <li><strong>Merchant Category Code:</strong> Merchant category code.</li>
                                                <li><strong>Acquirer Bin:</strong> Acquirer bin number.</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>8 </b>
                                    <p>Review all information carefully before creating the gateway.</p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>9 </b>
                                    <p>When finished, click the green <strong>Create Gateway</strong> button to save the configuration.</p>
                                </div>
                            </div>


                            <div className="creadit-api-lower-wrapper mt-80">
                                <div className="api-menu-item-wrapper">
                                    <h3 style={{color:'#397AB7'}}>Need Additional Help?</h3>
                                    <hr />
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <p>The instructions above were taken from the dedicated <Link to="https://help.konnektive.com/konnektive-crm/gateway-setup/adding-a-gateway" target='_blank'><b style={{margin:'0'}}>Konnektive help page</b></Link>. If you encounter any issues or need further assistance:<br/>
                                        <div className="listintri">
                                            <ul>
                                                <li>Contact your Konnektive assigned account manager (RECOMMENDED).</li>
                                                <li>Use the live chat feature in the Konnektive portal.</li>
                                                <li>Submit a ticket via the Konnektive support system.</li>
                                                <li>Check the <Link to="https://help.konnektive.com/konnektive-crm/gateway-setup/adding-a-gateway" target='_blank'><b style={{margin:'0'}}>Konnektive help page</b></Link> for more detailed guidance.</li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
                <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />

                <ScrollToTop smooth />
            </section>
        </>
    )
}
