import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import './test.css'; // Ensure to import your CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import config from '../Config';



gsap.registerPlugin(ScrollTrigger);

export const Tcbpayinfobx: React.FC = () => {
  useEffect(() => {
    const details = gsap.utils.toArray<HTMLDivElement>(".desktopContentSection:not(:first-child)");
    const photos = gsap.utils.toArray<HTMLDivElement>(".desktopPhoto:not(:first-child)");
    const allPhotos = gsap.utils.toArray<HTMLDivElement>(".desktopPhoto");

    gsap.set(photos, { yPercent: 101, autoAlpha: 0 }); // Set initial state

    const mm = gsap.matchMedia();

    mm.add("(min-width: 600px)", () => {
      console.log("desktop");

      ScrollTrigger.create({
        trigger: ".gallery",
        start: "top top",
        end: "bottom bottom",
        pin: ".right",
      });

      details.forEach((detail: HTMLDivElement, index: number) => {
        const headline = detail.querySelector("h4");
        const animation = gsap.timeline()
          .to(photos[index], { yPercent: 0, autoAlpha: 1, duration: 1, ease: "power1.out" })
          .set(allPhotos[index], { autoAlpha: 0 });

        ScrollTrigger.create({
          trigger: headline,
          start: "top 80%",
          end: "top 50%",
          animation: animation,
          scrub: true,
          markers: false,
        });
      });
    });



    return () => {
      console.log("cleanup");
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      mm.kill();
    };
  }, []);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="gallery">
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className="left">
              <div className="desktopContent">
                <div className="desktopContentSection">
                  <div className='tcbpayinfobx-inner'>
                    <h4>TCB Pay <span>Portal</span></h4>
                    <p><b style={{fontWeight:'800'}}>Your one-stop-shop payment solution</b></p>
                    <p>Our homemade payment processing solution offers unmatched simplicity and efficiency.</p>
                    <p>Accessible from any device at any time, enjoy the flexibility to accept payments in multiple currencies, and with multiple MIDs consolidated into one single account.</p>
                    <Link to="/portal" className="integration-btn1">Discover the Portal <FontAwesomeIcon icon={faArrowRight} /></Link>
                    <div className='hmrgtpic'><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic1.svg`} alt=''/></div>
                  </div>
                </div>
                <div className="desktopContentSection">
                  <div className='tcbpayinfobx-inner'>
                    <h4>TCB Pay <span>App</span></h4>
                    <p><b style={{fontWeight:'800'}}>Run your business from your phone or tablet</b></p>
                    <p>As an extension of the TCB Pay Portal, the TCB Pay App ensures your business always stays in your hands, wherever you are, whatever you do.</p>
                    <Link to="/app" className="integration-btn1">Discover the TCB Pay App <FontAwesomeIcon icon={faArrowRight} /></Link>
                    <div className='hmrgtpic'><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic2.svg`} alt=''/></div>
                  </div>
                </div>
                <div className="desktopContentSection">
                  <div className='tcbpayinfobx-inner'>
                    <h4>TCB Pay <span>Issuing</span></h4>
                    <p><b style={{fontWeight:'800'}}>Cutting-edge corporate card program </b></p>
                    <p>Our corporate card program is tailored to meet the unique needs of any business. We offer a wide array of options, including physical and digital credit cards that have both prepaid and postpaid capabilities and flexible limits.</p>
                    <Link to="/issuing" className="integration-btn1">Discover TCB Pay Issuing <FontAwesomeIcon icon={faArrowRight} /></Link>
                    <div className='hmrgtpic'><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic3.svg`} alt=''/></div>
                  </div>
                </div>
                <div className="desktopContentSection">
                  <div className='tcbpayinfobx-inner'>
                    <h4>TCB Pay <span>White-Label</span></h4>
                    <p><b style={{fontWeight:'800'}}>Payment solutions that looks like you, and only you </b></p>
                    <p>Looking to customize your own payment tools? We can provide white-labeled products and services for our partners and agents to use at their convenience.</p>
                    <Link to="/white-label" className="integration-btn1">Discover White-Label <FontAwesomeIcon icon={faArrowRight} /></Link>
                    <div className='hmrgtpic'><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic4.svg`} alt=''/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="right">
              <div className="desktopPhotos">
                <div className='desktopPhoto'><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic1.svg`} alt=''/></div>
                <div className="desktopPhoto green"><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic2.svg`} alt='' /></div>
                <div className="desktopPhoto pink"><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic3.svg`} alt='' /></div>
                <div className="desktopPhoto blue"><img src={`${config.siteBaseUrl}/assets/imgs/tcbpay-pic4.svg`} alt='' /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
