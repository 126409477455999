import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />

        <Container>
          <Row>
            <div className='text-left col-md-12'>
              <span><img src='assets/imgs/i-icon.svg' alt='' style={{ width: '17px' }} />ISSUING WIKI</span>
              <h1>Your guide to using TCB Pay Issuing</h1>
            </div>
            <div className='text-left col-md-7'>
              <p>Explore TCB Pay Issuing with this guide for managing digital and physical corporate cards, setting tailored spending profiles, and tracking cardholder activity. Suitable for businesses of any size, this resource covers everything needed to simplify card issuance, maintain spending control, and elevate your card management practices for greater operational efficiency.</p>
            </div>
          </Row>
        </Container>

    </section>
    </>
  )
}
