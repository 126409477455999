import React from 'react'
import { IntegrationCreditRefundSec } from './integrationCreditRefundSec'

export const IntegrationCreditRefund = () => {
  return (
    <>
    <IntegrationCreditRefundSec />
    </>
  )
}
