import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const AchProcessSec = () => {
  return (
    <>
    <section className="ecommerce-bx gradient-background9">
        <div className="wave19 d-lg-block">
            <img src="assets/imgs/wave-19.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h6 className="seamless-heading abut-txt text-left">The smarter way to move money</h6>
                </div>
                
                    <div className="tracking-facility text-left">
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/ach-icn1.svg" alt="" />
                            <h6>Cost-Effective</h6>
                            <p>ACH transfers typically have lower fees compared to credit card payments or wire transfers.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/ach-icn2.svg" alt="" />
                            <h6>Speed & Efficiency</h6>
                            <p>ACH allows for quick and automated transfers, reducing processing time for both businesses and customers.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/ach-icn3.svg" alt="" />
                            <h6>Security</h6>
                            <p>ACH transactions are encrypted and processed through secure channels, reducing the risk of fraud.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/ach-icn4.svg" alt="" />
                            <h6>Convenience</h6>
                            <p>Easily manage recurring payments and direct transfers without the need for checks or physical paperwork.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div className="wave4 d-lg-block">
        <img src="assets/imgs/wave-20.png" alt="" />
        </div>
        
    </section>

    <section className='streamlined-bx pt-50'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 d-flex align-items-center'>
                <div className='issuing-inbx'>
                    <h6 className="seamless-heading">Keep everything under <span>control</span></h6>
                    <div className="tracking-facility text-left">
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/ach-icon5.svg" alt="" />
                            <h6>Sales Management</h6>
                            <p>Easily manage and track all ACH sales in one streamlined platform. Process payments quickly and keep detailed records, ensuring smooth and secure transactions every time.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/ach-icon6.svg" alt="" />
                            <h6>Batch Upload</h6>
                            <p>Quickly upload and process multiple ACH transactions at once, saving time and reducing errors with streamlined batch uploads.</p>
                        </div>

                    </div>
                </div>
                 
                 
                </div>
                <div className='col-md-6 ps-4'><img src="assets/imgs/issuing-pic2.svg" alt="" /></div>
            </div>
        </div>
    </section>



    <section className='effort-area'>
        <div className='container'>
            <div className='row'>
            <div className='col-md-6 ps-4 order-sm-2 order-lg-1'>
                 <div className='left-side-img'>
                    <img src="assets/imgs/ach-pic1.svg" alt="" />
                 </div>
                </div>
                <div className='col-md-6 d-flex align-items-center text-left order-sm-1 order-lg-2'>
                <div className='issuing-inbx'>
                    <h6 className="seamless-heading">A flexible alternative to <span>card payments</span></h6>
                    <p className='achtxt'>Whether you choose not to accept credit card payments or you are unable to, TCB Pay’s ACH solution has you covered. Seamlessly accept direct bank transfers, ensuring that you never miss a transaction. ACH offers businesses a reliable way to get paid without relying on credit cards.</p>
                    <div className="tracking-facility text-left">
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/ach-icon7.svg" alt="" className='ach-icn7'/>
                            <h6>No Card? No Problem</h6>
                            <p>Accept payments directly from bank accounts, bypassing card payment limitations.</p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src="assets/imgs/ach-icon8.svg" alt="" />
                            <h6>Easier for Clients</h6>
                            <p>Let customers pay through direct transfers, simplifying their experience while maintaining security.</p>
                        </div>

                    </div>
                </div>
                 
                 
                </div>
            </div>
        </div>
    </section>
    <section className='achp-bg'>
        <div className="gallery pb-225 ">
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
        <div className='container'>
            <div className='row'>
            <div className='col-md-5 d-flex align-items-center'>
                <div className="left">
                <div className="desktopContent1">
                <div className="desktopContentSection1">
                    <div className='tcbpayinfobx-inner'>
                        <h4>Empower your payments with <span>TCB Pay Portal</span></h4>
                        <p>Dive into TCB Pay Portal to seamlessly handle ACH payments and explore a world of features—real-time tracking, e-commerce integration, and instant alerts. All from one secure, easy-to-use platform.</p>
                        <Link to="/portal" className="integration-btn1">Discover the Portal <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-md-7'>
                <div className="right1 ach-mt">
                    <div className='desktopPhoto1'><img src='assets/imgs/ach-pic2.svg' alt='' /></div>
                </div>
            </div>
            </div>
        </div>
        </div>

    </section>


    </>
  )
}
