import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqAccountSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                      <h2 className='faq-rgtsectitle'>My TCB Pay Account</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I receive my funds from my account?</div>
                        <div className='qusans'>
                            <p>Depending on your account, you can receive your funds in your bank account the following day.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What do I do if I have a delay in receiving my funds?</div>
                        <div className='qusans'>
                            <p>Contact <Link to="mailto:support@tcbpay.com">support@tcbpay.com</Link> and we will be more than happy to assist you!</p>
                        </div>
                    </div>


                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
