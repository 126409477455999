import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import config from '../Config';

export const TcbpayappTab = () => {
  return (
    <>
    <section className='apptab'>
        <div className="container">
            <div className='row'>
                <div className='col-md-12'>
                    <h6 className='seamless-heading'>Enjoy the <span>many features</span> our app has to offer</h6>
                    <p>Discover a powerful, all-in-one platform designed to streamline payments and manage<br/> transactions effortlessly. With cutting-edge technology and user-focused features, this app<br/> offers reliable, secure solutions for businesses of all sizes.</p>
                    <div className='col-md-3 text-center m-auto mb-5'><Link to={`${config.siteBaseUrl}/assets/pdf/App-Sell-Sheet.pdf`} className="highrisk-inner-btn justify-content-center" target='blank'><img src='assets/imgs/arw-agn.svg' /> Download product sell sheet</Link></div>
                </div>
            </div>
        </div>
        <div className="container tab-container-mb">
            <div className='row position-relative mb-custmwd'>
                <img src="assets/imgs/bluedots1.svg" alt="" className="blue-dots3" />
                <Tabs defaultActiveKey="qrcode" id="fill-tab-example" className="mb-3" fill>
                <Tab eventKey="qrcode" title="QR Code Payments">
                   <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Instant Payments Made Easy</h6>
                            <p>With Scan-to-Pay, provide your clients with a fast, contactless, and secure checkout experience.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                <Tab eventKey="chargebacks" title="Chargebacks">
                    <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Manage Chargebacks Effortlessly</h6>
                            <p>With our advanced security measures and streamlined dispute resolution processes, TCB Pay helps you reduce your chargeback rate and protect your revenue.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic1.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                <Tab eventKey="credit-card-API" title="Credit Card Processing">
                    <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Effortless Transactions</h6>
                            <p>Experience fast, secure, and reliable credit card processing that enhances your business efficiency and customer satisfaction.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic2.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                <Tab eventKey="card-not-present" title="Card-Not-Present">
                    <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Beyond the Swipe</h6>
                            <p>Experience seamless, secure, and efficient card-not-present transactions that cater to your customers wherever they are.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic3.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                <Tab eventKey="transaction" title="Transaction History">
                    <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Flawless Transactions Management</h6>
                            <p>Easily access your previous transactions to issue refunds, void transactions, or share receipts with your customers.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic4.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                <Tab eventKey="invoices" title="Invoices & Subscriptions">
                    <div className='row d-flex align-items-center mb-bgtab'>
                        <div className='col-md-5 order-sm-2 order-lg-1 md-custmwd1'>
                            <div className='tab-innerbx'>
                            <h6>Streamlined Billing Solutions</h6>
                            <p>Generate invoices allowing easy online payments through a payment link. Establish subscriptions and plans for scheduled recurring billing.</p>
                            </div>
                        </div>
                        <div className='col-md-7 order-sm-1 order-lg-2'>
                            <img src='assets/imgs/tab-pic5.svg' alt='' className='custmtab-pic' />
                        </div>
                   </div>
                </Tab>
                </Tabs>
            </div>
        </div>
    </section>

    </>
  )
}
