import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingSpendingProfile = () => {
    const exampleCodeText_4 = `{
	"message": "Spending profile list successfully recieved.",
	"data": {
    	"profiles": [
       {
       "spending_profile_details": {
		"spending_profile_uid": "{unique id of spending profile}",
    		"sp_name": "spending profile name"
"cycle_type": "M",
"cycle_reset_day": "23",
		"cycle_spending_limit": 10000,
		"daily_spending_limit": 2000,
		"max_amt_per_tran": 1000,
		"created_date": "0000-00-00 00:00:00",
		"modified_date": "0000-00-00 00:00:00",
"deleted_date": "0000-00-00 00:00:00"
"is_deleted": "NO"
        	}
}
    	]
	}
}`;
      
const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
    element.classList.add("show");
    const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
    }, 2000);
    }
}, []);
              
return (
<section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
    <div className="container">
        <div className="row">
            <div className="col-lg-3">
                <IntrigrationLeftSec />
            </div>
            <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                    <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                    </div>
            
                    <div className="creadit-api-lower-wrapper">
                    <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
                                <div className="api-menu-item-wrapper mb-40">
                                    <p>For every API request, the following headers and parameters are required:
                                    </p>
                                    <ul>
                                        <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                                        <li style={{listStyleType:'disc'}}>Parameters:
                                            <ul>
                                                <li>- GET Requests: Include these parameters in the parameters area</li>
                                                <li>- POST Requests: Include these parameters in the request JSON body.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    
                                <div className="api-table">
            
                                <div className="table-responsive mb-20">
                                    <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '100px' }}>Variable Name</th>
                                            <th style={{ width: '420px' }}>Description</th>
                                            <th style={{ width: '100px' }}>Required</th>
                                            <th style={{ width: '130px' }}>Expected Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>api_username</td>
                                            <td>Unique API username associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        <tr>
                                            <td>api_key</td>
                                            <td>Unique API key associated with the account</td>
                                            <td>Yes</td>
                                            <td>String </td>
                                        </tr>
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                                <ul>
                                    <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                                    <li>- All dates and times are in GMT.</li>
                                </ul>
            
                                </div>
                                </div>
                    <div className="api-menu-item-title"><h2>Spending Profiles APIs</h2></div>

                    <div className="api-menu-item-wrapper">
                        <h3>Spending Profile List</h3>
                        <hr/>
                        <ul className="card-url">
                        <li>
                                <div className="card-url-list">
                                    <p>Name of API:</p>
                                    <span>List of Spending Profiles Against Account                                     </span>
                                </div>
                                
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Description:</p>
                                    <span>Get a list of all spending profiles associated with an account.
                                    </span>
                                </div>
                                
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>URL:</p>
                                    <span>https://issuing.tcbpay.com/apis/spendingprofile/list</span>
                                </div>
                                
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Sandbox endpoint URL:</p>
                                    <span>https://issuing-stg.tcbpay.com/apis/spendingprofile/list</span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Method:</p>
                                    <span>GET</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="api-table">
                    <div className="example-box-wrapper">
                        <div className="d-flex align-items-start">
                            <b></b>
                            <p><span>Example Response Payload(JSON)
                            </span></p>
                        </div>
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip4">
                            <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                            <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_4">
                                <pre>{exampleCodeText_4}</pre>
                            </div>
    
                            </div>
                    </div>

                    </div>


                
                </div>


            </div>
        </div>
    </div>
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
    <ScrollToTop smooth />
</section>
)
}
