import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';


export const CompactRobust = () => {
  return (
    <>
    <section className="featuresbx">
        <div className="container">
            <div className='row'>
                <div className='col-md-12'>
                    <h6 className='seamless-heading'>Compact yet <span>robust</span></h6>
                </div>
            </div>
                
            <div className="featuresbx-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/seamless_icon.svg" alt="" /></div>
                        <h4>Compact Yet Robust</h4>
                        <p>The TCB Pay App includes all the essential features of the TCB Pay portal, enabling you to manage your business in any situation.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/feature-icon1.svg" alt="" /></div>
                            <h4>Dedicated Support</h4>
                            <p>Our support team is available 24/7 to assist our merchants with unparalleled customer service. Just email, call or text us whenever you need us.</p>
                        </div>
                        <Link to="https://www.youtube.com/@TCBPay" className="highrisk-inner-btn m-visible" target='blank'><img src='assets/imgs/arw-agn.svg' /> Check our video tutorials</Link>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/seamless_icon6.svg" alt="" /></div>
                            <h4>Get Paid Easily</h4>
                            <p>Manually enter card information or use a card reader to tap, swipe, or insert cards. Customers can also pay with their digital wallet.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv'><img src="assets/imgs/seamless_icon1.svg" alt="" /></div>
                            <h4>White-Label</h4>
                            <p>Tailor your payment solutions by customizing the TCB Pay App and other TCB Pay products to reflect your branding.</p>
                        </div> 
                        <Link to="/white-label" className="highrisk-inner-btn mr-20 m-visible"><img src='assets/imgs/arw-agn.svg' /> View our white-label offerings</Link>
                    </div>
                </div>
            </div>

            <div className='row mt-20'>
            <div className='col-md-3 d-none d-lg-block'>&nbsp;</div>
            <div className='col-md-3 text-left'><Link to="https://www.youtube.com/@TCBPay" className="highrisk-inner-btn d-none d-lg-block" target='blank'><img src='assets/imgs/arw-agn.svg' /> Check our video tutorials</Link></div>
            <div className='col-md-3 d-none d-lg-block'>&nbsp;</div>
            <div className='col-md-3 text-left'><Link to="/white-label" className="highrisk-inner-btn mr-20 d-none d-lg-block"><img src='assets/imgs/arw-agn.svg' /> View our white-label offerings</Link></div>
            </div>
            
            
        </div>
    </section>
    </>
  )
}
