import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const AchReport = () => {
    const exampleCodeText_4 = `<html lang="en">
<head>
<meta charset="UTF-8">
<title>TCB Pay ACH API Report Test</title>
</head>
<body>
<form name="" action="https://gateway2.tcbpay.com/apis/ach_report.php"
method="post">
<input type="text" name="username" value="tcbpayusername"/>
<input type="text" name="api_key" value="tcbpayapikey"/>
<input type="text" name="start_date" value="2019-04-01"/>
<input type="text" name="end_date" value="2019-04-15"/>
<br/>
<input type="submit" value="submit"/>
</form>
</body>
</html>`;
        const exampleCodeText_5 = `{
  "response": {
    "code": "00",
    "description": "success",
    "no_of_transactions": "1",
    "total_pages": 1,
    "current_page": "1",
    "transactions": [
      {
        "transaction_id": "A9169",
        "parent_transaction_id": "",
        "transaction_type": "SALE",
        "transaction_source": "Moto",
        "initiated_by": "api_user (goutam@mailinator.com)",
        "transaction_status": "INACTIVE",
        "decline_reason": "",
        "transaction_time": "2025-01-30 07:20:49",
        "time_zone": "America/New_York",
        "gmt_time": "2025-01-30 12:20:49",
        "account_no": "********2222",
        "routing_no": "********0025",
        "account_type": "Checking",
        "auth_response_code": "Success",
        "error_response": "",
        "currency": "USD",
        "total_amount": "8.88",
        "amount": "8.88",
        "surcharge": "0",
        "tax": "0",
        "tip": "0",
        "first_name": "Grk",
        "last_name": "Karm",
        "company_name": "",
        "address_1": "555 West 5th Avenue",
        "address_2": "bill 2nd",
        "city": "Columbus",
        "state": "OH",
        "zip_code": "43201",
        "email": "goutam@tcbpay.com",
        "phone_no": "+19999999999",
        "subscription_id": null,
        "ship_first_name": "Grk",
        "ship_last_name": "Karm",
        "ship_company_name": "",
        "ship_address_1": "555 West 5th Avenue",
        "ship_address_2": "bill 2nd",
        "ship_city": "Columbus",
        "ship_state": "OH",
        "ship_zip_code": "43201",
        "ship_phone_no": "+19999999999",
        "is_risk_hold": "NO",
        "IP": null,
        "reference_id": null
      }
    ]
  }
}`;
    
        const onCopy = React.useCallback((id:number) => {
            const element = document.getElementById("myTooltip"+id);
            if (element) {
              element.classList.add("show");
              const intervalId = setInterval(() => {
                element.classList.remove("show");
                clearInterval(intervalId);
              }, 2000);
            }
          }, []);
    
      return (
        <section className="credit-api-main">
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
          <div className="container">
              <div className="row">
                  <div className="col-lg-3">
                      <IntrigrationLeftSec />
                  </div>
                  <div className="col-lg-9">
                      <div className="creadit-api-upper-wrapper">
                            <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                          {/* <h2>ACH API</h2>
                          <ul className="card-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>URL To Call: </p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_sale.php</Link></span>
                                  </div>
                                
                              </li>
                              
                              <li>
                                  <div className="card-url-list">
                                      <p>Example:</p>
                                      <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                  </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Production endpoint URL:</p>
                                      <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                  </div>
                              </li>
                          </ul> */}
                      </div>
    
                      <div className="creadit-api-lower-wrapper">
                          <div className="api-menu-item-title">
                              <h2>ACH</h2>
                          </div>
                          <div className="api-menu-item-wrapper">
                              <h3>Report API</h3>
                              <hr />
                              <p>This API is responsible for generating transaction reports.</p>

                              <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox endpoint URL:</p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_report.php </Link></span>
                                  </div>
                                
                              </li>

                              <li>
                                  <div className="card-url-list">
                                      <p>Production endpoint URL</p>
                                      <span><Link to=''>{config.gatewayApisUrlProd}/apis/ach_report.php</Link></span>
                                 </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method of form submission</p>
                                      <span><Link to=''>POST</Link></span>
                                 </div>
                              </li>
                              <br/>
                              </ul>
                          </div>
                      </div>
    
                      <div className="api-table">
                          <h5>This table describes the request parameters for Report API.</h5>
    
                          <div className="table-responsive">
                                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th style={{ width: '100px' }}>Variable Name</th>
                                        <th style={{ width: '420px' }}>Description</th>
                                        <th style={{ width: '100px' }}>Required</th>
                                        <th style={{ width: '130px' }}>Expected Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>username</td>
                                        <td>TCB Pay Gateway Username</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway Username</td>
                                      </tr>
                                      <tr>
                                        <td>api_key</td>
                                        <td>TCB Pay Gateway API Key</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway API Key</td>
                                      </tr>
                                      <tr>
                                        <td>start_date</td>
                                        <td>From transaction date</td>
                                        <td>Y</td>
                                        <td>date format YYYY-MM-DD</td>
                                      </tr>
                                      <tr>
                                        <td>end_date</td>
                                        <td>To transaction date</td>
                                        <td>Y</td>
                                        <td>date format YYYY-MM-DD</td>
                                      </tr>
                                      <tr>
                                        <td>transaction_type</td>
                                        <td>Type of transaction</td>
                                        <td>N</td>
                                        <td>(default all transaction) SALE,AUTHORIZE,CAPTURE,REFUND,VOID,CREDIT
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>transaction_status</td>
                                        <td>Status of transaction</td>
                                        <td>N</td>
                                        <td>(default all transaction) SUCCESS,FAIL
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>transaction_id</td>
                                        <td>TCBPay ID of the transaction, If we need to know the details of a specific transaction</td>
                                        <td>N</td>
                                        <td>100000000000001605
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>page_no</td>
                                        <td>Page number of the entire result set. Each page has a maximum of 100 records listed</td>
                                        <td>N</td>
                                        <td>(default 1) can be up to total page number
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>reference_id</td>
                                        <td>Transaction reference id</td>
                                        <td>N</td>
                                        <td>Reference ID should be unique</td>
                                      </tr>
                                    </tbody>
                                  </table>
                          </div>
    
                          <div className="example-box-wrapper">
                              <div className="d-flex align-items-start">
                                  <b></b>
                                 <p><span>Example</span></p>
                              </div>
                              <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip4">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                   
                                </div>
    
                                <div className="ex-text-box" id="ExampleCodeText_4">
                                    <pre>{exampleCodeText_4}</pre>
                                </div>
        
                                </div>
                          </div>
                      </div>
    
                      
                          <div className="api-menu-item-wrapper mt-60">
                              <h3>Response:</h3>
                              <hr />
                              <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three additional elements are returned along with code and description which are no_of_transactions, total_pages, current_page, and an array of transactions. The response code is always 00 for a successful API call.</p>
                              <div className="example-box-wrapper mb-20">
                                  <div className="d-flex align-items-start">
                                      <b></b>
                                      <span>Sample Response :</span>
                                  </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip5">
                                    <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                    <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    
                                    </div>
    
                                    <div className="ex-text-box" id="ExampleCodeText_5">
                                        <pre>{exampleCodeText_5}</pre>
                                    </div>
        
                                </div>
                              </div>
                          </div>
                   
                          <div className="api-table">
                          <h5>Error Codes</h5>
    
                          <div className="table-responsive">
                            <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                            <th>Error Code</th>
                            <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                            <td>01</td>
                            <td>Merchant Information is Missing</td>
                            </tr>
                            <tr>
                            <td>02</td>
                            <td>Transaction Currency is missing</td>
                            </tr>
                            <tr>
                            <td>03</td>
                            <td>Account Number is missing or Invalid</td>
                            </tr>
                            <tr>
                            <td>04</td>
                            <td>Routing Number is missing or invalid</td>
                            </tr>
                            <tr>
                            <td>05</td>
                            <td>Check Number is missing or invalid</td>
                            </tr>
                            <tr>
                            <td>06</td>
                            <td>Invalid Amount</td>
                            </tr>
                            <tr>
                            <td>07</td>
                            <td>Account Type is Missing</td>
                            </tr>
                            <tr>
                            <td>08</td>
                            <td>Proper Zip code is missing</td>
                            </tr>
                            <tr>
                            <td>09</td>
                            <td>Proper Customer Email Id is missing</td>
                            </tr>
                            <tr>
                            <td>10</td>
                            <td>Invalid Processor</td>
                            </tr>
                            <tr>
                            <td>11</td>
                            <td>Requested Currency is not allowed for this merchant</td>
                            </tr>
                            <tr>
                            <td>12</td>
                            <td>Invalid Merchant Information</td>
                            </tr>
                            <tr>
                            <td>13</td>
                            <td>Invalid Country Code</td>
                            </tr>
                            <tr>
                            <td>14</td>
                            <td>Sale Volume exceeded for this Merchant</td>
                            </tr>
                            <tr>
                            <td>15</td>
                            <td>Monthly Volume exceeded for this Merchant</td>
                            </tr>
                            <tr>
                            <td>16</td>
                            <td>Transaction limit  exceeded for this Merchant</td>
                            </tr>
                            <tr>
                            <td>17</td>
                            <td>IP-wise transaction limit  exceeded for this Merchant</td>
                            </tr>
                            <tr>
                            <td>18</td>
                            <td>Bank Name is missing</td>
                            </tr>
                            <tr>
                            <td>19</td>
                            <td>Transaction Start Date is missing or invalid</td>
                            </tr>
                            <tr>
                            <td>20</td>
                            <td>Transaction End Date is missing or invalid</td>
                            </tr>
                            <tr>
                            <td>P1</td>
                            <td>Invalid page number</td>
                            </tr>
                            <tr>
                            <td>A1</td>
                            <td>Transaction entry failed</td>
                            </tr>

                            </tbody>
                            </table>
                          </div>
                        </div>   
    
    
                  </div>
              </div>
          </div>
          <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
          <ScrollToTop smooth />
        </section>
      )
    }
    
