import React from 'react'
import { FaqTcbAppSec } from './faqTcbAppSec'

export const FaqTcbApp = () => {
  return (
    <>
    <FaqTcbAppSec />
    </>
  )
}
