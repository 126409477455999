import React from 'react'
import { SystemDetailSec } from './systemDetailSec'

export const SystemstatusDetail = () => {
  return (
    <>
    <SystemDetailSec />
    </>
  )
}
