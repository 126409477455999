import React from 'react'
import { FaqIntegrationsSec } from './faqIntegrationsSec'

export const FaqIntegrations = () => {
  return (
    <>
    <FaqIntegrationsSec />
    </>
  )
}
