import React from 'react'
import { SystemStatusSec } from './systemStatusSec'

export const SystemStatus = () => {
  return (
    <>
    <SystemStatusSec />
    </>
  )
}
