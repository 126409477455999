import React from 'react'
import { FaqWhitelabelSec } from './faqWhitelabelSec'


export const FaqWhitelabel = () => {
  return (
    <>
    <FaqWhitelabelSec />
    </>
  )
}
