import axios from 'axios'
import config from '../Config';
export class IntegrantionModuleService{
    private static URL:string = config.internalApiUrl;

    public static getModuleDetails(module_name:any){
        const postData = {
            module_name: module_name
          };
        let UserURL:string = `${this.URL}/module_version_details.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        })

    }
}