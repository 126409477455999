import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqaccSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                      <h2 className='faq-rgtsectitle'>Account Creation</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I apply for a TCB Pay merchant account?</div>
                        <div className='qusans'>
                            <p>Apply at www.app.tcbpay.com.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How long does the application process take?</div>
                        <div className='qusans'>
                            <p>The approval process can be as fast as 24 hours or longer depending on your business. Either way, we will be sure to keep you informed on your application status.</p>
                        </div>
                    </div>

                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I open a TCB Pay Issuing account?</div>
                        <div className='qusans'>
                            <p>If you want to start using TCB Pay Issuing, please contact +1 866 444-8585 via call, text, or email <Link to="mailto:support@tcbpay.com">support@tcbpay.com.</Link> You can also <Link to="https://calendly.com/chris-tcb" target="blank" >schedule a free setup consultation</Link> with our Sales team.</p>
                        </div>
                    </div>

                   
                      
                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
