import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqWhitelabelSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                      <h2 className='faq-rgtsectitle'>White-Label</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What customizations do you offer?</div>
                        <div className='qusans'>
                            <p>Discover our White-Label offerings on its dedicated product page. </p>
                            <p>Please contact us at <Link to="mailto:support@tcbpay.com">support@tcbpay.com</Link> and we will be more than happy to walk you through our white-labeling program.
                            </p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>If these customizations don't suit me, what can I do?</div>
                        <div className='qusans'>
                            <p>If the white-labeling solutions do not work for your business, we can work with you to help you find the best solutions to optimize your business. Contact us and we’ll discuss the best solutions for your business.</p>
                        </div>
                    </div>
                

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
