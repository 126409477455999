import React from 'react'
import { IntegrationCreditCaptureSec } from './integrationCreditCaptureSec'

export const IntegrationCreditCapture = () => {
  return (
    <>
    <IntegrationCreditCaptureSec />
    </>
  )
}
