import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsFillChatFill } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import config from '../Config';
{/* <BsTelephoneFill />
<BsFillChatFill /> */}


export const Footer = () => {
    
    const cDate = new Date();
    var cYear = cDate.getFullYear();
  return (
   <>
   
    <footer className="footer-mainbg">
        <div className="footer-container">
            <div className="container pb-80 pt-80 ontop">
                <div className="querybx">
                    <p><b>Any questions? We can help.</b></p>
                    <p>Tap to chat with our customer support team now.</p>
                    <div className="query-icon">
                        <Link to="mailto:support@tcbpay.com" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon1.svg`} alt="" /></Link>
                        <Link to="tel:+1(866)444-8585"><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon2.svg`} alt="" /></Link>
                        {/* <Link to="tel:+1(866)444-8585"><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon3.svg`} alt="" /></Link> */}
                    </div>
                </div>
                <div className='row mt-50 mb-50 align-items-center'>
                    <div className='col-lg-3'>
                        <div className="logo icon-img-170">
                            <img src={`${config.siteBaseUrl}/assets/imgs/logo-light.svg`} alt="Logo" />
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        <div className="share-icon d-flex justify-content-end">
                            <Link to="https://www.linkedin.com/company/tcbpay/" target='blank'><FaLinkedinIn /></Link>
                            <Link to="https://www.instagram.com/tcbpay/" target='blank'><FaInstagram /></Link>
                            <Link to="https://www.youtube.com/@TCBPay?app=desktop" target='blank'><FaYoutube /></Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Products</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to={`${config.siteBaseUrl}/portal`}>TCB Pay Portal</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/app`}>TCB Pay App</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/issuing`}>TCB Pay Issuing</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/white-label`}>TCB Pay White Label</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Services</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to={`${config.siteBaseUrl}/retail`}>Retail</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/ecommerce`}>Ecommerce</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/high-risk`}>High-Risk</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/integrations`}>Integrations</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/it-solutions`}>IT Solutions</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/ach-processing`}>ACH Processing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Resources</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to={`${config.siteBaseUrl}/about-us`}>About Us</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/our-team`}>Our Team</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/careers`}>Careers</Link>
                            </li>
                            <li>
                                <Link to={config.blogUrl} >Blog</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/partners`}>Partners</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/security`}>Security</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/faq`}>FAQ</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/pricing`}>Pricing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Merchants</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to={config.issuingUrl} target='blank'>Issuing Login </Link>
                            </li>
                            <li>
                                <Link to={config.portalUrl} target='blank'>Portal Login</Link>
                            </li>
                            <li>
                                <Link to={`${config.portalUrl}/app/signup`} target='blank'>Open an Account</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/contact`}>Contact Us</Link>
                            </li> 
                            <li>
                                <Link to={`${config.siteBaseUrl}/system-status`}>System Status</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/wiki-issuing`}>Issuing Wiki</Link>
                            </li>
                            <li>
                                <Link to={`${config.siteBaseUrl}/wiki-portal`}>Portal Wiki</Link>
                            </li>
                             
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mid-footer pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ml-auto d-flex">
                                <p>TCB Pay is a registered <b>ISO</b> of Citizens Bank, N.A., Concord, CA and Fresno First Bank, Fresno, CA. ● TCB Pay is a registered <b>ISO/SP</b> of Westamerica Bank, Santa Rosa, CA. ● TCB Pay is a registered <b>PSP</b> of Credorax Bank, Valletta, Malta. ● TCB Pay is a registered <b>Payment Facilitator</b> of Fifth Third Bank, Cincinnati, OH.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 order-sm-2 order-lg-1">
                            <div className="tcblink">
                                <a>©TCB Pay LTD {cYear}</a>
                            </div>
                        </div>
                        <div className="col-lg-10 order-sm-1 order-lg-2">
                            <div className="copyright d-flex">
                                <div className="ml-auto">
                                    <ul className='footerLast'>
                                        <li><Link to={`${config.siteBaseUrl}/privacy-policy`}>Privacy Policy</Link></li>
                                        <li><Link to={`${config.siteBaseUrl}/terms-and-conditions`}>Terms & Conditions</Link></li>
                                        <li><Link to={`${config.siteBaseUrl}/payfac-terms-and-conditions`}>PayFac Terms & Conditions</Link></li>
                                        <li><Link to={`${config.siteBaseUrl}/code-of-conduct`}>Code of Conduct</Link></li>
                                        <li><Link to={`${config.siteBaseUrl}/cookies-policy`}>Cookies Policy</Link></li>
                                        <li><Link to={`${config.siteBaseUrl}/personal-guarantee`}>Personal Guarantee</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="userConsent"
      style={{ background: "#397AB7", opacity: "0.85", padding: "0px 107px", textAlign:"left", alignItems:"center"}}
      buttonStyle={{ background: "#fff", color: "#397AB7", fontSize: "15px", borderRadius:"23px", padding: "5px 20px", margin:"15px 10px" }}
      declineButtonStyle={{  background: "rgb(113 159 203)", color: "#fff", fontSize: "15px", borderRadius:"23px", padding: "5px 20px", margin:"15px 0"}}
      enableDeclineButton
    >
      <div className="cookietxt" style={{margin:"0",fontSize:"15px",fontWeight:"400"}}>We use cookies to offer you a better experience, and analyze site traffic. <br/>
      By continuing to use this website, you consent to the use of cookies in accordance with our <Link to={`${config.siteBaseUrl}/cookies-policy`} style={{color:"#fff"}}><b>Cookies Policy.</b></Link></div>
    </CookieConsent>
    
    </>
  );
};
