import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {
  const scrollToMiddle = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
    <>
    <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-5'>
            <span><img src='assets/imgs/issuing-icon.png' alt='' /> TCB PAY ISSUING</span>
            <h1>Cutting-edge corporate card program</h1>
            <p>Our corporate card program is tailored to meet the unique needs of any business. We offer a wide array of options, including physical and digital credit cards that have both prepaid and postpaid capabilities.</p>
            <a onClick={scrollToMiddle} className="get-btn1">Get Started</a>
            <Link to={`${config.siteBaseUrl}/contact`} className="contact-btn">Contact Us</Link>
            </div>
            <div className='col-md-7'>
            <img src='assets/imgs/issuing-banner1.svg' alt='' className='banner2'/>
            </div>
          </Row>    
        </Container>
    </section>
    </>
  )
}
