import React from 'react'
import { FaqaccSec } from './faqaccSec'

export const FaqAccountCreation = () => {
  return (
    <>
    <FaqaccSec />
    </>
  )
}
