import React from 'react'
import { IntrigrationSec } from './intrigrationSec'

export const IntrigrationSubscriptionsDeletePlan = () => {
  return (
    <>
    <IntrigrationSec /> 
    </>
  )
}
