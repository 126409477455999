import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink, useParams  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import StickyBox from "react-sticky-box";
import config from '../Config';

import { FaqService } from '../services/FaqService';
import classNames from 'classnames';

interface IParams {
    seo_url: any;
    loading: boolean;
    faqList: any;
    errorMsg: string;
  }


export const FaqAboutSec = () => {

    const [state, setState] = useState<IParams>({
        seo_url: '' as any,
        loading: false,
        faqList: [] as any,
        errorMsg: "",
    });

    const {seo_url} = useParams<any>();

    const handleSearchDate = (dateStr:string) => {

        if(dateStr.trim()!=''){
            FaqService.getSearchFaqList(dateStr)
            .then((res) =>{
                    setState({
                        ...state,
                        loading: false,
                        faqList: res.data as any,
                    });             
                }
            )
            .catch((err) =>{
                    setState({
                        ...state,
                        loading: false,
                        errorMsg: err.message,
                    });
                }
            );
        } else {

            setState({ ...state, loading: true });
            FaqService.getFaqList(seo_url)
            .then((res) =>
            setState({
                ...state,
                loading: false,
                faqList: res.data as any,
            })
            )
            .catch((err) =>
            setState({
                ...state,
                loading: false,
                errorMsg: err.message,
            })
            );
        }
    }

    
    //network request
    useEffect(() => {
        setState({ ...state, loading: true });
        FaqService.getFaqList(seo_url)
        .then((res) =>
        setState({
            ...state,
            loading: false,
            faqList: res.data as any,
        })
        )
        .catch((err) =>
        setState({
            ...state,
            loading: false,
            errorMsg: err.message,
        })
        );
    //eslint-disable-next-line
    }, [seo_url]);
    const { loading, faqList, errorMsg } = state;
    const listData = faqList.response?.list;
    const listData_length = listData ? Object.keys(listData).length : 0;

  return (
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">


                <StickyBox offsetTop={50} offsetBottom={20}>
                    <section className='faq-leftsec'>
                        <div className='faq-leftsectitle mb-faq'>FAQ</div>
                        <div className="form-group has-search1">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input type="text" onChange={(e) => handleSearchDate(e.target.value)} className="form-control" placeholder="Search" />
                        </div>
                        <FaqLeftSec  />

                    </section> 
                </StickyBox>
                  
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">

                    { faqList.response?.category_id==6 &&
                        <>
                            <h2 className='faq-rgtsectitle'>TCB Pay Portal Wiki</h2>
                            <div className='faq-rgt mt-30 mb-40'>
                                <div className='row'>
                                <div className='col-md-7'>
                                    <div className='innerbx pb-30'>
                                    <h6>User guide</h6>
                                    <p className="mt-20 mb-20">Explore the <b>TCB Pay portal Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform.</p>
                                    <Link className='wiki-btn mb-0' to="/wiki-portal">Go to TCB Pay Portal Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </div>
                                </div>
                                <div className='col-md-5'><img src={`${config.siteBaseUrl}/assets/imgs/faq-pic1.webp`} alt="" className='faqissuingpic' /></div> 
                                </div>
                            </div>
                        </>
                    }

                    {faqList.response?.category_id==8 &&
                        <>
                            <h2 className='faq-rgtsectitle'>TCB Pay Issuing Wiki</h2>
                            <div className='faq-rgt mt-30 mb-40'>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='innerbx pb-30'>
                                        <h6>User guide</h6>
                                        <p className="mt-20 mb-20">Explore the <b>TCB Pay Issuing Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform. </p>
                                        <Link className='wiki-btn mb-0' to="/wiki-issuing">Go to TCB Pay Issuing Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                                        </div>
                                    </div>
                                    <div className='col-md-5'><img src={`${config.siteBaseUrl}/assets/imgs/faq-pic.webp`} alt="" className='faqissuingpic' /></div> 
                                </div>
                            </div>
                        </>
                    }


                      <h2 className='faq-rgtsectitle'>{faqList.response?.category_name}</h2>

                    {listData_length > 0 
                        ? 
                        Object.entries(listData)?.map(([faqKey,faqVal]: [string, any]) => (
                            <>
                                <div className='faq-qusbx'>
                                    <div className='qusheading'>{faqVal.title} </div>
                                    <div className='qusans' dangerouslySetInnerHTML={{__html: faqVal.content}}>
                                        
                                    </div>
                                </div>
                            </>
                        ))
                        :
                        <>
                            <div className='faq-qusbx'>
                                {/* <div className='qusheading'>Empty</div> */}
                                <div className='qusans'>
                                    No result found.
                                </div>
                            </div>
                        </>
                    }

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
   </section>
  )
}
