import React from 'react'
import { Ourteam } from './ourteam'

export const Teamall = () => {
  return (
    <>
    <Ourteam />
    </>
  )
}
