import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';

export const LegalACHTermsAndConditionsSec = () => {

    const {seo_url} = useParams<any>();
        
    return (
        <>
        <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-start">











<div className="accordion mblegal-mnu" id="faqaccordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <NavLink to='' className="sidebar-linkmb" >
            <span className="align-middle">ACH Terms and Conditions</span>
        </NavLink>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
        <ul className="sidebar-nav groupaside">
            <li className="sidebar-item">
                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
            </li>
        </ul>
      
      </div>
    </div>
  </div>

</div>















                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                            </div>
                            <div className="tab-content">

                                <div className="tab-pane fade show active">
                                    <div className="terms_and_conditions-sec">
                                        <div className="container">
                                            <div className="title-sec">
                                                <h2><span> ACH Terms and Conditions </span></h2>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">

                                                    <div className="text2">

                                                        <p>These terms and conditions apply to ACH services provided by Processing
                                                            Partners LLC, DBA TCB Pay (&#34;TCB&#34;) to the Merchant. </p>
                                                        <p>
                                                            TCB provides electronic transaction processing services to organizations
                                                            that receive payments from customers by paper checks or electronic
                                                            automated clearing house (&#34;ACH&#34;) transactions. With respect to
                                                            ACH
                                                            transactions, debit and credit transactions are submitted to the ACH
                                                            Network in conjunction with ACH check processing origination and
                                                            settlement services. Merchant represents that it is lawfully engaged in
                                                            the business of selling goods and/or services to third parties from whom
                                                            it will receive payment by ACH transactions and with whom it will
                                                            initiate and process ACH transactions in the U.S. Federal Reserve Bank
                                                            System, using TCB&#39;s services.
                                                        </p>

                                                        <div className="pp-sub-head">
                                                            <h5>The Merchant and TCB agree as follows:</h5>
                                                        </div>


                                                        <ul>
                                                            <li style={{listStyle: 'auto'}}> TCB agrees to provide to the Merchant the
                                                                Services hereinafter defined.</li>

                                                            <li style={{listStyle: 'auto'}}>
                                                                Merchant agrees to pay to TCB all fees for such Services set forth
                                                                in the application that Merchant signed and submitted to TCB.
                                                            </li>

                                                            <li style={{listStyle: 'auto'}}>
                                                                Merchant agrees to use the Services in accordance with this
                                                                Agreement.
                                                            </li>
                                                            <li style={{listStyle: 'auto'}}>
                                                                This Agreement will become effective on the date TCB activates the
                                                                account of the Merchant (the &#34;Effective Date&#34;).
                                                            </li>
                                                        </ul>


                                                    </div>

                                                    <div className="pp-sub-head">
                                                        <h5>Definitions</h5>
                                                    </div>

                                                    <div className="text2">
                                                        <p>The following terms used in this Agreement shall have the meaning
                                                            specified below: </p>



                                                        <p> <b> ACH Transaction: </b>
                                                            An electronic payment transaction originated by Merchant and processed
                                                            through the ACH Network in the Federal
                                                            Reserve System. </p>


                                                        <p> <b> Administrator:</b> The Merchant&#39;s employee who has been designated
                                                            as the
                                                            Merchant&#39;s primary contact with TCB for the Services and has
                                                            been appointed by the Merchant to manage the administration of Services
                                                            access, including passwords, and communicate authorizations to
                                                            TCB. </p>

                                                        <p> <b> Agreement: </b>These terms and conditions and the merchant
                                                            application signed
                                                            and submitted by the Merchant to TCB, including any
                                                            documents referenced therein and amendments to the foregoing. </p>

                                                        <p> <b> Confidential Information: </b>Certain confidential and proprietary
                                                            information
                                                            of TCB and its providers, including without limitation
                                                            information concerning the Services, Software programs and documentation
                                                            used by TCB and its providers to provide the Services to
                                                            Merchant, the know-how, technology, techniques, trade secrets, or
                                                            business
                                                            or marketing plans related thereto and passwords and other
                                                            information or materials of a confidential and proprietary nature that
                                                            TCB
                                                            and its providers provide under this Agreement. </p>


                                                        <p> <b> Customer: </b> Merchant&#39;s customer who submits a payment to Merchant
                                                            through a
                                                            check or ACH transfer. </p>


                                                        <p> <b> Entry: </b> A transaction submitted by Merchant to TCB for processing by the
                                                            Services and further defined in the NACHA Rules. NACHA Rules: The
                                                            current
                                                            rules, regulations and procedural guidelines published by the National
                                                            Automated Clearing House Association
                                                            (&#34;NACH&#34;) and/or all regional payment alliances associated with NACHA.
                                                        </p>


                                                        <p> <b> Originating Depository Financial Institution: </b> In an ACH
                                                            Transaction, the
                                                            financial institution which receives the Entry and transmits the
                                                            Entry to its ACH operator for transmittal to a Receiving Depository
                                                            Financial Institution for debit or credit to the Customer&#39;s account,
                                                            as these terms are further defined in the NACHA Rules. </p>


                                                        <p> <b> Services: </b> The ACH processing services, and the related goods
                                                            and services
                                                            provided to Merchant under this Agreement. </p>

                                                        <p> <b> Settlement Account: </b> A commercial demand deposit bank account
                                                            which the
                                                            Merchant has established for TCB and its providers for access
                                                            and use to settle financial payment transactions processed under this
                                                            Agreement. </p>

                                                        <p> <b> Software: </b> Software programs and associated documentation and
                                                            materials that
                                                            TCB and its providers provide to Merchant for use with the
                                                            Services. </p>

                                                    </div>

                                                    <div className="pp-sub-head">
                                                        <h5>Set-Up And Delivery Of Services</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            In conjunction with this Agreement, the Merchant has completed and
                                                            submitted to TCB an initial written application and supporting
                                                            documentation about the Merchant&#39;s business and financial status, for
                                                            TCB&#39;s consideration in the account set-up and provision of the
                                                            Services to the Merchant. Merchant acknowledges that TCB shall be
                                                            entitled to rely upon the validity, accuracy and completeness of the
                                                            information provided by Merchant in its application and supporting
                                                            documentation to TCB, for TCB&#39;s use in performing its due diligence
                                                            review of Merchant&#39;s status and financial standing and for determining:

                                                        </p>

                                                        <ul className="pt-0">
                                                            <li style={{listStyle: 'lower-alpha'}}> if TCB approves Merchant&#39;s application
                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                if TCB will provide all or any of the Services to Merchant under
                                                                this Agreement; and,
                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                the credit and account processing standards and limits that TCB will
                                                                apply to the processing of Merchant&#39;s Entries.

                                                            </li>

                                                        </ul>


                                                        <p> After the initial acceptance and set-up of the Merchant for TCB&#39;s
                                                            provision
                                                            of the Services, TCB may request that the Merchant provide
                                                            updated information and supporting documentation to TCB to confirm
                                                            Merchant&#39;s current business and credit status, which Merchant shall
                                                            apply best efforts to deliver to TCB within three (3) business days
                                                            following receipt of TCB&#39;s written request. Any failure by the Merchant
                                                            to
                                                            provide the information and supporting documentation requested by TCB
                                                            via
                                                            the application or by other means within a timely manner
                                                            shall be deemed to be a material breach of this Agreement by the
                                                            Merchant.
                                                        </p>


                                                        <p> When the Merchant&#39;s application has been approved by TCB and its account
                                                            with TCB has been activated, TCB shall provide the Services
                                                            selected by the Merchant in its application. Merchant shall utilize and
                                                            access the Services in accordance with the terms of this Agreement
                                                            and the practices and procedures established by TCB and its providers
                                                            for
                                                            the Services which have been communicated in writing to
                                                            Merchant. As part of the Merchant set-up and boarding process, TCB will
                                                            provide Merchant with an administrative and gateway username
                                                            and password (&#34;Password&#34;) to access the Services which are hosted on
                                                            TCB&#39;s
                                                            processing system. Merchant will designate an Administrator
                                                            who shall be the individual vested with the authority to determine who
                                                            will
                                                            be authorized to use the Services; establish separate passwords
                                                            for each user; and establish limits on each user's authority to access
                                                            information and conduct transactions. The merchant is responsible for
                                                            the actions of its Administrator, the authority the Administrator gives
                                                            others to act on the Merchant&#39;s behalf and the actions of the persons
                                                            designated by the Administrator to use the Services. Merchant shall take
                                                            reasonable security procedures and practices to safeguard the
                                                            confidentiality of the passwords; limit access to its passwords solely
                                                            to
                                                            persons who have a need to know such information; closely and
                                                            regularly monitor the activities of employees who access the Services;
                                                            and
                                                            prohibit its employees and agents from initiating entries in the
                                                            Services without proper authorization and supervision and adequate
                                                            security
                                                            controls.
                                                        </p>


                                                        <p> TCB will provide Merchant Services for the Entry types indicated in the
                                                            Merchant set-up and boarding documentation which the Merchant
                                                            wishes to utilize. TCB reserves the right to withdraw the Services
                                                            provided
                                                            generally to its customers including Merchant for individual Entry
                                                            types from general market availability and coverage under this Agreement
                                                            upon ninety (90) days prior written notice to Merchant. To the
                                                            extent reasonably possible, TCB will first attempt to process an Entry
                                                            in
                                                            the Services as an ACH Transaction provided that the Entry fully
                                                            qualifies to be processed as an ACH Transaction according to applicable
                                                            NACHA Rules and TCB has received all the required information
                                                            from the Image or paper check necessary to process the transaction as an
                                                            ACH
                                                            Transaction.
                                                        </p>


                                                        <p> Prior to submitting an Entry to TCB for processing, the Merchant shall
                                                            secure all authorizations and approvals from its Customer and deliver
                                                            any notifications pertaining to that Entry which are required by the
                                                            NACHA
                                                            Rules and/or applicable laws and regulations. Merchant shall be
                                                            responsible for the accuracy and propriety of all Entries submitted to
                                                            TCB
                                                            for processing. If the Merchant utilizes a scanner to create Images
                                                            which are delivered to TCB for processing, the Merchant shall be solely
                                                            responsible for ensuring the accuracy and completeness of the
                                                            Image transmitted to TCB for processing.
                                                        </p>


                                                        <p> Merchant acknowledges that TCB has specific processing deadlines imposed
                                                            by
                                                            its ODFI and the ACH operator for ACH Transactions. Files
                                                            received by the deadline will be transmitted that day to the Federal
                                                            Reserve
                                                            Bank for settlement on the effective entry day. Files received
                                                            after the deadline will be processed the next Banking Day as defined in
                                                            the
                                                            NACHA Rules.
                                                        </p>


                                                        <p> In the event of any conflicts in the instructions received by TCB
                                                            regarding
                                                            the Merchant or any Entries relating to them, TCB may at its option
                                                            and with or without notice, hold or interplead, comply with the legal
                                                            process or other order, or otherwise limit access by Merchant or by TCB
                                                            to the funds, Entries or proceeds thereof.
                                                        </p>


                                                        <p> TCB may make available to Merchant end-user training to assist Merchant
                                                            in
                                                            understanding and using the Services and the TCB processing
                                                            system. The training product(s) are provided to the Merchant for its
                                                            sole
                                                            internal use. In the case of proprietary courseware, TCB and its
                                                            licensors reserve all ownership rights in and to the courseware
                                                            materials.
                                                            If any training classes are performed by TCB at the Merchant&#39;s
                                                            site, the Merchant shall pay the applicable onsite training class fee
                                                            quoted
                                                            by TCB and the reimbursable out-of-pocket travel expenses of
                                                            the TCB trainer who travels to and from the Merchant&#39;s location to
                                                            deliver
                                                            the training session. For online classes and learning modules
                                                            provided by TCB, the Merchant shall pay the training class fee specified
                                                            at
                                                            the time of online class registration. If TCB offers a license which
                                                            permits the Merchant to perform its own internal training classes for
                                                            the
                                                            personnel of the Merchant and its Customers, the Merchant shall
                                                            pay the annual license fee quoted by TCB for the use of the modules
                                                            licensed
                                                            by the Merchant for this purpose.
                                                        </p>



                                                        <p> Merchants may be required to maintain a reserve (&#34;Settlement Reserve&#34;)
                                                            of an
                                                            amount to be determined by TCB. Merchant hereby
                                                            acknowledges and agrees that any Settlement Reserve will be deposited in
                                                            a
                                                            TCB account for exclusive use by TCB for purposes of offsetting
                                                            any Merchant obligations under this Agreement. If the Merchant&#39;s
                                                            Settlement
                                                            Reserve falls below the required amount, the Merchant
                                                            authorizes TCB to immediately replenish the Settlement Reserve to an
                                                            amount
                                                            to be determined by TCB via an ACH debit to the Settlement
                                                            Account or by a direct deposit to the Settlement Reserve. No interest
                                                            will
                                                            be paid on the Settlement Reserve. Merchant grants TCB a security
                                                            interest in any Settlement Reserve so that TCB may enforce any
                                                            obligation
                                                            owed by Merchant under this Agreement without notice or
                                                            demand to Merchant. Merchant&#39;s obligation to maintain a Settlement
                                                            Reserve
                                                            shall survive the termination of this Agreement.
                                                        </p>



                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Recoupment And Set-Off</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            Merchant shall immediately reimburse TCB for any returns or shortfalls
                                                            that occur in Merchant&#39;s Settlement Account. TCB reserves the right in
                                                            its sole discretion to debit the Settlement Account for any amounts due
                                                            under this Agreement or delay the availability of funds for deposit,
                                                            without prior written notice to Merchant, if TCB deems itself at
                                                            financial or relative risk for all Services performed under this
                                                            Agreement. Merchant hereby acknowledges and agrees that TCB shall have a
                                                            right of setoff against:
                                                        </p>

                                                        <ul className="pt-0">
                                                            <li style={{listStyle: 'lower-alpha'}}> any amounts TCB is obligated to deposit
                                                                into the Merchant&#39;s account, and
                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                any other amounts TCB may owe Merchant under this Agreement.
                                                            </li>

                                                        </ul>


                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Pricing And Payment</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Merchant shall pay TCB the fees, penalties, and charges for the Products
                                                            and/or Services as set forth herein, shown in the current standard
                                                            published fee schedule for merchants located in the United States which
                                                            has been provided to Merchant in the application for the Services,
                                                            as set forth on TCB&#39;s website, or as disclosed to Merchant in any other
                                                            documents or materials by TCB. All amounts are stated and due and
                                                            payable in U.S. dollars and are exclusive of any taxes or other charges
                                                            which may be imposed by a government entity, except for taxes due
                                                            on TCB&#39;s income. For Product and Services fees invoiced by TCB directly
                                                            to the Merchant, TCB reserves the right to require the Merchant&#39;s
                                                            payment of these fees due under this Agreement via an ACH debit made by
                                                            TCB against the Merchant&#39;s Settlement Account or other
                                                            banking account. Merchant shall provide TCB with all necessary bank
                                                            account, routing, and related information. TCB will deduct
                                                            automatically all such fees on or about the first business day of each
                                                            month for the preceding month&#39;s services. Interest shall accrue at the
                                                            lesser of three (3) percent per month or the maximum amount permitted by
                                                            applicable law (&#34;Late Fee&#34;) for any fees that remain unpaid
                                                            beyond any due dates. In the event of a dispute made in good faith as to
                                                            the amount of fees, the Merchant agrees to remit payment on any
                                                            undisputed amount(s); and the Late Fee shall not accrue as to any
                                                            disputed amounts unless not paid within thirty (30) calendar days after
                                                            said dispute has been resolved by both parties. Merchant shall have
                                                            sixty (60) days from the payment by Merchant of any fees due under
                                                            this Agreement to notify TCB of any errors in payment of fee by
                                                            Merchant. If the Merchant does not notify TCB within the sixty (60) day
                                                            period, the Merchant shall be deemed to have accepted without question
                                                            such fee payment and may not in the future contest the amount
                                                            the Merchant paid or seek reimbursement for any discrepancies. Upon
                                                            receipt of notice, TCB shall have thirty (30) days to correct any
                                                            errors.
                                                        </p>


                                                        <p> TCB may increase fees payable by Merchant under this Agreement by giving
                                                            Merchant a thirty (30) day advance notice. </p>



                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Nacha Rules And Tcb Guidelines </h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            Each party shall comply with the current NACHA Rules which apply to ACH
                                                            Transactions processed under this Agreement. In addition, TCB
                                                            may publish to Merchant and other merchants its own standard operating
                                                            and implementation guidelines for the Services with respect to
                                                            specific NACHA Rules which will govern and apply to this Agreement as if
                                                            set forth herein.

                                                        </p>

                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5>Confidential Information And Security Procedures:
                                                        </h5>
                                                    </div>


                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            As a condition to the receipt of the Confidential Information from TCB,
                                                            the Merchant shall:
                                                        </p>

                                                        <ul className="pt-0">
                                                            <li style={{listStyle: 'lower-alpha'}}> not disclose in any manner, directly or
                                                                indirectly, to any third party any portion of Confidential
                                                                Information

                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                not use the Confidential Information in any fashion except to
                                                                perform its obligations hereunder or with the TCB&#39;s express prior
                                                                written
                                                                consent

                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                disclose Confidential Information, in whole or in part, only to its
                                                                employees and agents who need to have access thereto for the
                                                                Merchant&#39;s internal business purposes


                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>

                                                                take all necessary steps to ensure that its employees and agents are
                                                                informed of and comply with the confidentiality restrictions
                                                                contained in this Agreement; and,


                                                            </li>

                                                            <li style={{listStyle: 'lower-alpha'}}>
                                                                take all necessary precautions to protect the confidentiality of the
                                                                Confidential Information received hereunder and exercise at least
                                                                the same degree of care in safeguarding the Confidential Information
                                                                as it would with its own confidential information, and in no event
                                                                apply less than a reasonable standard of care to prevent disclosure.

                                                            </li>

                                                        </ul>



                                                        <p>
                                                            Merchant shall comply with the security procedures described in this
                                                            Agreement and in any TCB policies and procedures communicated in
                                                            writing to a Merchant. Merchant acknowledges that the purpose of these
                                                            security procedures is for verification of the authenticity of an Entry
                                                            and not to detect an error in the transmission or content of an Entry.
                                                            No security procedure for the detection of any such error has been
                                                            agreed upon between TCB and Merchant. Merchant is strictly responsible
                                                            to establish and maintain the procedures to safeguard against
                                                            unauthorized transmissions.
                                                        </p>


                                                        <p> Merchant understands that the password and Services access and use
                                                            instructions provided hereunder are confidential and agrees to
                                                            assume all risks of accidental disclosure or inadvertent or wrongful use
                                                            by any third party whatsoever, whether such disclosure of use are
                                                            on account of Merchant&#39;s negligence or deliberate acts. Merchant
                                                            acknowledges that no person from TCB will ever ask for any password
                                                            and that TCB employees do not need and should not ask for Merchant&#39;s
                                                            password.
                                                        </p>


                                                        <p> Merchant shall change its password periodically and whenever anyone who
                                                            has had access to a password is no longer employed or
                                                            authorized by Merchant to use the Services. TCB may require the Merchant
                                                            to change its password at any time. TCB may deny access to the
                                                            Services without prior notice if it is unable to confirm to its
                                                            satisfaction any person&#39;s authority to access the Services or if TCB
                                                            believes
                                                            such action is necessary for security reasons.
                                                        </p>


                                                        <p> Merchant acknowledges that the Services and Software provided by TCB
                                                            under this Agreement incorporate trade secrets of TCB and its
                                                            providers and licensors, and as such is protected by civil and criminal
                                                            law. Merchant shall notify TCB immediately of the unauthorized
                                                            possession, use or knowledge of any item supplied under this Agreement
                                                            by any person or organization not authorized by this Agreement
                                                            to have such possession, use or knowledge. TCB shall have, in addition
                                                            to any other remedies available to it at law or equity, the right to
                                                            seek injunctive relief enjoining any wrongful use or possession of the
                                                            trade secrets contained in the Services or Software by any party.
                                                            Merchant acknowledges that irreparable harm will occur to TCB in the
                                                            event of such wrongful use or possession of the trade secrets
                                                            contained in the Services or Software and that other remedies are
                                                            inadequate.

                                                        </p>
                                                    </div>


                                                    <div className="pp-sub-head">
                                                        <h5>Data Privacy And Security By Provider</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            In accordance with data privacy laws and regulations applicable to this
                                                            Agreement, which may include but not be limited to the Gramm-
                                                            Leach-Bliley Act (&#34;GLBA&#34;) and the Health Insurance Portability and
                                                            Accountability Act (&#34;HIPAA&#34;), TCB shall not disclose or permit access to
                                                            or
                                                            use of the non-public personal information of Merchant or its Customers
                                                            made available by Merchant to TCB for any purposes other than
                                                            those specifically required to fulfill TCB&#39;s contractual obligations
                                                            with Merchant hereunder. In connection with providing services to the
                                                            Merchant, TCB shall take all commercially reasonable steps to ensure the
                                                            privacy and security of the information of the Merchant and its
                                                            Customers in TCB&#39;s possession and protect against anticipated threats
                                                            and hazards to the security of such information.

                                                        </p>
                                                    </div>

                                                    <div className="pp-sub-head">
                                                        <h5>Warranties</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            TCB warrants to Merchant that its Services will be performed in a
                                                            professional and timely manner consistent with ACH transaction
                                                            processing industry standards and per the NACHA Rules and applicable
                                                            laws and regulations and any written TCB policies and procedures
                                                            communicated by TCB to Merchant. If the Merchant discovers an error in
                                                            the Services or Software which has been caused by TCB, the
                                                            Merchant shall immediately notify TCB of the existence and details of
                                                            the error. TCB shall apply commercially reasonable efforts to correct
                                                            the error within a reasonable time after TCB&#39;s receipt of notification
                                                            of the error.

                                                        </p>


                                                        <p> EXCEPT FOR THE FOREGOING WARRANTY, THE SERVICES ARE PROVIDED &#34;AS IS&#34;
                                                            WITHOUT
                                                            ANY WARRANTY WHATSOEVER. TCB DISCLAIMS
                                                            ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, TO THE MERCHANT AS TO
                                                            ANY
                                                            MATTER WHATSOEVER, INCLUDING ALL IMPLIED
                                                            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                                                            NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL
                                                            OR WRITTEN INFORMATION OR ADVICE GIVEN BY TCB OR ITS EMPLOYEES OR
                                                            REPRESENTATIVES SHALL CREATE A WARRANTY OR IN ANY
                                                            WAY INCREASE THE SCOPE OF TCB&#39;S OBLIGATIONS. </p>


                                                        <p> TCB does not guarantee the completeness or accuracy of the information
                                                            provided from a third-party database. TCB shall have no liability to
                                                            Merchant for any invalid Customer information provided by Merchant or
                                                            Entries returned unpaid to Merchant. Merchant understands that
                                                            certain Services are not provided by TCB, but by TCB&#39;s vendors, and that
                                                            TCB
                                                            shall have no liability to Merchant for any Service provided by
                                                            any of its vendors or third party. </p>


                                                        <p className="mb-0"> Merchant warrants that: </p>

                                                        <ul className="pt-0">
                                                            <li style={{listStyle: 'auto'}}> All Entries submitted to TCB for
                                                                processing will comply with applicable laws and regulations and the
                                                                NACHA Rules pertaining to the
                                                                Entries


                                                            </li>
                                                            <li style={{listStyle: 'auto'}}> Merchant will comply with all laws,
                                                                regulations, and the NACHA Rules applicable to Merchant&#39;s activities
                                                                covered by this Agreement

                                                            </li>
                                                            <li style={{listStyle: 'auto'}}> All information provided by Merchant
                                                                initially in its application and supporting documentation and
                                                                subsequently in all updates to its
                                                                application and supporting documentation provided to TCB is valid,
                                                                complete, accurate and up to date


                                                            </li>
                                                            <li style={{listStyle: 'auto'}}> The individual signing and submitting
                                                                this Agreement, the application for purchase of the Services from
                                                                TCB, and all future updates to
                                                                the application and supporting documentation has the legal authority
                                                                to make and bind Merchant to the agreements, warranties and
                                                                commitments stated in this Agreement and the submitted application
                                                                on Merchant&#39;s behalf.


                                                            </li>
                                                            <li style={{listStyle: 'auto'}}> It is TCB&#39;s corporate policy not to
                                                                knowingly provide Services for any person or organization whose use
                                                                of the Services involves or
                                                                pertains to any activity which is illegal under U.S. law or involves
                                                                an activity or business with which TCB declines to accept and
                                                                conduct
                                                                business generally (&#34;Excluded Activity or Activities&#34;). Merchant
                                                                warrants that it will not conduct any Excluded Activities, which
                                                                include but are
                                                                not limited to the
                                                                following:


                                                            </li>
                                                        </ul>




                                                    </div>
                                                    <div className="pp-sub-head">
                                                        <h5 style={{ color: '#1E609D' }}>List of primary PTOB products/services</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                            TCB may publish to its customers from time to time a more detailed and
                                                            comprehensive list of businesses and activities which TCB includes
                                                            within the scope of Excluded Activities. TCB reserves the right to
                                                            reject any proposed Customer account or to refuse to process a
                                                            transaction
                                                            for a Customer that TCB deems, in its sole discretion, may use the
                                                            Processing Services in conjunction with any Excluded Activity. If TCB
                                                            discovers after accepting a Customer account that the Customer is
                                                            utilizing the Processing Services in conjunction with an Excluded
                                                            Activity,
                                                            TCB may immediately terminate its provision of Processing Services to
                                                            that Customer upon written notice to the Customer.

                                                        </p>

                                                        <div className="pp-sub-head">
                                                            <h5>Indemnification</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p className="mb-0">
                                                                TCB shall indemnify, defend, and hold Merchant harmless from and
                                                                against all claims, actions, losses and expenses, including
                                                                reasonable
                                                                attorney&#39;s fees and legal costs, incurred by Merchant arising out of
                                                                a third-party claim that the Services or Software owned and provided
                                                                by
                                                                TCB under this Agreement infringe the valid intellectual property
                                                                rights of the third party, provided that TCB is promptly notified by
                                                                Merchant of its receipt of notice of such claim, is given control of
                                                                the defence or settlement of such claim, and is given reasonable
                                                                assistance requested by TCB concerning such claim. TCB shall not,
                                                                however, indemnify, defend, and hold harmless or otherwise be liable
                                                                if
                                                                the claim, action, or lawsuit is:

                                                            </p>

                                                            <ul className="pt-0">
                                                                <li style={{listStyle: 'lower-alpha'}}> asserted by an affiliated company of
                                                                    the Merchant.


                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> results from the use of Services in
                                                                    breach of this Agreement or modification of the Services by
                                                                    Merchant or its agents; or,



                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> is a claim, action, or lawsuit by an
                                                                    independent third party caused by a product for which TCB is not
                                                                    responsible.


                                                                </li>

                                                            </ul>

                                                            <p className="mb-0"> Merchant shall indemnify, defend, and hold TCB, and the ODFI
                                                                harmless
                                                                from and against all claims, actions, losses, and expenses,
                                                                including
                                                                reasonable attorney&#39;s fees and legal costs, incurred by TCB, and/or
                                                                the ODFI arising out of:
                                                            </p>

                                                            <ul className="pt-0">
                                                                <li style={{listStyle: 'lower-alpha'}}> Merchant&#39;s and its subcontractors&#39;
                                                                    and agents&#39; breach of this Agreement, the NACHA Rules or
                                                                    applicable laws and regulations


                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> the return of an Entry due to
                                                                    incorrect or incomplete data or information provided by the
                                                                    Merchant in the submission of the Entry to
                                                                    TCB, a closed Customer account, or insufficient funds in the
                                                                    Customer account.




                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> fraudulent activity, wrongful or
                                                                    unauthorized use of the Services, or submission of fraudulent or
                                                                    illegal Entries by Merchant, its
                                                                    subcontractors, employees, or agents or a third party who has
                                                                    gained access to the Services using Merchant&#39;s password



                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> the negligence of Merchant and that
                                                                    of its subcontractors, agents, and employees; and,



                                                                </li>

                                                                <li style={{listStyle: 'lower-alpha'}}> any representation or warranty made
                                                                    by Merchant or any subcontractor, employee, or agent of Merchant
                                                                    to any third person other
                                                                    than as specifically authorized by this Agreement.




                                                                </li>



                                                            </ul>



                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Limitations Of Liability</h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                In no event shall tcb, or the odfi be liable to the merchant or any
                                                                other party for any consequential, indirect, special, incidental,
                                                                reliance, or
                                                                exemplary damages arising out of or relating to this agreement or
                                                                the services, whether foreseeable or unforeseeable, and whether
                                                                based
                                                                on breach of any express or implied warranty, breach of contract,
                                                                misrepresentation, negligence, strict liability in tort, or other
                                                                cause of
                                                                action (including, but not limited to, damages for loss of data,
                                                                goodwill, profits, investments, use of money, or use of facilities;
                                                                interruption
                                                                in use or availability of data; stoppage of other work or impairment
                                                                of other assets; or labor claims), even if such party has been
                                                                advised of
                                                                the possibility of such damages. tcb&#39;s aggregate, cumulative
                                                                liability to the merchant for all claims of actual direct damages
                                                                relating to the
                                                                services, this agreement, or the relationship between tcb and
                                                                merchant, including any cause of action in contract, negligence,
                                                                tort, strict
                                                                liability or otherwise, shall not exceed an amount equal to the
                                                                total amount of all fees paid by the merchant to tcb for services
                                                                delivered
                                                                under this agreement during the three (3) month period preceding the
                                                                origination of the claim giving rise to liability.

                                                            </p>
                                                        </div>

                                                        <div className="pp-sub-head">
                                                            <h5>Term And Termination </h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                Term: The initial term of this Agreement shall be set forth as month
                                                                to month; upon expiration of the initial term, this Agreement shall
                                                                automatically renew for an additional thirty (30) days, unless
                                                                either party gives written notice of its election not to renew this
                                                                Agreement to
                                                                the other party no later than sixty (60) calendar days prior to the
                                                                end of the current term. Notwithstanding any other provisions in
                                                                this
                                                                Agreement, TCB may cease providing the Services without notice in
                                                                the event

                                                            </p>

                                                            <ul>
                                                                <li style={{listStyle: 'auto'}}> There is a data security breach or
                                                                    other event that involves a high level of potential exposure to
                                                                    damages for TCB due to breach of
                                                                    this Agreement by Merchant that TCB believes in its reasonable
                                                                    judgment justifies this Agreement to be terminated immediately;
                                                                    or,



                                                                </li>
                                                                <li style={{listStyle: 'auto'}}> NACHA, a bank or a third party with
                                                                    control over TCB orders TCB to cease providing the Services, or
                                                                    its providers cease providing
                                                                    services in support of the Services.




                                                                </li>
                                                            </ul>





                                                        </div>

                                                        <div className="pp-sub-head">
                                                            <h5>Termination For Cause</h5>
                                                        </div>
                                                        <div className="text2">

                                                            <div className="pp-sub-head">
                                                                <h5 style={{border:0}}>Termination Due to Material Breach</h5>
                                                            </div>




                                                            <p>
                                                                This Agreement may be terminated by either party for cause upon
                                                                giving the other party written notice of the breach of this
                                                                Agreement
                                                                committed by the other party and giving the other party a reasonable
                                                                time to cure the breach. For this Agreement, a reasonable time to
                                                                cure the breach specified in the written notice shall be deemed to
                                                                be five (5) business days from receipt of the written notice.

                                                            </p>
                                                            <p>
                                                                Notwithstanding the foregoing, in the event either party materially
                                                                breaches any provision of this Agreement by fraud, act of intent to
                                                                defraud, failure to notify the other party of a material change in
                                                                the party&#39;s financial structure or a material change in the
                                                                information
                                                                originally provided to induce the other party to enter into the
                                                                Agreement, the non-breaching party may immediately terminate this
                                                                Agreement in writing without giving the breaching party an
                                                                opportunity to cure the breach with prior written notice.

                                                            </p>

                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>Termination Due To Changed Circumstances
                                                            </h5>
                                                        </div>
                                                        <div className="text2">
                                                            <p>
                                                                TCB reserves the right to terminate this Agreement in whole or in
                                                                part at any time due to the occurrence of any of the following
                                                                changed
                                                                circumstances:

                                                            </p>

                                                            <ul>
                                                                <li style={{listStyle: 'auto'}}> Merchant ceases the operation of a
                                                                    substantial part of its business, becomes insolvent, enters into
                                                                    suspension of payments,
                                                                    moratorium, reorganization or bankruptcy, makes a general
                                                                    assignment for the benefit of creditors, admits its inability to
                                                                    pay debts as they
                                                                    mature, suffers or permits the appointment of a receiver for its
                                                                    business or assets, or avails itself of or becomes subject to
                                                                    any other judicial
                                                                    or administrative proceeding that relates to insolvency or
                                                                    protection of creditors&#39; rights, any of which in TCB&#39;s
                                                                    reasonable judgment impairs
                                                                    the ability of Merchant to perform its responsibilities under
                                                                    this Agreement




                                                                </li>
                                                                <li style={{listStyle: 'auto'}}> In TCB&#39;s sole judgment, there is a
                                                                    deterioration or other materially negative change in the
                                                                    Merchant&#39;s financial status or structure
                                                                    which increases the financial risk being assumed by TCB in
                                                                    processing the Merchant&#39;s Entries





                                                                </li>
                                                                <li style={{listStyle: 'auto'}}> There is a change in the laws,
                                                                    regulations or NACHA Rules that are applicable to this Agreement
                                                                    and TCB&#39;s provision of any of the
                                                                    Services which restricts or prohibits TCB from providing the
                                                                    affected Services to Merchant or significantly increases TCB&#39;s
                                                                    costs in providing
                                                                    the affected Services to its customers generally; or,




                                                                </li>
                                                                <li style={{listStyle: 'auto'}}> Merchant's business or all or a
                                                                    substantial part of its assets is acquired by a competitor of
                                                                    TCB or its providers,





                                                                </li>
                                                            </ul>


                                                            <p> In these instances, TCB will provide written notice of the
                                                                termination
                                                                on this basis to the Merchant, which shall become effective upon
                                                                receipt by the Merchant. </p>

                                                            <p> The provisions of this Agreement and any other provisions of the
                                                                other
                                                                Sections of this Agreement which by their nature should reasonably
                                                                be expected to continue to apply after termination of this Agreement
                                                                shall survive the termination of this Agreement. </p>





                                                        </div>
                                                        <div className="pp-sub-head">
                                                            <h5>General Provisions</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p>


                                                                Governing Law; Waiver of Jury Trial; Arbitration.
                                                                This Agreement will be governed by and construed in accordance with
                                                                the laws of the State of Wyoming without reference to conflict of
                                                                law
                                                                provisions. Any action, proceeding, arbitration or mediation
                                                                relating to or arising from this Agreement must be brought, held, or
                                                                otherwise
                                                                occur in the federal judicial district that includes Wyoming. PLEASE
                                                                READ THIS PROVISION CAREFULLY. IT PROVIDES THAT ALL DISPUTES
                                                                MUST BE RESOLVED BY BINDING ARBITRATION. ARBITRATION REPLACES THE
                                                                RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO A TRIAL
                                                                BY JURY AND THE RIGHT TO PARTICIPATE IN A class ACTION OR SIMILAR
                                                                PROCEEDING AGAINST TCB. Any claim, dispute, or controversy
                                                                (&#34;Claim&#34;) by either Merchant or TCB against the other, or against
                                                                the employees, agents, parents, subsidiaries, affiliates,
                                                                beneficiaries, agents
                                                                or assigns of the other, arising from or relating in any way to this
                                                                Agreement or to our relationship, including Claims regarding the
                                                                applicability of this arbitration clause or the validity of the
                                                                entire Agreement, shall be resolved exclusively and finally by
                                                                binding arbitration administered by the American Arbitration
                                                                Association, under its Commercial Arbitration Rules in effect at the
                                                                time the Claim is filed, except
                                                                as otherwise provided below. All Claims are subject to arbitration,
                                                                no matter what theory they are based on or what remedy they seek.
                                                                This
                                                                includes Claims based on contract, tort (including intentional
                                                                tort), fraud, agency, your or our negligence, statutory or
                                                                regulatory provisions,
                                                                or any other sources of law. Claims and remedies sought as part of a
                                                                class action, private attorney general or other representative
                                                                action
                                                                are subject to arbitration on an individual (non-class,
                                                                non-representative) basis, and the arbitrator may award relief only
                                                                on an individual
                                                                (non-class, non-representative) basis. The arbitration will be
                                                                conducted before a single arbitrator and will be limited solely to
                                                                the Claim
                                                                between Merchant and TCB. The arbitration, or any portion of it,
                                                                will not be consolidated with any other arbitration and will not be
                                                                conducted on a class-wide or class-action basis. If either party
                                                                prevails in the arbitration of any Claim against the other, the
                                                                non-prevailing
                                                                party will reimburse the prevailing party for any fees it paid to
                                                                the American Arbitration Association in connection with the
                                                                arbitration, as
                                                                well as for any reasonable attorneys&#39; fees incurred by the
                                                                prevailing party in connection with such arbitration. Any decision
                                                                rendered in such
                                                                arbitration proceedings will be final and binding on the parties,
                                                                and judgment may be entered in a court of competent jurisdiction.
                                                                Any
                                                                arbitration hearing will take place at a location within the federal
                                                                judicial district that includes Wyoming. This arbitration agreement
                                                                applies
                                                                to all Claims now in existence or that may arise in the future.
                                                                Nothing in this Agreement shall be construed to prevent any party&#39;s
                                                                use of (or
                                                                advancement of any Claims, defences, or offsets in) bankruptcy or
                                                                repossession, replevin, judicial foreclosure or any other
                                                                prejudgment or
                                                                provisional remedy relating to any collateral, security or other
                                                                property interests for contractual debts now or hereafter owed by
                                                                either
                                                                party to the other. However, the parties hereto may have recourse to
                                                                courts of justice to seek injunctive or seizure orders only; the
                                                                issuance
                                                                of such orders by a court of justice shall not give competence to
                                                                the court to hear the matter, which shall remain subject to
                                                                arbitration as
                                                                provided for hereunder. IN THE ABSENCE OF THIS ARBITRATION
                                                                AGREEMENT, THE MERCHANT AND TCB MAY OTHERWISE HAVE HAD A
                                                                RIGHT OR OPPORTUNITY TO LITIGATE CLAIMS THROUGH A COURT BEFORE A
                                                                JUDGE OR A JURY, AND/OR TO PARTICIPATE OR BE
                                                                REPRESENTED IN LITIGATION FILED IN COURT BY OTHERS (INCLUDING class
                                                                ACTIONS), BUT EXCEPT AS OTHERWISE PROVIDED ABOVE,
                                                                THOSE RIGHTS, INCLUDING ANY RIGHT TO A JURY TRIAL, ARE WAIVED AND
                                                                ALL CLAIMS MUST NOW BE RESOLVED THROUGH ARBITRATION.

                                                            </p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Notice</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> Any notice required or permitted hereunder shall be in writing and
                                                                shall be sent by certified mail, return receipt requested, or by
                                                                overnight express mail, with proof of delivery retained, and
                                                                addressed to the respective parties at the address set forth below.
                                                                Notice of breach or termination of this Agreement sent to TCB shall
                                                                be directed to the same address, attention Legal Department</p>

                                                        </div>



                                                        <div className="pp-sub-head">
                                                            <h5>Assignment</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> This Agreement shall not be assigned or delegated in whole or in
                                                                part by Merchant to any other party without the prior written
                                                                consent of TCB, which shall not be unreasonably withheld. TCB may
                                                                assign this Agreement in its sole discretion without the written
                                                                consent of the Merchant.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Legal Fees</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> In the event of any dispute arising out of or related to the terms
                                                                of this Agreement, the prevailing party shall be entitled to recover
                                                                its reasonable attorney&#39;s fees, court costs and collection expenses
                                                                in addition to any other recovery.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Force Majeure</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> If performance by TCB, the ODFI or any of their respective
                                                                affiliates, of any Services or obligation under this Agreement is
                                                                prevented, restricted, delayed or interfered with by reason of acts
                                                                or events beyond their reasonable control, including but not limited
                                                                to labor disputes and strikes; acts of nature, fire, floods,
                                                                lightning, hurricanes, earthquakes or severe weather; utility or
                                                                communications failures; failures of the ODFI, ACH or Federal
                                                                Reserve Bank system network; computer-associated outages or delay in
                                                                receiving electronic data; war, civil commotion or acts of
                                                                terrorism; or the introduction of any new law, order or regulation
                                                                which prohibits or restricts the performance of this Agreement, then
                                                                TCB, the ODFI, and their respective affiliates affected by the
                                                                occurrence of such acts or events shall be excused from their
                                                                performance hereunder to the extent of the prevention, restriction,
                                                                delay or interference. </p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Amendment</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> This Agreement may be amended by TCB by posting a new version of
                                                                this Agreement within the control panel or any place that the
                                                                Merchant has access to view the revised Agreement. Any new version
                                                                of this Agreement will immediately replace in its entirety this
                                                                Agreement.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Entire Agreement</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> This Agreement contains the entire agreement between Merchant and
                                                                TCB relating to the subject matter addressed herein, and supersedes
                                                                any prior or contemporaneous understandings or agreements, whether
                                                                oral or written, between the parties regarding the subject matter of
                                                                this Agreement. This Agreement shall be binding upon and shall inure
                                                                only to the benefit of the parties hereto and their respective
                                                                successors and permitted assigns. Nothing in this Agreement, express
                                                                or implied, is intended to confer or shall be deemed to confer upon
                                                                any persons or entities not parties to this Agreement, any rights,
                                                                or remedies under or by reason of this Agreement.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Form And Delivery Of Signed Agreement</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> This Agreement is executed by the Merchant through its signature of
                                                                its application and the Merchant shall deliver to TCB an original,
                                                                facsimile, scanned or other electronic copy of the original signed
                                                                application by physical or electronic means. For legal evidentiary
                                                                purposes, a facsimile, scanned or other electronic copy of this
                                                                Agreement bearing the signature of the authorized representative of
                                                                the Merchant shall be accepted as an equivalent to a signed original
                                                                copy of this Agreement.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Independent Contractors</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> The parties are and shall remain independent contractors and shall
                                                                have no legal right or authority to make any binding commitments on
                                                                behalf of the other party.</p>

                                                        </div>



                                                        <div className="pp-sub-head">
                                                            <h5>Third-Party Providers</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> TCB&#39;s third-party providers are providing some of the Services
                                                                specified in this Agreement and, as a result, these third-party
                                                                providers shall be intended third-party beneficiaries of this
                                                                Agreement between TCB and Merchant. Each third-party services
                                                                provider shall have the right to enforce directly against Merchant
                                                                the terms of this Agreement which relate to the provision of the
                                                                third-party provider&#39;s services to Merchant and the ownership and
                                                                protection of the intellectual property rights of the third-party
                                                                provider and its licensors in and to its services. Merchant
                                                                acknowledges that the third-party providers shall have no
                                                                responsibility or liability regarding TCB&#39;s obligations to Merchant
                                                                under this Agreement.</p>

                                                        </div>



                                                        <div className="pp-sub-head">
                                                            <h5>Severability</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> If any provision of this Agreement is held by a court of competent
                                                                jurisdiction to be invalid, void, or unenforceable for any reason,
                                                                the remaining provisions not so declared shall nevertheless continue
                                                                in full force and effect but shall be construed in a manner to
                                                                effectuate the intent of this Agreement as a whole, notwithstanding
                                                                such stricken provision or provisions.</p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Drafting</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> No provision of this Agreement shall be construed against any party
                                                                merely because that party or counsel drafted or revised the
                                                                provision in question. All parties have been advised and have had an
                                                                opportunity to consult with legal counsel of their choosing
                                                                regarding the force and effect of the terms set forth herein. This
                                                                Agreement shall be deemed to be jointly prepared by the parties and
                                                                therefore any ambiguity or uncertainty shall be interpreted
                                                                accordingly. </p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Waiver</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> No term or provision of this Agreement shall be deemed waived, and
                                                                no breach excused, unless such waiver or consent shall be in writing
                                                                and signed by the party claimed to have waived or consented. Any
                                                                consent by any party to, or waiver of, a breach by the other party,
                                                                whether express or implied, shall not constitute a consent to,
                                                                waiver of, or excuse for any different or subsequent breach. </p>

                                                        </div>


                                                        <div className="pp-sub-head">
                                                            <h5>Section Headings</h5>
                                                        </div>
                                                        <div className="text2">


                                                            <p> The section headings contained in this Agreement are for convenient
                                                                reference only and shall not in any way affect the meaning or
                                                                interpretation of this Agreement.</p>

                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
        </section>
        </>
    )
}
