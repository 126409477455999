import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import config from '../Config';
import { Swiper as SwiperType } from 'swiper';
import { Mousewheel,Pagination } from 'swiper/modules';
// SwiperCore.use([Pagination]);

export const Aboutcontent = () => {

    const swiperRef = useRef<SwiperType | null>(null);
    const progressBarRef = useRef<HTMLDivElement | null>(null); // Define the progressBarRef
    const [progress, setProgress] = useState(0.5); // Set initial progress to 50%
    const totalSlides = 10; // Total number of slides
    const slidesPerView = 4; // Number of slides visible at once

    useEffect(() => {
        const handleProgressUpdate = () => {
            if (swiperRef.current) {
                const currentIndex = swiperRef.current.activeIndex;

                // Calculate progress based on total slides and slides per view
                const calculatedProgress = (currentIndex / (totalSlides - slidesPerView));
                setProgress(Math.min(0.5 + calculatedProgress * 0.5, 1)); // Start at 50%, fill remaining 50% as you scroll
            }
        };

        if (swiperRef.current) {
            swiperRef.current.on('slideChange', handleProgressUpdate);
            handleProgressUpdate();
        }

        return () => {
            if (swiperRef.current) {
                swiperRef.current.off('slideChange', handleProgressUpdate);
            }
        };
    }, []);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const onMouseMove = (event: MouseEvent) => {
            const rect = progressBarRef.current!.getBoundingClientRect();
            const x = event.clientX - rect.left; // Get mouse position relative to the progress bar
            const percentage = Math.max(0, Math.min(1, x / rect.width)); // Clamp between 0 and 1

            // Update the swiper to the corresponding slide
            if (swiperRef.current) {
                swiperRef.current.slideTo(Math.floor(percentage * (totalSlides - slidesPerView)));
            }
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };
  return (
    <>
    <section className="abut-area gradient-background5">
        <div className="wave3 d-lg-block">
            <img src="assets/imgs/wave-12.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-lg-8 mb-40 text-center">
                <h6 className="seamless-heading abut-txt">Our mission</h6>
                <p className='abut-txt1'>At TCB Pay, we pride ourselves on being transparent with our clients and gladly choose long-term relationships over profit. As an independent global processing payment solution, we set ourselves apart from the competition with our thorough customer service team and our dedicated team members.</p>
                </div>   
            </div>
        </div>

        <div className="wave4 d-lg-block">
        <img src="assets/imgs/wave-13.png" alt="" />
        </div>
        
    </section>

    <section className='abut-seclast'>
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                <h6 className="seamless-heading mb-90">Our story</h6>
                </div>
               
            </div>
            <div className="slider-swiper1">
              <div className='row'>
                <div className='col-md-12'>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        pagination={{ clickable: true }}
                        mousewheel={true}
                        direction={'horizontal'}
                        modules={[Mousewheel,Pagination]}
                        onSwiper={(swiper: SwiperType) => { // Type swiper here
                            swiperRef.current = swiper;
                        }}
                        breakpoints={{
                            320: {
                                width: 320,
                                slidesPerView: 1,
                              },
                            576: {
                              width: 576,
                              slidesPerView: 2,
                            },
                            768: {
                              width: 768,
                              slidesPerView: 2,
                            },

                            
                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                                <div className='abut-linecruve'><img src="assets/imgs/about/abut-line.png" alt="" /></div>
                                <div className='bluebx'>
                                    <h4>2014</h4>
                                    <p>TCB Pay was founded in <b>Henderson, NV.</b></p>
                                </div>
                                
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line1.png" alt="" /></div>
                            <div className='yelwbx'>
                            <h4>2015</h4>
                            <p>Expanded and opened a satellite office in <b>Los Angeles, CA.</b></p>
                            </div> 
                                
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line2.png" alt="" /></div>
                            <div className='bluebx'>
                            <h4>2017</h4>
                            <p>Began hiring an in-house development team to <b>build proprietary software/tools.</b></p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line3.png" alt="" /></div>
                            <div className='yelwbx'>
                            <h4>2018</h4>
                            <p>Opened a satellite office in Toronto, Canada.</p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line4.png" alt="" /></div>
                            <div className='bluebx'>
                            <h4>2019</h4>
                            <p>The first <b>TCB Pay Gateway</b> was created.</p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line5.png" alt="" /></div>
                            <div className='yelwbx'>
                            <h4>2020</h4>
                            <p>The <b>TCB Pay Web Application</b> and the <b>TCB Pay App</b> were released.</p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line6.png" alt="" /></div>
                            <div className='bluebx'>
                            <h4>2021</h4>
                            <p>Achieved <b>PCI Level 1 Compliance</b> status certified by WorldPay.</p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line7.png" alt="" /></div>
                            <div className='yelwbx'>
                            <h4>2022</h4>
                            <p>Became certified with <b>TSYS</b>. Moved headquarters to <b>Boca Raton, FL.</b></p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line8.png" alt="" /></div>
                            <div className='bluebx'>
                            <h4>2023</h4>
                            <p><b>TCB Pay Issuing</b> was released.<br/>
                            Opened a satellite office in <b>Malta and New York City.</b></p>
                            </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='slider-swiperbx1'>
                            <div className='abut-linecruve'><img src="assets/imgs/about/abut-line9.png" alt="" /></div>
                            <div className='yelwbx'>
                            <h4>2024</h4>
                            <p>Launch of the <b>TCB Pay Portal,</b> an upgraded version of the TCB Pay Gateway featuring significant enhancements and additional functionalities.</p>
                            </div>
                            </div>
                        </SwiperSlide>


                    </Swiper>

                    <div
                    ref={progressBarRef}
                    className="progress-bar-container"
                    style={{
                        width: '100%',
                        height: '10px',
                        backgroundColor: '#e0e0e0',
                        position: 'relative',
                        marginTop: '20px',
                        cursor: 'pointer',
                    }}
                    onMouseDown={handleMouseDown}
                >
                    <div
                        className="progress-bar"
                        style={{
                            width: `${progress * 100}%`,
                            height: '100%',
                            background: `linear-gradient(to right, yellow 50%, blue)`, // Half yellow, half blue
                            transition: 'width 0.3s',
                        }}
                    />
                    </div>
                </div>
            </div>  
            </div>
            
        </div>
    </section>

    <section className="abut-area1 gradient-background6">
        <div className="wave3 d-lg-block">
            <img src="assets/imgs/wave-14.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-lg-8 mb-40 text-center">
                <h6 className="seamless-heading abut-txt2">Our culture</h6>
                <p className='abut-txt3'>At TCB Pay, we think the only way to do business is to serve others, do more for them, and charge less. We have shaped our company and everything we do by this motto because it’s true to the values of those we do business with. <br/>We are here to support independent business owners and take one less worry off their back, whether it is accepting payments, consulting with them on business solutions, or just providing customer service.</p>
                </div>   
            </div>
        </div>

        <div className="wave4 d-lg-block">
        <img src="assets/imgs/wave-15.png" alt="" />
        </div>
        
    </section>

    <section className='abut-seclast pb-210'>
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                <h6 className="seamless-heading mb-90">Our values</h6>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon1.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Teamwork</div>
                        <div className='abut-iconbxtxt1'>Together, we achieve more, leveraging diverse perspectives and collaborative efforts to drive success.</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon2.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Think outside the box</div>
                        <div className='abut-iconbxtxt1'> We embrace creativity and innovation, constantly seeking new perspectives and solutions to overcome challenges.</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon3.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Respect and inclusion</div>
                        <div className='abut-iconbxtxt1'> We value and celebrate the unique contributions of every individual, fostering an environment of respect, dignity, and belonging.</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon4.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Growth</div>
                        <div className='abut-iconbxtxt1'> Our commitment to continuous improvement and personal development empowers each team member to reach their full potential.</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon5.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Trust</div>
                        <div className='abut-iconbxtxt1'> We prioritize integrity and transparency in all our interactions, fostering trust among our team members, partners, and merchants.</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='abut-seclast-inner'>
                        <div className='abut-iconbx'><img src='assets/imgs/abut-icon6.svg' alt='' /></div>
                        <div className='abut-iconbx1'>
                        <div className='abut-iconbxtxt'>Work hard, play hard</div>
                        <div className='abut-iconbxtxt1'> We maintain a balance between dedication to our goals and enjoying the journey, celebrating achievements, and fostering a supportive culture.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
