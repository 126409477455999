import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IntegrationCreditReportSec = () => {

    const exampleCodeText_1 = `<form name="" action="${config.gatewayApisUrlProd}/apis/report.php" method="post">
    <input type="text" name="username" value="tcbpayusername"/>
    <input type="text" name="api_key" value="tcbpayapikey"/>
    <input type="text" name="start_date" value="2021-01-15"/>
    <input type="text" name="end_date" value="2021-03-15"/>
    <br/>
    <input type="submit" value="submit"/>
</form>`;
    const exampleCodeText_2 = `{                    
    "response": {
        "code": "00",      
        "description": "success",            
        "no_of_transactions": "450",  
        "total_pages": 5,                      
        "current_page": "1",     
        "transactions": [            
            {    
                "transaction_id": "100000000000001605",  
                "parent_transaction_id": "", 
                "transaction_type": "SALE",  
                "transaction_source": "Moto",
                "initiated_by": "System User",  
                "transaction_status": "SUCCESS",    
                "decline_reason": "",  
                "transaction_time": "2021-08-17 03:10:50",    
                "time_zone": "America/Caracas",
                "gmt_time": "2021-08-17 07:10:50",    
                "card_type": "MASTERCARD",
                "3d_secure_transaction": "NO",   
                "credit_card_number": "************2205",  
                "card_expiry_date": "12/2025",
                "auth_response_code": "Success",  
                "avs_response_code": "Z",
                "avs_response": "Not Matched",
                "cvv_response_code": "M",
                "cvv_response": "Matched",
                "error_response": "Approved",
                "currency": "USD", 
                "total_amount": "2",
                "amount": "2",
                "surcharge": "0", 
                "tax": "0", 
                "tip": "0",  
                "first_name": "Tanmoy",
                "last_name": "Mondal",
                "company_name": "",
                "address_1": "802 E Frierson Ave", 
                "address_2": "",   
                "city": "TAMPA",
                "state": "FL",   
                "zip_code": "33606", 
                "email": "tanumondal@gmail.com",  
                "phone_no": "", 
                "subscription_id": "",
                "is_risk_hold": "NO",    
                "IP": "192.168.0.93"      
                "reference_id": ""         
            }               
        ]               
    }                 
}`;


    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);


  return (
    <>
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                        <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      <h2>Credit Card API</h2>
                      <ul className="card-url">
                          <li>
                              <div className="card-url-list">
                                   <p>Sandbox endpoint URL:</p>
                                   <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                              </div>
                            
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Example:</p>
                                  <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Production endpoint URL:</p>
                                  <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                              </div>
                          </li>
                      </ul>
                  </div>

                  <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title">
                          <h2>Report</h2>
                      </div>
                      <div className="api-menu-item-wrapper">
                          <h3>Report API</h3>
                          <hr />
                          <p>This API is responsible for generating transaction reports.</p>
                          <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>URL to call</p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/report.php </Link></span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method of form submission</p>
                                      <span><Link to=''>POST</Link></span>
                                 </div>
                              </li>                              
                          </ul>
                      </div>
                  </div>


                  <div className="api-table pt-100">
                      <h5>This table describes the request parameters for Report API.</h5>

                        <div className="table-responsive">
                            <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                <th style={{ width: '145px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Value</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                            <td>username</td>
                            <td>TCB Pay Gateway Username</td>
                            <td>Y</td>
                            <td>TCB Pay Gateway Username</td>
                            </tr>
                            <tr>
                            <td>api_key</td>
                            <td>TCB Pay Gateway API Key</td>
                            <td>Y</td>
                            <td>TCB Pay Gateway API Key</td>
                            </tr>
                            <tr>
                            <td>start_date</td>
                            <td>From transaction date</td>
                            <td>Y</td>
                            <td>date format YYYY-MM-DD</td>
                            </tr>
                            <tr>
                            <td>end_date</td>
                            <td>To transaction date</td>
                            <td>Y</td>
                            <td>date format YYYY-MM-DD</td>
                            </tr>
                            <tr>
                            <td>transaction_type</td>
                            <td>Type of transaction</td>
                            <td>N</td>
                            <td>(default all transaction) SALE,AUTHORIZE,CAPTURE,REFUND,VOID,CREDIT
                            </td>
                            </tr>
                            <tr>
                            <td>transaction_status</td>
                            <td>Status of transaction</td>
                            <td>N</td>
                            <td>(default all transaction) SUCCESS,FAIL
                            </td>
                            </tr>
                            <tr>
                            <td>transaction_id</td>
                            <td>TCBPay ID of the transaction, If we need to know the details of a specific transaction</td>
                            <td>N</td>
                            <td>100000000000001605
                            </td>
                            </tr>
                            <tr>
                            <td>page_no</td>
                            <td>Page number of the entire result set. Each page has a maximum of 100 records listed</td>
                            <td>N</td>
                            <td>(default 1) can be upto total page number
                            </td>
                            </tr>
                            <tr>
                            <td>reference_id</td>
                            <td>Transaction reference id</td>
                            <td>N</td>
                            <td>Reference ID should be unique</td>
                            </tr>
                            </tbody>
                            </table>
                        </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip1">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_1">
                                <pre>{exampleCodeText_1}</pre>
                            </div>
    
                        </div>
                      </div>
                  </div>
                  
                <div className="api-menu-item-wrapper mt-60">
                    <h3>Response</h3>
                    <hr />
                    <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, and array of transactions. The response code is always 00 for a successful API call.</p>
                    <div className="example-box-wrapper mb-20">
                        <div className="d-flex align-items-start">
                            <b></b>
                            <span>Sample Response</span>
                        </div>
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip2">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_2">
                                <pre>{exampleCodeText_2}</pre>
                            </div>
    
                        </div>
                    </div>
                </div>
                <div className="api-table pt-50">
                <h5>Response Codes</h5>
                <div className="table-responsive">
                    <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                        <thead>
                        <tr>
                            <th style={{ width: '145px' }}>Code</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>01</td>
                            <td>Username is missing or invalid</td>
                        </tr>
                        <tr>
                            <td>02</td>
                            <td>Credit Card is missing or invalid</td>
                        </tr>
                        <tr>
                            <td>03</td>
                            <td>Expiration Date is missing or invalid</td>
                        </tr>
                        <tr>
                            <td>04</td>
                            <td>First/Last Name is missing</td>
                        </tr>
                        <tr>
                            <td>05</td>
                            <td>Invalid Country Code</td>
                        </tr>
                        <tr>
                            <td>06</td>
                            <td>The proper cardholder Email ID is missing</td>
                        </tr>
                        <tr>
                            <td>07</td>
                            <td>Invalid Amount</td>
                        </tr>
                        <tr>
                            <td>08</td>
                            <td>Transaction Currency is missing</td>
                        </tr>
                        <tr>
                            <td>09</td>
                            <td>API Key is missing or invalid</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Requested Currency is not allowed for this merchant</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Couldn't process a transaction due to load balancer rules</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>The transaction ID is missing or invalid</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Refund is not possible, the transaction has already been voided.</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>Refund is not possible, it exceeds Actual Amount</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>Capture is not possible</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>Void is not possible</td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>Sale limit exceeded</td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>Monthly limit exceeded</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>Transaction limit exceeded</td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>IP/Transaction limit exceeded</td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>Message contains words that have been identified as profanity and this message has been blocked due to company policy. Please try again.</td>
                        </tr>
                        <tr>
                            <td>22</td>
                            <td>IP Address is missing/invalid</td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td>Date is invalid date format</td>
                        </tr>
                        <tr>
                            <td>24</td>
                            <td>Transaction Type not valid</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>Transaction Status not valid</td>
                        </tr>
                        <tr>
                            <td>26</td>
                            <td>Potential duplicate transaction</td>
                        </tr>
                        <tr>
                            <td>A1</td>
                            <td>Transaction entry failed</td>
                        </tr>
                        <tr>
                            <td>A2</td>
                            <td>Transaction update failed</td>
                        </tr>
                        <tr>
                            <td>P1</td>
                            <td>Invalid page number</td>
                        </tr>
                        <tr>
                            <td>UN</td>
                            <td>Unknown error, please contact an administrator</td>
                        </tr>
                        <tr>
                            <td>F</td>
                            <td>Your transaction is declined</td>
                        </tr>
                        <tr>
                            <td>00</td>
                            <td>Transaction entry is successful</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </div>

              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
    </section>
    </>
  )
}
