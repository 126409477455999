import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const FaqTcbPortalSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                  <h2 className='faq-rgtsectitle'>TCB Pay Portal Wiki</h2>
                  <div className='faq-rgt mt-30 mb-40'>
                    <div className='row'>
                       <div className='col-md-7'>
                        <div className='innerbx pb-30'>
                        <h6>User guide</h6>
                        <p className="mt-20 mb-20">Explore the <b>TCB Pay portal Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform.</p>
                        <Link className='wiki-btn mb-0' target='_blank' to="/wiki-portal">Go to TCB Pay Portal Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                      </div>
                      <div className='col-md-5'><img src="assets/imgs/faq-pic1.webp" alt="" className='faqissuingpic' /></div> 
                    </div>
                    </div>

                    <h2 className='faq-rgtsectitle'>TCB Pay Portal</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is the TCB Pay Portal?</div>
                        <div className='qusans'>
                            <p>More than a payment gateway, the TCB Pay Portal is your one-stop shop for all your payment needs. </p>
                            <p>With the Portal, you can easily process card and ACH payments, send payment links via QR code, create invoices, set up recurring payments, manage your customer database, track chargebacks, and more.
                            </p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is a payment gateway?</div>
                        <div className='qusans'>
                            <p>A payment gateway is a virtual terminal that is connected to your merchant account and allows you to accept credit card payments, both in person and online. </p>
                        </div>
                    </div>

                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I know my transactions are secure?</div>
                        <div className='qusans'>
                            <p>At TCB Pay, our security solutions include encryption and tokenization to protect card data. Encryption protects the card data as it travels across various systems and networks while Tokenization replaces cardholder data with a unique token value that is substituted for the payment data in the processing environment. In the unlikely event a breach occurs, the sensitive cardholder information is virtually inaccessible.</p>
                            <p>For more information, please visit tcbpay.com/security.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do you maintain security?</div>
                        <div className='qusans'>
                            <p>As data breaches increasingly impact the processing industry the need for sophisticated technologies to identify and remedy gaps and weaknesses is for everyone’s benefit. TCB Pay can help protect your business from security vulnerabilities tied to payment card data and maintain a Payment Card Industry Data Security Standard (PCI DSS) compliant status – ensuring core security requirements and PCI best practices are in place.</p>
                            <p>TCB recognizes that maintaining PCI DSS compliance can take a significant amount of your time and technical prowess to address. TCB Pay’s PCI Team offers a hands-on concierge service to our customers; not only protecting their business(es), but also their cardholders.</p>
                            <p>For more information, please visit tcbpay.com/security.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do you protect me from fraud?</div>
                        <div className='qusans'>
                            <p>TCB Pay offers various features to reduce fraud and provide peace of mind. These features include Address Verification Service (AVS) to check if the billing address matches what the issuing bank has on file, Card Verification Value (CVV) check that acts as an added security measure, 3DSecure (3DS) for increased chargeback protection, Lockdown Controls to restrict transactions, and exclusive Risk-Scoring along with continuous monitoring to recognize fraudulent transactions. 
                            </p>
                            <p>For more information, please visit tcbpay.com/security.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I receive my funds from my account?</div>
                        <div className='qusans'>
                            <p>Depending on your account, you can receive your funds in your bank account the following day.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What do I do if I have a delay in receiving my funds?</div>
                        <div className='qusans'>
                            <p>Contact <Link to="mailto:support@tcbpay.com">support@tcbpay.com</Link> and we will be more than happy to assist you!</p>
                        </div>
                    </div>


                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
