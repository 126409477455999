import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<script type="text/javascript" src="https://[server-name]/services/proxynization_api.js"></script>`;
  const exampleCodeText_2 = `<script type="text/javascript" >
    function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
      if(responseCode == "A01"){
      document.getElementById('creditcard_number').value = proxyNumber;
      document.getElementById("formID").submit();
      } else{
      alert(responseMessage);
      return false;
      }
    }
  </script>
  `;
  const exampleCodeText_3 = `<input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />`;
  const exampleCodeText_4 = `  <form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/add-card-vault.php" method="post">
  <input type="text" name="username" value=" tcbpayusername"/>
  <input type="text" name="api_key" value=" tcbpayapikey"/>
  <input type="text" name="creditcard_number" id="creditcard_number" value="4111111111111111"/>
  <input type="text" name="expiry_month" value="06"/>
  <input type="text" name="expiry_year" value="2025"/>
  <input type="text" name="card_holder_name" value="Roberto Diaz"/>
  <br/>
  <input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />
</form>

<script type="text/javascript" >
  function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
    if(responseCode == "A01"){
    document.getElementById('creditcard_number').value = proxyNumber;
    document.getElementById("formID").submit();
    } else{
    alert(responseMessage);
    return false;
    }
  }
</script>`;
  const exampleCodeText_5 = `{"response":{"code":"00","description":"Card is successfully added to vault","token":XXXXXXXXXXXXXXXXXXXXXXXXX}}`;
  

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h2>Vault API</h2>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/add-card-vault.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Add Card</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Add Card API</h3>
                            <hr />
                            <p>This API is responsible to store Card information inside TCB Pay Vault.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/add-card-vault.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                                <br />
                                <li>
                                    <div className="card-url-list">
                                         <p>Proxynization APIs</p>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                         <p>Sandbox </p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/services/proxynization_api.js</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Production</p>
                                        <span><Link to=''>{config.gatewayApisUrlProd}/services/proxynization_api.js</Link></span>
                                   </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                    <div className="example-box-wrapper mt-100 mb-50">
                        <div className="d-flex align-items-start">
                            <b>1 </b>
                            <p>Include proxynization_api.js script file. Please copy the following lines and paste them to your HTML page to enable the proxynization functionality within your application.
                            <span>Example</span>
                            </p>
                        </div>
                        
                        
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip1">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_1">
                                <pre>{exampleCodeText_1}</pre>
                            </div>
    
                        </div>

                    </div>

                    <div className="example-box-wrapper mb-50">
                        <div className="d-flex align-items-start">
                            <b className="">2 </b>
                            <p>Implement the callback function. The callback function will be invoked once the proxynization response is returned from our system. We make a function call which invokes the pre-defined callback function, and the proxynization response is used as an argument.
                            <span>Example</span>
                            </p>
                        </div>
                        
                       
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip2">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </div>
                            <div className="ex-text-box">
                                <pre>{exampleCodeText_2}</pre>
                            </div>
                        </div>
                    </div>

                    <div className="example-box-wrapper mb-50">
                        <div className="d-flex align-items-start">
                            <b className="me-3">3</b>
                            <p> Design your payment form and insert a proxynization call within the form’s submit action.
                            <ul className='api-innerlist'>
                            <li>
                                <div className="d-flex align-items-start mb-20">
                                    <b className="me-3">3.1</b><p> The first parameter can either be the value of the credit card number or the ID of the credit card number field. If the value of the parameter starts with a hashtag (#) symbol, the first parameter is assumed to be the ID of the credit card number field. If a hashtag is absent, the first parameter is assumed to be a credit card number.</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex align-items-start">
                                    <b className="me-3">3.2</b><p> The second parameter is the name of the callback function, which will be called when the proxynization call is completed.</p>
                                </div>
                            </li>
                            </ul>
                            <span>Example</span>
                            </p>

                        </div>                        
                        
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip3">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(3)} text={exampleCodeText_3}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </div>
                            <div className="ex-text-box">
                              <pre>{exampleCodeText_3}</pre>
                            </div>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>This table describes the request parameters for Add Card API.</h5>

                                <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Variable Name</th>
                                        <th>Description</th>
                                        <th>Required</th>
                                        <th>Expected Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>username</td>
                                        <td>TCB Pay Gateway Username</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway Username</td>
                                      </tr>
                                      <tr>
                                        <td>api_key</td>
                                        <td>TCB Pay Gateway API Key</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway API Key</td>
                                      </tr>
                                      <tr>
                                        <td>creditcard_number</td>
                                        <td>Credit Card Number</td>
                                        <td>Y</td>
                                        <td>4111111111111111</td>
                                      </tr>
                                      <tr>
                                        <td>expiry_month</td>
                                        <td>Expiry Month of the Credit Card</td>
                                        <td>Y</td>
                                        <td>06</td>
                                      </tr>
                                      <tr>
                                        <td>expiry_year</td>
                                        <td>Expiry Year of the Credit Card</td>
                                        <td>Y</td>
                                        <td>2025</td>
                                      </tr>
                                      <tr>
                                        <td>card_holder_name</td>
                                        <td>Card holder's name of the Credit Card</td>
                                        <td>Y</td>
                                        <td>Roberto Diaz</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip4">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box bighgt">
                                    <pre>{exampleCodeText_4}</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-60">
                            <h3>Response</h3>
                            <hr />
                            <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description and token. The response code is always 00 for a successful API call.</p>
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip5">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_5}</pre>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                 
                     


                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



