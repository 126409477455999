import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const EcommerceSec = () => {
  return (
    <>
    <section className="ecommerce-bx gradient-background9">
        <div className="wave19 d-lg-block">
            <img src="assets/imgs/wave-19.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h6 className="seamless-heading abut-txt text-left">We make the process easy</h6>
                </div>
                
                    <div className="tracking-facility text-left">
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/e-icn1.svg" alt="" />
                            <h6>Easy Onboarding Process</h6>
                            <p>Our sales team will assist you throughout the entire process, from submitting the application to setting up your account for a seamless start.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/e-icn2.svg" alt="" />
                            <h6>Secure Transactions</h6>
                            <p>Rely on our robust Portal to ensure safe and secure transactions, protecting your business and customers.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/e-icn3.svg" alt="" />
                            <h6>Seamless Integrations</h6>
                            <p>Easily integrate our payment system with your existing platform for smooth and efficient operations.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/e-icn4.svg" alt="" />
                            <h6>24/7 Customer Service</h6>
                            <p>Our support team is available 24/7 to assist our merchants with unparalleled customer service.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div className="wave4 d-lg-block">
        <img src="assets/imgs/wave-20.png" alt="" />
        </div>
        
    </section>

    <section className="gallery">
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center'>
            <div className="left">
              <div className="desktopContent">
              <div className="desktopContentSection1">
                  <div className='tcbpayinfobx-inner'>
                    <h4>Discover the <span>Portal</span></h4>
                    <p>The TCB Pay Portal is simple, efficient, and accessible from any device. It allows you to accept payments in multiple currencies and consolidate multiple MIDs into one account.</p>
                    <Link to="/portal" className="integration-btn1">Discover the Portal <FontAwesomeIcon icon={faArrowRight} /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="right1">
                <div className='desktopPhoto1'><img src='assets/imgs/e-pic1.svg' alt=''/></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className='our-integrationsbx pb-0 bg-none'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-8 m-auto'>
                    <div className='our-integration-inner text-center'>
                        <h6 className='text-center'>Our <span>integrations</span></h6>
                        <p className='text-center'>Our technology offers seamless integration capabilities, allowing you to connect with various third-party systems and applications to enhance your business operations.</p>
                        <Link to="/integrations" className="integration-btn text-center">Discover our integrations <FontAwesomeIcon icon={faArrowRight} /> </Link>
                    </div>    
                </div>
                <div className='col-md-12'>
                   <div className='our-integration-pic'>
                        <div className="marquee marquee--8 mb-180">
                        <img className="marquee__item" src="assets/imgs/logo/our-logo1.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo2.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo3.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo4.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo5.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo6.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo7.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo8.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo9.svg" alt="" />
                        <img className="marquee__item" src="assets/imgs/logo/our-logo10.svg" alt="" />
                    </div>
                   </div>
                </div>
                    </div>
                </div>
    </section>

    <section className="gallery pb-180">
    <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center'>
            <div className="left">
              <div className="desktopContent">
              <div className="desktopContentSection1">
                  <div className='tcbpayinfobx-inner'>
                    <h4>Discover our <span>White-Label</span> offerings </h4>
                    <p>Our White-Label service offers customizable products and services, allowing you to create a seamless customer experience with your branding. It’s cost-effective, saving you money on development, infrastructure, and staff, as we handle these aspects.</p> 
                    <p>Our customer support team ensures a swift setup process, simplifying it for busy users. The solution is scalable, accommodating your business growth without technical limitations.</p>
                    <Link to="/white-label" className="integration-btn1">Discover White-Label <FontAwesomeIcon icon={faArrowRight} /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="right1">
                <div className='desktopPhoto1'><img src='assets/imgs/e-pic2.svg' alt=''/></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
