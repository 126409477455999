import React from 'react'
import { IntegrationCreditApplePay } from './integrationCreditApplePay'

export const IntegrationCreditApple = () => {
  return (
    <>
    <IntegrationCreditApplePay />
    </>
  )
}
