import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaqService } from '../services/FaqService';
import classNames from 'classnames';
import config from '../Config';

interface IState {
    loading: boolean;
    faqCategory: any;
    faqList: any;
    errorMsg: string;
  }

export const FaqSec = () => {
    const [state, setState] = useState<IState>({
        loading: false,
        faqCategory: [] as any,
        faqList: [] as any,
        errorMsg: "",
    });

    const handleSearchDate = (dateStr:string) => {

        if(dateStr.trim()!=''){
            FaqService.getSearchFaqList(dateStr)
            .then((res) =>{
                    setState({
                        ...state,
                        loading: false,
                        faqList: res.data as any,
                    });             
                }
            )
            .catch((err) =>{
                    setState({
                        ...state,
                        loading: false,
                        errorMsg: err.message,
                    });
                }
            );
        } else {
            setState({
                ...state,
                loading: false,
                faqList: [] as any,
            });
        }
    }


    //network request
    useEffect(() => {
        setState({ ...state, loading: true });
        FaqService.getFaqCategory()
        .then((res) =>
        setState({
            ...state,
            loading: false,
            faqCategory: res.data as any,
        })
        )
        .catch((err) =>
        setState({
            ...state,
            loading: false,
            errorMsg: err.message,
        })
        );
    //eslint-disable-next-line
    }, []);
    const { loading, faqCategory, faqList, errorMsg } = state;
    const topicsCat = faqCategory.response?.topics;
    const productsCat = faqCategory.response?.products;

    const listData = faqList.response?.list;
    const listData_length = listData ? Object.keys(listData).length : 0;

  return (
    <>
    <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='faqdots'/>
      
        <Container>
           <Row className='justify-content-md-center'>
            <Col xs lg="7">
            <div className='searchbar-txt'> Hi, how can we help?</div>
            <div className="form-group has-search">
            <span className="fa fa-search form-control-feedback"></span>
            <input type="text" onChange={(e) => handleSearchDate(e.target.value)} className="form-control" placeholder="Search" />

            {listData_length > 0 &&
                <>
                <ul className='faq-srchbx'>
                    {Object.entries(listData)?.map(([faqKey,faqVal]: [string, any]) => (
                    <>
                        <li><Link to={`${config.siteBaseUrl}/faq/${faqVal.category_seo_url}`}><span className="fa fa-search form-control-feedback"></span> {faqVal.title}</Link></li>
                    </>
                    ))}
                </ul>
                </>
            }

            </div>
            </Col>
          </Row>
          <Row>
            <Col xs lg="12">
            <div className='faq-bx'> 

            {topicsCat?.length > 0 &&
                <>
                <div className='faq-title'>Topics</div>
                <div className="faq-innerbx">
                    <div className="row">
                        {topicsCat?.map((topics: any) => (
                            <>
                            <div className="col-md-4">
                                <div className="inr-box1 innerwht">
                                    <div className="imginerdiv1" dangerouslySetInnerHTML={{__html: topics.icon}}>

                                    </div>

                                    <h4>{topics.name}</h4>
                                    <Link to={`${config.siteBaseUrl}/faq/${topics.seo_url}`}>
                                        <div dangerouslySetInnerHTML={{__html: topics.content}}></div>
                                    </Link>
                                    <Link to={`${config.siteBaseUrl}/faq/${topics.seo_url}`} className='faq-viewall'>View all</Link>
                                </div>
                                
                            </div>
                            </>
                        ))}
                            <div className="col-md-4">
                                <div className="inr-box1 innerwht">
                                    <div className="imginerdiv1">
                                        <svg width="20.214" height="25.7" viewBox="0 0 20.214 25.7">
                                            <defs>
                                                <linearGradient id="linear-gradient3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stop-color="#ffbd4b"/>
                                                <stop offset="1" stop-color="#ffe3ac"/>
                                                </linearGradient>
                                            </defs>
                                            <g id="_Group_" data-name="&lt;Group&gt;" transform="translate(-100.319 -58.998)">
                                                <path id="_Path_" data-name="&lt;Path&gt;" d="M103.616,79.493V59H102.4a2.017,2.017,0,0,0-2.038,2.036V80.277a2.957,2.957,0,0,1,2.038-.784ZM102.4,84.7h16.765V80.534H102.4a2.082,2.082,0,1,0,0,4.164Z" transform="translate(0 0)" fill="url(#linear-gradient3)"/>
                                                <path id="_Compound_Path_" data-name="&lt;Compound Path&gt;" d="M182.875,59H167V79.495h15.875ZM172.01,65.884a3.123,3.123,0,1,1,4.094,2.968.664.664,0,0,0-.451.637V71.1h-1.041V69.489a1.7,1.7,0,0,1,1.168-1.627,2.081,2.081,0,1,0-2.73-1.978v.533H172.01Zm3.644,5.673v1.5h-1.041v-1.5Zm4.909,5.14h-11.2a.521.521,0,0,1,0-1.041h11.2a.521.521,0,1,1,0,1.041Z" transform="translate(-62.343 -0.002)" fill="url(#linear-gradient3)"/>
                                            </g>
                                        </svg>
                                    </div>

                                    <h4>Discover our Wikis</h4>
                                    <Link to="/wiki-portal">
                                        <div>
                                            <p><b>TCB Pay Portal Wiki</b><br />Step-by-step guide for TCB Pay platform.</p>
                                        </div>
                                    </Link>
                                    <Link to="/wiki-issuing">
                                        <div>
                                            <p><b>TCB Pay Wiki Issuing</b><br />Instructions on managing and issuing cards.</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                    </div>
                </div>
                </>
            }
            {productsCat?.length > 0 &&
                <>
                <div className='faq-title'>Products</div>
                <div className="faq-innerbx mb-50 pb-150">
                    <div className="row">
                        {productsCat?.map((products: any) => (
                            <>
                            <div className="col-md-4">
                                <div className="inr-box1 innerwht blue">
                                    <div className="imginerdiv1" dangerouslySetInnerHTML={{__html: products.icon}}></div>
                                    <h4>{products.name}</h4>
                                    <Link to={`${config.siteBaseUrl}/faq/${products.seo_url}`}>
                                        <div dangerouslySetInnerHTML={{__html: products.content}}></div>
                                    </Link>
                                    <Link to={`${config.siteBaseUrl}/faq/${products.seo_url}`} className='faq-viewall1'>View all</Link>
                                </div>
                            </div>
                            </>
                        ))}
                    </div>
                </div>
                </>
            }

            </div>
            </Col>
          </Row>    
        </Container>

    </section>
    </>
  )
}
