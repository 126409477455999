import React from 'react'
import { LegalPersonalGuaranteeSec } from './legalPersonalGuaranteeSec'

export const LegalPersonalGuarantee = () => {
  return (
   <>
   <LegalPersonalGuaranteeSec />
   </>
  )
}
