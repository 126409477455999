import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IssuingCard = () => {
    const exampleCodeText_3 = `<input type="button" value="submit"  onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />`;
    const exampleCodeText_4 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
	"user_uid": "{Unique id of user}",
	"card_type": "DIGITAL",
	"profile": "Y",
	"spending_profile_uid": "null",
	"spending_limit": "1000"
	"shipping_address1": "123 Main St",
	"shipping_address2": "Apt 4B",
	"shipping_city": "Orlando",
	"shipping_state": "FL",
	"shipping_zip": "32829",
	"billing_address_type": "N",
	"billing_address1": "456 Elm St",
	"billing_address2": "Suite 101",
	"billing_city": "Austin",
	"billing_state": "TX",
	"billing_zip": "73301"
}`;
    const exampleCodeText_5 = `{
  	"message": "Card successfully created.",
 	 "code": "00",
 	 "data": {
		"card_number": "1234 1234 1234 1234",  // (encrypted)
		"card_exp": "00/00", // (encrypted)
		"card_cvv": "111",// (encrypted)
	"card_uid": "Unique id of card",
	"card_type": "DIGITAL",
	"profile_flag": true,
	"spending_profile_uid": "null",
	"spending_limit": "1000",
		"first_name": "firstname",
		"last_name": "lastname",
		"address_type": "C",
		"address1": "1234 street example",
		"address2": "apt 123",
		"city": "Austin",
		"state": "TX",
		"zip": "12345",
		"created_date": "0000-00-00 00:00:00"
 	 }
}`;

const exampleCodeText_6 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
	"user_uid": "{Unique id of user to be modified}"
	"phone_number": "4071234567"
	"billing_address_type": "N",
	"billing_address1": "123 example street", 
	"billing_address2": "apt 123", 
	"billing_city": "example",
	"billing_state": "TX",
	"billing_zip": "12345",
}`;
    const exampleCodeText_7 = `{
	"message": "User successfully modified.",
	"code": 00,
	"data": { 
"user_uid": "{Unique id of user}", 
"first_name": "firstname", 
"last_name": "lastname", 
"user_type": "user",
"email": "example@example.com", 
"phone_number": "4071234567", 
"employee_number": "999999999", 
"shipping_address1": "123 example street", 
"shipping_address2": "apt 123", 
"shipping_city": "example", 
"shipping_state": "TX", 
"shipping_zip": "12345", 
"billing_address_type": "N",
	"billing_address1": "123 example street", 
	"billing_address2": "apt 123", 
	"billing_city": "example",
	"billing_state": "TX",
	"billing_zip": "12345",
	"modified_date": "2024-01-01 23:59:59"
}
}`;

const exampleCodeText_8 = `{
	"api_username": "{Unique api username}",
	"api_key": "{Unique api_key}",
"status":"DELETE",
"user_uid": "{Unique id of user}"
}`;
const exampleCodeText_9 = `{
"message": "User status successfully changed.",
"code": 00,
	"user_uid": "{Unique id of user}"
"status":"DELETED",
	"deleted_date": "2024-01-01 23:59:59",.
}`;

const exampleCodeText_10 = `{
	"message": "Users successfully received.",
	"code": 00,
	"current_page": 1,
	"total_pages": 1,
	"total_record_count": 1,
	"data": {
       	"users": [
        {
       "user_details": { 
		"user_uid": "{Unique id of user}",
"first_name": "firstname", 
"last_name": "lastname", 
"email": "example@example.com", 
"phone_number": "4071234567", 
"user_type": "USER",
"employee_number": "999999999", 
"shipping_address1": "123 example street", 
"shipping_address2": "apt 123", 
"shipping_city": "example", 
"shipping_state": "TX", 
"shipping_zip": "12345", 
"billing_address1": "123 example street", 
"billing_address2": "apt 123", 
"billing_city": "example", 
"billing_state": "TX", 
"billing_zip": "12345" 
"status": "active",
"created_date": "2024-01-01 23:59:59",
"modified_date": "2024-01-01 23:59:59",
"deleted_date": "2024-01-01 23:59:59",
"is_deleted": "1"
}
}
      	]
	}
}`;
const exampleCodeText_11 = `{
  "api_username": "{Unique api username}",
  "api_key": "{Unique api_key}",
 "user_access_uid": "{unique id of user}"
  "card_uid": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "user_uid": [
	"{unique id of user}",
	"{unique id of user}"
  ],
  "operation_type": "Add"
}`;

const exampleCodeText_12 = `{
  "message": "Access list successfully modified",
  "code": 00,
  "cards": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "users": [
	"{unique id of user}",
	"{unique id of user}"
  ]
}`;

const exampleCodeText_13 = `{ "message": "Access list successfully recieved",
  "code": 00,
  "cards": [
	"{unique id of card}",
	"{unique id of card}",
	"{unique id of card}"
  ],
  "users": [
	"{unique id of user}",
	"{unique id of user}"
  ]
}`;

    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);

  return (
  <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                    <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                    </div>
              
                    <div className="creadit-api-lower-wrapper">
                    <div className="api-menu-item-title"><h2>Issuing API Documentation</h2></div>
            
            <div className="api-menu-item-wrapper mb-40">
                <p>For every API request, the following headers and parameters are required:
                </p>
                <ul>
                    <li style={{listStyleType:'disc'}}>Header: Concatenated AES 128-bit ECB encrypted value of (api_user, api_key, current_timestamp) using secret key. Use Pipe (|) as a separator.</li>
                    <li style={{listStyleType:'disc'}}>Parameters:
                        <ul>
                            <li>- GET Requests: Include these parameters in the parameters area</li>
                            <li>- POST Requests: Include these parameters in the request JSON body.</li>
                        </ul>
                    </li>
                </ul>
                
            <div className="api-table">

            <div className="table-responsive mb-20">
                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th style={{ width: '100px' }}>Variable Name</th>
                        <th style={{ width: '420px' }}>Description</th>
                        <th style={{ width: '100px' }}>Required</th>
                        <th style={{ width: '130px' }}>Expected Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>api_username</td>
                        <td>Unique API username associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    <tr>
                        <td>api_key</td>
                        <td>Unique API key associated with the account</td>
                        <td>Yes</td>
                        <td>String </td>
                    </tr>
                    
                    
                    </tbody>
                </table>
            </div>
            <ul>
                <li>- API username, API key, and secret key will be provided upon onboarding.</li>
                <li>- All dates and times are in GMT.</li>
            </ul>

            </div>
            </div>
                    <div className="api-menu-item-title"><h2>Card APIs</h2></div>

                    <div className="api-menu-item-wrapper">
                        <h3>Add Card</h3>
                        <hr/>
                        <ul className="card-url">
                        <li>
                                <div className="card-url-list">
                                    <p>Name of API:</p>
                                    <span>Card Add</span>
                                </div>
                                
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Description:</p>
                                    <span> This API allows you to add a new card to the system.</span>
                                </div>
                                
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>URL:</p>
                                    <span>https://issuing.tcbpay.com/apis/cards/add</span>
                                </div>
                                
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Sandbox endpoint URL:</p>
                                    <span>https://issuing-stg.tcbpay.com/apis/cards/add</span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Method:</p>
                                    <span>POST</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="api-table">
                      <h5>JSON Body Parameters:</h5>

                      <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>user_uid</td>
                                <td>Unique ID associated with the user who will become the cardholder for this card (Max 1 User)</td>
                                <td>Yes</td>
                                <td>String </td>
                              </tr>
                              <tr>
                                <td>card_type</td>
                                <td>Type of card to be created</td>
                                <td>Yes</td>
                                <td>String(“DIGITAL”, “PHYSICAL”)</td>
                              </tr>
                              
                              <tr>
                                <td>profile</td>
                                <td>Specifies whether the card is prepaid or credit</td>
                                <td>Yes</td>
                                <td>String (“N” for prepaid, “Y” for credit</td>
                              </tr>
                              <tr>
                                <td>spending_profile_uid</td>
                                <td>Unique ID of existing preset spending</td>
                                <td>Conditional if profile = “Y”</td>
                                <td>varchar</td>
                              </tr>
                              <tr>
                                <td>spending_limit</td>
                                <td>Specific amount card will have to spend</td>
                                <td>Conditional if profile = “N”</td>
                                <td>Integer</td>
                              </tr>
                              <tr>
                                <td>shipping_address_type</td>
                                <td>Specifies address card will be shipped to<br/>
                                1. Address user has saved on profile<br/>
                                2. Address of account (company)</td>
                                <td>Conditional (
                                Required if card_type = “PHYSICAL” )
                                </td>
                                <td>String (
                                1 character
                                “U” - User Shipping
                                “C” - Company
                                )
                                </td>
                              </tr>
                              <tr>
                                <td>billing_address_type</td>
                                <td>Specifies if billing address will be<br/>
                                1. Shipping address user has saved on profile<br/>
                                2. Address of account (company)<br/>
                                3. Billing address user has saved on profile<br/>
                                4. Specified address using ‘billing_’ variable fields
                                </td>
                                <td>Yes</td>
                                <td>String (
                                “U” - User shipping
                                “C” - Company
                                “B” - User billing
                                “N” - specific
                                )
                                </td>
                              </tr>
                              <tr>
                                <td>billing_address1</td>
                                <td>Primary billing address used for AVS (Address Verification System) on digital cards.(For physical cards shipping address will be used)</td>
                                <td>Conditional
                                (Required if card_type = DIGITAL)
                                </td>
                                <td>String (100 characters)</td>
                              </tr>
                              <tr>
                                <td>billing_address2</td>
                                <td>Secondary billing address used for AVS (Address Verification System) on digital cards.(For physical cards shipping address will be used)</td>
                                <td>No</td>
                                <td>String</td>
                              </tr>
                              <tr>
                                <td>billing_city</td>
                                <td>Billing city associated with the billing address used for AVS on digital cards</td>
                                <td>Conditional
(Required if card_type = DIGITAL)
</td>
                                <td>String (e.g., “Orlando”, “Austin”, “Boston”)</td>
                              </tr>
                              <tr>
                                <td>billing_state</td>
                                <td>Billing state associated with the billing address used for AVS on digital cards</td>
                                <td>Conditional
(Required if card_type = DIGITAL)
</td>
                                <td>US State Abbreviation i.e. (FL, GA, NY, TX, etc.)</td>
                              </tr>
                              <tr>
                                <td>billing_zip</td>
                                <td>Billing zip associated with the billing address used for AVS on digital cards
                                </td>
                                <td>Conditional
(Required if card_type = DIGITAL)
</td>
                                <td>5-Digit integer (e.g., 32829, 12129, 24747)</td>
                              </tr>

                              
                           
                            </tbody>
                          </table>
                      </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example Request Payload Body(JSON)</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip4">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_4">
                                <pre>{exampleCodeText_4}</pre>
                            </div>
    
                            </div>
                      </div>
                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example Response Payload(JSON) </span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip5">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_5">
                                <pre>{exampleCodeText_5}</pre>
                            </div>
    
                            </div>
                      </div>
                    </div>


                  
                    <div className="api-menu-item-wrapper mt-60">
                        <h3>Response Codes</h3>
                        <hr />
                        <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, two elements are returned, code, description token. The response code is always 00 for a successful API call.</p>
                        <div className="table-responsive  mb-20">
                        <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: '100px' }}>Code</th>
                              <th style={{ width: '420px' }}>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>00</td>
                              <td>Success</td>
                            </tr>
                            <tr>
                              <td>01</td>
                              <td>API Key is missing or invalid.</td>
                            </tr> 
                            <tr>
                              <td>02</td>
                              <td>Username is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>03</td>
                              <td>User uid is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>04</td>
                              <td>User Type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>05</td>
                              <td>Analyst can only be changed to Admin or User.</td>
                            </tr>
                            <tr>
                              <td>06</td>
                              <td>Admin or User cannot be changed to Analyst.</td>
                            </tr>
                            <tr>
                              <td>07</td>
                              <td>First/Last name is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>08</td>
                              <td>Email is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>09</td>
                              <td>Email already exists.</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>Phone number is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td>Phone number already exists.</td>
                            </tr>
                            <tr>
                              <td>12</td>
                              <td>Employee number is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>13</td>
                              <td>Employee number already exists.</td>
                            </tr>
                            <tr>
                              <td>14</td>
                              <td>Driver’s license number is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>15</td>
                              <td>Driver’s license number already exists.</td>
                            </tr>
                            <tr>
                              <td>16</td>
                              <td>Billing address type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>17</td>
                              <td>Billing address 1 is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>18</td>
                              <td>Billing address 2 is invalid.</td>
                            </tr>
                            <tr>
                              <td>19</td>
                              <td>Billing city is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>20</td>
                              <td>Billing state is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>21</td>
                              <td>Billing ZIP code is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>22</td>
                              <td>Shipping address 1 is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>23</td>
                              <td>Shipping address 2 is invalid.</td>
                            </tr> 
                            <tr>
                              <td>24</td>
                              <td>Shipping city is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>25</td>
                              <td>Shipping state is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>26</td>
                              <td>Shipping ZIP code is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>27</td>
                              <td>Start date is invalid date format.</td>
                            </tr>
                            <tr>
                              <td>28</td>
                              <td>End date is invalid date format.</td>
                            </tr>
                            <tr>
                              <td>29</td>
                              <td>Activation code is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>30</td>
                              <td>User status is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>31</td>
                              <td>Password is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>32</td>
                              <td>Failed to modify user status.</td>
                            </tr>
                            <tr>
                              <td>33</td>
                              <td>Failed to modify user.</td>
                            </tr>
                            <tr>
                              <td>34</td>
                              <td>Page number is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>35</td>
                              <td>Failed to fetch user list.</td>
                            </tr>
                            <tr>
                              <td>36</td>
                              <td>Failed to cancel cards and modify user status.</td>
                            </tr>
                            <tr>
                              <td>37</td>
                              <td>Can’t delete a user with cards assigned. </td>
                            </tr>
                            <tr>
                              <td>38</td>
                              <td>Card uids missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>39</td>
                              <td>User uids missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>40</td>
                              <td>Operation type is missing or invalid.</td>
                            </tr>
                            <tr>
                              <td>41</td>
                              <td>Failed to modify analyst user access.</td>
                            </tr>                                                        
                          </tbody>
                        </table>
                    </div>
                    </div>
               
                </div>


              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
  </section>
  )
}
