import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import 'aos/dist/aos.css';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { Home } from './homePage/home';
import { TcbpayPortal } from './tcbpaypotal/tcbpayPortal';
import { Tcbpayapp } from './tcbpayapp/tcbpayApp';
import { WhiteLabel } from './whitelabel/whiteLabel';
import { TcbpayIssuing } from './tcbpayIssuing/tcbpayIssuing';
import { ResourcesPrice } from './resourcesPricing/resourcesPrice';
import { AboutUs } from './aboutUs/aboutUs';
import { Header2 } from './components/Header2';
import { Partner } from './partner/partner';
import { ContactUs } from './contactUs/contactUs';
import { Teamall } from './ourteam/teamall';
import { AlertNotify } from './alertsNotify/alertNotify';
import { Ecommerce } from './ecommerce/ecommerce';
import { Retail } from './retail/retail';
import { AchProcessing } from './achProcessing/achProcessing';
import { Careers } from './careers/careers';
import { ItSolutions } from './itSolutions/itSolutions';
import { Security } from './security/security';
import { IntrigrationCreditCard } from './intrigrationCreditCard/intrigrationCreditCard';
import { Integration } from './integrations/integration';
import { IntegrationCreditANI } from './integrationCreditCardANI/integrationCreditANI';
import { IntegrationCreditApple } from './integrationCreditCardApplePay/integrationCreditApple';
import { IntegrationCreditAuthorize } from './integrationCreditCardAuthorize/integrationCreditAuthorize';
import { IntegrationCreditCapture } from './integrationCreditCardCapture/integrationCreditCapture';
import { IntegrationCreditVoid } from './integrationCreditCardVoid/integrationCreditVoid';
import { IntegrationCreditRefund } from './integrationCreditCardRefund/integrationCreditRefund';
import { IntegrationCreditReport } from './integrationCreditCardReport/integrationCreditReport';
import { IntegrationCreditSandbox } from './integrationCreditCardSandbox/integrationCreditSandbox';
import { IntegrationUnreferenced } from './integrationUnreferencedRefund/integrationUnreferenced';
import { IntrigrationVaultAddCard } from './intrigrationVaultAddCard/intrigrationVaultAddCard';
import { IntrigrationVaultAuthorize } from './intrigrationVaultAuthorize/intrigrationVaultAuthorize';
import { IntrigrationVaultCardDetails } from './intrigrationVaultCardDetails/intrigrationVaultCardDetails';
import { IntrigrationVaultDeleteCard } from './intrigrationVaultDeleteCard/intrigrationVaultDeleteCard';
import { IntrigrationVaultResponseCodes } from './intrigrationVaultResponseCodes/intrigrationVaultResponseCodes';
import { IntrigrationVaultSale } from './intrigrationVaultSale/intrigrationVaultSale';
import { IntrigrationVaultSandbox } from './intrigrationVaultSandbox/intrigrationVaultSandbox';
import { IntrigrationSubscriptionsAddPlan } from './intrigrationSubscriptionsAddPlan/intrigrationSubscriptionsAddPlan';
import { IntrigrationSubscriptionsAddSubscription } from './intrigrationSubscriptionsAddSubscription/intrigrationSubscriptionsAddSubscription';
import { IntrigrationSubscriptionsApplePaySubscription } from './intrigrationSubscriptionsApplePaySubscription/intrigrationSubscriptionsApplePaySubscription';
import { IntrigrationSubscriptionsDeletePlan } from './intrigrationSubscriptionsDeletePlan/intrigrationSubscriptionsDeletePlan';
import { IntrigrationSubscriptionsDeleteSubscription } from './intrigrationSubscriptionsDeleteSubscription/intrigrationSubscriptionsDeleteSubscription';
import { IntrigrationSubscriptionsPlanList } from './intrigrationSubscriptionsPlanList/intrigrationSubscriptionsPlanList';
import { IntrigrationSubscriptionsResponseCodes } from './intrigrationSubscriptionsResponseCodes/intrigrationSubscriptionsResponseCodes';
import { IntrigrationSubscriptionsSubscriptionList } from './intrigrationSubscriptionsSubscriptionList/intrigrationSubscriptionsSubscriptionList';
import { IntrigrationSubscriptionsUpdatePlan } from './intrigrationSubscriptionsUpdatePlan/intrigrationSubscriptionsUpdatePlan';
import { IntrigrationBankVerification } from './intrigrationBankVerification/intrigrationBankVerification';
import { IntrigrationBankVerificationResponseCodes } from './intrigrationBankVerificationResponseCodes/intrigrationBankVerificationResponseCodes';
import { IntegrationsShopify } from './integrationsShopify/integrationsShopify';
import { IntegrationsPrestaShop } from './integrationsPrestaShop/integrationsPrestaShop';
import { IntegrationsOpenCart } from './integrationsOpenCart/integrationsOpenCart';
import { IntegrationsWooCommerce } from './integrationsWooCommerce/integrationsWooCommerce';
import { IntegrationsMagento } from './integrationsMagento/integrationsMagento';
import { IntegrationsXCart } from './integrationsXCart/integrationsXCart';
import { IntegrationsOsCommerce } from './integrationsOsCommerce/integrationsOsCommerce';
import { ZenCart } from './zencart/zenCart';
import { IntegrationsKonnektiveCrm } from './integrationsKonnektiveCrm/integrationsKonnektiveCrm';
import { IntegrationsSticky } from './integrationsSticky/integrationsSticky';
import { WikiPortal } from './wikiPortal/wikiPortal';
import { WikiIssuing } from './wikiIssuing/wikiIssuing';
import { SystemStatus } from './systemstatus/systemStatus';
import { SystemstatusDetail } from './systemstatusDetails/systemstatusDetail';
import { Highrisk } from './highrisk/highrisk';
import { FaqLnk } from './faq/faqLnk';
import ScrollToTop from "./components/ScrollToTop";
import { FaqAboutSec } from './faqAboutTcb/faqAboutSec';
import { FaqAccountCreation } from './faqAccountCreation/faqAccountCreation';
import { FaqAccount } from './faqAccount/faqAccount';
import { FaqTcbPortal } from './faqTcbPortal/faqTcbPortal';
import { FaqIntegrations } from './faqIntegrations/faqIntegrations';
import { FaqTcbApp } from './faqTcbApp/faqTcbApp';
import { FaqWhitelabel } from './faqWhiteLabel/faqWhitelabel';
import { FaqEquipment } from './faqEquipment/faqEquipment';
import { FaqIssuing } from './faqTcbIssuing/faqIssuing';
import { Notfound } from './notfound/notfound';
import { Header3 } from './components/Header3';
import { Notfound403 } from './notfound403/notfound403';
import { Whitel404 } from './whitel404/whitel404';
import { Whitel403 } from './whitel403/whitel403';
import { LegalPayfacTermsAndConditions } from './legalPayfacTermsAndConditions/legalPayfacTermsAndConditions';
import { LegalTermsAndConditions } from './legalTermsAndConditions/legalTermsAndConditions';
import { LegalPrivacyPolicy } from './legalPrivacyPolicy/legalPrivacyPolicy';
import { LegalCookiesPolicy } from './legalCookiesPolicy/legalCookiesPolicy';
import { LegalCodeOfConduct } from './legalCodeOfConduct/legalCodeOfConduct';
import { LegalPersonalGuarantee } from './legalPersonalGuarantee/legalPersonalGuarantee';
import { LegalACHTermsAndConditions } from './legalACHTermsAndConditions/legalACHTermsAndConditions';



import { Footer1 } from './components/Footer1';
import { AchSaleAPI } from './achSale/achSaleAPI';
import { AchCreditAPI } from './achCredit/achCreditAPI';
import { AchrefundAPI } from './achRefund/achrefundAPI';
import { AchCountryAPI } from './achCountry/achCountryAPI';
import { AchCurrencyAPI } from './achCurrency/achCurrencyAPI';
import { AchResponseAPI } from './achResponse/achResponseAPI';
import { AchReportAPI } from './achReport/achReportAPI';
import { IssuingAccountFunding } from './issuingAccountFunding/issuingAccountFunding';
import { IssuingTransactionsApi } from './issuingTransactionsApi/issuingTransactionsApi';
import { IssuingAddUser } from './issuingAddUsers/issuingAddUser';
import { IssuingModifyUser } from './issuingModifyUser/issuingModifyUser';
import { IssuingModifyUserStatus } from './issuingModifyUserStatus/issuingModifyUserStatus';
import { IssuingListUsers } from './issuingListUsers/issuingListUsers';
import { IssuingAnalystUserAccessModify } from './issuingAnalystUserAccessModify/issuingAnalystUserAccessModify';
import { IssuingAnalystUserAccess } from './issuingAnalystUserAccess/issuingAnalystUserAccess';
import { IssuingAddCard } from './issuingAddCard/issuingAddCard';
import { IssuingModifyCardStatus } from './issuingModifyCardStatus/issuingModifyCardStatus';
import { IssuingCardReload } from './issuingCardReload/issuingCardReload';
import { IssuingCardDetail } from './issuingCardDetails/issuingCardDetail';
import { IssuingListCardsUser } from './issuingListCardsUser/issuingListCardsUser';
import { IssuingSpendingProfileList } from './issuingSpendingProfileList/issuingSpendingProfileList';
import { IssuingSpendingProfileAdd } from './issuingSpendingProfileAdd/issuingSpendingProfileAdd';
import { IssuingSpendingProfileModify } from './issuingSpendingProfileModify/issuingSpendingProfileModify';
import { IssuingSpendingProfileDelete } from './issuingSpendingProfileDelete/issuingSpendingProfileDelete';
import { SecurityPolicy } from './securityPolicy/securityPolicy';
import { Inscryption } from './inscryption/inscryption';




function App() {
  
  return (
    <Router>
      <div className="App">
        <ScrollToTop/>
        <Routes>
          <Route path="/portal" element={<Header />} />
          <Route path="/app" element={<Header />} />
          <Route path="/issuing" element={<Header />} />
          <Route path="/alert-notifications" element={<Header />} />
          <Route path="/white-label" element={<Header />} />
          <Route path="/pricing" element={<Header />} />
          <Route path="/about-us" element={<Header2 />} />
          <Route path="/partners" element={<Header2 />} />
          <Route path="/our-team" element={<Header2 />} />
          <Route path="/contact" element={<Header2 />} />
          <Route path="/ecommerce" element={<Header2 />} />
          <Route path="/retail" element={<Header2 />} />
          <Route path="/ach-processing" element={<Header2 />} />
          <Route path="/careers" element={<Header2 />} />
          <Route path="/it-solutions" element={<Header2 />} />
          <Route path="/security" element={<Header2 />} />
          <Route path="/integrations" element={<Header2 />} />
          <Route path="/integrations/credit-card-API/sale" element={<Header2 />} />
          <Route path="/integrations/credit-card-API/ani-API" element={<Header2 />} />
          <Route path="/integrations/credit-card-API/apple-pay" element={<Header2 />} />
          <Route path="/integrations/credit-card-API/authorize" element={<Header2 />} />
          <Route path='/integrations/credit-card-API/capture' element={<Header2/>} />
          <Route path='/integrations/credit-card-API/void' element={<Header2 />} />
          <Route path='/integrations/credit-card-API/refund' element={<Header2 />} />
          <Route path='/integrations/credit-card-API/report' element={<Header2 />} />
          <Route path='/integrations/credit-card-API/sandbox' element={<Header2 />} />
          <Route path='/integrations/credit-card-API/unreferenced-refund' element={<Header2 />} />
          <Route path='/integrations/vault-API/add-card' element={<Header2 />} />
          <Route path='/integrations/vault-API/authorize' element={<Header2 />} />
          <Route path='/integrations/vault-API/card-details' element={<Header2 />} />
          <Route path='/integrations/vault-API/delete-card' element={<Header2 />} />
          <Route path='/integrations/vault-API/response-codes' element={<Header2 />} />
          <Route path='/integrations/vault-API/sale' element={<Header2 />} />
          <Route path='/integrations/vault-API/sandbox' element={<Header2 />} />
          <Route path='/integrations/subscription-API/add-plan' element={<Header2 />} />
          <Route path='/integrations/subscription-API/add-subscription' element={<Header2 />} />
          <Route path='/integrations/subscription-API/apple-pay-subscription' element={<Header2 />} />
          <Route path='/integrations/subscription-API/delete-plan' element={<Header2 />} />
          <Route path='/integrations/subscription-API/delete-subscription' element={<Header2 />} />
          <Route path='/integrations/subscription-API/plan-list' element={<Header2 />} />
          <Route path='/integrations/subscription-API/response-codes' element={<Header2 />} />
          <Route path='/integrations/subscription-API/subscription-list' element={<Header2 />} />
          <Route path='/integrations/subscription-API/update-plan' element={<Header2 />} />
          <Route path='/integrations/bank-verification-API/bank-verification' element={<Header2 />} />
          <Route path='/integrations/bank-verification-API/response-codes' element={<Header2 />} />
          <Route path='/integrations/shopify' element={<Header2 />} />
          <Route path='/integrations/PrestaShop' element={<Header2 />} />
          <Route path='/integrations/OpenCart' element={<Header2 />} />
          <Route path='/integrations/WooCommerce' element={<Header2 />} />
          <Route path='/integrations/magento' element={<Header2 />} />
          <Route path='/integrations/XCart' element={<Header2 />} />
          <Route path='/integrations/osCommerce' element={<Header2 />} />
          <Route path='/integrations/ZenCart' element={<Header2 />} />
          <Route path='/integrations/konnektive-crm' element={<Header2 />} />
          <Route path='/integrations/sticky' element={<Header2 />} />
          <Route path='/integrations/ach/sale' element={<Header2 />} />
          <Route path='/integrations/ach/credit' element={<Header2 />} />
          <Route path='/integrations/ach/refund-API' element={<Header2 />} />
          <Route path='/integrations/ach/countrylist-API' element={<Header2 />} />
          <Route path='/integrations/ach/currenctlist-API' element={<Header2 />} />
          <Route path='/integrations/ach/response-API' element={<Header2 />} />
          <Route path='/integrations/ach/report-API' element={<Header2 />} />
          <Route path='/integrations/issuing/add-users' element={<Header2 />} />
          <Route path='/integrations/issuing/modify-user' element={<Header2 />} />
          <Route path='/integrations/issuing/list-users' element={<Header2 />} />
          <Route path='/integrations/issuing/analyst-user-access-modify' element={<Header2 />} />
          <Route path='/integrations/issuing/analyst-user-access' element={<Header2 />} />
          <Route path='/integrations/issuing/add-card' element={<Header2 />} />
          <Route path='/integrations/issuing/cards-status' element={<Header2 />} />
          <Route path='/integrations/issuing/card-reload' element={<Header2 />} />
          <Route path='/integrations/issuing/card-details' element={<Header2 />} />
          <Route path='/integrations/issuing/list-cards-user' element={<Header2 />} />
          <Route path='/integrations/issuing/spending-profile-list' element={<Header2 />} />
          <Route path='/integrations/issuing/spending-profile-add' element={<Header2 />} />
          <Route path='/integrations/issuing/spending-profile-modify' element={<Header2 />} />
          <Route path='/integrations/issuing/spending-profile-delete' element={<Header2 />} />
          <Route path='/integrations/issuing/modify-user-status' element={<Header2 />} />

          <Route path='/integrations/issuing/transactions' element={<Header2 />} />
          <Route path='/integrations/issuing/account-funding' element={<Header2 />} />
          <Route path='/wiki-portal' element={<Header2 />} />
          <Route path='/wiki-issuing' element={<Header2 />} />
          <Route path='/terms-and-conditions' element={<Header2 />} />
          <Route path='/privacy-policy' element={<Header2 />} />
          <Route path='/payfac-terms-and-conditions' element={<Header2 />} />
          <Route path='/code-of-conduct' element={<Header2 />} />
          <Route path='/cookies-policy' element={<Header2 />} />
          <Route path='/personal-guarantee' element={<Header2 />} />
          <Route path='/ach-terms-and-conditions' element={<Header2 />} />
          <Route path='/system-status' element={<Header2 />} />
          <Route path='/all-incidents' element={<Header2 />} />
          <Route path='/high-risk' element={<Header2 />} />
          <Route path='/faqabout' element={<Header2 />} />
          <Route path='/faq' element={<Header />} />
          <Route path="/faq/:seo_url" element={<Header2 />} />
          <Route path='/faqaccountcreation' element={<Header2 />} />
          <Route path='/faqaccount' element={<Header2 />} />
          <Route path='/faqtcbportal' element={<Header2 />} /> 
          <Route path='/faqintegrations' element={<Header2 />} /> 
          <Route path='/faqtcbapp' element={<Header2 />} /> 
          <Route path='/faqwhitelabel' element={<Header2 />} /> 
          <Route path='/faqequipment' element={<Header2 />} />
          <Route path='/faqissuing' element={<Header2 />} />
          <Route path="*" element={<Header3 />} />
          <Route path="/" element={<Header />} />
          <Route path="/404" element={<Header3 />} />
          <Route path="/403" element={<Header3 />} />
          <Route path="/w404" element={<Header3 />} />
          <Route path="/w403" element={<Header3 />} />
          <Route path="/security-policy" element={<Header2 />} />
          <Route path="/encryption" element={<Header2 />} />
        </Routes>

        <main>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="portal" element={<TcbpayPortal />} />
            <Route path="app" element={<Tcbpayapp />} />
            <Route path="white-label" element={<WhiteLabel />} />
            <Route path="issuing" element={<TcbpayIssuing />} />
            <Route path="pricing" element={<ResourcesPrice />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="partners" element={<Partner />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="our-team" element={<Teamall />} />
            <Route path="alert-notifications" element={<AlertNotify />} />
            <Route path="ecommerce" element={<Ecommerce />} />
            <Route path="retail" element={<Retail />} />
            <Route path="ach-processing" element={<AchProcessing />} />
            <Route path="careers" element={<Careers />} />
            <Route path="it-solutions" element={<ItSolutions />} />
            <Route path="security" element={<Security />} />
            <Route path="integrations" element={<Integration />} />
            <Route path="integrations/credit-card-API/sale" element={<IntrigrationCreditCard />} />
            <Route path="integrations/credit-card-API/ani-API" element={<IntegrationCreditANI />} />
            <Route path="integrations/credit-card-API/apple-pay" element={<IntegrationCreditApple />} />
            <Route path="integrations/credit-card-API/authorize" element={<IntegrationCreditAuthorize />} />
            <Route path='integrations/credit-card-API/capture' element={<IntegrationCreditCapture />} />
            <Route path='integrations/credit-card-API/void' element={<IntegrationCreditVoid />} />
            <Route path='integrations/credit-card-API/refund' element={<IntegrationCreditRefund />} />
            <Route path='integrations/credit-card-API/report' element={<IntegrationCreditReport />} />
            <Route path='integrations/credit-card-API/sandbox' element={<IntegrationCreditSandbox />} />
            <Route path='integrations/credit-card-API/unreferenced-refund' element={<IntegrationUnreferenced />} />
            <Route path='integrations/vault-API/add-card' element={<IntrigrationVaultAddCard />} />
            <Route path='integrations/vault-API/authorize' element={<IntrigrationVaultAuthorize />} />
            <Route path='integrations/vault-API/card-details' element={<IntrigrationVaultCardDetails />} />
            <Route path='integrations/vault-API/delete-card' element={<IntrigrationVaultDeleteCard />} />
            <Route path='integrations/vault-API/response-codes' element={<IntrigrationVaultResponseCodes />} />
            <Route path='integrations/vault-API/sale' element={<IntrigrationVaultSale />} />
            <Route path='integrations/vault-API/sandbox' element={<IntrigrationVaultSandbox />} />
            <Route path='integrations/subscription-API/add-plan' element={<IntrigrationSubscriptionsAddPlan />} />
            <Route path='integrations/subscription-API/add-subscription' element={<IntrigrationSubscriptionsAddSubscription />} />
            <Route path='integrations/subscription-API/apple-pay-subscription' element={<IntrigrationSubscriptionsApplePaySubscription />} />
            <Route path='integrations/subscription-API/delete-plan' element={<IntrigrationSubscriptionsDeletePlan />} />
            <Route path='integrations/subscription-API/delete-subscription' element={<IntrigrationSubscriptionsDeleteSubscription />} />
            <Route path='integrations/subscription-API/plan-list' element={<IntrigrationSubscriptionsPlanList />} />
            <Route path='integrations/subscription-API/response-codes' element={<IntrigrationSubscriptionsResponseCodes />} />
            <Route path='integrations/subscription-API/subscription-list' element={<IntrigrationSubscriptionsSubscriptionList />} />
            <Route path='integrations/subscription-API/update-plan' element={<IntrigrationSubscriptionsUpdatePlan />} />
            <Route path='integrations/bank-verification-API/bank-verification' element={<IntrigrationBankVerification />} />
            <Route path='integrations/bank-verification-API/response-codes' element={<IntrigrationBankVerificationResponseCodes />} />
            <Route path='integrations/ach/sale' element={<AchSaleAPI />} />
            <Route path='integrations/ach/credit' element={<AchCreditAPI />} />
            <Route path='integrations/ach/refund-API' element={<AchrefundAPI />} />
            <Route path='integrations/ach/countrylist-API' element={<AchCountryAPI />} />
            <Route path='integrations/ach/currenctlist-API' element={<AchCurrencyAPI />} />
            <Route path='integrations/ach/response-API' element={<AchResponseAPI />} />
            <Route path='integrations/ach/report-API' element={<AchReportAPI />} />
            <Route path='integrations/issuing/add-users' element={<IssuingAddUser />} />
            <Route path='integrations/issuing/modify-user' element={<IssuingModifyUser />} />
            <Route path='integrations/issuing/modify-user-status' element={<IssuingModifyUserStatus />} />
            <Route path='integrations/issuing/list-users' element={<IssuingListUsers />} />
            <Route path='integrations/issuing/analyst-user-access-modify' element={<IssuingAnalystUserAccessModify />} />
            <Route path='integrations/issuing/analyst-user-access' element={<IssuingAnalystUserAccess />} />
            <Route path='integrations/issuing/add-card' element={<IssuingAddCard />} />
            <Route path='integrations/issuing/cards-status' element={<IssuingModifyCardStatus />} />
            <Route path='integrations/issuing/card-reload' element={<IssuingCardReload />} />
            <Route path='integrations/issuing/card-details' element={<IssuingCardDetail />} />
            <Route path='integrations/issuing/list-cards-user' element={<IssuingListCardsUser />} />
            <Route path='integrations/issuing/spending-profile-list' element={<IssuingSpendingProfileList />} />
            <Route path='integrations/issuing/spending-profile-add' element={<IssuingSpendingProfileAdd />} />
            <Route path='integrations/issuing/spending-profile-modify' element={<IssuingSpendingProfileModify />} />
            <Route path='integrations/issuing/spending-profile-delete' element={<IssuingSpendingProfileDelete />} />

            <Route path='integrations/issuing/account-funding' element={<IssuingAccountFunding />} />
            <Route path='integrations/issuing/transactions' element={<IssuingTransactionsApi />} />
            
            <Route path='integrations/shopify' element={<IntegrationsShopify />} />
            <Route path='integrations/PrestaShop' element={<IntegrationsPrestaShop />} />
            <Route path='integrations/OpenCart' element={<IntegrationsOpenCart />} />
            <Route path='integrations/WooCommerce' element={<IntegrationsWooCommerce />} />
            <Route path='integrations/magento' element={<IntegrationsMagento />} />
            <Route path='integrations/XCart' element={<IntegrationsXCart />} />
            <Route path='integrations/osCommerce' element={<IntegrationsOsCommerce />} />
            <Route path='integrations/ZenCart' element={<ZenCart />} />
            <Route path='integrations/konnektive-crm' element={<IntegrationsKonnektiveCrm />} />
            <Route path='integrations/sticky' element={<IntegrationsSticky />} />
            <Route path='wiki-portal' element={<WikiPortal />} />
            <Route path='wiki-issuing' element={<WikiIssuing />} />
            <Route path='terms-and-conditions' element={<LegalTermsAndConditions />} />
            <Route path='privacy-policy' element={<LegalPrivacyPolicy />} />
            <Route path='payfac-terms-and-conditions' element={<LegalPayfacTermsAndConditions />} />
            <Route path='code-of-conduct' element={<LegalCodeOfConduct />} />
            <Route path='cookies-policy' element={<LegalCookiesPolicy />} />
            <Route path='personal-guarantee' element={<LegalPersonalGuarantee />} />
            <Route path='ach-terms-and-conditions' element={<LegalACHTermsAndConditions />} />
            <Route path='system-status' element={<SystemStatus />} />
            <Route path='all-incidents' element={<SystemstatusDetail />} />
            <Route path='high-risk' element={<Highrisk />} />
            <Route path='faq' element={<FaqLnk />} />
            <Route path='faqabout' element={<FaqAboutSec />} />
            <Route path="/faq/:seo_url" element={<FaqAboutSec />} />
            <Route path='faqaccountcreation' element={<FaqAccountCreation />} />
            <Route path='faqaccount' element={<FaqAccount />} />  
            <Route path='faqtcbportal' element={<FaqTcbPortal />} />
            <Route path='faqintegrations' element={<FaqIntegrations />} />
            <Route path='faqtcbapp' element={<FaqTcbApp />} />
            <Route path='faqwhitelabel' element={<FaqWhitelabel />} />
            <Route path='faqequipment' element={<FaqEquipment />} />
            <Route path='faqissuing' element={<FaqIssuing />} />
            <Route path='*' element={<Notfound />} />
            <Route path='404' element={<Notfound />} />
            <Route path='403' element={<Notfound403 />} />
            <Route path='w404' element={<Whitel404 />} />
            <Route path='w403' element={<Whitel403 />} />
            <Route path="security-policy" element={<SecurityPolicy />} />
            <Route path="encryption" element={<Inscryption />} />


          </Routes>
        </main>

        <Footer />
      </div>
    </Router>


    // <Router basename="/tcbpay-website">
    //   <div className="App">
    //     <ScrollToTop/>
    //       <Routes>
    //         <Route path="/portal" element={<Header />} />
    //         <Route path="/app" element={<Header />} />
    //         <Route path="/issuing" element={<Header />} />
    //         <Route path="/a-n" element={<Header />} />
    //         <Route path="/white-label" element={<Header />} />
    //         <Route path="/pricing" element={<Header />} />
    //         <Route path="/about-us" element={<Header2 />} />
    //         <Route path="/partners" element={<Header2 />} />
    //         <Route path="/our-team" element={<Header2 />} />
    //         <Route path="/contact" element={<Header2 />} />
    //         <Route path="/ecommerce" element={<Header2 />} />
    //         <Route path="/retail" element={<Header2 />} />
    //         <Route path="/ach-processing" element={<Header2 />} />
    //         <Route path="/careers" element={<Header2 />} />
    //         <Route path="/it-solutions" element={<Header2 />} />
    //         <Route path="/security" element={<Header2 />} />
    //         <Route path="/integrations" element={<Header2 />} />
    //         <Route path="/integrations/credit-card-API/sale" element={<Header2 />} />
    //         <Route path="/integrations/credit-card-API/ani-API" element={<Header2 />} />
    //         <Route path="/integrations/credit-card-API/apple-pay" element={<Header2 />} />
    //         <Route path="/integrations/credit-card-API/authorize" element={<Header2 />} />
    //         <Route path='/integrations/credit-card-API/capture' element={<Header2/>} />
    //         <Route path='/integrations/credit-card-API/void' element={<Header2 />} />
    //         <Route path='/integrations/credit-card-API/refund' element={<Header2 />} />
    //         <Route path='/integrations/credit-card-API/report' element={<Header2 />} />
    //         <Route path='/integrations/credit-card-API/sandbox' element={<Header2 />} />
    //         <Route path='/integrations/credit-card-API/unreferenced-refund' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/add-card' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/authorize' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/card-details' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/delete-card' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/response-codes' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/sale' element={<Header2 />} />
    //         <Route path='/integrations/vault-API/sandbox' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/add-plan' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/add-subscription' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/apple-pay-subscription' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/delete-plan' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/delete-subscription' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/plan-list' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/response-codes' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/subscription-list' element={<Header2 />} />
    //         <Route path='/integrations/subscription-API/update-plan' element={<Header2 />} />
    //         <Route path='/integrations/bank-verification-API/bank-verification' element={<Header2 />} />
    //         <Route path='/integrations/bank-verification-API/response-codes' element={<Header2 />} />
    //         <Route path='/integrations/shopify' element={<Header2 />} />
    //         <Route path='/integrations/PrestaShop' element={<Header2 />} />
    //         <Route path='/integrations/OpenCart' element={<Header2 />} />
    //         <Route path='/integrations/WooCommerce' element={<Header2 />} />
    //         <Route path='/integrations/magento' element={<Header2 />} />
    //         <Route path='/integrations/XCart' element={<Header2 />} />
    //         <Route path='/integrations/osCommerce' element={<Header2 />} />
    //         <Route path='/integrations/ZenCart' element={<Header2 />} />
    //         <Route path='/integrations/konnektive-crm' element={<Header2 />} />
    //         <Route path='/integrations/sticky' element={<Header2 />} />
    //         <Route path='/integrations/ach/sale' element={<Header2 />} />
    //         <Route path='/integrations/ach/credit' element={<Header2 />} />
    //         <Route path='/integrations/ach/refund-API' element={<Header2 />} />
    //         <Route path='/integrations/ach/countrylist-API' element={<Header2 />} />
    //         <Route path='/integrations/ach/currenctlist-API' element={<Header2 />} />
    //         <Route path='/integrations/ach/response-API' element={<Header2 />} />
    //         <Route path='/integrations/ach/report-API' element={<Header2 />} />
    //         <Route path='/integrations/issuing/add-users' element={<Header2 />} />
    //         <Route path='/integrations/issuing/modify-user' element={<Header2 />} />
    //         <Route path='/integrations/issuing/list-users' element={<Header2 />} />
    //         <Route path='/integrations/issuing/analyst-user-access-modify' element={<Header2 />} />
    //         <Route path='/integrations/issuing/analyst-user-access' element={<Header2 />} />
    //         <Route path='/integrations/issuing/add-card' element={<Header2 />} />
    //         <Route path='/integrations/issuing/cards-status' element={<Header2 />} />
    //         <Route path='/integrations/issuing/card-reload' element={<Header2 />} />
    //         <Route path='/integrations/issuing/card-details' element={<Header2 />} />
    //         <Route path='/integrations/issuing/list-cards-user' element={<Header2 />} />
    //         <Route path='/integrations/issuing/spending-profile-list' element={<Header2 />} />
    //         <Route path='/integrations/issuing/spending-profile-add' element={<Header2 />} />
    //         <Route path='/integrations/issuing/spending-profile-modify' element={<Header2 />} />
    //         <Route path='/integrations/issuing/spending-profile-delete' element={<Header2 />} />



    //         <Route path='/integrations/transactions-API/transactions' element={<Header2 />} />
    //         <Route path='/integrations/account-funding-API/account-funding' element={<Header2 />} />



    //         <Route path='/wiki-portal' element={<Header2 />} />
    //         <Route path='/wiki-issuing' element={<Header2 />} />
    //         <Route path='/terms-and-conditions' element={<Header2 />} />
    //         <Route path='/privacy-policy' element={<Header2 />} />
    //         <Route path='/payfac-terms-and-conditions' element={<Header2 />} />
    //         <Route path='/code-of-conduct' element={<Header2 />} />
    //         <Route path='/cookies-policy' element={<Header2 />} />
    //         <Route path='/personal-guarantee' element={<Header2 />} />
    //         <Route path='/ach-terms-and-conditions' element={<Header2 />} />
    //         <Route path='/system-status' element={<Header2 />} />
    //         <Route path='/all-incidents' element={<Header2 />} />
    //         <Route path='/high-risk' element={<Header2 />} />
    //         <Route path='/faqabout' element={<Header2 />} />
    //         <Route path='/faq' element={<Header />} />
    //         <Route path="/faq/:seo_url" element={<Header2 />} />
    //         <Route path='/faqaccountcreation' element={<Header2 />} />
    //         <Route path='/faqaccount' element={<Header2 />} />
    //         <Route path='/faqtcbportal' element={<Header2 />} /> 
    //         <Route path='/faqintegrations' element={<Header2 />} /> 
    //         <Route path='/faqtcbapp' element={<Header2 />} /> 
    //         <Route path='/faqwhitelabel' element={<Header2 />} /> 
    //         <Route path='/faqequipment' element={<Header2 />} />
    //         <Route path='/faqissuing' element={<Header2 />} />
    //         <Route path="*" element={<Header3 />} />
    //         <Route path="/" element={<Header />} />
    //         <Route path="/404" element={<Header3 />} />
    //         <Route path="/403" element={<Header3 />} />
    //         <Route path="/w404" element={<Header3 />} />
    //         <Route path="/w403" element={<Header3 />} />
    //       </Routes>

    //       <main>
    //       <Routes>
    //         <Route path="" element={<Home />} />
    //         <Route path="portal" element={<TcbpayPortal />} />
    //         <Route path="app" element={<Tcbpayapp />} />
    //         <Route path="white-label" element={<WhiteLabel />} />
    //         <Route path="issuing" element={<TcbpayIssuing />} />
    //         <Route path="pricing" element={<ResourcesPrice />} />
    //         <Route path="about-us" element={<AboutUs />} />
    //         <Route path="partners" element={<Partner />} />
    //         <Route path="contact" element={<ContactUs />} />
    //         <Route path="our-team" element={<Teamall />} />
    //         <Route path="a-n" element={<AlertNotify />} />
    //         <Route path="ecommerce" element={<Ecommerce />} />
    //         <Route path="retail" element={<Retail />} />
    //         <Route path="ach-processing" element={<AchProcessing />} />
    //         <Route path="careers" element={<Careers />} />
    //         <Route path="it-solutions" element={<ItSolutions />} />
    //         <Route path="security" element={<Security />} />
    //         <Route path="integrations" element={<Integration />} />
    //         <Route path="integrations/credit-card-API/sale" element={<IntrigrationCreditCard />} />
    //         <Route path="integrations/credit-card-API/ani-API" element={<IntegrationCreditANI />} />
    //         <Route path="integrations/credit-card-API/apple-pay" element={<IntegrationCreditApple />} />
    //         <Route path="integrations/credit-card-API/authorize" element={<IntegrationCreditAuthorize />} />
    //         <Route path='integrations/credit-card-API/capture' element={<IntegrationCreditCapture />} />
    //         <Route path='integrations/credit-card-API/void' element={<IntegrationCreditVoid />} />
    //         <Route path='integrations/credit-card-API/refund' element={<IntegrationCreditRefund />} />
    //         <Route path='integrations/credit-card-API/report' element={<IntegrationCreditReport />} />
    //         <Route path='integrations/credit-card-API/sandbox' element={<IntegrationCreditSandbox />} />
    //         <Route path='integrations/credit-card-API/unreferenced-refund' element={<IntegrationUnreferenced />} />
    //         <Route path='integrations/vault-API/add-card' element={<IntrigrationVaultAddCard />} />
    //         <Route path='integrations/vault-API/authorize' element={<IntrigrationVaultAuthorize />} />
    //         <Route path='integrations/vault-API/card-details' element={<IntrigrationVaultCardDetails />} />
    //         <Route path='integrations/vault-API/delete-card' element={<IntrigrationVaultDeleteCard />} />
    //         <Route path='integrations/vault-API/response-codes' element={<IntrigrationVaultResponseCodes />} />
    //         <Route path='integrations/vault-API/sale' element={<IntrigrationVaultSale />} />
    //         <Route path='integrations/vault-API/sandbox' element={<IntrigrationVaultSandbox />} />
    //         <Route path='integrations/subscription-API/add-plan' element={<IntrigrationSubscriptionsAddPlan />} />
    //         <Route path='integrations/subscription-API/add-subscription' element={<IntrigrationSubscriptionsAddSubscription />} />
    //         <Route path='integrations/subscription-API/apple-pay-subscription' element={<IntrigrationSubscriptionsApplePaySubscription />} />
    //         <Route path='integrations/subscription-API/delete-plan' element={<IntrigrationSubscriptionsDeletePlan />} />
    //         <Route path='integrations/subscription-API/delete-subscription' element={<IntrigrationSubscriptionsDeleteSubscription />} />
    //         <Route path='integrations/subscription-API/plan-list' element={<IntrigrationSubscriptionsPlanList />} />
    //         <Route path='integrations/subscription-API/response-codes' element={<IntrigrationSubscriptionsResponseCodes />} />
    //         <Route path='integrations/subscription-API/subscription-list' element={<IntrigrationSubscriptionsSubscriptionList />} />
    //         <Route path='integrations/subscription-API/update-plan' element={<IntrigrationSubscriptionsUpdatePlan />} />
    //         <Route path='integrations/bank-verification-API/bank-verification' element={<IntrigrationBankVerification />} />
    //         <Route path='integrations/bank-verification-API/response-codes' element={<IntrigrationBankVerificationResponseCodes />} />
    //         <Route path='integrations/ach/sale' element={<AchSaleAPI />} />
    //         <Route path='integrations/ach/credit' element={<AchCreditAPI />} />
    //         <Route path='integrations/ach/refund-API' element={<AchrefundAPI />} />
    //         <Route path='integrations/ach/countrylist-API' element={<AchCountryAPI />} />
    //         <Route path='integrations/ach/currenctlist-API' element={<AchCurrencyAPI />} />
    //         <Route path='integrations/ach/response-API' element={<AchResponseAPI />} />
    //         <Route path='integrations/ach/report-API' element={<AchReportAPI />} />
    //         <Route path='integrations/issuing/add-users' element={<IssuingAddUser />} />
    //         <Route path='integrations/issuing/modify-user' element={<IssuingModifyUser />} />
    //         <Route path='integrations/issuing/modify-user-status' element={<IssuingModifyUserStatus />} />
    //         <Route path='integrations/issuing/list-users' element={<IssuingListUsers />} />
    //         <Route path='integrations/issuing/analyst-user-access-modify' element={<IssuingAnalystUserAccessModify />} />
    //         <Route path='integrations/issuing/analyst-user-access' element={<IssuingAnalystUserAccess />} />
    //         <Route path='integrations/issuing/add-card' element={<IssuingAddCard />} />
    //         <Route path='integrations/issuing/cards-status' element={<IssuingModifyCardStatus />} />
    //         <Route path='integrations/issuing/card-reload' element={<IssuingCardReload />} />
    //         <Route path='integrations/issuing/card-details' element={<IssuingCardDetail />} />
    //         <Route path='integrations/issuing/list-cards-user' element={<IssuingListCardsUser />} />
    //         <Route path='integrations/issuing/spending-profile-list' element={<IssuingSpendingProfileList />} />
    //         <Route path='integrations/issuing/spending-profile-add' element={<IssuingSpendingProfileAdd />} />
    //         <Route path='integrations/issuing/spending-profile-modify' element={<IssuingSpendingProfileModify />} />
    //         <Route path='integrations/issuing/spending-profile-delete' element={<IssuingSpendingProfileDelete />} />
            
            

    //         <Route path='integrations/account-funding-API/account-funding' element={<IssuingAccountFunding />} />
    //         <Route path='integrations/transactions-API/transactions' element={<IssuingTransactionsApi />} />
            
    //         <Route path='integrations/shopify' element={<IntegrationsShopify />} />
    //         <Route path='integrations/PrestaShop' element={<IntegrationsPrestaShop />} />
    //         <Route path='integrations/OpenCart' element={<IntegrationsOpenCart />} />
    //         <Route path='integrations/WooCommerce' element={<IntegrationsWooCommerce />} />
    //         <Route path='integrations/magento' element={<IntegrationsMagento />} />
    //         <Route path='integrations/XCart' element={<IntegrationsXCart />} />
    //         <Route path='integrations/osCommerce' element={<IntegrationsOsCommerce />} />
    //         <Route path='integrations/ZenCart' element={<ZenCart />} />
    //         <Route path='integrations/konnektive-crm' element={<IntegrationsKonnektiveCrm />} />
    //         <Route path='integrations/sticky' element={<IntegrationsSticky />} />
    //         <Route path='wiki-portal' element={<WikiPortal />} />
    //         <Route path='wiki-issuing' element={<WikiIssuing />} />
    //         <Route path='terms-and-conditions' element={<LegalTermsAndConditions />} />
    //         <Route path='privacy-policy' element={<LegalPrivacyPolicy />} />
    //         <Route path='payfac-terms-and-conditions' element={<LegalPayfacTermsAndConditions />} />
    //         <Route path='code-of-conduct' element={<LegalCodeOfConduct />} />
    //         <Route path='cookies-policy' element={<LegalCookiesPolicy />} />
    //         <Route path='personal-guarantee' element={<LegalPersonalGuarantee />} />
    //         <Route path='ach-terms-and-conditions' element={<LegalACHTermsAndConditions />} />
    //         <Route path='system-status' element={<SystemStatus />} />
    //         <Route path='all-incidents' element={<SystemstatusDetail />} />
    //         <Route path='high-risk' element={<Highrisk />} />
    //         <Route path='faq' element={<FaqLnk />} />
    //         <Route path='faqabout' element={<FaqAboutSec />} />
    //         <Route path="/faq/:seo_url" element={<FaqAboutSec />} />
    //         <Route path='faqaccountcreation' element={<FaqAccountCreation />} />
    //         <Route path='faqaccount' element={<FaqAccount />} />  
    //         <Route path='faqtcbportal' element={<FaqTcbPortal />} />
    //         <Route path='faqintegrations' element={<FaqIntegrations />} />
    //         <Route path='faqtcbapp' element={<FaqTcbApp />} />
    //         <Route path='faqwhitelabel' element={<FaqWhitelabel />} />
    //         <Route path='faqequipment' element={<FaqEquipment />} />
    //         <Route path='faqissuing' element={<FaqIssuing />} />
    //         <Route path='*' element={<Notfound />} />
    //         <Route path='404' element={<Notfound />} />
    //         <Route path='403' element={<Notfound403 />} />
    //         <Route path='w404' element={<Whitel404 />} />
    //         <Route path='w403' element={<Whitel403 />} />

    //         </Routes>
    //       </main>

    //       <Footer />
    //   </div>
    // </Router>
  );
}

export default App;

