import React from 'react'
import Iframe from 'react-iframe'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const WikiPortalSec = () => {
    return (
        <>
        <section className='wiki-iframebox'>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
            <Container>
                <Row>
                    <Col xs lg="12" className='wiki-iframe'>
                        <Iframe url="https://wiki.tcbpay.com/app/page/1xAoDlosTZwdEtdo0puWzRnhV4j-dUmaR?p=1xAoDlosTZwdEtdo0puWzRnhV4j-dUmaR"
                            width="100%"
                            height="720px"
                            id=""
                            className=""
                            display="block"
                            position="relative" />
                    </Col>
                </Row>
            </Container>
        </section>
    
    
    </>
  )
}
