import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {

  const scrollToMiddle = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
    <>
    <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-5'>
            <span><img src='assets/imgs/label-icon.png' alt=''/> TCB PAY WHITE LABEL</span>
            <h1>A platform that looks like you, and only you</h1>
            <p>Looking to customize your own payment processing tools?</p>
            <p>We can work with you to provide white-labeled products and services for our partners and agents to use at their convenience.</p>
            {/* <Link to={`${config.siteBaseUrl}#show-calendly`} className="get-btn1">Get Started</Link> */}
            <a onClick={scrollToMiddle} className="get-btn1" style={{cursor:'pointer'}}>Get Started</a>
            <Link to={`${config.siteBaseUrl}/contact`} className="contact-btn">Contact Us</Link>
            </div>
            <div className='text-left col-md-1'></div>
            <div className='col-md-6'>
            <img src='assets/imgs/white-labelbanner.svg' alt='' className='banner2'/>
            </div>
          </Row>    
        </Container>
    </section>
    </>
  )
}
