import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span><img src='assets/imgs/r-icon.svg' alt='' />RETAIL</span>
            <h1>Streamline operations with our payment solutions</h1>
            </Col>
            <Col xs lg="7" className='text-left'>
            <p>TCB Pay is your trusted partner for optimizing retail operations with our advanced payment solutions. From seamless transactions to enhanced customer experiences, we’re dedicated to helping your business thrive.</p>
            {/* <Link className="get-btn2" to={`${config.siteBaseUrl}#show-calendly`}>Get Started</Link> */}
            <Link to="https://portal.tcbpay.com/app/signup" target="_blank" className="get-btn2">Get Started</Link>
            <Link className="contact-btn1" to={`${config.siteBaseUrl}/contact`}>Contact Us</Link>
            </Col>
          </Row>  
        </Container>
    </section>
    </>
  )
}
