import React, { useRef } from 'react'
import { Faq } from './faq'
import { Banner } from './banner'
import { Issuingcard } from './issuingcard'
import { Calender } from './calender'

export const TcbpayIssuing = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
    <Banner scrollRef={scrollRef} />
    <Issuingcard />
    <Calender ref={scrollRef} />
    <Faq />
    </>
  )
}
