import React from 'react'
import config from '../Config';

export const SecuritySec = () => {
  return (
    <>
    <section className='security-bx '>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='security-list '>
                        <ul>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon1.svg' alt='' /> <span>AML</span></div>
                              <div className='security-list-rgt'><p>The Anti-Money Laundering (AML) Policy is a set of rules and procedures aimed at preventing criminals from disguising illegal funds as legitimate income.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon2.svg' alt='' className='icn27' style={{width:'27px'}}/> <span>ANI</span></div>
                              <div className='security-list-rgt'><p>Visa Account Name Inquiry (ANI) helps Visa partners, such as online merchants, verify that a cardholder’s name matches the name held by their issuing bank. This adds an extra security check during card onboarding and pre-transaction processes, reducing the risk of fraud in Card Not Present (CNP) transactions and push/pull payments.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon3.svg' alt='' /> <span>AVS</span></div>
                              <div className='security-list-rgt'><p>AVS (Address Verification System) verifies that the billing address provided by the cardholder matches the address on file with the credit card issuer. This helps reduce the risk of fraudulent transactions by confirming the cardholder’s identity.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon4.svg' alt='' /> <span>Card ID Verification</span></div>
                              <div className='security-list-rgt'><p>Card ID Verification, also known as Card Verification Value (CVV) or Card Security Code (CSC), is a security feature used in credit and debit card transactions. It consists of a three or four-digit code printed on the card, separate from the card number.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon5.svg' alt='' /> <span>Change Logs</span></div>
                              <div className='security-list-rgt'><p>Our Change Logs feature allows you to keep track of all the changes made to your account, including what was changed, when it happened, and who made the change.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon6.svg' alt='' /> <span>3D Secure</span></div>
                              <div className='security-list-rgt'><p>3D Secure offers an added layer of security for online credit and debit card transactions, validating the online shopper is the cardholder; adding another authentication step for online payments. Merchants use 3D Secure to achieve higher coverage against fraud losses, potentially avoiding liability for fraudulent transactions even if the transaction was properly authorized.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon7.svg' alt='' className='icn32' style={{width:'32px'}} /> <span>Ecommerce Security</span></div>
                              <div className='security-list-rgt'><p>TCB Pay is dedicated to a high level of security so our merchants can process your eCommerce transactions with ease. The TCB Pay online payment system is secure and meets all required regulations and codes.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon8.svg' alt='' /> <span>Encrypted Transactions</span></div>
                              <div className='security-list-rgt'><p>Encrypted transactions refer to financial or data exchanges that are secured using encryption techniques to protect sensitive information from unauthorized access or interception. Encryption involves converting data into a coded format using cryptographic algorithms, making it unreadable to anyone without the appropriate decryption key.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon9.svg' alt='' className='icn32' style={{width:'32px'}} /> <span>2FA</span></div>
                              <div className='security-list-rgt'><p>By requiring both your password and the one-time code sent to your email or phone, 2FA (2-Factor Authentication) adds an extra layer of security to your account, reducing the risk of unauthorized access, even if your password is compromised.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon10.svg' alt='' /> <span>Fraud</span></div>
                              <div className='security-list-rgt'><p>If you have cause to be suspicious of potential fraud, call TCB Pay Risk Department immediately at +1 (866) 444-8585. We will ensure the proper measures are taken to resolve your issue or answer your concern.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon11.svg' alt='' /> <span>IP Monitoring</span></div>
                              <div className='security-list-rgt'><p>IP monitoring involves tracking and analyzing the internet protocol (IP) addresses accessing your account. It’s used for security to detect and manage network traffic, identify threats, and maintain system integrity by monitoring devices connecting your account, their activity, and location.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon12.svg' alt='' /> <span>KYC</span></div>
                              <div className='security-list-rgt'><p>KYC stands for “Know Your Customer.” It’s a process used by businesses, especially financial institutions, to verify the identity of their customers before providing services. KYC involves collecting and verifying information about customers’ identities, addresses, and other relevant details to ensure compliance with regulations and to mitigate the risk of fraud, money laundering, and other illicit activities.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon13.svg' alt='' className='icn27' style={{width:'27px'}} /> <span>Notifications & Alerts</span></div>
                              <div className='security-list-rgt'><p>You have the option to receive notifications for any suspicious activity on your account, such as an unusual login or transaction.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon14.svg' alt='' className='icn32' style={{width:'32px'}}/> <span>PCI Compliance</span></div>
                              <div className='security-list-rgt'><p>The Payment Card Industry (PCI) council, includes the card brands American Express, VISA, and Mastercard, standardized technical and security compliance programs requiring Service Providers, Banks and high-volume Merchants to follow strict security guidelines, including, but not limited to:</p>
                              <ul>
                                <li>● Protecting cardholder data</li>
                                <li>● Maintaining a vulnerability management program </li>
                                <li>● Implementing strong access control measures</li>
                                <li>● Maintaining an information security policy </li>
                              </ul>
                              <p>In accordance with these guidelines and with a third-party security assessment, TCB Pay has been issued a certificate of PCI Compliance toward the requirements of the Payment Card Industry (PCI) Data Security Standards (DSS) validation methods.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon15.svg' alt='' className='icn27' style={{width:'27px'}} /> <span>Risk Rules Automation</span></div>
                              <div className='security-list-rgt'><p>Risk Rules Automation refers to the use of automated systems to enforce predefined rules and criteria for assessing and managing risk. These rules are typically based on factors such as transaction volume, customer behavior, geographic location, or other indicators of potential risk.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon16.svg' alt='' /> <span>Secure Tokenization</span></div>
                              <div className='security-list-rgt'><p>Secure Tokenization lets merchants facilitate PCI Compliance by no longer storing customers’ sensitive card details; generating random unique tokens for each cardholder, and for each credit payment method. The TCB Pay Secure Tokenization Program promotes both convenience and security – customers no longer need to re-enter their information for each purchase and merchants never store any sensitive payment card information.</p></div>
                            </li>
                            <li className='d-flex'>
                              <div className='security-list-lft'><img src='assets/imgs/s-icon17.svg' alt='' /> <span>Vault</span></div>
                              <div className='security-list-rgt'><p>Our vault provides a secure storage for your  customers’ information. You no longer have to worry about repeatedly entering the details of a returning customer while keeping their information safe.</p></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
    </section>
    </>
  )
}
