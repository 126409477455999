import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='bghgt'/>
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span><img src={`${config.siteBaseUrl}/assets/imgs/in-icon.svg`} alt='' />INTEGRATIONS</span>
            <h2>Bringing business and technology together</h2>
            </Col>
            <Col xs lg="7" className='text-left'>
            <p>TCB Pay offers plenty of integrations so you can give your customers the best shopping experience.</p>
            <Link className="get-btn2 mt-10" to={`${config.siteBaseUrl}#show-calendly`}>Get Started</Link>
            <Link className="contact-btn1 mt-10" to={`${config.siteBaseUrl}/contact`}>Contact Us</Link>
            </Col>
          </Row>  
        </Container>
    </section>
    </>
  )
}
