import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { FaqLeftSec } from '../components/faqLeftSec';
import ScrollToTop from "react-scroll-to-top";

export const FaqTcbAppSec = () => {
  return (
    <>
    <section className="credit-api-main">
    <img src="{`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`}" alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <FaqLeftSec />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-8">
                  <div className="creadit-api-upper-wrapper">
                  
                    <h2 className='faq-rgtsectitle'>TCB Pay App</h2>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>What is the TCB Pay App?</div>
                        <div className='qusans'>
                            <p>The TCB Pay App is a convenient extension of the TCB Pay Portal, and has all of its essential features, enabling you to manage your business in any situation.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I connect my card reader to my phone?</div>
                        <div className='qusans'>
                            <p>When you open up the TCB Pay mobile app, the app will prompt you to connect to the BBPOS device. Make sure the device is turned on and the light is blinking blue. The app will then discover the BBPOS device and you will be able to hit the connect button. </p>
                            <p>IMPORTANT: Do not connect the BBPOS device to your Bluetooth outside of using the app, this will prohibit you from connecting the terminal within the TCB Pay app.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>Do I need to charge my card reader before using it?</div>
                        <div className='qusans'>
                            <p>Your BBPOS device has a long-lasting battery and can support 800 EMV transactions, 5000 magnetic card swipe transactions, or 1000 EMV contactless transactions. We will charge it for you before you receive it, but we encourage you to charge it before your first use.</p>
                        </div>
                    </div>
                    <div className='faq-qusbx'>
                        <div className='qusheading'>How do I download the TCB Pay mobile app?</div>
                        <div className='qusans'>
                            <p>Depending on your device, you can go to the <Link to="https://play.google.com/store/apps/details?id=com.tcbpay&pli=1" target="blank">Google Play</Link> or <Link to="https://apps.apple.com/us/app/tcb-pay/id1490827069?ls=1" target='blank'>iOS App Store</Link>  and search for “TCB Pay”. From there, you will be able to download the app to your device. </p>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
      </div>

      <ScrollToTop smooth />
    </section>
    </>
  )
}
