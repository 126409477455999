import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';


export const SecurityPolicySec = () => {
    return (
        <>
            <section className='legal-bx tc-pp-cc-cp-pg'>
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="d-flex align-items-start">
                                <div className="privacy_policy-sec p-0">
                                        <div className="title-sec pb-20">
                                            <h2 className='text-left text-bold'><span>Security-Policy-TCBPAY LTD</span></h2>
                                        </div>

                                        <div id="privacy_p" className="text-left doc-content">
                                            <div className="pp-content">
                                                <div className="text2">
                                                    <h4>Introduction</h4>
                                                    <p>
                                                        At <b>TCB PAY LTD, Security</b> is a top priority to ensure <b>data confidentiality, integrity, and availability.</b> This policy defines <b>security controls, secure coding practices, infrastructure security standards, and user responsibilities</b> to mitigate cyber risks.
                                                    </p>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>This document covers:</p>
                                                    <ul>
                                                        <li>Secure coding standards for application security.</li>
                                                        <li>Infrastructure security best practices.</li>
                                                        <li>User behaviour guidelines to prevent unauthorized operations.</li>
                                                        <li>Incident handling & compliance measures.</li>

                                                    </ul>
                                                </div>
                                                <div className="pp-sub-head">
                                                    <h5>Scope</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>This policy applies to:</p>
                                                    <ul>
                                                        <li><b>Application Developers & Engineering Teams –</b> Responsible for writing and deploying secure code.</li>
                                                        <li><b>Infrastructure & Security Teams –</b> Maintaining secure networks, servers, and cloud services.</li>
                                                        <li><b>All Employees & Users –</b> Preventing unauthorized activities and following security best practices.</li>

                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>Application Security: Secure Coding Standards</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>Secure Coding Practices</p>
                                                    <ul>
                                                        <li><b>Follow Secure coding standard documents and attend trainings.</b></li>
                                                        <li><b>Use Input Validation & Output Encoding</b> to prevent <b>SQL Injection, XSS, CSRF attacks.</b></li>
                                                        <li>Enforce Least Privilege for API & Database Access (No Hardcoded Credentials).</li>
                                                        <li>Apply Strong Authentication Mechanisms (MFA- Authenticator App).</li>
                                                        <li>Enable Secure Session Management (Short expiration times, session encryption).</li>
                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>Code Deployment & CI/CD Security</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <ul>
                                                        <li>Infrastructure-as-Code (IaC) Best Practices to prevent misconfigurations.</li>
                                                        <li>Secure API Design (Rate-limiting, encrypted tokens).</li>
                                                        <li>Implement Web Application Firewalls (WAF) for API protection.</li>
                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>Infrastructure Security Standards</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>Network & Cloud Security Controls</p>
                                                    <ul>
                                                        <li>Firewalls & Intrusion Detection (IDS/IPS) actively monitor malicious traffic.</li>
                                                        <li>AWS Security Groups & IAM Policies enforce least privilege & Zero Trust principles.</li>
                                                        <li>Data Encryption: AES-256 for databases, TLS 1.2/1.3 for network security.</li>
                                                        <li>Automated Patch Management to eliminate security vulnerabilities.</li>
                                                        <li>Follow Network policy in details</li>
                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>Server Hardening & Access Control</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <ul>
                                                        <li>Disable Unnecessary Services & Ports to reduce the attack surface.</li>
                                                        <li>Use Role-Based Access Control (RBAC) for Admin & System Access.</li>
                                                        <li>Log All Access & Monitor User Activities through Manifest-IT.</li>
                                                        <li>Implement Bastion Hosts for Secure Remote Access.</li>   
                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>User Security: Preventing Unauthorized Operations</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>User Access & Behaviour Controls</p>
                                                    <ul>
                                                        <li>Restrict administrative privileges to authorized personnel only.</li>
                                                        <li>Mandate Multi-Factor Authentication (MFA) for all privileged accounts.</li>
                                                        <li>Regularly audit user access logs & disable inactive accounts.</li>
                                                        <li>Enforce a strict Clean Desk & Data Handling Policy.</li>
                                                        <li>Role base access to be provided.</li>
                                                        <li>Only minimal level of access is provided.</li>
                                                        <li>As HR off-boarding process review application/infra access in details and document the same on monday.com.</li>  
                                                    </ul>
                                                </div>

                                                <div className="pp-sub-head">
                                                    <h5>Incident Response & Compliance Measures</h5>
                                                </div>
                                                <div className="pp-hi">
                                                    <p><b>Security Incident Handling</b></p>
                                                    <p>All security incidents must be reported immediately to the IT Security Team.
                                                    Incident Response Team (IRT) follows predefined containment & remediation protocols.</p>
                                                    <p>Security Forensics & Log Analysis for Root Cause Investigation.</p>
                                                </div>

                                                <div className="pp-sub-head mt-30">
                                                    <h3>Policy Review & Update Frequency</h3>
                                                </div>
                                                <div className="pp-hi">
                                                    <p><b>This Information Security Policy is reviewed and updated quarterly</b> to align with evolving cybersecurity threats.</p>
                                                </div>

                                                <div className="pp-sub-head mt-30">
                                                    <h3>Conclusion</h3>
                                                </div>
                                                <div className="pp-hi">
                                                    <p>By implementing this Information Security Policy, TCB PAY LTD ensures:</p>
                                                    <ul>
                                                        <li>Secure software development practices to prevent vulnerabilities.</li>
                                                        <li>Robust infrastructure security & access control for cloud environments.</li>
                                                        <li>Strict user behaviour guidelines to prevent insider threats.</li>
                                                        <li>Proactive monitoring, incident handling, and regulatory compliance.</li> 
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
