import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Ourteam = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm ourtrm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span><img src='assets/imgs/tm-icon.svg' alt='' />TEAM</span>
            <h1>Meet the TCB Pay Team</h1>
            </div>
            <div className='text-left col-md-7'>
            <p>The TCB Pay international team is a dynamic group of professionals dedicated to revolutionizing global payments. With expertise across various regions and industries, they specialize in providing seamless, secure, and innovative payment solutions for businesses and consumers worldwide.</p>
            {/* <Link className="get-btn2" to="">Get Started</Link>
            <Link className="contact-btn1" to="">Contact Us</Link> */}
            </div>
          </Row>  
        </Container>
    </section>

    <section className='ourteam'>
        <Container>
            <Row>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-center">
                        <div className='teamlist'>
                        <img src='assets/imgs/team1.png' alt='' />
                        <h3>Benjamin Haguel</h3>
                        <p>Founder & CEO</p>
                        <span><Link to="https://www.linkedin.com/in/bhaguel/" target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-between">
                        <div className='teamlist'>
                        <img src='assets/imgs/team2.png' alt='' />
                        <h3>Eric Medina</h3>
                        <p>Managing Partner</p>
                        <span><Link to="https://www.linkedin.com/in/ericmedinajr/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team3.png' alt='' />
                        <h3>Skyler Muñoz</h3>
                        <p>Chief Operating Officer</p>
                        <span><Link to="https://www.linkedin.com/in/skyler-munoz-a696aa117/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team4.png' alt='' />
                        <h3>Subhajyoti Moulick</h3>
                        <p>Chief Technology Officer</p>
                        <span><Link to="https://www.linkedin.com/in/subhajyoti-krishnaghosh-moulick/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team5.png' alt='' />
                        <h3>Laurie Evina</h3>
                        <p>Chief Marketing Officer</p>
                        <span><Link to="https://www.linkedin.com/in/laurieanh/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-between">
                        <div className='teamlist'>
                        <img src='assets/imgs/team6.png' alt='' />
                        <h3>Tanmoy Mondal</h3>
                        <p>IT Team Lead</p>
                        <span><Link to="https://www.linkedin.com/in/tanmoy-mondal-99b516a"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team7.png' alt='' />
                        <h3>Krishnakanta Sarkar</h3>
                        <p>Senior Application Developer</p>
                        <span><Link to="https://www.linkedin.com/in/krishnakanta-sarkar-bb70a6b2"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team8.png' alt='' />
                        <h3>Georgina Santos</h3>
                        <p>Communications Associate</p>
                        <span><Link to="https://www.linkedin.com/in/georginasl/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team9.png' alt='' />
                        <h3>Goutam Karmakar</h3>
                        <p>Senior Application Developer</p>
                        <span><Link to="https://www.linkedin.com/in/goutam-karmakar-44a133270/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-between">
                        <div className='teamlist'>
                        <img src='assets/imgs/team10.png' alt='' />
                        <h3>Chris Stroud</h3>
                        <p>Sales Director</p>
                        <span><Link to="https://www.linkedin.com/in/chris-stroud-29b774229/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team11.png' alt='' />
                        <h3>Lindsey Redmond</h3>
                        <p>Operations Manager</p>
                        <span><Link to="https://www.linkedin.com/in/lindsey-redmond-514bbb271/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team12.png' alt='' />
                        <h3>Seth Medina</h3>
                        <p>Junior Software Developer</p>
                        <span><Link to="https://www.linkedin.com/in/seth-medina-921013291/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        {/* <div className='teamlist'>
                        <img src='assets/imgs/team13.png' alt='' />
                        <h3>Madhumita Paul</h3>
                        <p>Junior UI/UX Designer</p>
                        <span><Link to="https://www.linkedin.com/in/madhumita-paul-598334210"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div> */}
                        <div className='teamlist'>
                        <img src='assets/imgs/team14.png' alt='' />
                        <h3>Sharmistha Chatterjee</h3>
                        <p>Senior UI/UX Developer</p>
                        <span><Link to="https://www.linkedin.com/in/sharmistha-chatterjee-07755a54"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-between">
                        {/* <div className='teamlist'>
                        <img src='assets/imgs/team14.png' alt='' />
                        <h3>Sharmistha Chatterjee</h3>
                        <p>Senior UI/UX Developer</p>
                        <span><Link to="https://www.linkedin.com/in/sharmistha-chatterjee-07755a54"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div> */}
                        <div className='teamlist'>
                        <img src='assets/imgs/team15.png' alt='' />
                        <h3>Samiran Mondal</h3>
                        <p>Senior Application Developer</p>
                        <span><Link to="https://www.linkedin.com/in/samiran-mondal-9a935a248"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team16.png' alt='' />
                        <h3>Nathalia Medina</h3>
                        <p>Project Manager</p>
                        <span><Link to="https://www.linkedin.com/in/nathalia-medina-01a870311/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team17.png' alt='' />
                        <h3>Tanmoy Sau</h3>
                        <p>Android Application Developer</p>
                        <span><Link to="https://www.linkedin.com/in/tanmoy-sau-55a46711b/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team19.png' alt='' />
                        <h3>Johanna Aidan</h3>
                        <p>Digital Marketing Coordinator</p>
                        <span><Link to="https://www.linkedin.com/in/johanna-aidan-337553172/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="d-flex justify-content-between">
                        <div className='teamlist'>
                        <img src='assets/imgs/team20.png' alt='' />
                        <h3>Gabriel Medina</h3>
                        <p>Underwriter</p>
                        <span><Link to="https://www.linkedin.com/in/gabriel-medina-05a091288/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team21.png' alt='' />
                        <h3>Soumalya Nandy</h3>
                        <p>Server Admin</p>
                        <span><Link to="https://www.linkedin.com/in/soumalya-nandy/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist'>
                        <img src='assets/imgs/team22.png' alt='' />
                        <h3>Atanu Paul</h3>
                        <p>iOS Developer</p>
                        <span><Link to="https://www.linkedin.com/in/atanu-paul-5b3002189/"  target='_blank'><img src='assets/imgs/linkedin.svg' alt='' /></Link></span>
                        </div>
                        <div className='teamlist d-none d-sm-block'>
                        <div style={{opacity:"0"}}>
                        <img src='assets/imgs/team23.png' alt='' />
                        </div>
                        </div>
                    </div>
                </div>
            </Row> 
        </Container> 
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots"></img>
    </section>
    </>
  )
}
