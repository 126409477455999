import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink, useParams  } from 'react-router-dom';
import config from '../Config';

export const Whitel404Sec = () => {
  return (
    <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <div className="notfound-wrap">
                        <h2><img src={`${config.siteBaseUrl}/assets/imgs/w404.svg`} alt="w404" /></h2>
                    </div>
                </div>

                <div className="page-not-found page403">
                    <div className="notfound-wrap">
                        <h2>Sorry, Page Not Found</h2>
                        <p>The page you requested could not be found.</p>
                        <Link to="">Go Back</Link>
                    </div>
                </div>
            </div>
    </div>
  )
}
