import React, { useEffect, forwardRef } from 'react';
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const Calender = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.type = 'text/javascript';
        script.async = true;
        
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, [])

  return (
    <>
    <section ref={ref} className="effort-area-bottom efrt-bg">
            <div className="container">
                <div className="col-lg-12 m-auto">
                    <div className='calendly-inline-widget' data-url='https://calendly.com/chris-tcb/issuing?hide_gdpr_banner=1&primary_color=397ab7'></div> 
                </div>     
            </div>
    </section>


    <section className="nutshell">
                {/* <div className="bluedotsbg d-none d-lg-block">
           <img src='assets/imgs/bluedots.png' alt='' /> 
        </div> */}

        <div className="container">
            <div className='nutshell-inner'>
                <div className="row">
                    <div className="col-lg-12 mb-40">
                        <h6 className="seamless-heading">The <span>essence</span> of TCB Pay Issuing</h6>
                    </div>
                </div>
                <div className="row dksmls">
                    <div className='col-md-3'>
                        <div className='nutshell-list'>
                            <div className='deepblue'>
                                <div className='heading'>Card Issuing & Management</div>
                                <div className='listtxt'>
                                    <h6>Card Issuing</h6>
                                    <ul>
                                        <li>Instant digital card issuing</li>
                                        <li>7-10 days delivery for physical cards</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Card Format</h6>
                                    <ul>
                                        <li>Physical card </li>
                                        <li>Digital card</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Card Type</h6>
                                    <ul>
                                        <li>Credit cards</li>
                                        <li>Reloadable prepaid cards</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Account Type</h6>
                                    <ul>
                                        <li><b>Prepaid account</b></li>
                                        <li>Postpaid account</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Spending Profiles</h6>
                                    <ul>
                                        <li>Assign cards to a spending profile </li>
                                        <li>Weekly and monthly cycles </li>
                                        <li>Set cycle limit </li>
                                        <li>Set daily limit </li>
                                        <li>Set maximum amount per </li>
                                        <li>transaction</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Controls</h6>
                                    <ul>
                                        <li><b>Restrict card usage by MCC</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='nutshell-list'>
                            <div className='deepyellow'>
                                <div className='heading'>User & Platform Management</div>
                                <div className='listtxt'>
                                    <h6>Management Platform</h6>
                                    <ul>
                                        <li>View your spending  </li>
                                        <li>See your limits</li>
                                        <li>Dispute a transaction</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Admin Panel</h6>
                                    <ul>
                                        <li>View account balance </li>
                                        <li>ACH or wire funding</li>
                                        <li><b>Batch user creation</b></li>
                                        <li><b>Batch card assignment</b></li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>User Access</h6>
                                    <ul>
                                        <li>Multi-user capabilities </li>
                                        <li>Role management</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Mobile</h6>
                                    <ul>
                                        <li>Mobile-friendly view</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Change Logs</h6>
                                    <ul>
                                        <li>Card history</li>
                                        <li>User history</li>
                                        <li>Spending profile history</li>
                                        <li>Funding history</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='nutshell-list'>
                            <div className='skyblue'>
                                <div className='heading'>Security</div>
                                <div className='listtxt'>
                                    <h6>Card Security</h6>
                                    <ul>
                                        <li>Lock/unlock cards </li>
                                        <li>Card replacement option</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Account Security</h6>
                                    <ul>
                                        <li>2-factor authentication</li>
                                        <li><b>Notifications & alerts</b></li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Fraud Prevention</h6>
                                    <ul>
                                        <li>Automated spending alerts</li> 
                                        <li>AI-powered fraud detection</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Compliance</h6>
                                    <ul>
                                        <li>PCI DSS compliance </li> 
                                        <li>GDPR compliance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='nutshell-list'>
                            <div className='orange'>
                                <div className='heading'>Reporting & Fees</div>
                                <div className='listtxt'>
                                    <h6>Reporting</h6>
                                    <ul> 
                                        <li>Real-time transactions </li>
                                        <li>Spending by category </li>
                                        <li>Spending by user </li>
                                        <li>Refunds </li>
                                        <li>Transaction declined</li>
                                        <li>Export reports (CSV) </li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>100% Free*</h6>
                                    <ul>
                                        <li><b>Free TCB Pay cards</b></li>
                                        <li><b>No monthly fee</b></li>
                                        <li>Free account setup</li>
                                        <li>No commitment</li>
                                    </ul>
                                    <small><span>*</span> For physical cards, shipping & handling fees might apply. Virtual cards are 100% free.</small>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='row d-block d-sm-none'>
                    <div className="accordiontcbpotal" id="accordionExample">
                        <div className="accordion-item nutshell-list" style={{border: '1px solid #CCDAE6'}}>
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{background:'#397AB7'}}>
                                <div className='pricing-column'>
                                <div className='pricing-row-title'>Card Issuing & Management</div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            <div className='deepblue' style={{border:'0'}}>
                                <div className='listtxt'>
                                    <h6>Card Issuing</h6>
                                    <ul>
                                        <li>Instant digital card issuing</li>
                                        <li>7-10 days delivery for physical cards</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Card Format</h6>
                                    <ul>
                                        <li>Physical card </li>
                                        <li>Digital card</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Card Type</h6>
                                    <ul>
                                        <li>Credit cards</li>
                                        <li>Reloadable prepaid cards</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Account Type</h6>
                                    <ul>
                                        <li><b>Prepaid account</b></li>
                                        <li>Postpaid account</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Spending Profiles</h6>
                                    <ul>
                                        <li>Assign cards to a spending profile </li>
                                        <li>Weekly and monthly cycles </li>
                                        <li>Set cycle limit </li>
                                        <li>Set daily limit </li>
                                        <li>Set maximum amount per </li>
                                        <li>transaction</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Controls</h6>
                                    <ul>
                                        <li><b>Restrict card usage by MCC</b></li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item nutshell-list" style={{border: '1px solid #f0a611'}}>
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button deepyellow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{background:'#F0A611'}}>
                                <div className='pricing-column'>
                                <div className='pricing-row-title'>User & Platform Management</div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            <div className='deepyellow' style={{border:'0'}}>
                                <div className='listtxt'>
                                    <h6>Management Platform</h6>
                                    <ul>
                                        <li>View your spending  </li>
                                        <li>See your limits</li>
                                        <li>Dispute a transaction</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Admin Panel</h6>
                                    <ul>
                                        <li>View account balance </li>
                                        <li>ACH or wire funding</li>
                                        <li><b>Batch user creation</b></li>
                                        <li><b>Batch card assignment</b></li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>User Access</h6>
                                    <ul>
                                        <li>Multi-user capabilities </li>
                                        <li>Role management</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Mobile</h6>
                                    <ul>
                                        <li>Mobile-friendly view</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Change Logs</h6>
                                    <ul>
                                        <li>Card history</li>
                                        <li>User history</li>
                                        <li>Spending profile history</li>
                                        <li>Funding history</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item nutshell-list" style={{border: '1px solid #6da2d3'}}>
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button skyblue collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{background:'#6DA2D3'}}>
                                <div className='pricing-column'>
                                <div className='pricing-row-title'>Security</div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            <div className='skyblue' style={{border:'0'}}>
                                <div className='listtxt'>
                                    <h6>Card Security</h6>
                                    <ul>
                                        <li>Lock/unlock cards </li>
                                        <li>Card replacement option</li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Account Security</h6>
                                    <ul>
                                        <li>2-factor authentication</li>
                                        <li><b>Notifications & alerts</b></li>
                                    </ul>
                                </div>
                                <div className='listtxt'>
                                    <h6>Fraud Prevention</h6>
                                    <ul>
                                        <li>Automated spending alerts</li> 
                                        <li>AI-powered fraud detection</li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>Compliance</h6>
                                    <ul>
                                        <li>PCI DSS compliance </li> 
                                        <li>GDPR compliance</li>
                                    </ul>
                                </div>
                            </div>    
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item nutshell-list" style={{border: '1px solid #feca61'}}>
                            <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button orange heading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{background:'#FECA61'}}>
                                <div className='pricing-column'>
                                <div className='pricing-row-title'>Reporting & Fees</div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            <div className='orange' style={{border:'0'}}>
                                <div className='listtxt'>
                                    <h6>Reporting</h6>
                                    <ul> 
                                        <li>Real-time transactions </li>
                                        <li>Spending by category </li>
                                        <li>Spending by user </li>
                                        <li>Refunds </li>
                                        <li>Transaction declined</li>
                                        <li>Export reports (CSV) </li>
                                    </ul>
                                </div>
                                <div className='listtxt border-0'>
                                    <h6>100% Free*</h6>
                                    <ul>
                                        <li><b>Free TCB Pay cards</b></li>
                                        <li><b>No monthly fee</b></li>
                                        <li>Free account setup</li>
                                        <li>No commitment</li>
                                    </ul>
                                    <small><span>*</span> For physical cards, shipping & handling fees might apply. Virtual cards are 100% free.</small>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    </section>

            <section className="nutshell gradient-background2">
                <div className="wave5 d-none d-lg-block">
                    <img src={`${config.siteBaseUrl}/assets/imgs/wave-5a.png`} alt="" />
                </div>


                <div className="wave6 d-none d-lg-block">
                    <img src={`${config.siteBaseUrl}/assets/imgs/wave-6.png`} alt="" />
                </div>

            </section>


    <section className='streamlined-bx mb-180'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 order-sm-2 order-lg-1'><img src={`${config.siteBaseUrl}/assets/imgs/API-Integration.gif`} alt="" className='max-wd5'/></div>
                <div className='col-md-6 ps-4 order-sm-1 order-lg-2'>
                <h6 className="seamless-heading mb-40">Bring TCB Pay Issuing to your <span>own platform</span></h6>
                 <p className='mb-0'>With TCB Pay’s Issuing API, you can bring advanced card issuing and management directly into your platform. Design a solution that fits your business needs while benefiting from a secure, high-performance infrastructure.</p>
                
                 <div className="tracking-facility text-left d-none d-sm-block">
                        <div className="tracking-inr-box">
                            <img src={`${config.siteBaseUrl}/assets/imgs/issuing-apiicon1.svg`} alt="" />
                            <h6>Full Integration</h6>
                            <p>Embed TCB Pay’s card-issuing capabilities into your platform. Create and manage physical and digital cards, configure permissions, and monitor activity in one place.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src={`${config.siteBaseUrl}/assets/imgs/issuing-apiicon2.svg`} alt="" />
                            <h6>Customizable Features</h6>
                            <p>Set spending limits, define transaction permissions, and adjust card settings in real time. Access detailed API documentation and responsive support to simplify integration.</p>
                        </div>

                        <Link to="/integrations/issuing/add-users" className="integration-btn3 mt-10 ">Discover the Issuing API <FontAwesomeIcon icon={faArrowRight} /></Link>
                 </div>
                 <div className="tracking-facility text-left d-block d-sm-none">
                 <Link to="/integrations/issuing/add-users" className="integration-btn3 mb-40">Discover the Issuing API  <FontAwesomeIcon icon={faArrowRight} /></Link>
                        <div className="tracking-inr-box">
                            <img src={`${config.siteBaseUrl}/assets/imgs/issuing-apiicon1.svg`} alt="" />
                            <h6>Full Integration</h6>
                            <p>Embed TCB Pay’s card-issuing capabilities into your platform. Create and manage physical and digital cards, configure permissions, and monitor activity in one place.
                            </p>
                        </div>
                        <div className="tracking-inr-box">
                            <img src={`${config.siteBaseUrl}/assets/imgs/issuing-apiicon2.svg`} alt="" />
                            <h6>Customizable Features</h6>
                            <p>Set spending limits, define transaction permissions, and adjust card settings in real time. Access detailed API documentation and responsive support to simplify integration.</p>
                        </div>
                       
                 </div>
                 
                </div>
            </div>
        </div>
    </section>      


    </>
  );
});
