import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import config from '../Config';

export const Featuresbx = () => {
  return (
    <>
    <section className="featuresbx">
        <div className="container">
            <div className='row'>
                <div className='col-md-12'><h6>Features & benefits</h6></div>
            </div>
                
                <div className="featuresbx-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box">
                        <div className='imginerdiv'><img src={`${config.siteBaseUrl}/assets/imgs/feature-icon1.svg`} alt="" /></div>
                        <h4>Dedicated Support</h4>
                        <p>Our support team is available 24/7 to assist our merchants with unparalleled customer service.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box">
                        <div className='imginerdiv'><img src={`${config.siteBaseUrl}/assets/imgs/feature-icon2.svg`} alt="" /></div>
                            <h4>100% Transparency</h4>
                            <p>No commitment, no month-to-month agreements, and no setup or hidden fees. No surprises, just honest business.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box">
                        <div className='imginerdiv'><img src={`${config.siteBaseUrl}/assets/imgs/feature-icon3.svg`} alt="" /></div>
                            <h4>High Security</h4>
                            <p>Rest easy with industry-leading security tools and services to help safeguard your business against card data theft.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box">
                        <div className='imginerdiv'><img src={`${config.siteBaseUrl}/assets/imgs/feature-icon4.svg`} alt="" /></div>
                            <h4>Reliable Platform</h4>
                            <p>Enjoy TCB Pay’s highly stable platform with outage protection so you can run transactions whenever you need to.</p>
                        </div> 
                    </div>
                </div>
            </div>
            
            
        </div>
    </section>
    </>
  )
}
