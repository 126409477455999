import React from 'react'
import { LegalTermsAndConditionsSec } from './legalTermsAndConditionsSec'


export const LegalTermsAndConditions = () => {
  return (
   <>
   <LegalTermsAndConditionsSec />
   </>
  )
}
