import React from 'react'
import { LegalCodeOfConductSec } from './legalCodeOfConductSec'

export const LegalCodeOfConduct = () => {
  return (
   <>
   <LegalCodeOfConductSec />
   </>
  )
}
