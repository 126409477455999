import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';

export const LegalCookiesPolicySec = () => {

    const {seo_url} = useParams<any>();
        
    return (
        <>
        <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-start">











<div className="accordion mblegal-mnu" id="faqaccordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <NavLink to='' className="sidebar-linkmb" >
            <span className="align-middle">Cookies Policy</span>
        </NavLink>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
        <ul className="sidebar-nav groupaside">
            <li className="sidebar-item">
                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
            </li>
        </ul>
      
      </div>
    </div>
  </div>

</div>















                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                            </div>
                            <div className="tab-content">

                                <div className="tab-pane fade show active">
                                    <div className="cookie_policy-sec">
                                        <div className="container">
                                            <div className="title-sec">
                                                <h2><span>Cookies Policy</span></h2>
                                            </div>
                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="text2">
                                                        <p>
                                                            <b> Effective Date: </b>
                                                             February 25, 2025 
                                                        </p>
                                                        <p>
                                                        TCB Pay (“Company,” “we,” “us,” or “our”) uses cookies and similar tracking technologies to enhance your experience on our website. This policy explains how we use cookies, what types we use, and how you can control your preferences.
                                                        </p>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>What Are Cookies?</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                        Cookies are small text files stored on your device (computer, tablet, or mobile phone) when you visit a website. They help improve website functionality, enhance security, analyze usage patterns, and provide personalized experiences.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Types of Cookies We Use</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p>We use the following categories of cookies: </p>
                                                        <ul>
                                                            <li>Essential Cookies: These are necessary for the website to function and cannot be disabled. They include authentication, security, and accessibility-related cookies. </li>
                                                            <li>Analytics & Performance Cookies: These help us analyze website traffic, usage patterns, and improve our services. We use tools such as Google Analytics.</li>
                                                            <li>Functional Cookies: These enhance website functionality by remembering user preferences, such as language settings. </li>
                                                            <li>Marketing & Advertising Cookies: These track user activity to deliver personalized ads based on browsing behavior. Third-party advertisers may place these cookies.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>How We Use Cookies</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p>We use cookies to: </p>
                                                        <ul>
                                                            <li>Ensure the website operates properly.</li>
                                                            <li>Analyze and improve website performance.</li>
                                                            <li>Provide personalized user experiences.</li>
                                                            <li>Deliver targeted advertisements.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Third-Party Cookies</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>
                                                        We may allow third-party service providers (such as Google Analytics, Facebook, or advertising networks) to set cookies on our website. These providers help us analyze web traffic and deliver relevant advertisements.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Managing Cookie Preferences</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p>You can manage your cookie preferences in the following ways:</p>
                                                        <ul>
                                                            <li>Browser Settings: Adjust cookie settings in your web browser to accept, reject, or delete cookies.</li>
                                                            <li>Cookie Banner: Upon visiting our site, you will be given the option to accept or customize your cookie preferences.</li>
                                                            <li>Opt-Out of Tracking: You can opt out of certain third-party analytics and advertising cookies via tools such as the <Link to="https://thenai.org/" target='_blank' style={{color:'#397AB7'}}>Network Advertising Initiative</Link> or <Link to="https://youronlinechoices.eu/" target='_blank' style={{color:'#397AB7'}}>Your Online Choices.</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Retention of Cookies</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p>Cookies remain on your device for varying durations:</p>
                                                        <ul>
                                                            <li>Session Cookies: Deleted when you close your browser.</li>
                                                            <li>Persistent Cookies: Remain for a set period or until manually deleted.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5>Your Rights and Privacy</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p>Depending on your location, you may have rights under applicable data privacy laws (e.g., GDPR, CCPA). This includes the right to:</p>
                                                        <ul>
                                                            <li>Access your data.</li>
                                                            <li>Request deletion of personal data.</li>
                                                            <li>Withdraw cookie consent at any time.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>





                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5> Changes to This Policy</h5>
                                                    </div>
                                                    <div className="text2">
                                                        <p>We may update this Cookies Policy from time to time. Any changes will be posted on this page, and significant updates will be communicated through a notice on our website.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="privacy_p" className="text-left doc-content">
                                                <div className="pp-content">
                                                    <div className="pp-sub-head">
                                                        <h5> Contact Us</h5>
                                                    </div>
                                                    <div className="pp-hi">
                                                        <p className='mb-40'>If you have any questions about our Cookies Policy, contact us at:</p>
                                                        <address className="c-policy">
                                                            <p>
                                                                TCB Pay<br/>
                                                                433 Plaza Real Suite #275 Boca Raton, FL 33432<br/>
                                                                <span>  <strong> Email: </strong> </span> <Link to="mailto:info@tcbpay.com">info@tcbpay.com</Link> <br />
                                                                <span> <strong> Phone: </strong> </span> <Link to="tel:866-444-8585"> 866-444-8585 </Link> <br />
                                                                
                                                            </p> 
                                                        </address>
                                                        <p className='mt-30'>By continuing to use our website, you consent to the use of cookies as described in this policy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
        </section>
        </>
    )
}
