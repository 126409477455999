import React,{useState,useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';
import { LegalCodeConductFormSec } from './legalCodeConductFormSec';

export const LegalCodeOfConductSec = () => {

    const {seo_url} = useParams<any>();
        
    return (
        <>
        <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="d-flex align-items-start">











<div className="accordion mblegal-mnu" id="faqaccordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <NavLink to='' className="sidebar-linkmb" >
            <span className="align-middle">Code of Conduct</span>
        </NavLink>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
        <ul className="sidebar-nav groupaside">
            <li className="sidebar-item">
                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
            </li>
            <li className="sidebar-item">
                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
            </li>
        </ul>
      
      </div>
    </div>
  </div>

</div>















                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <NavLink to={`/terms-and-conditions`} className="nav-link" >Terms and Conditions</NavLink>
                                <NavLink to={`/privacy-policy`} className="nav-link" >Privacy Policy</NavLink>
                                <NavLink to={`/payfac-terms-and-conditions`} className="nav-link" >Payfac Terms and Conditions</NavLink>
                                <NavLink to={`/code-of-conduct`} className="nav-link" >Code of Conduct</NavLink>
                                <NavLink to={`/cookies-policy`} className="nav-link" >Cookies Policy</NavLink>
                                <NavLink to={`/personal-guarantee`} className="nav-link" >Personal Guarantee</NavLink>
                                <NavLink to={`/ach-terms-and-conditions`} className="nav-link" >ACH Terms and Conditions</NavLink>
                            </div>
                            <div className="tab-content">

                                <div className="tab-pane fade show active">
                                    <div className="code-of-conduct-sec">
                                        <div className="container">
                                            <div className="title-sec">
                                                <h2><span> Code of Conduct </span></h2>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row-pad">
                                                <div className="text2">
                                                    <div className="text-left text-hi">
                                                        <p>If you have a concern pertaining to the Code of Conduct for the Credit and Debit Card Industry, you may contact us through a variety of channels:</p>
                                                    </div>
                                                    <address className="mt-4 text-left">
                                                        <p>
                                                            <span>Our Toll-free number:</span> <Link to="tel:+1-866-444-8585">+1-866-444-8585</Link><br />
                                                            <span>E-mail:</span> <Link to="mailto:complaints@tcbpay.com">complaints@tcbpay.com </Link> <br />
                                                            <span>Mail:</span> 10 Four Seasons Place, Suite 1000 Toronto, ON M9B 6H7
                                                        </p>
                                                    </address>

                                                    <div className="text-left text-hi">
                                                        <p>
                                                            To assist us in reviewing your complaint, please provide the following, where applicable: A summary of your concerns, details, such as the name of the person you were dealing with, the
                                                            date the concern occurred, date spoken to our representative, copies of any supporting documentation ( i.e. agreements, statements, correspondence )
                                                        </p>
                                                        <p>Or complete this form:</p>
                                                    </div>

                                                    <LegalCodeConductFormSec/>

                                                </div>
                                            </div>

                                            <div className="row center-block">
                                                <div className="col-sm-12">
                                                    <form id="cocForm" name="form" className="form-frame partnership-form mb-4 mt-4" >
                                                        
                                                        
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="row-pad">
                                                <div className="text2">
                                                    <div className="text-left">
                                                        <p>Please visit the Financial Consumer Agency of Canada's website for more information.</p>
                                                    </div>
                                                    <div className="text-left">
                                                        <p>
                                                            If you believe TCB Pay's conduct is contrary to the Code of Conduct for the Credit and Debit Card Industry and feel your concerns regarding our products or services have not been
                                                            adequately addressed, you may report the issue directly with the Financial Consumer Agency of Canada (FCAC) to file a Code of Conduct Complaint.
                                                        </p>
                                                    </div>
                                                    <address className="mb-0 text-left">
                                                        <p className="mt-4">
                                                            <span>E-mail:</span> <Link to="mailto:info@fcac-acfc.gc.ca"> info@fcac-acfc.gc.ca </Link> <br />
                                                            <span>Telephone:</span> <Link to="tel:+1-855-823-8783"> +1-855-823-8783 </Link>
                                                        </p>
                                                        <p className="mt-0 write-to d-flex">
                                                            <span>Write to:</span>
                                                            <span>
                                                                Financial Consumer Agency of Canada (FCAC) <br />
                                                                6th Floor, Enterprise Building <br />
                                                                427 Laurier Ave. West <br />
                                                                Ottawa, ON K1R 1B9
                                                            </span>
                                                        </p>
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
        </section>
        </>
    )
}
