import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import config from '../Config';


export const InscryptionsSec = () => {
  return (
    <>
    <section className='legal-bx tc-pp-cc-cp-pg'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} className='legalbg' alt="" />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="d-flex align-items-start">
                        <div className="privacy_policy-sec p-0">
                                <div className="title-sec pb-20">
                                    <h2 className='text-left text-bold'><span>Cardholder Data Management Policy for TCB PAY LTD</span></h2>
                                </div>

                                <div id="privacy_p" className="text-left doc-content">
                                    <div className="pp-content">
                                        <div className="text2">
                                            <h4>Introduction</h4>
                                            <p>
                                            At TCB PAY LTD, securing cardholder data (CHD) is a top priority. This 
                                            policy defines how CHD is encrypted, stored, accessed, and securely disposed of to comply with PCI DSS and financial security standards.
                                            </p>
                                        </div>
                                        
                                        <div className="pp-sub-head">
                                            <h5>Scope</h5>
                                        </div>
                                        <div className="pp-hi">
                                            <p>This policy applies to:</p>
                                            <ul>
                                                <li>All employees, vendors, and systems handling cardholder data.</li>
                                                <li>All payment processing applications, databases, and secure vaults storing CHD.</li>
                                                <li>Encryption mechanisms, access management, and compliance regulations.</li>
                                            </ul>
                                        </div>

                                        <div className="pp-sub-head">
                                            <h5>Adding Cardholder Data</h5>
                                        </div>
                                        <div className="pp-hi">
                                            <p><b>Secure Encryption Process</b></p>
                                            <p>Card data is encrypted before storage using four encryption 
                                            algorithms, selected using the RAND function.</p>
                                            <p> Two key values which are encrypted by master key are generated:</p>
                                            <ul>
                                                <li>One stored at the database level.</li>
                                                <li>One stored at the server level.</li>
                                                <li> Encryption keys are root password-protected and secured with
                                                strict access controls.</li>
                                            </ul>
                                        </div>

                                        <div className="pp-sub-head">
                                            <h5>Decryption & Key Management</h5>
                                        </div>
                                        <div className="pp-hi">
                                            <ul>
                                                <li> The two encrypted values merge to generate a unique key for 
                                                decryption. After that we encrypt the card information using AES-256-OFB,
                                                AES-128-CTR, aes-256-cbc, AES-128-CFB8 methods.</li>
                                                <li>Only Super Admin users can view the full card number; normal 
                                                users see only masked data.</li>
                                                <li>Decrypted values are never stored in plaintext and are 
                                                generated only when required.</li>
                                            </ul>
                                        </div>


                                        <div className="pp-sub-head">
                                            <h5>Card Data Retention & Disposal</h5>
                                        </div>
                                        <div className="pp-hi">
                                            <p><b>Retention Policy</b></p>
                                            <ul>
                                                <li>Card data is stored only until the expiration date.</li>
                                                <li>Automated checks identify expired cards for removal.</li>
                                                <li>Data is retained strictly as per PCI DSS requirements.</li>
                                            </ul>
                                        </div>
                                        <div className="pp-hi">
                                            <p><b>Secure Data Disposal</b></p>
                                            <p>Upon expiration, the following card details are securely deleted</p>
                                            <ul>
                                                <li>CVV (Card Verification Value)</li>
                                                <li>PAN (Primary Account Number - Card Number)</li>
                                                <li>Expiry Date <br />
                                                <ul>
                                                    <li>Secure deletion ensures data is unrecoverable.</li>
                                                    <li>Deletion logs are maintained for audit compliance.</li>
                                                </ul>
                                                </li>                                              
                                            </ul>
                                        </div>


                                        <div className="pp-sub-head">
                                            <h5>Security & Compliance Measures</h5>
                                        </div>
                                        <div className="pp-hi">
                                            <ul>
                                                <li>Data Masking: Only the last four digits of the card are visible to normal users.</li>
                                                <li>Encryption in Transit & At Rest: TLS 1.2+ for transmission, AES-256 for storage.</li>
                                                <li>Role-Based Access Control (RBAC): Only authorized personnel can access CHD.</li>
                                                <li>Automated Audits & Logging: All access and deletions are logged.</li>
                                                <li>Quarterly Security Assessments: Regular penetration testing & compliance audits.</li>
                                            </ul>
                                        </div>

                                        <div className="pp-sub-head mt-30">
                                            <h3>Conclusion</h3>
                                        </div>
                                        <div className="pp-hi">
                                            <p>By implementing this Cardholder Data Management Policy, TCB PAY 
                                            LTD ensures:</p>
                                            <ul>
                                                <li>Strong encryption & secure data storage.</li>
                                                <li>Strict access control & monitoring.</li>
                                                <li>Compliance with PCI DSS & financial regulations.</li>
                                                <li>A structured process for secure card data disposal.</li> 
                                            </ul>
                                            <p>This policy is mandatory for all employees, system 
                                            administrators, and vendors handling cardholder data.</p>
                                        </div>

                                    </div>
                                </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
