import React from 'react'
import { LegalPrivacyPolicySec } from './legalPrivacyPolicySec'

export const LegalPrivacyPolicy = () => {
  return (
   <>
   <LegalPrivacyPolicySec />
   </>
  )
}
