import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='bghgt'/>
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span><img src='assets/imgs/s-icon.svg' alt='' />SECURITY</span>
            <h1>Run your business safely and stress-free</h1>
            </Col>
            <Col xs lg="7" className='text-left'>
            <p>At TCB Pay, security is our top priority. We prioritize the protection of our merchants’ data above all else. Our platform is fortified with the latest security features, updated regularly to ensure the highest level of security for our merchants’ peace of mind.</p>
            {/* <Link className="get-btn2" to={`${config.siteBaseUrl}#show-calendly`}>Get Started</Link> */}
            <Link to="https://portal.tcbpay.com/app/signup" target="_blank" className="get-btn2">Get Started</Link>
            <Link className="contact-btn1" to={`${config.siteBaseUrl}/contact`}>Contact Us</Link>
            </Col>
          </Row>  
        </Container>
    </section>
    </>
  )
}
