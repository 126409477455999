import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const Faq = () => {
  return (
    <>
    <section className='faqbx'>
       <img src="assets/imgs/orng-bg.png" alt=""  className='orng-dots'/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='faq-lft'>
                    <h6 className="seamless-heading mb-30">FAQ</h6>
                        <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How do I use my card?</Accordion.Header>
                            <Accordion.Body>
                            You may swipe, insert, or tap the card anywhere Mastercard © is accepted. You may also add the card to your mobile wallet and pay with your phone.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Where can I use my card?</Accordion.Header>
                            <Accordion.Body>
                            Your TCB Pay card is accepted at a multitude of Mastercard locations worldwide. It includes customizable controls at the card level, allowing your company to specify limits on where, when, and how the card is used. For detailed guidelines on your card's usage for business expenses, please consult with your TCB Pay program administrator.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How can I get a TCB Pay corporate card?</Accordion.Header>
                            <Accordion.Body>
                            To obtain a corporate card, get in touch with our Sales team which will submit a TCB Pay Issuing application on your behalf. This process typically requires submitting your business's financial information, including credit history, financial statements, and tax identification. Once approved, your company is set up with an account, and you can start distributing cards to designated employees.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What is the advantage of choosing TCB Pay Issuing?</Accordion.Header>
                            <Accordion.Body>
                            TCB Pay's corporate card offers benefits such as efficient expense management, instant reporting, and potential savings. Their platform is tailored to help businesses control their expenditures, automate expense reporting, and provide valuable financial insights.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>What is a corporate card?</Accordion.Header>
                            <Accordion.Body>
                            A corporate card is a specialized credit card issued to employees for charging approved business-related expenses. It's linked to the company's account, facilitating the management of corporate expenditures and simplifying the purchasing and accounting processes within the organization.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Why switch to a corporate card?</Accordion.Header>
                            <Accordion.Body>
                            Opting for a corporate card streamlines expense management, offering a clearer track of spending, reducing the necessity for cash advances, and enhancing adherence to company spending policies.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>How much does the TCB Pay corporate card cost?</Accordion.Header>
                            <Accordion.Body>
                            The good news is… TCB Pay Issuing won’t cost you anything! The TCB Pay corporate card is offered without any monthly or annual fees, making it a cost-effective solution for business expense management.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Will applying impact my personal credit score?</Accordion.Header>
                            <Accordion.Body>
                            Generally, a corporate card application involves a credit inquiry on the business rather than the individual, so it shouldn't affect your personal credit score. However, it's wise to confirm with TCB Pay to understand their specific credit assessment process.
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </div>
                    
                </div>
                <div className='col-md-6'>
                    <div className='faq-rgt'>
                      <img src="assets/imgs/faq-pic.webp" alt="" />
                      <div className='innerbx'>
                        <h6>User guide</h6>
                      <p>Explore the <b>TCB Pay Issuing Wiki</b> for an all-encompassing guide on how to navigate and utilize our platform.</p>
                      <Link className='wiki-btn' to="/wiki-issuing">Go to TCB Pay Issuing Wiki <FontAwesomeIcon icon={faArrowRight} /></Link>
                      </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
