import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const Achrefund = () => {
const exampleCodeText_4 = `<form name="" action="https://gateway2-stg.tcbpay.com/apis/ach_refund.php" method="post">
<input type="text" name="username" value=" tcbpayusername"/>
<input type="text" name="api_key" value=" tcbpayapikey"/>
<input type="text" name="transaction_id" value=""/>
<input type="text" name="amount" value="14.5"/>
<input type="checkbox" name="email_receipt" value="Y"/>
<br/>
<input type="submit" value="submit"/>
</form>`;
        const exampleCodeText_5 = `{"response":{"code":"01","description":" Merchant Information is Missing"}}`;
    
        const onCopy = React.useCallback((id:number) => {
            const element = document.getElementById("myTooltip"+id);
            if (element) {
              element.classList.add("show");
              const intervalId = setInterval(() => {
                element.classList.remove("show");
                clearInterval(intervalId);
              }, 2000);
            }
          }, []);
    
      return (
        <section className="credit-api-main">
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
          <div className="container">
              <div className="row">
                  <div className="col-lg-3">
                      <IntrigrationLeftSec />
                  </div>
                  <div className="col-lg-9">
                      <div className="creadit-api-upper-wrapper">
                            <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                          {/* <h2>ACH API</h2>
                          <ul className="card-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>URL To Call: </p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_sale.php</Link></span>
                                  </div>
                                
                              </li>
                              
                              <li>
                                  <div className="card-url-list">
                                      <p>Example:</p>
                                      <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                  </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Production endpoint URL:</p>
                                      <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                  </div>
                              </li>
                          </ul> */}
                      </div>
    
                      <div className="creadit-api-lower-wrapper">
                          <div className="api-menu-item-title">
                              <h2>ACH</h2>
                          </div>
                          <div className="api-menu-item-wrapper">
                              <h3>Refund API</h3>
                              <hr />
                              <p>This API is responsible for refunding an existing successful transaction.</p>
                              <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox endpoint URL:</p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/ach_refund.php </Link></span>
                                  </div>
                                
                              </li>

                              <li>
                                  <div className="card-url-list">
                                      <p>Production endpoint URL</p>
                                      <span><Link to=''>{config.gatewayApisUrlProd}/apis/ach_refund.php</Link></span>
                                 </div>
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method of form submission</p>
                                      <span><Link to=''>POST</Link></span>
                                 </div>
                              </li>
                              <br/>
                              </ul>
                          </div>
                      </div>
    
                      <div className="api-table">
                          <h5>This table describes the request parameters for Refund API.</h5>
    
                          <div className="table-responsive">
                                <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                    <th style={{ width: '100px' }}>Variable Name</th>
                                    <th style={{ width: '420px' }}>Description</th>
                                    <th style={{ width: '100px' }}>Required</th>
                                    <th style={{ width: '130px' }}>Expected Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>username</td>
                                    <td>TCB Pay Gateway Username</td>
                                    <td>Y</td>
                                    <td>TCB Pay Gateway Username</td>
                                    </tr>
                                    <tr>
                                    <td>api_key</td>
                                    <td>TCB Pay Gateway API Key</td>
                                    <td>Y</td>
                                    <td>TCB Pay Gateway API Key</td>
                                    </tr>
                                    <tr>
                                    <td>transaction_id</td>
                                    <td>Unique transaction ID generated by gateway</td>
                                    <td>Y</td>
                                    <td>-</td>
                                    </tr>
                                    <tr>
                                    <td>amount</td>
                                    <td>Amount to be charged, &lt;= original transaction amount</td>
                                    <td>Y</td>
                                    <td>133.24</td>
                                    </tr>
                                    <tr>
                                    <td>email_receipt</td>
                                    <td>Send transaction details to the specified email during authorization</td>
                                    <td>N</td>
                                    <td>Y or N (default value N) 
                                        Y- Send email 
                                        N- Not send 
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                          </div>
    
                          <div className="example-box-wrapper">
                              <div className="d-flex align-items-start">
                                  <b></b>
                                 <p><span>Example</span></p>
                              </div>
                              <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip4">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                   
                                </div>
    
                                <div className="ex-text-box" id="ExampleCodeText_4">
                                    <pre>{exampleCodeText_4}</pre>
                                </div>
        
                                </div>
                          </div>
                      </div>
    
                      
                          <div className="api-menu-item-wrapper mt-60">
                              <h3>Response:</h3>
                              <hr />
                              <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, and transaction_id. The response code is always 00 for a successful API call.</p>
                              <div className="example-box-wrapper mb-20">
                                  <div className="d-flex align-items-start">
                                      <b></b>
                                      <span>Sample Response :</span>
                                  </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip5">
                                    <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                    <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    
                                    </div>
    
                                    <div className="ex-text-box" id="ExampleCodeText_5">
                                        <pre>{exampleCodeText_5}</pre>
                                    </div>
        
                                </div>
                              </div>
                          </div>
                   
                       
    
    
                  </div>
              </div>
          </div>
          <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
          <ScrollToTop smooth />
        </section>
      )
    }    