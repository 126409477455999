import React from 'react'
import { FaqTcbIssuingSec } from './faqTcbIssuingSec'

export const FaqIssuing = () => {
  return (
    <>
    <FaqTcbIssuingSec />
    </>
  )
}
