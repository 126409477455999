import React from 'react'
import { FaqAccountSec } from './faqAccountSec'

export const FaqAccount = () => {
  return (
    <>
    <FaqAccountSec />
    </>
  )
}
